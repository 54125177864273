import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IosShare from '@mui/icons-material/IosShare';

const CopyToClipboardButton = ({
  copyLink,
  buttonProps,
}: {
  copyLink: string;
  buttonProps: ButtonProps;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
    if (copyLink) {
      navigator?.clipboard?.writeText?.(copyLink);
    }
  };

  const { sx, ...restButtonProps } = buttonProps;

  // Helper function to apply !important
  const applyImportant = (styles: Record<string, any>) =>
    Object.keys(styles).reduce((acc, key) => {
      acc[key] = `${styles[key]} !important`;
      return acc;
    }, {} as Record<string, any>);

  return (
    <>
      <Button
        id="button-share-persona"
        sx={{
          ...applyImportant({
            width: '42px', // Fixed width
            height: '42px', // Fixed height
            borderRadius: '130px', // Circular design
            border: '1.08px solid',
            borderColor: 'primary.main',
            backgroundColor: 'background.default',
            boxShadow: `
              0px 1px 4px rgba(0, 0, 0, 0.1),
              0px 1px 4px rgba(0, 0, 0, 0.1)
            `,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: 'secondary.light', // Optional hover effect
            },
          
          ...sx,
        }),
        }}
        onClick={handleClick}
        {...restButtonProps}
      >
        <IosShare />
      </Button>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  );
};

export default CopyToClipboardButton;
