import { Box } from "@mui/material";
import Banner from "./banner";
import FeaturedCreators from "./featuredCreators";
import FeaturedCourses from "./featuredCourses";
import { useAuth } from "../../hooks/useAuth";
import CallToAction from "./callToAction";

const LandingPage: React.FC = () => {
  const { authUser, authLoading } = useAuth();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        pb: 3,
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        sx={{
          maxWidth: {
            xl: '1200px',
          },
          px: {
            xs: 0,
            md: 2.5
          }
        }}
        gap={5}
      >
        <Banner />
        <FeaturedCreators />
        <FeaturedCourses />

        {
          ((!authUser && !authLoading) ||
           (authUser && authUser?.userType === 'fan')) &&
          <CallToAction />
        }
      </Box>
    </Box>
  )
}

export default LandingPage;
