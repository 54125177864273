import { Box, Button } from "@mui/material"
import { genericFormProps } from "../signup.types"
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const Footer: React.FC<genericFormProps> = (props) => {
  const {
    values,
    formSubmitting,
    isValid,
    dirty,
    validationSchema,
  } = props;

  const { authUser, authLoading } = useAuth();
  const navigate = useNavigate();

  return (
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-end'
      width='100%'
      borderTop={0.75}
      pt={1.5}
      sx={{
        borderColor: (theme) => theme.palette.divider
      }}
    >
      <Box
        display='flex'
        gap={1.25}
      >
        <Button
          variant="outlined"
          size='large'
          sx={{
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={() => {
            navigate(`/${authUser.userName}`)
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size='large'
          type='submit'
          disabled={!isValid || !dirty || formSubmitting || !authUser || authLoading}
          sx={{
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
        >
          {!!authUser?.index ? 'Save' : 'Next'}
        </Button>
      </Box>

    </Box>
  )
}
