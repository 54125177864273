
import { SVGProps, memo } from 'react';
const MycoursesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="15" height="20" viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M11.4709 0.500008C12.0232 0.500004 12.4709 0.947721 12.4709 1.50001V15.4495C12.4709 16.0017 12.0232 16.4495 11.4709 16.4495H3.18415C2.78108 16.4495 2.54367 16.9019 2.77273 17.2336L2.95633 17.4995C3.14304 17.7698 3.45063 17.9312 3.77919 17.9312L12.4822 17.9311C13.0345 17.9311 13.4822 17.4834 13.4822 16.9311V2.04292C13.4822 1.62406 13.8217 1.2845 14.2406 1.2845C14.6595 1.2845 14.999 1.62406 14.999 2.04292V18.4999C14.999 19.0522 14.5513 19.4999 13.999 19.4999L2.99858 19.5C2.67002 19.5 2.36243 19.3386 2.17572 19.0683L0.17618 16.1729C0.0608135 16.0059 -0.000976562 15.8077 -0.000976562 15.6047V1.5001C-0.000976562 0.947814 0.446732 0.5001 0.999014 0.500096L11.4709 0.500008ZM2.51588 14.8807C1.96359 14.8807 1.51588 14.4329 1.51588 13.8807V3.06888C1.51588 2.5166 1.96359 2.06889 2.51587 2.06888L9.95407 2.06882C10.5064 2.06882 10.9541 2.51653 10.9541 3.06882V13.8807C10.9541 14.433 10.5064 14.8807 9.95408 14.8807H2.51588Z" fill={props?.color || "#424242"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M8.51026 5.96787C8.51026 5.69173 8.2864 5.46787 8.01026 5.46787H4.4597C4.18356 5.46787 3.9597 5.69173 3.9597 5.96787V7.0596C3.9597 7.33574 4.18355 7.5596 4.4597 7.5596H8.01026C8.2864 7.5596 8.51026 7.33574 8.51026 7.0596V5.96787ZM3.44284 3.89907C2.89056 3.89907 2.44284 4.34679 2.44284 4.89907V8.1284C2.44284 8.68069 2.89056 9.1284 3.44284 9.1284H9.02711C9.5794 9.1284 10.0271 8.68068 10.0271 8.1284V4.89907C10.0271 4.34679 9.5794 3.89907 9.02711 3.89907H3.44284Z" fill={props?.color || "#424242"}/>
</svg>



);

export default memo(MycoursesIcon)
