import { Avatar, Badge, Box, Button, CircularProgress, ListItemIcon, TextField, Typography, useTheme } from "@mui/material";
import { toast } from 'react-toastify';
import { useCallback, useEffect, useState } from "react";
import SoopraLogo from "../../svg/SoopraLogo";
import { useAuth } from "../../hooks/useAuth";
import { LoginFormProps } from ".";
import { useDropzone } from 'react-dropzone';
import { SUPPORTED_IMAGE_MIME_TYPES } from '../../config/const';
import AddIcon from '@mui/icons-material/Add';
import AccountIcon from "../../svg/AccountIcon";
import { UserCreateProfileFormType } from "../creatorOnBoarding/signup.types";

const DEFAULT_ERROR_STATE = {fullName: null, userName: null};
Object.freeze(DEFAULT_ERROR_STATE);

const SetupAccount: React.FC<LoginFormProps> = ({setFormType}) => {
  const [ fullName, setFullName ] = useState<string>("");
  const [ userName, setUserName ] = useState<string>("");
  const [ profilePic, setProfilePic ] = useState(null);
  const [ errors, setErrors ] = useState(DEFAULT_ERROR_STATE);
  const [ isSubmitting, setIsSubmitting ] = useState<boolean>(false);
  const theme = useTheme();
  const { authUser, checkUserExistWithUsername, updateCreatedProfileData } = useAuth();

  useEffect(() => {
    // clear errors on mount and with changing authUser
    setErrors(DEFAULT_ERROR_STATE)

    if (authUser?.documentId) {
      // preload some defaults
      if (authUser.fullName) {
        setFullName(authUser.fullName);
        setUserName(authUser?.fullName?.toLowerCase().replace(/ /g, '_') || '')
      }
      if (authUser.profilePicUrl) { setProfilePic(authUser.profilePicUrl) };
    }
  }, [authUser])


  const handleSubmit = async () => {
    setErrors(DEFAULT_ERROR_STATE);
    if (fullName && userName) {

      // check userName doesnt have spaces
      let validation = /^(?=[a-zA-Z0-9._]{2,29}$)(?!.*[_.]{2})[^_.].*[^_.]$/g;
      if (!validation.test(userName)) {
        setErrors({fullName: null, userName: 'Please enter valid username'});
        return;
      }

      setIsSubmitting(true);
      const isUserNameExists = await checkUserExistWithUsername(userName);
      if (isUserNameExists) {
        toast.error('Account with this username already exists');
        let err = {fullName: null, userName: 'Account with this username already exists'};
        setErrors(err);
        setIsSubmitting(false);
        return;
      }

      let userFormData: UserCreateProfileFormType = {
        documentId: authUser.documentId,
        fullName: fullName,
        userName: userName,
        profilePhoto: profilePic,
      }

      updateCreatedProfileData(userFormData, 'create')
        .then(() => {
          toast.success('Profile created successfully');
          setFormType('accountcreated')
        })
        .catch(() => {
          toast.error('Sorry there was an error. Please try again later');
        })
        .finally(() => {
          setIsSubmitting(false);
        })
    } else {
      let err = {fullName: null, userName: null};
      if (!fullName) err.fullName = 'Full Name is required';
      if (!userName) err.userName = 'Username is required';
      setErrors(err)
    }
  }

  const fileValidator = (file: any) => {
    if (!SUPPORTED_IMAGE_MIME_TYPES.includes(file?.type)) {
      return false;
    }
    return true;
  };

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      // console.log(acceptedFiles)
      const isValid = fileValidator(acceptedFiles[0]);
      if (!isValid) {
        toast.error('Please select a valid profile picture');
      }
      if (isValid) {
        setProfilePic(acceptedFiles[0]);
      }
    }, []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png'],
    },
    maxFiles: 1,
  });

  const avatarInput = useCallback(() => {
    const profileUploaded =
      (profilePic && typeof profilePic === 'object' && profilePic?.name)
        ? URL.createObjectURL(profilePic)
        : (profilePic && typeof profilePic === 'string' )
        ? profilePic
        : '';

    return (
      <Box
        borderRadius={1}
        display='flex'
        gap={1}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='100%'
        height='147px'
        sx={{
          border: 1,
          borderStyle: isDragActive ? 'dashed' : 'solid',
          borderColor: (theme) => isDragActive ?
            theme.palette.action.active :
            theme.palette.primary._states.focusVisible,
          cursor: 'pointer',
          "&:hover": {
            borderColor: (theme) => theme.palette.action.active,
          },
        }}
        {...getRootProps({
          onClick: event => event.preventDefault(),
        })}
      >
        <Box // pseudo-element just for darkening background when isDragActive
          position='absolute'
          width='calc(100% - 64px)'
          height='147px'
          borderRadius={1}
          sx={{
            boxShadow: '0 0 0 1600px rgba(0,0,0,0.15)',
            zIndex: 1000,
            opacity: isDragActive ? 1 : 0,
            transition: 'opacity 0.1s ease-in-out',
          }}
        />
        <input
          type='file'
          accept='image/*,image/heif,image/heic'
          id='file-input-profile-photo'
          name='profilePhoto'
          hidden
          {...getInputProps(
            {
              onClick: event => event.stopPropagation(),
            }
          )}
        />
        <label htmlFor='file-input-profile-photo'>
        <Box
          display="flex"
          alignItems="center"
          gap={2} // Add space between avatar and text
          width="100%"
          sx={{
            cursor: 'pointer',
          }}
        >
          {
            !profileUploaded ?
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <AddIcon
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    color: (theme) => theme.palette.primary.contrastText,
                    borderRadius: '100%'
                  }}
                />
              }
              sx={{
                cursor: 'pointer',
              }}
            >
              <ListItemIcon
                sx={{
                  backgroundColor: (theme) => theme.palette.action.disabledBackground,
                  color: (theme) => theme.palette.primary.main,
                  width: 73,
                  height: 73,
                  borderRadius: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AccountIcon color='inherit' fill='inherit' width='40px' height='40px'/>
              </ListItemIcon>
            </Badge> :
            <Avatar
              alt={fullName}
              src={profileUploaded}
              sx={{
                width: 100,
                height: 100,
                border: '2px solid #fff',
                borderRadius: '35%',
                objectFit: 'cover',
              }}
            >
              {fullName ? fullName[0] : ''}
            </Avatar>
          }
        </Box>
        </label>
        <Typography variant='body2'
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          {!!profileUploaded ? 'Edit Avatar' : 'Add Avatar'}
        </Typography>
      </Box>
    )
  }, [profilePic, isDragActive]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      p={4}
      display='flex'
      flexDirection='column'
      width='100%'
      maxWidth={'sm'}
    >
      <Box
        display='flex'
        justifyContent='center'
        alignItems='flex-start'
        pb={3}
        width='100%'
      >
        <SoopraLogo color={theme.palette.primary.main} width='145px' height='42px'/>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        // gap={1}
        py={1}
      >
        <Typography variant="h5" textAlign='center'>
          Create Account
        </Typography>
        <Typography variant="body2" textAlign='center'
          sx={{
            color: (theme) => theme.palette.text.secondary
          }}
        >
          Add your details to personalize your account.
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
        py={2}
      >
        {avatarInput()}
        <TextField
          type='text'
          fullWidth
          margin='none'
          label='Full Name'
          placeholder="Full Name"
          value={fullName}
          error={!!errors.fullName}
          helperText={errors.fullName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setFullName(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSubmit();
            }
          }}
          autoComplete='off'
        />
        <TextField
          type='text'
          fullWidth
          margin='none'
          label='Username'
          placeholder="Username"
          value={userName}
          error={!!errors.userName}
          helperText={errors.userName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserName(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              handleSubmit();
            }
          }}
          autoComplete='off'
        />
        <Button
          variant="contained"
          size="large"
          sx={{
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          disabled={!userName || !fullName}
          onClick={handleSubmit}
          startIcon={
            <Box display='flex' alignItems='center'>
              {isSubmitting && (
                <CircularProgress
                  size={20}
                  color='inherit'
                  sx={{
                    marginRight: 2,
                    marginLeft: -4.5,
                  }}
                />
              )}
            </Box>
          }
        >
          Continue
        </Button>
      </Box>

    </Box>
  )
}

export default SetupAccount;
