import React, { useRef, useEffect, useState } from "react";
import { useLocation, useParams, useNavigate, Navigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material/';
import Avatar from '@mui/material/Avatar';
import { useAuth } from '../../hooks/useAuth';
import DefaultUserImage from '../../assets/images/default-avatar.png';
import { COURSE_PRICES, STRIPE_PRODUCT_LOOKUP_KEYS } from "../../config/const";
import { fetchResultsByStudentAndCourse, getCourseInfo } from "../../utils/api";
import StripeCheckoutForm from "../StripeCheckoutForm";

const showCourses = process.env.REACT_APP_SHOW_MY_COURSE === "true";
const enableDevTools = process.env.REACT_APP_ENABLE_DEVTOOLS === 'true';
const allowTakeCourse = process.env.REACT_APP_ALLOW_TAKE_COURSE === "true";

const CourseDetails: React.FC = () => {
  const location = useLocation()
  const { courseId } = useParams();
  const isLoading = useRef<boolean>(true);
  const [courseItem, setCourseItem] = useState(null);
  let [ isStripeOpen, setIsStripeOpen ] = useState<boolean>(false);
  let [ stripeItem, setStripeItem ] = useState(null);
  let [ stripeItemId, setStripeItemId ] = useState(null);
  const [ isEnrolled, setIsEnrolled ] = useState(false);
  const navigate = useNavigate();
  const { authUser, authLoading, setLoginModalVisible } = useAuth();

  useEffect(() => {
    if ((showCourses || authUser?.beta_tester)){
      loadCourseData();
      if (authUser?.uid){
        fetchResultsByStudentAndCourse(authUser?.uid, courseId)
          .then((results) => {
            let enrolledCourses = results.map((result: any) => result.courseId )
            setIsEnrolled(enrolledCourses.includes(courseId) || courseItem?.creator_id === authUser?.uid)
          })
      }
    }
  }, [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (courseItem && authUser && !isEnrolled){
      if (courseItem?.creator_id === authUser?.uid){
        setIsEnrolled(true);
      }
    }
  }, [courseItem, authUser, isEnrolled])

  useEffect(() => {
    if (isEnrolled) {
      setIsStripeOpen(false)
    }
  }, [isEnrolled])

  useEffect(() => {
    if (location?.state) {
      setStripeItem(location.state.item);
      setStripeItemId(location.state.item_id);
      setIsStripeOpen(location.state.isStripeOpen);
    }
  }, [location.state]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isStripeOpen) {
      setStripeItem(null);
      setStripeItemId(null);
    }
  }, [isStripeOpen])

  if (!(showCourses || authUser?.beta_tester || authLoading)) {
    return <Navigate to="/creators"/>
  }

  let loadCourseData = () => {
    if (location?.state) {
      setStripeItem(location.state.item || null)
      setStripeItemId(location.state.item_id || null)
      setIsStripeOpen(location.state.isStripeOpen || false)
    }
    if (location?.state?.courseItem) {
      setCourseItem(location?.state?.courseItem || null)
    } else {
      // load course data
      isLoading.current = true;
      getCourseInfo(courseId)
        .then(resp => {
          // console.log('API response:', resp); // Log the API response
          isLoading.current = false;
          if (resp.ok) return resp.json();
          throw new Error('Network response was not ok');
        })
        .then(course => {
          course.docId = courseId;
          // console.log('Course data:', course); // Log the course data
          setCourseItem(course);
        })
        .catch(err => {
          console.error('Error fetching course info:', err); // Log errors
        });
    }
  }

  let handleEnrollCourse = (event: any, item: any, item_id: any) => {
    event.preventDefault();
    if (authUser?.documentId){
      setStripeItem(item);
      setStripeItemId(item_id);
      setIsStripeOpen(true);
    } else {
      // if not logged in, force login
      // pass in redirectURL and redirectState
      // use redirectURL to get back here
      // use redirectState to control the state of the popup modal

      setLoginModalVisible({
        isOpen: true,
        formType: 'signin',
        currentStep: 1,
        redirectOptions: {
          redirectURL: `/courses/${courseId}`,
          redirectState: {
            item: item,
            item_id: item_id,
            isStripeOpen: true,
            courseItem: courseItem
          }
        }
      })
    }
  };

  let handleStartCourse = (event: any, courseId: string) => {
    event.preventDefault();
    navigate(`/learn/${courseId}`)
  }

  const EnrollOrTakeCourse = () => {
    return (
      !isEnrolled ?
        <Button
          id={`button-enroll-${courseItem.docId}`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            width: '150px',
            mr: 4,
          }}
          fullWidth
          onClick={(event) => handleEnrollCourse(event, STRIPE_PRODUCT_LOOKUP_KEYS[courseItem?.is_free_course ? "free_course" : courseItem.module_count], courseItem.docId)}
        >
          {"Enroll: " + COURSE_PRICES[courseItem?.is_free_course ? "free_course" : courseItem?.module_count]}
        </Button> :
      (allowTakeCourse || authUser?.beta_tester) ?
        <Button
          id={`button-take-course-${courseItem.docId}`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            width: '150px',
            mr: 4,
          }}
          fullWidth
          onClick={(event) => {
            event.preventDefault();
            navigate(`/learn/${courseItem.docId}`)
          }}
        >
          Take Course
        </Button> :
        <Button
          id={`button-enrolled-${courseItem.docId}`}
          color="primary"
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            width: '150px',
            mr: 4,
            '&.Mui-disabled': {
              color: '#353535 !important',
            },
          }}
          fullWidth
          disabled={true}
        >
          Enrolled
        </Button>
    )
  }

  return (
    courseItem ? (
        <Box display='flex' flex={1} >
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='left'
            textAlign='left'
            sx={{
              mb: 4
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'lightgray',
                position: 'absolute',
                left: 0,
                right: 0,
                height: '100px',
                zIndex: 0
              }}
            >
              {
                // Fills out the background
              }
            </Box>
            <Box
              className={"course-details-header-box"}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'lightgray',
                zIndex: 1
              }}
            >
              <Box
                className={"course-details-creator-box"}
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  m: 2
                }}
              >
                <Avatar
                  alt={courseItem?.creator_name}
                  src={courseItem?.creator_avatar || DefaultUserImage}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    ml: 3
                  }}
                >
                  <Typography
                    fontSize={'1rem'}
                    fontWeight={700}
                    align='left'
                    color={'black'}
                  >
                    {courseItem.name}
                  </Typography>
                  <Typography
                    fontSize={'0.9rem'}
                    fontWeight={400}
                    align='left'
                    color={'black'}
                  >
                    {courseItem.creator_name}
                  </Typography>
                  <Typography
                    fontSize={'0.9rem'}
                    fontWeight={400}
                    align='left'
                    color={'black'}
                  >
                    {courseItem.module_count + " Module Course"}
                  </Typography>
                </Box>
              </Box>
              <Box
                className={"course-details-buttons-box"}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {enableDevTools &&
                  <div
                    style={{
                      border: '2px solid #000',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '6px 12px !important',
                      marginRight: 4,
                    }}
                  >
                    <p style={{ fontSize: '12px', padding: '0px', margin: '0px', }}>
                      Dev Tools for dev and testing only
                    </p>
                    <Button
                      id={`button-take-course-${courseItem.docId}`}
                      className={"course-details-temp-take-course"}
                      variant="contained"
                      sx={{
                        height: '32px !important',
                        borderRadius:'6px',
                        width: '150px',
                      }}
                      fullWidth
                      onClick={(event) => handleStartCourse(event, courseId)}
                    >
                      {"Start Course"}
                    </Button>
                  </div>
                }
                <EnrollOrTakeCourse />
              </Box>
            </Box>
            <Box
              className="course-details-description-box"
              sx={{
                mt: 4,
                ml: 2
              }}
            >
              <Box sx={{display: 'flex'}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Subject:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {courseItem?.subject}
                </Typography>
              </Box>

              <Box sx={{display: 'flex'}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Course:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {courseItem?.name}
                </Typography>
              </Box>

              <Box sx={{display: 'flex'}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Instructor:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {courseItem?.creator_name}
                </Typography>
              </Box>

              <Box sx={{display: 'flex'}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Grade:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {courseItem?.grade}
                </Typography>
              </Box>

              <Box sx={{display: 'flex'}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Duration:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {courseItem?.module_count + " Modules"}
                </Typography>
              </Box>

              <Box sx={{display: 'flex'}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Price:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {COURSE_PRICES[courseItem?.is_free_course ? "free_course" : courseItem?.module_count]}
                </Typography>
              </Box>

              <Box className={"course-details-overview-box"} sx={{display: 'flex', mt: 4, mb: 2}}>
                <Typography fontSize={'1rem'} fontWeight={700} align='left' color={'black'} >
                  Overview:
                </Typography>
                <Typography fontSize={'1rem'} fontWeight={400} align='left' color={'black'} sx={{ml: 1}}>
                  {courseItem?.overview ? courseItem.overview : courseItem.description}
                </Typography>
              </Box>
            </Box>
            {
              courseItem?.modules.map((mod: any, index: number) => {
                return <Box
                  sx={{
                    ml: 2,
                    mt: 2,
                  }}
                  key={`module-${index}`}
                >
                    <Typography
                      fontSize={'1rem'}
                      fontWeight={700}
                      align='left'
                      color={'black'}
                    >
                      {`Module ${index+1}: ${mod.title}`}
                    </Typography>
                    {mod?.topics.map((topic: any, top_index: number) => {
                      return <Typography
                        fontSize={'0.9rem'}
                        fontWeight={400}
                        align='left'
                        color={'black'}
                        sx={{
                          ml: 2
                        }}
                        key={`module-${index}-topic-${top_index}`}
                      >
                        {`${top_index+1}. ${topic.name}`}
                      </Typography>
                    })}
                  </Box>
              })
            }
          </Box>
          <StripeCheckoutForm item={stripeItem} isOpen={isStripeOpen} setIsOpen={setIsStripeOpen} itemId={stripeItemId} redirectURL={`/courses/${courseId}`}/>
        </Box>
      ) : isLoading.current ? (
        <Box
          display='flex'
          width='100%'
          padding='1.5rem'
          alignItems='center'
          justifyContent='center'
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box
          mt={2}
          rowGap={2}
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Typography
            component='h5'
            variant='body2'
            fontSize='1rem'
            fontWeight={500}
          >
            Course not found
          </Typography>
        </Box>
      )
    )
}

export default CourseDetails;
