import { Button } from "@mui/material";
import CallToActionIcon from "../../svg/CallToActionIcon";
import { useNavigate } from "react-router-dom";

interface CreatePersonaButtonProps {
  iconOnly?: boolean;
}

export const CreatePersonaButton: React.FC<CreatePersonaButtonProps> = (props) => {
  const {iconOnly} = props;
  const navigate = useNavigate();

  return (
    <Button // this button is custom
      size='small'
      variant="contained"
      sx={[
        {
          borderRadius: (theme) => `${theme.shape.borderRadiusRound*4}px !important`,
          // boxShadow: '0px 1px 12px #C2E8FF !important',
          boxShadow: (theme) => `${theme.shadows[2]} !important`,
          width: 'fit-content',
          backgroundColor: 'white !important',
          color: 'black !important',
          py: (theme) => `${theme.spacing(0.75)} !important`,
          px: (theme) => `${theme.spacing(2)} !important`,
        },
        iconOnly && {
          "& span": {
            mx: 0,
          }
        }
      ]}
      startIcon={<CallToActionIcon />}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        navigate('/creator-onboarding')
      }}
      href={'/creator-onboarding'}
    >
      {iconOnly ? '' : 'Create My AI Persona'}
    </Button>
  )
}
