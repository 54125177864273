// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import FanCreatorProfileItem from './fanCreatorProfileItem';
// import useMediaQuery from '@mui/material/useMediaQuery';

// interface FanCreatorItemType {
//   creatorItem: any;
// }

// function FanCreatorItem(props: FanCreatorItemType) {
//   const { creatorItem } = props || {};
//   const matches = useMediaQuery('(max-width:900px)');

//   return (
//     // Adjust the spacing here to ensure equal distance between rows and columns
//     (<Grid item
//       md={matches ? 12 : 6}
//       sm={matches ? 12 : 6}
//       xs={12}
//       key={creatorItem?.creatorId}
//       sx={{
//         display: 'flex',
//         justifyContent: 'center'
//       }}
//     >
//       <Box
//         sx={{
//           minWidth: '320px',
//           maxWidth: '600px',
//           width: '100%',
//           borderRadius: '4px',
//           boxShadow: '0px 0px 4px 0px #bbb',
//           padding: '5px'
//         }}
//       >
//         <FanCreatorProfileItem profileData={creatorItem} />
//         {/* Additional content */}
//       </Box>
//     </Grid>)
//   );
// }

// export default React.memo(FanCreatorItem);

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { Grid2 } from '@mui/material';
// import FanCreatorProfileItem from './fanCreatorProfileItem';
// import useMediaQuery from '@mui/material/useMediaQuery';

// interface FanCreatorItemType {
//   creatorItem: any;
// }

// function FanCreatorItem(props: FanCreatorItemType) {
//   const { creatorItem } = props || {};

//   return (
//     // Adjust the spacing here to ensure equal distance between rows and columns
//     <Grid item xs={12} sm={6} md={6} lg={3} xl={3} key={creatorItem?.creatorId}>
//       <FanCreatorProfileItem profileData={creatorItem} />
//     </Grid>
//   );
// }

// export default React.memo(FanCreatorItem);

import * as React from 'react';
import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import { Grid2 as Grid } from "@mui/material";
import FanCreatorProfileItem from './fanCreatorProfileItem';
import useMediaQuery from '@mui/material/useMediaQuery';

interface FanCreatorItemType {
  creatorItem: any;
}

function FanCreatorItem(props: FanCreatorItemType) {
  const { creatorItem } = props || {};

  return (
    // Adjust the spacing here to ensure equal distance between rows and columns
    <Grid
      size={{
        xs: 12,
        sm: 6,
        lg: 4,
        xl: 3,
        xxl: 3
      }}      
      key={creatorItem?.creatorId}
    >
      <FanCreatorProfileItem profileData={creatorItem} />
    </Grid>
  );
}

export default React.memo(FanCreatorItem);
