
import { SVGProps, memo } from 'react';
const SocialmediaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0 0.757967C0 0.339353 0.341043 0 0.761741 0H17.2383C17.659 0 18 0.339353 18 0.757967V11.6879C18 12.1065 17.659 12.4459 17.2383 12.4459H12.6004L9.62404 16.6767C9.4815 16.8793 9.24857 17 9 17C8.75143 17 8.5185 16.8793 8.37596 16.6767L5.39963 12.4459H0.761741C0.341043 12.4459 0 12.1065 0 11.6879V0.757967ZM1.52348 1.51593V10.9299H5.79623C6.0448 10.9299 6.27773 11.0506 6.42027 11.2532L9 14.9203L11.5797 11.2532C11.7223 11.0506 11.9552 10.9299 12.2038 10.9299H16.4765V1.51593H1.52348Z" fill={props?.color || "#424242"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.0182 3.45289C10.0383 2.48664 11.6334 2.50421 12.6325 3.50296C12.6409 3.5108 12.6491 3.51884 12.6571 3.52708C13.6786 4.57349 13.6782 6.262 12.6574 7.30884L9.72061 10.3207C9.52264 10.5237 9.25894 10.6242 8.99991 10.6242C8.73871 10.6242 8.47628 10.5228 8.27921 10.3207L5.34271 7.30915C4.32163 6.26281 4.32175 4.57445 5.34212 3.52768M9.0182 3.45289C7.98002 2.4684 6.35018 2.49303 5.34212 3.52768L9.0182 3.45289ZM10.0409 4.57803L9.5589 5.06652C9.41512 5.21224 9.21833 5.29406 9.0131 5.29345C8.80787 5.29284 8.61157 5.20986 8.46866 5.0633L8.00033 4.583C7.56519 4.13674 6.87004 4.13719 6.43611 4.5827C5.98847 5.04178 5.98859 5.79508 6.43552 6.25292L8.99991 8.88284L11.564 6.25322C12.0063 5.7996 12.0112 5.05903 11.5801 4.59977C11.5744 4.59428 11.5687 4.58869 11.5632 4.583C11.1408 4.14981 10.4655 4.1481 10.0409 4.57803Z" fill={props?.color || "#424242"}/>
</svg>

);

export default memo(SocialmediaIcon)
