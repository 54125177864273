import { Box } from "@mui/material";
import LeftNav from "./views/leftNav";
import Search from "./views/leftNav/Search";
import Notifications from "./views/leftNav/Notifications";
import TopNavBarMobile from "./views/leftNav/topNavBarMobile";
import BottomNavBarMobile from "./views/leftNav/bottomNavBarMobile";
import { Outlet } from "react-router-dom";
import LoginModal from "./views/loginModal";
import ContactUsModal from "./views/userChat/contactUsModal";


const AppContainer: React.FC = () => {

  return (
    <Box
      display="flex"
      height="100%"
      width='100%'
      flexDirection="row"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <LeftNav />
      <LoginModal />
      <ContactUsModal />

      <Box
        sx={{
          flexGrow: 1,
          height: '100%',
          bgcolor: (theme) => theme.palette.background.default,
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Search />
        <Notifications />
        <TopNavBarMobile />
        <Box
          sx={{
            flexGrow: 1,
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Outlet />
        </Box>
        <BottomNavBarMobile />
      </Box>
    </Box>
  )
}

export default AppContainer;
