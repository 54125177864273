import { Box, Typography } from "@mui/material"

export const PremiumTraining = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={0.5}
      p={1.5}
      width='100%'
      sx={{
        bgcolor: (theme) => theme.palette.primary._states.hover,
      }}
    >
      <Typography variant='h6'>
      Premium Training Material
      </Typography>
      <Typography variant="body2">
      *Requires Paid Subscription
      </Typography>
    </Box>
  )
}
