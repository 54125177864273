import React, { useRef, useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid2 as Grid } from "@mui/material";
import { useAuth } from '../../hooks/useAuth';
import {
  searchCoursesList,
  getSortedCoursesList,
  fetchCourseResultsByStudent,
} from '../../utils/api';
import StripeCheckoutForm from '../StripeCheckoutForm';
import { Navigate, useLocation } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './courseItem.css';
import CourseGridItem from './courseGridItem';

const showCourses = process.env.REACT_APP_SHOW_MY_COURSE === 'true';
const PAGE_LIMIT = 14;

const CoursesList: React.FC = () => {
  const searchValue = useRef<string>('');
  const isLoading = useRef<boolean>(false);
  const { authUser, authLoading, setLoginModalVisible } = useAuth();
  const currentPage = useRef<number>(1);
  const fullyLoaded = useRef<boolean>(false);
  const lastVisibleDoc = useRef<any>(null);
  const [coursesData, setCoursesData] = useState([]);
  const [ , setRefresh] = useState<boolean>(false);
  // const searchTimer = useRef<any>(0);
  let [isStripeOpen, setIsStripeOpen] = useState(false);
  let [stripeItem, setStripeItem] = useState(null);
  let [stripeItemId, setStripeItemId] = useState(null);
  let [redirectURL, setRedirectURL] = useState(null);
  let [enrolledCourses, setEnrolledCourses] = useState([]);
  const location = useLocation();
  const bottom = useRef(null);

  useEffect(() => {
    if (location?.state) {
      setStripeItem(location.state.item);
      setStripeItemId(location.state.item_id);
      setIsStripeOpen(location.state.isStripeOpen);
      setRedirectURL(location.state.redirectURL);
    }
  }, [location.state]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isStripeOpen) {
      setStripeItem(null);
      setStripeItemId(null);
      setRedirectURL(null);
    }
  }, [isStripeOpen])

  useEffect(() => {
    if (authUser) {
      fetchCourseResultsByStudent(authUser.uid).then((results) => {
        setEnrolledCourses(
          results.map((result: any) => {
            return result.courseId;
          })
        );
      });
    }
  }, [authUser]);

  useEffect(() => {
    // handle searchValue changes
    if (
      searchValue.current?.length &&
      !isLoading.current &&
      (showCourses || authUser?.beta_tester)
    ) {
      currentPage.current = 1;
      fullyLoaded.current = false;
      lastVisibleDoc.current = null;
      setCoursesData([]);
      coursesSearch();
    } else {
      // default to sortBy list on empty searchValue
      if (!isLoading.current && (showCourses || authUser?.beta_tester)) {
        currentPage.current = 1;
        fullyLoaded.current = false;
        handleFetchCourses();
      }
    }
  }, [searchValue.current]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (searchValue.current?.length) {
            coursesSearch();
          } else {
            handleFetchCourses();
          }
        }
      });
      observer.observe(bottom.current);

      return () => {
        observer.disconnect();
      };
    } catch (err) {
      // To-do: Error handling when observer fails
    }
  }, [coursesData]); //eslint-disable-line react-hooks/exhaustive-deps

  let coursesSearch = async () => {
    if (
      !fullyLoaded.current &&
      !isLoading.current &&
      (showCourses || authUser?.beta_tester)
    ) {
      isLoading.current = true;
      setRefresh((curState) => !curState);

      searchCoursesList(
        searchValue.current || '',
        currentPage.current,
        PAGE_LIMIT
      )
        .then(async (res: any) => {
          if (res.ok) {
            await res.json().then((data) => {
              if (data.data.length === 0) {
                fullyLoaded.current = true;
              }

              let redoSearch = false;

              // make sure the search at least fills the page
              setCoursesData((prevData) => {
                if (prevData.length + data.data.length < 6) {
                  redoSearch = true;
                }
                return [...prevData, ...data.data];
              });
              currentPage.current++;
              isLoading.current = false;

              if (redoSearch && data.data.length) coursesSearch();
            });
          } else {
            isLoading.current = false;
            throw res.error;
          }
        })
        .catch((err) => {
          console.error('Error in courses search:', err);
          isLoading.current = false;
          fullyLoaded.current = true;
          setRefresh((curState) => !curState);
        });
    }
  };

  // const handleSearch = (event: any) => {
  //   const newValue = event?.target?.value.toLowerCase();
  //   if (searchTimer?.current) {
  //     clearTimeout(searchTimer.current);
  //     searchTimer.current = 0;
  //   }

  //   if (newValue.length >= 2) {
  //     searchTimer.current = setTimeout(() => {
  //       searchValue.current = newValue;
  //       searchTimer.current = 0;
  //       setRefresh(!refresh);
  //     }, 250);
  //   } else if (newValue.length === 0) {
  //     searchValue.current = newValue;
  //     searchTimer.current = 0;
  //     setRefresh(!refresh);
  //   }
  // };

  const handleFetchCourses = () => {
    if (!fullyLoaded.current && !isLoading.current) {
      isLoading.current = true;
      setRefresh((curState) => !curState);
      if (currentPage.current === 1) {
        setCoursesData([]);
      }

      const fetchData = getSortedCoursesList(currentPage.current, PAGE_LIMIT);

      fetchData
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            isLoading.current = false;
            throw new Error('Failed to fetch data');
          }
        })
        .then((data) => {
          currentPage.current += 1;
          if (!data.data.length) {
            fullyLoaded.current = true;
          }
          isLoading.current = false;

          setCoursesData((prevData) => [...prevData, ...data.data]);
        })
        .catch((err) => {
          console.error('Error in courses fetch:', err);
          isLoading.current = false;
          fullyLoaded.current = true;
          setRefresh((curState) => !curState);
        });
    }
  };

  let handleEnrollCourse = (event: any, item: any, item_id: any) => {
    event.preventDefault();
    if (authUser?.documentId) {
      setStripeItem(item);
      setStripeItemId(item_id);
      setRedirectURL(`/courses/${item_id}`);
      setIsStripeOpen(true);
    } else {
      // if not logged in, force login
      // pass in redirectURL and redirectState
      // use redirectURL to get back here
      // use redirectState to control the state of the popup modal

      setLoginModalVisible({
        isOpen: true,
        formType: 'signin',
        currentStep: 1,
        redirectOptions: {
          redirectURL: '/courses',
          redirectState: {
            item: item,
            item_id: item_id,
            isStripeOpen: true,
            redirectURL: `/courses/${item_id}`,
          },
        }
      })
    }
  };

  if (!(showCourses || authUser?.beta_tester || authLoading)) {
    return <Navigate to='/creators' />;
  }

  // const PrevArrow = (props: any) => {
  //   const { className, onClick } = props;
  //   return (
  //     <ArrowBackIosIcon
  //       className={className}
  //       onClick={onClick}
  //       style={{ color: 'black', zIndex: 1 }}
  //     />
  //   );
  // };

  // const NextArrow = (props: any) => {
  //   const { className, onClick } = props;
  //   return (
  //     <ArrowForwardIosIcon
  //       className={className}
  //       onClick={onClick}
  //       style={{ color: 'black', zIndex: 1 }}
  //     />
  //   );
  // };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   prevArrow: <PrevArrow />,
  //   nextArrow: <NextArrow />,
  //   responsive: [
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         dots: true,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         dots: true,
  //       },
  //     },
  //   ],
  // };

  return (
    <Box
      display='flex'
      flex={1}
      bgcolor={(theme) => theme.palette.background.default}
    >
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        textAlign='center'
        // bgcolor='white'
        className='custom-dots'
      >
        {/* Banner Section */}
        {/* <Box width="100%" mb={3}>
  <Slider {...settings}>
    <div>
      <Grid container alignItems="center" justifyContent="center" p={2}>
        <Grid item xs={12} sm={4} className="hide-on-mobile">
          <img src={BannerImage1} alt="Banner 1" style={{ paddingLeft: '70px', height: '215px', paddingTop: '20px' }} />
        </Grid>
        <Grid item xs={12} sm={8} textAlign={{ xs: "center", sm: "left" }} sx={{ paddingTop: { xs: '20px', sm: 0 } }}>
          <Typography variant="h4" sx={{ fontSize: { xs: '1.5rem', sm: '2.125rem' } }}>Bite Sized Learning</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Introducing guided learning courses carefully designed by teachers with AI assistance to help students learn through self-paced, personalized lessons.</Typography>
          <Typography variant="body1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>Learn from top-tier educators from institutions like Stanford, Harvard, and more.</Typography>
        </Grid>
      </Grid>
    </div>
    {[
      { imgSrc: BannerImage2, title: "STEM Courses Crafted By Experts", description: "Soopra offers STEM (Science, Technology, Engineering, and Mathematics) courses designed by teachers and assisted by AI to help students learn through self-paced, personalized learning. It is an informative, fun, safe, and secure learning environment for students.", },
      { imgSrc: BannerImage3, title: "Conversational Learning", description: "Students can learn at their own pace by chatting with AI versions of top-tier educators in a guided environment. Each course has a set of modules that cover a range of topics for students to explore. Learn a little everyday, or learn all at once–it’s your choice!" },
      { imgSrc: BannerImage4, title: "Assignments With Real-Time Grading", description: "Students can learn at their own pace by chatting with AI versions of top-tier educators in a guided environment. Each course has a set of modules that cover a range of topics for students to explore. Learn a little everyday, or learn all at once–it’s your choice!" },
      { imgSrc: BannerImage5, title: "Accessible, Affordable", description: "We are excited to bring world-class education to students everywhere. Our goal is to make quality education accessible and affordable. With Soopra, students can learn from top-tier educators from institutions like Stanford, Harvard, and more for as little as $40 a course." },
    ].map((banner, index) => (
      <div key={index}>
        <Grid container alignItems="center" justifyContent="center" p={2}>
          <Grid item xs={12} sm={4} className="hide-on-mobile">
            <img src={banner.imgSrc} alt={`Banner ${index + 2}`} className="banner-image" />
          </Grid>
          <Grid item xs={12} sm={8} textAlign={{ xs: "center", sm: "left" }} sx={{ paddingTop: { xs: '20px', sm: 0 } }}>
            <Typography variant="h4" sx={{ fontSize: { xs: '1.3rem', sm: '2.125rem' } }}>{banner.title}</Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}>{banner.description}</Typography>
          </Grid>
        </Grid>
      </div>
    ))}
  </Slider>
</Box> */}

        <Grid
          container
          sx={{
            width: '100%',
            margin: 'auto',
            maxWidth: 'lg',
          }}
        >
          {/* <TextField
            type='text'
            margin='normal'
            fullWidth
            id='text-input-search'
            label='Search for courses'
            name='search'
            autoComplete='off'
            className='courses-search'
            onKeyDown={debounce(handleSearch, 500)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: '100%',
              flexGrow: 1,
              '&.courses-search input': {
                borderWidth: 0,
              },
              mt: 4.5,
              mx: 4,
            }}
          /> */}

          <Typography
            variant='h7'
            color='text.primary'
            align='left'
            sx={{
              mt: { xs: 2, sm: 2.5, md: 4.5 },
              mx: { xs: 2.5, md: 4 },
            }}
          >
            Courses
          </Typography>

          {coursesData?.length >= 1 ? (
            <Box width='100%'>
              <Grid
                container
                sx={{
                  px: { xs: 1.25, md: 2.5 },
                  pt: { xs: 1, sm: 1.25, md: 1 },
                  pb: { xs: 1, sm: 1.25, md: 2 },
                  margin: 'auto',
                  maxWidth: 'lg',
                }}
              >
                {coursesData?.map((courseItem) => (
                  <CourseGridItem
                    courseItem={courseItem}
                    key={courseItem?.course_id}
                    handleEnrollCourse={handleEnrollCourse}
                    isEnrolled={
                      enrolledCourses.includes(courseItem?.course_id) ||
                      courseItem?.creator_id === authUser?.uid
                    }
                  />
                ))}
              </Grid>
              {fullyLoaded.current || !isLoading.current ? (
                <></>
              ) : (
                <Box
                  display='flex'
                  width='100%'
                  padding='1.5rem'
                  alignItems='center'
                  justifyContent='center'
                >
                  <CircularProgress size={30} />
                </Box>
              )}
            </Box>
          ) : isLoading.current && coursesData?.length === 0 ? (
            <Box
              display='flex'
              width='100%'
              padding='1.5rem'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box
              mt={2}
              rowGap={2}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Typography
                component='h5'
                variant='body2'
                fontSize='1rem'
                fontWeight={500}
              >
                No courses found
              </Typography>
            </Box>
          )}
        </Grid>
        <div className='custom-bottom' ref={bottom} />
      </Box>
      <StripeCheckoutForm
        item={stripeItem}
        isOpen={isStripeOpen}
        setIsOpen={setIsStripeOpen}
        itemId={stripeItemId}
        redirectURL={redirectURL}
      />
    </Box>
  );
};

export default CoursesList;
