import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import { UserStreaksRequest } from '../userStreaks/userStreaks.types';

interface CreatorFanListType {
  userStreaksData: UserStreaksRequest[];
  mode: 'profile' | 'list';
}

function CreatorFanList(props: CreatorFanListType) {
  const { userStreaksData, mode } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [maxVisibleFans, setMaxVisibleFans] = React.useState(0);

  React.useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const avatarWidth = mode === 'list' ? 40 : 50; 
        const maxFans = Math.floor(containerWidth / avatarWidth);
        setMaxVisibleFans(maxFans);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [mode]);

  return (
    <Box
      ref={containerRef}
      display="flex"
      textAlign="center"
      justifyContent="flex-start"
      width="99%"
      overflow="hidden"
      whiteSpace="nowrap"
      flexWrap="nowrap"
    >
      {userStreaksData?.slice(0, maxVisibleFans).map((userStreaks, index) => (
        <Box
          key={userStreaks?.fanId}
          m={1}
          flexDirection="column"
          display="flex"
          alignItems="center"
          sx={{ margin: '0px 9px 0px 0px', paddingTop: '16px' }}
        >
          <Badge
            overlap="circular"
            invisible={!userStreaks?.streaksCount}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginBottom="10px"
                position="relative"
              >
                <Box
                  position="absolute"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bottom={mode === 'list' ? 5 : 6}
                  left={0}
                  right={0}
                >
                  <Typography
                    color="#fff"
                    fontSize="10px"
                    lineHeight="normal"
                  >
                    {userStreaks?.streaksCount || 0}
                  </Typography>
                </Box>
              </Box>
            }
          >
            <Avatar
              src={userStreaks?.fanProfile?.profilePhotoURL}
              alt={userStreaks?.fanProfile?.fullName}
              sx={{
                mb: 0.8,
                width: mode === 'list' ? 30 : 40,
                height: mode === 'list' ? 30 : 40,
                fontSize: mode === 'list' ? '1.75rem' : '2.1875rem',
              }}
            >
              {userStreaks?.fanProfile?.fullName
                ? userStreaks?.fanProfile?.fullName[0]
                : ''}
            </Avatar>
          </Badge>
        </Box>
      ))}
      {userStreaksData.length > maxVisibleFans && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: '16px',
            backgroundColor: '#424242',
            color: '#fff',
            borderRadius: '50%',
            width: mode === 'list' ? 30 : 40,
            height: mode === 'list' ? 30 : 40,
            fontSize: mode === 'list' ? '0.875rem' : '1rem',
            textAlign: 'center',
            flexShrink: 0,
          }}
        >
          +{userStreaksData.length - maxVisibleFans}
        </Box>
      )}
    </Box>
  );
}

export default React.memo(CreatorFanList);
