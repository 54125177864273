import { SVGProps, memo } from 'react';

const ChatIcon2 = (props: SVGProps<SVGSVGElement>) => (

<svg width="15" height="14" viewBox="0 0 15 14" fill={props.fill || "none"} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.68174 9.74216L10.5499 11.4578L10.0378 9.73538L10.9939 9.18737C12.2557 8.46409 13.1 7.1127 13.1 5.56695C13.1 3.26104 11.2196 1.39174 8.9 1.39174H6.1C3.7804 1.39174 1.9 3.26104 1.9 5.56695C1.9 7.87286 3.7804 9.74216 6.1 9.74216H7.68174ZM0.5 5.56695C0.5 2.49241 3.00721 0 6.1 0H8.9C11.9928 0 14.5 2.49241 14.5 5.56695C14.5 7.6303 13.3708 9.43146 11.6932 10.393L12.4694 13.0041C12.6869 13.7357 11.8202 14.3051 11.2284 13.8194L7.17849 11.1339H6.1C3.0072 11.1339 0.5 8.64149 0.5 5.56695Z"/>
</svg>

);

export default memo(ChatIcon2);
