import { Box, Button, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import { useContext, useEffect, useState } from "react";
import { TierContext } from "../../../context/TierContext";
import { fetchTwitterData, getTwitterAuthUrl } from "../../../utils/api";
import { useAuth } from "../../../hooks/useAuth";
import TwitterIcon from "../../../svg/TwitterIcon";
import { toast } from "react-toastify";

const TwitterForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty
  } = props;

  const [twitterData, setTwitterData] = useState<{
    userName: string,
    authenticated: boolean,
  }>({userName: null, authenticated: null});
  const { checkPermission } = useContext(TierContext);
  const { authUser } = useAuth();

  const handleTwitterAuth = async () => {
    try {
      const authUrl = await getTwitterAuthUrl();
      window.location.href = authUrl;
    } catch (error) {
      console.error('Error during Twitter authentication:', error);
    }
  };

  // triggers when TwitterCallback.tsx redirects back to this form
  useEffect(() => {
    const twitterConnected = localStorage.getItem('twitterConnected');
    if (twitterConnected) {
      toast.success('Twitter connected successfully!');
      localStorage.removeItem('twitterConnected');
    }
  }, []);

  useEffect(() => {
    const fetchTwitterInfo = async () => {
      if (!authUser?.documentId) {
        console.error("User ID is undefined. Cannot fetch Twitter data.");
        return;
      }

      try {
        const data = await fetchTwitterData(authUser.documentId);

        if (data.is_twitter_authenticated) {
          setTwitterData({
            userName: data.username,
            authenticated: true
          })
          setFieldValue('twitterURL', `https://twitter.com/${data.username}`);
        }
      } catch (error) {
        console.error('Error fetching Twitter data:', error);
      }
    };

    fetchTwitterInfo();
  }, [authUser?.documentId, setFieldValue]);

  useEffect(() => {
    const handleCallbackMessage = (event) => {
      if (event?.data?.username) {
        setTwitterData({
          userName: event.data.username,
          authenticated: true
        })
      }
    };

    window.addEventListener('message', handleCallbackMessage);

    return () => {
      window.removeEventListener('message', handleCallbackMessage);
    };
  }, []);

  return (
    <Box display='flex' flexDirection='column' gap={2} width='100%'>
      <Typography variant='h6' mb={-1.5} >
        X (Twitter)
      </Typography>
      <Typography variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        Connect your X account and your last 100 posts at the time of updating will be used to train your AI Persona.
      </Typography>

      {twitterData.authenticated ? (
        <Typography variant='body2' color='Green'>
          Connected as: {twitterData.userName || 'Authenticated'}
        </Typography>
      ) : (
        <Button
          variant="contained"
          size='large'
          fullWidth={false}
          sx={{
            width: 'fit-content',
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={() => {
            if (checkPermission("train:twitter")) {
              handleTwitterAuth()
            }
          }}
          disabled={formSubmitting}
        >
          Connect X Account
        </Button>
      )}
    </Box>
  )
}

export default TwitterForm;
