import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { CONTACT_US_MAIL } from '../../config/const';
import { IconButton, Link, useTheme } from '@mui/material';
import CloseIcon from "../../svg/CloseIcon";
import { useNavigate } from 'react-router-dom';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useContext } from 'react';
import { NavContext } from '../../context/NavContext';

const ContactUsModal = () => {
  const { handleContactModal, setHandleContactModal} = useContext(NavContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleContactUsModalClose = () => {
    setHandleContactModal(false);
  }

  return (
    <Dialog
      open={Boolean(handleContactModal)}
      onClose={handleContactUsModalClose}
      PaperProps={{
        sx: {
          m: 0,
          p: 0,
          borderRadius: 2,
          maxWidth: {
            xs: '350px',
            sm: '500px',
          },
        }
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        gap={3}
        p={3}
      >
        <Box
          display='flex'
          flexDirection='column'
          gap={1.5}
        >
          <Box
            display='flex'
            justifyContent='space-between'
          >
            <Typography variant='h6'>
              {'Contact Us'}
            </Typography>
            <Box mt={-0.5}>
              <IconButton
                onClick={handleContactUsModalClose}
                color='primary'
              >
                <CloseIcon
                  height='16px'
                  width='16px'
                  stroke={theme.palette.text.primary}
                  strokeWidth={2}
                />
              </IconButton>
            </Box>
          </Box>

          <Typography variant='body1' color='primary'>
            Reach out at any time for help, or to give feedback and suggestions at {
              <Link href={`mailto:${CONTACT_US_MAIL}`} target='_self'>{CONTACT_US_MAIL}</Link>
            }
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              md: 'row'
            },
            justifyContent: 'flex-end',
          }}
          gap={1.5}
        >
          <Button
            id='button-mail-contact-us'
            color='primary'
            variant='contained'
            href={`mailto:${CONTACT_US_MAIL}`}
            startIcon={<EmailOutlinedIcon />}
            onClick={() => {
              handleContactUsModalClose();
            }}
          >
            Email Soopra
          </Button>
          <Button
            id='button-faq-contact-us'
            color='secondary'
            variant='contained'
            href='/faq'
            onClick={(event) => {
              event.preventDefault()
              event.stopPropagation()
              handleContactUsModalClose()
              navigate('faq')
            }}
            startIcon={<HelpOutlineOutlinedIcon />}
          >
            View FAQ
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ContactUsModal;
