import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import ChatIcon from '@mui/icons-material/Chat';
import VolumeIcon2 from '../svg/VolumeIcon2';
import ChatIcon2 from '../svg/ChatIcon2';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import { useContext, useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';
import { VolumeContext } from '../context/VolumeContext';
import { useTheme } from '@mui/material/styles';

interface VoiceChatToggleProps {
  toggleVoiceChat: (newSetting: boolean) => void;
  useVoiceChat: boolean;
  audioQueue?: any;
}

const VoiceChatToggle: React.FC<VoiceChatToggleProps> = (props) => {
  const { toggleVoiceChat, useVoiceChat, audioQueue } = props;

  let [visibleVolSlider, setVisibleVolSlider] = useState(false);
  const { volume } = useContext(VolumeContext);
  let [volumeSliderVal, setVolumeSliderVal] = useState(volume.current || 50);
  const theme = useTheme();

  useEffect(() => {
    if (volume && volume.current) {
      setVolumeSliderVal(volume.current * 50);
    }
  }, [volume]);

  let handleVolumeChange = (event: Event, newValue: number) => {
    volume.current = newValue / 50.0;
    if (audioQueue && audioQueue?.current) {
      audioQueue.current.setVolume(newValue / 50.0);
    }
    setVolumeSliderVal(newValue);
  };

  return (
    <>
      {useVoiceChat ? (
        // <Box
        //   sx={{
        //     display: 'flex',
        //     justifyContent: 'left',
        //     alignItems: 'flex-end',
        //     position: 'absolute',
        //     zIndex: '2',
        //     left: '0',
        //     bottom: '0',
        //   }}
        // >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            zIndex: '2',
            margin: '0 auto',
            maxWidth: '600px',
          }}
        >
          <IconButton
            id='button-voice-chat-toggle-off'
            size='small'
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '50px',
              ml: '24px',
              mb: '24px',
              color: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                theme.palette.background['paper-elevation-2'],
              border: `1px solid ${theme.palette.divider}`,
              // boxShadow: `0px 0px 4px ${grey[400]}`,
              '&:hover': {
                backgroundColor: 'transparent',
                // '& svg': {
                //   color: 'mainBlue.main'
                // }
              },
            }}
            onClick={() => {
              toggleVoiceChat(false);
            }}
          >
            <ChatIcon
              sx={{
                color: (theme) => theme.palette.primary.main,
                transition: 'all 0.25s',
              }}
            />
          </IconButton>
          <Stack
            direction={'column'}
          >
             {visibleVolSlider && (
            <Box
              sx={{
                position: 'relative',
                height: 120,
                // left: '-45px',
                // bottom: '65px',
                px: '10px',
                pt: '10px'
              }}
              onMouseLeave={() => {
                setVisibleVolSlider(false);
              }}
            >
              <Slider
                id='slider-voice-volume'
                sx={{
                  '& input[type="range"]': {
                    WebkitAppearance: 'slider-vertical',
                  },
                }}
                orientation='vertical'
                value={volumeSliderVal}
                onChange={handleVolumeChange}
                aria-label='Temperature'
                valueLabelDisplay='auto'
                // color="mainBlue"
              />
            </Box>
          )}
          <IconButton
            id='button-show-volume'
            size='small'
            sx={{
              width: '50px',
              height: '50px',
              borderRadius: '50px',
              mr: '24px',
              mb: '24px',
              color: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                theme.palette.background['paper-elevation-2'],
              border: `1px solid ${theme.palette.divider}`,
              // boxShadow: `0px 0px 4px ${grey[400]}`,
              '&:hover': {
                backgroundColor: 'transparent',
                // '& svg': {
                //   color: 'mainBlue.main'
                // }
              },
            }}
            onClick={() => {
              setVisibleVolSlider(!visibleVolSlider);
            }}
          >
            {!volume || volume?.current === 0 ? (
              <VolumeOffIcon
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  transition: 'all 0.25s',
                }}
              />
            ) : volume.current < 1 ? (
              <VolumeDownIcon
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  transition: 'all 0.25s',
                }}
              />
            ) : (
              <VolumeUpIcon
                sx={{
                  color: (theme) => theme.palette.primary.main,
                  transition: 'all 0.25s',
                }}
              />
            )}
          </IconButton>
         
          </Stack>
        </Box>
      ) : (
        <IconButton
          id='button-voice-chat-toggle-on'
          size='small'
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '40px',
            ml: '16px',
            color: (theme) => theme.palette.primary.main,
            backgroundColor: (theme) =>
              theme.palette.background['paper-elevation-2'],
            border: `1px solid ${theme.palette.divider}`,
            // boxShadow: `0px 0px 4px ${grey[400]}`,
            '&:hover': {
              backgroundColor: 'transparent',
              // '& svg': {
              //   color: 'mainBlue.main'
              // }
            },
          }}
          onClick={() => {
            toggleVoiceChat(true);
          }}
        >
          <GraphicEqIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              transition: 'all 0.25s',
            }}
          />
        </IconButton>
      )}
    </>
  );
};

export default VoiceChatToggle;
