// import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
// import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

// import { Avatar, Box, Button, IconButton, Popover, Typography, Dialog, DialogContent, DialogActions } from '@mui/material';
// import ThumbsUpIcon from '../../svg/ThumbsUpIcon';
// import ThumbsDownIcon from '../../svg/ThumbsDownIcon';
// import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import MicIcon from '@mui/icons-material/Mic';
// import StopIcon from '@mui/icons-material/Stop';
// import {
//   MessageDataType,
// } from './messages.types';
// import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
// import { grey } from '@mui/material/colors';
// import VoiceChatToggle from '../../component/voiceChatToggle';
// import AudioChunkQueue from './audioChunkQueue';
// import { VolumeContext } from '../../context/VolumeContext';
// import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
// import { preprocessTextForVoice, preprocessLaTeX } from '../../utils/helper';
// import { useCreditsContext } from '../navbar';
// import SubscribeButton from '../Purchases/subscribeButton';
// import { useAuth } from '../../hooks/useAuth';
// import AdditionalCreditsButton from '../Purchases/additionalCreditsButton';
// import Markdown from './Markdown';
// import PremiumPurchaseButton from '../Purchases/premiumPurchaseButton';
// import safarivoice from '../../assets/images/Safarivoicefix.png';

// interface VoiceChatProps {
//   handleMessageSend: (userMessage: string, firstMessage: boolean, isVoiceMessage: boolean) => Promise<any>,
//   creatorProfileData: any,
//   handleLikeDislikeMessage: (documentId: string, value: number) => void,
//   messageDataItem?: MessageDataType,
//   toggleVoiceChat: (newSetting: boolean) => void,
//   useVoiceChat: boolean,
//   setErrorCode: React.Dispatch<React.SetStateAction<number>>,
//   setShowTimeoutModal: React.Dispatch<React.SetStateAction<boolean>>,
//   showCheckPermissionsModal: () => void,
//   isWidget?: boolean,
//   showTranscript?: boolean,
//   ignoreCredits?: boolean,
//   showAssignmentButton?: boolean,
//   handleStartAssignment?: () => void,
//   isAwaitingMessage?: boolean,
//   setIsAwaitingMessage?: React.Dispatch<React.SetStateAction<boolean>>,
//   shouldPlayMessage?: boolean,
//   shouldStopAudio?: boolean,
//   setShouldStopAudio?: React.Dispatch<React.SetStateAction<boolean>>,
//   setShouldPlayMessage?: React.Dispatch<React.SetStateAction<boolean>>,
//   requiredPremiumKey?: string,
//   premiumAccess?: boolean,
// }

// const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

// const VoiceChat: React.FC<VoiceChatProps> = (props) => {
//   const {
//     handleMessageSend,
//     creatorProfileData,
//     handleLikeDislikeMessage,
//     messageDataItem,
//     toggleVoiceChat,
//     useVoiceChat,
//     setErrorCode,
//     setShowTimeoutModal,
//     showCheckPermissionsModal,
//     isWidget=false,
//     ignoreCredits,
//     showAssignmentButton,
//     handleStartAssignment,
//     showTranscript,
//     isAwaitingMessage,
//     setIsAwaitingMessage,
//     shouldPlayMessage,
//     setShouldPlayMessage,
//     shouldStopAudio = false,
//     setShouldStopAudio,
//     requiredPremiumKey,
//     premiumAccess
//   } = props;

//   const documentId = messageDataItem?.documentId;
//   const likeDislike = messageDataItem?.likeDislike;
//   const message = messageDataItem?.editedMessage || messageDataItem?.message;
//   const eventTracker = useAnalyticsEventTracker();

//   const {
//     transcript,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();

//   const [disableMicrophone, setDisableMicrophone] = useState(false);
//   const listeningStartTime = useRef(0);
//   const avatarCanvas = useRef(null);
//   const setAvatarCanvas = useCallback((val: any) => {
//     resizeCanvases();
//     avatarCanvas.current = val;
//   }, []) //eslint-disable-line react-hooks/exhaustive-deps
//   const microphoneCanvas = useRef(null);
//   const setMicrophoneCanvas = useCallback((val: any) => {
//     resizeCanvases();
//     microphoneCanvas.current = val;
//   }, []) //eslint-disable-line react-hooks/exhaustive-deps
//   const micButton = useRef(null);
//   const setMicButton = useCallback((val: any) => {
//     micButton.current = val;
//   }, []) //eslint-disable-line react-hooks/exhaustive-deps
//   const isListening = useRef(false);
//   const isPlayingAudio = useRef(false);
//   const setIsPlayingAudio = useCallback((val: boolean) => {
//     requestAnimationFrame(speakingAnimation);
//     isPlayingAudio.current = val;
//   }, []) //eslint-disable-line react-hooks/exhaustive-deps
//   const awaitingResp = useRef(false);
//   const awaitingRespStartTime = useRef(Date.now());
//   const audioAnalyzer = useRef(null);
//   const { volume } = useContext(VolumeContext);
//   const volumeControl = useRef(null);
//   const audioQueue = useRef(null);
//   const emptyTranscriptCount = useRef(0);
//   const px_ratio = useRef(window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth);
//   const [forceRefresh, setForceRefresh] = useState<boolean>(false);
//   const { credits } = useCreditsContext();
//   const { authUser, isAnonymousUser } = useAuth();
//   const isSelfChat = authUser?.documentId === creatorProfileData?.documentId;
//   const [isSafari, setIsSafari] = useState(false);
//   const [openHelpPopup, setOpenHelpPopup] = useState(false);

//   useEffect(() => {
//     const detectSafariBrowser = () => {
//       const userAgent = navigator.userAgent;
//       const isSafariBrowser = /Safari/.test(userAgent) && !/Chrome|CriOS/.test(userAgent);
//       const isNotMobile = !/Mobi/.test(userAgent);

//       if (isSafariBrowser && isNotMobile) {
//         setIsSafari(true);
//       }
//     };
//     detectSafariBrowser();
//   }, []);

//   const handleOpenHelpPopup = () => {
//     setOpenHelpPopup(true);
//   };

//   const handleCloseHelpPopup = () => {
//     setOpenHelpPopup(false);
//   };

//   React.useEffect(() => {
//     if (browserSupportsSpeechRecognition){
//       window.addEventListener('resize', resizeCanvases, false);
//       resizeCanvases();

//       return function cleanup() {
//         window.removeEventListener('resize', resizeCanvases, false);
//         awaitingResp.current = false;
//         isListening.current = false;
//         setIsPlayingAudio(false);
//         setDisableMicrophone(false);
//         stopAudio();
//       }
//     } else {
//       // automatically switch to chat if browser not supported
//       toggleVoiceChat(false);
//     }
//   }, []); //eslint-disable-line react-hooks/exhaustive-deps

//   React.useEffect(() => {
//     if (messageDataItem === undefined){
//       // send initial message here
//       handleStopListening(true);
//     }
//   }, [messageDataItem]) //eslint-disable-line react-hooks/exhaustive-deps

//   const startListening = () => {
//     if (!isListening.current){
//       resetTranscript();
//       SpeechRecognition.startListening({ continuous: true });
//       listeningStartTime.current = Date.now();
//       isListening.current = true;
//       requestAnimationFrame(listeningAnimation);
//     }
//   };

//   const handleStopListening = async (initialMessage: Boolean) => {
//     SpeechRecognition.stopListening();
//     isListening.current = false;
//     if (transcript.length > 0 || initialMessage){
//       emptyTranscriptCount.current = 0;
//       awaitingResp.current = true;
//       awaitingRespStartTime.current = Date.now();
//       setDisableMicrophone(true);
//       resizeCanvases(); // force animations to update sizes
//       requestAnimationFrame(thinkingAnimation);
//       try {
//         let msg = await handleMessageSend(transcript || "", !!initialMessage, true)
//         chunkAndGetTTS(msg);
//       } catch (err) {
//         console.log(err)
//         awaitingResp.current = false;
//         setDisableMicrophone(false);
//       };
//     } else {
//       emptyTranscriptCount.current++;
//       if (emptyTranscriptCount.current >= 3){
//         showCheckPermissionsModal();
//       }
//     }
//   };

//   const stopAudio = () => {
//     audioQueue.current?.forceStop();
//   };

//   useEffect(() => {
//     if (isAwaitingMessage) {
//       awaitingResp.current = true;
//       setIsAwaitingMessage(false);
//     }
//   }, [isAwaitingMessage]) //eslint-disable-line react-hooks/exhaustive-deps

//   useEffect(() => {
//     if (shouldPlayMessage) {
//       handlePlayMessage();
//       setShouldPlayMessage(false);
//     }
//   }, [shouldPlayMessage]) //eslint-disable-line react-hooks/exhaustive-deps

//   useEffect(() => {
//     if (shouldStopAudio) {
//       setShouldStopAudio(false);
//       stopAudio();
//     }
//   }, [shouldStopAudio]) //eslint-disable-line react-hooks/exhaustive-deps

//   const handlePlayMessage = async () => {
//     awaitingResp.current = true;
//     awaitingRespStartTime.current = Date.now();
//     setDisableMicrophone(true);
//     resizeCanvases(); // force animations to update sizes
//     requestAnimationFrame(thinkingAnimation);

//     chunkAndGetTTS(message)
//   };

//   function chunkAndGetTTS(message: string){
//       if (message){
//         try {
//           let msg = preprocessTextForVoice(message);
//           let sentences = msg.split(". ");
//           // rejoin chunks to avoid rate limits
//           // keep first sentence alone so it plays as fast as possible
//           // everything else should be less than 500 characters long
//           let chunks = [];
//           if (sentences.length) chunks.push(sentences[0]);
//           if (sentences.length > 1) chunks.push(sentences[1]);
//           for (let i = 2; i < sentences.length; i++) {
//             let str = sentences[i];
//             if (chunks[chunks.length - 1].length + str.length + 2 < 500) {
//               chunks[chunks.length - 1] += ". " + str;
//             } else {
//               chunks.push(str);
//             }
//           }

//           // create queue which will start playing when first chunk is recieved
//           audioQueue.current = new AudioChunkQueue(chunks,
//               creatorProfileData.voiceId,
//               awaitingResp,
//               volumeControl,
//               audioAnalyzer,
//               setDisableMicrophone,
//               setIsPlayingAudio,
//               volume.current,
//               setErrorCode,
//               setShowTimeoutModal,
//           );
//           eventTracker('voice-chat');
//         } catch (e) {
//           eventTracker('voice-chat failed');
//           throw e;
//         }

//       } else {
//         awaitingResp.current = false;
//         setDisableMicrophone(false);
//       }
//   };

//   function resizeCanvases() {
//     // update zoom level:
//     function updateZoom(){
//       if (window.screen.availWidth < 900){
//         // force mobile devices to look a certain way
//         px_ratio.current = 1;
//       } else {
//         let newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
//         if(newPx_ratio !== px_ratio.current){
//           px_ratio.current = newPx_ratio;
//         }
//       }
//     }
//     updateZoom();

//     const microphoneBox = document.getElementById('microphone-canvas-box');
//     const avatarCanvasBox = document.getElementById('avatar-canvas-box');
//     if (microphoneCanvas.current){
//       microphoneCanvas.current.width = microphoneBox.clientWidth;
//       microphoneCanvas.current.height = microphoneBox.clientHeight;
//       requestAnimationFrame(listeningAnimation);
//     }
//     if (avatarCanvas.current){
//       avatarCanvas.current.width = avatarCanvasBox.clientWidth;
//       avatarCanvas.current.height = avatarCanvasBox.clientHeight;
//       requestAnimationFrame(thinkingAnimation);
//     }
//     setForceRefresh(!forceRefresh)
//   };

//   const animateConcentricCircles = (ctx, center, innerRadius, outerRadius) => {
//     ctx.fillStyle = '#FFFFFF'; // white
//     ctx.beginPath();
//     ctx.arc(...center, outerRadius+5,0,2*Math.PI);
//     ctx.fill();

//     ctx.fillStyle = '#94D7FF'; // light blue
//     ctx.beginPath();
//     ctx.arc(...center, outerRadius,0,2*Math.PI);
//     ctx.fill();

//     ctx.fillStyle = '#5EABDA'; // main blue
//     ctx.beginPath();
//     ctx.arc(...center, innerRadius + (outerRadius-innerRadius)*2/3,0,2*Math.PI);
//     ctx.fill();

//     ctx.fillStyle = '#3486B8'; // dark blue
//     ctx.beginPath();
//     ctx.arc(...center, innerRadius + (outerRadius-innerRadius)*1/3,0,2*Math.PI);
//     ctx.fill();
//   };

//   const listeningAnimation = () => {
//     const canvas = microphoneCanvas.current;
//     if (canvas){
//       const ctx = canvas.getContext('2d');
//       ctx.clearRect(0,0,canvas.width,canvas.height);
//       const grd = ctx.createLinearGradient(0, 0, 0, canvas.height);
//       grd.addColorStop(0, "white");
//       grd.addColorStop(1, "#B8E5FA");
//       ctx.fillStyle = grd;
//       ctx.fillRect(0,0,canvas.width,canvas.height);

//       if (!isListening?.current){
//         // solid rectangle
//         ctx.fillStyle = '#8ad5f8'; // light blue
//         ctx.fillRect(0,32+32,
//                     canvas.width,canvas.height-32-32);
//       } else {
//         let maxAmp = (32+32);
//         let startY = (32+32);
//         let amp, freq, phase;

//         ctx.fillStyle = '#3a9de0'; // dark blue
//         amp = maxAmp/4 * Math.sin(0.96*(Date.now()-awaitingRespStartTime.current)/1000); // 1s oscillations
//         freq = 2*Math.PI/canvas.width * 2.78; // 2 waves per canvas width
//         phase = 2*Math.PI*((0.7*(Date.now()-awaitingRespStartTime.current)/1000) % 1);
//         fillWave(canvas, ctx, startY, amp, freq, phase);

//         ctx.fillStyle = '#3EACEF'; // main blue
//         amp = maxAmp/2.5 * Math.sin(1.13*(Date.now()-awaitingRespStartTime.current)/1000); // 1s oscillations
//         freq = 2*Math.PI/canvas.width * 3.14; // 2 waves per canvas width
//         phase = 2*Math.PI*((-0.4*(Date.now()-awaitingRespStartTime.current)/1000) % 1);
//         fillWave(canvas, ctx, startY, amp, freq, phase);

//         ctx.fillStyle = '#8ad5f8'; // light blue
//         amp = maxAmp/3 * Math.sin(3*(Date.now()-awaitingRespStartTime.current)/1000); // 1s oscillations
//         freq = 2*Math.PI/canvas.width * 2; // 2 waves per canvas width
//         phase = 2*Math.PI*((0.38*(Date.now()-awaitingRespStartTime.current)/1000) % 1);
//         fillWave(canvas, ctx, startY, amp, freq, phase);
//         // keep drawing animation until a response is recieved
//         requestAnimationFrame(listeningAnimation)
//       }
//     }
//   }

//   const fillWave = (canvas, ctx, startY, amplitude, freq, phase) => {
//     ctx.beginPath();
//     ctx.moveTo(0, startY);

//     for (let x = 0; x < canvas.width; x++){
//       let y = amplitude * Math.sin(freq*x + phase);
//       ctx.lineTo(x,y+startY);
//     }

//     ctx.lineTo(canvas.width, canvas.height);
//     ctx.lineTo(0, canvas.height);
//     ctx.lineTo(0,startY);
//     ctx.fill();
//   };

//   const thinkingAnimation = () => {
//     const canvas = avatarCanvas.current;
//     if (canvas){
//       const ctx = canvas.getContext('2d');
//       ctx.clearRect(0,0,canvas.width,canvas.height);

//       if (awaitingResp.current) {
//         // keep drawing animation until a response is recieved
//         let curTime = Date.now();
//         let freq = 0.75; // Hz
//         let center = [canvas.width/2, canvas.height/2];
//         let radius = canvas.width/2/1.5 + 10;

//         ctx.translate(...center);
//         ctx.save()
//         ctx.beginPath()
//         ctx.arc(0, 0, radius, 0, Math.PI * 2, false)
//         ctx.clip()
//         const grd = ctx.createLinearGradient(0, 0, radius, 0);
//         grd.addColorStop(0, "#3a9de0");
//         grd.addColorStop(1, "white");
//         ctx.fillStyle = grd;
//         ctx.rotate(2*Math.PI * (freq*curTime/1000 % 1));
//         ctx.fillRect(0, 0, radius, radius);
//         ctx.closePath();
//         ctx.restore();
//         ctx.resetTransform();

//         requestAnimationFrame(thinkingAnimation)
//       }
//     }
//   };

//   const speakingAnimation = () => {
//     const canvas = avatarCanvas.current;
//     if (canvas){
//       const ctx = canvas.getContext('2d');
//       ctx.clearRect(0,0,canvas.width,canvas.height);

//       if (isPlayingAudio.current){
//         // draw speaking animation
//         let curTime = Date.now();
//         let center = [canvas.width/2, canvas.height/2];
//         let innerRadius = canvas.width/2/1.5 + 5;
//         let freq = 0.5; // frequency of pulsation in Hz
//         let maxChange = canvas.width/2 - innerRadius;
//         let outerRadius: any;

//         if (audioAnalyzer.current) {
//           const bufferLength = audioAnalyzer.current.frequencyBinCount;
//           const dataArray = new Uint8Array(bufferLength);
//           audioAnalyzer.current.getByteFrequencyData(dataArray);

//           let sum = 0;
//           dataArray.forEach(el => sum += el * el)

//           const volume = Math.sqrt(sum / dataArray.length)

//           // circle radius increases with volume
//           outerRadius = innerRadius + maxChange*volume/80;
//         } else {
//           // show sinusoidal animation if no analyzer
//           outerRadius = innerRadius + maxChange/2*(1-Math.cos(2*Math.PI*freq*(curTime-listeningStartTime.current)/1000));
//         }
//         animateConcentricCircles(ctx, center, innerRadius, outerRadius);

//         requestAnimationFrame(speakingAnimation)
//       }
//     }
//   };

//   if (!browserSupportsSpeechRecognition) {
//     return <span>Browser doesn't support speech recognition.</span>;
//   }

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'stretch',
//         height: '100%',
//         width: '100%',
//         marginTop: isWidget ? '20px' : '0',
//       }}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: px_ratio.current < 1.5 ? 'column' : 'row',
//           alignItems: 'center',
//           justifyContent: 'space-evenly',
//           flexGrow: 1,
//           width: '100%',
//           maxHeight: 'calc(100% - 128px)',
//           overflowY: 'hidden',
//         }}
//       >
//         <Box
//           sx={{
//             width: isWidget ? '30vh':'34vh',
//             height: isWidget ? '30vh': '34vh',
//             position: 'relative',
//             mr: px_ratio.current < 1.5 ? '0px' : '25px',
//           }}
//         >
//           <Box
//             id='avatar-canvas-box'
//             sx={{
//               position: 'absolute',
//               width: '150%',
//               height: '150%',
//               top: '50%',
//               left: '50%',
//               transform: 'translate(-50%, -50%)',
//               overflow: 'hidden',
//             }}>
//             <canvas
//               id='avatar-canvas'
//               ref={setAvatarCanvas}
//             ></canvas>
//           </Box>
//           <Avatar
//             src={creatorProfileData?.profilePhotoURL}
//             alt={creatorProfileData?.fullName}
//             sx={{
//               width: '100%',
//               height: '100%',
//               fontSize: '8.125rem'
//             }}
//           >{creatorProfileData?.fullName ? creatorProfileData?.fullName[0] : ''}</Avatar>
//         </Box>
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             width: '100%',
//           }}
//         >
//           <Typography p={1} sx={{zIndex: 3}}>
//             {creatorProfileData?.fullName}
//           </Typography>

//           { showTranscript && (!awaitingResp.current || isPlayingAudio) && messageDataItem?.direction === 'incoming' &&
//             <Box
//               sx={{
//                 p: 1,
//                 zIndex: 3,
//                 mr: 2,
//                 ml: 2,
//                 mb: 1,
//                 maxWidth: {
//                   xs: '100%',
//                   md: '75%'
//                 },
//                 maxHeight: 'calc(66vh  - 324px)',
//                 overflowY: 'scroll',
//               }}
//             >
//               <Markdown className={`markdown ${messageDataItem.direction}`}>
//                 {preprocessLaTeX(messageDataItem.editedMessage) || preprocessLaTeX(messageDataItem.message)}
//               </Markdown>
//             </Box>
//           }

//           {/* for liking/unliking response */}
//           {
//             (enableMonetization && !isAnonymousUser && !isSelfChat && !isWidget &&
//             ((!requiredPremiumKey && !ignoreCredits && (credits === 0)) ||
//              (requiredPremiumKey && !premiumAccess && !ignoreCredits))) ?
//             (
//               (requiredPremiumKey && !premiumAccess) ?
//               <PremiumPurchaseButton requiredPremiumKey={requiredPremiumKey} redirectURL={`/${creatorProfileData.userName}/chat`}/> :
//               authUser.isSubscribed ?
//                 <AdditionalCreditsButton redirectURL={`/${creatorProfileData.userName}/chat`}/> :
//                 <SubscribeButton redirectURL={`/${creatorProfileData.userName}/chat`}/>
//             ) :
//             <Box
//               sx={{
//                 display: 'flex',
//                 visibility: messageDataItem && !awaitingResp.current ? 'visible' : 'hidden'
//               }}
//             >
//               <IconButton
//                 id={`button-like-message-${documentId}`}
//                 sx={{
//                   padding: '4px',
//                   margin: '0px 15px',
//                   color: likeDislike === 1 ? '#4B9CCD' : '#7D7D7D',
//                 }}
//                 onClick={() => handleLikeDislikeMessage(documentId, 1)}
//               >
//                 <ThumbsUpIcon
//                   height='30px'
//                   width='30px'
//                   fill={likeDislike === 1 ? '#4B9CCD' : 'none'}
//                 />
//               </IconButton>
//               <IconButton
//                 id={`button-dislike-message-${documentId}`}
//                 sx={{
//                   padding: '4px',
//                   margin: '0px 15px',
//                   color: likeDislike === 2 ? '#4B9CCD' : '#7D7D7D',
//                 }}
//                 onClick={() => handleLikeDislikeMessage(documentId, 2)}
//               >
//                 <ThumbsDownIcon
//                   height='30px'
//                   width='30px'
//                   fill={likeDislike === 2 ? '#4B9CCD' : 'none'}
//                 />
//               </IconButton>
//               <IconButton
//                 id={`button-play-message-${documentId}`}
//                 sx={{
//                   padding: '4px',
//                   margin: '0px 15px',
//                   color: '#4B9CCD',
//                 }}
//                 onClick={handlePlayMessage}
//               >
//                 <PlayArrowIcon
//                   sx={{
//                     height: '30px',
//                     width: '30px',
//                     fill: 'none',
//                     stroke: '#7D7D7D',
//                     strokeWidth: '0.6px'
//                   }}
//                 />
//               </IconButton>

//             </Box>
//           }
//         </Box>
//       </Box>
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           justifyContent: 'flex-end',
//           width: '100%',
//           maxHeight: '128px'
//         }}
//       >
//         <Box
//           id='microphone-canvas-box'
//           sx={{
//             position: 'absolute',
//             width: '100%',
//             height: '100%',
//             maxHeight: '128px',
//             overflow: 'hidden'
//           }}
//         >
//           <canvas
//             id='microphone-canvas'
//             ref={setMicrophoneCanvas}
//             width='800'
//           />

//         </Box>

//         {
//           showAssignmentButton &&
//           <Popover
//             open={showAssignmentButton}
//             anchorEl={micButton.current}
//             anchorOrigin={{
//               vertical: 'center',
//               horizontal: 'center'
//             }}
//             transformOrigin={{
//               vertical: 'center',
//               horizontal: 'center',
//             }}
//             slotProps={{
//               paper: {
//                 sx: {
//                   background: 'transparent',
//                   boxShadow: 'none',
//                 }
//               }
//             }}
//             sx={{
//               zIndex: 1,
//             }}
//           >
//             <Button
//               onClick={handleStartAssignment}
//               variant="contained"
//               sx={{
//                 mt: 2,
//                 mb: 2,
//                 ml: 2,
//                 mr: 2,
//                 maxWidth: '200px',
//                 alignSelf: 'center',
//                 boxShadow: '0 0 0 12px #C2E8FF',
//                 '&:hover': {
//                   boxShadow: '0 0 0 12px #C2E8FF !important',
//                 }
//               }}>
//               Start Assignment
//             </Button>
//           </Popover>
//         }

//         <VoiceChatToggle
//           toggleVoiceChat={toggleVoiceChat}
//           useVoiceChat={useVoiceChat}
//           audioQueue={audioQueue}
//         />

//         <IconButton
//           id={`button-microphone`}
//           ref={setMicButton}
//           sx={{
//             padding: '10px',
//             mt: '39px',
//             mb: '39px',
//             backgroundColor: 'white',
//             boxShadow: `0 0 5px ${grey[500]},
//                         0 0 0 12px #C2E8FF`,
//             ':hover': {
//               backgroundColor: 'white',
//               '& svg': {
//                 color: isListening.current ? 'red' : '#3486B8',
//                 transition: 'all 0.25s',
//               }
//             },
//             '&.Mui-disabled': {
//               backgroundColor: 'white',
//             }
//           }}
//           disabled={((enableMonetization && !isAnonymousUser && !isWidget &&
//             !isSelfChat && !ignoreCredits &&
//             ((!requiredPremiumKey && (credits === 0)) || (requiredPremiumKey && !premiumAccess)))
//             || disableMicrophone
//             || showAssignmentButton)}
//           onPointerDown={() => {
//             if (!isListening.current){
//               startListening();
//             } else {
//               handleStopListening(false);
//             }
//           }}
//         >
//           {
//             isListening.current ?
//             <StopIcon
//               sx={{
//                 height: '30px',
//                 width: '30px',
//                 color: 'red'
//               }}
//             /> :
//             <MicIcon
//               sx={{
//                 height: '30px',
//                 width: '30px',
//                 color: ((enableMonetization && !isAnonymousUser && !isSelfChat && !ignoreCredits && (credits === 0)) || disableMicrophone) ? grey[400] : (isListening.current ? '#3486B8' : grey[900])
//               }}
//             />
//           }
//         </IconButton>
//         {
//           <Typography
//             sx={{
//               position: 'relative',
//               height: '0px',
//               top: '-9.5em',
//               color: '#3486B8',
//             }}
//           >
//             {((enableMonetization && !isAnonymousUser && !isSelfChat && !ignoreCredits && (credits === 0)) || disableMicrophone || showAssignmentButton ) ? ' ' : (isListening.current ? "Listening..." : "Click to start speaking...")}
//           </Typography>
//         }

//       {isSafari && (
//           <Box
//             sx={{
//               position: 'absolute',
//               right: '20px',
//               top: '10px',
//               cursor: 'pointer',
//             }}
//             onClick={handleOpenHelpPopup}
//           >
//             <Typography variant="body2" color="primary">
//               Having Trouble with Voice?
//             </Typography>
//           </Box>
//         )}

//         <Dialog open={openHelpPopup} onClose={handleCloseHelpPopup}>
//           <DialogContent>
//             <Typography
//               variant="h5"
//               gutterBottom
//               align="center"
//               sx={{ fontWeight: 'bold' }}
//             >
//               Having Trouble With Voice?
//             </Typography>
//             <Typography
//                 align="center"
//                 sx={{ color: 'black', fontSize: '1.2rem' }}
//               >
//               You may need to adjust your safari settings!
//             </Typography>
//             <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
//               <img src={safarivoice} alt="Safari Voice Settings Help" style={{ maxWidth: '100%' }} />
//             </Box>
//           </DialogContent>
//            <DialogActions sx={{ justifyContent: 'center', mt: -3, paddingBottom: '20px', width: '100%' }}>
//               <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
//                 <Button
//                   onClick={handleCloseHelpPopup}
//                   variant='outlined'
//                   sx={{
//                     color: '#009CDB !important',
//                     border: '1px solid #009CDB !important',
//                     width: '50%',
//                     maxHeight: '36px'
//                   }}
//                 >
//                   I'm not having any problems
//                 </Button>
//                 <Button
//                   onClick={() => window.open('/faq')}
//                   variant='contained'
//                   sx={{ width: '50%',maxHeight: '36px' }}
//                 >
//                   Go to FAQs
//                 </Button>
//               </Box>
//             </DialogActions>

//         </Dialog>
//       </Box>
//     </Box>
//   );
// };

// export default VoiceChat;

import React, {
  useState,
  useRef,
  useContext,
  useCallback,
  useEffect,
  useLayoutEffect,
} from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import {
  Avatar,
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from '@mui/material';
import ThumbsUpIcon from '../../svg/ThumbsUpIcon';
import ThumbsDownIcon from '../../svg/ThumbsDownIcon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import { MessageDataType } from './messages.types';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import { grey } from '@mui/material/colors';
import VoiceChatToggle from '../../component/voiceChatToggle';
import AudioChunkQueue from './audioChunkQueue';
import { VolumeContext } from '../../context/VolumeContext';
import useAnalyticsEventTracker from '../../hooks/useAnalyticsEventTracker';
import { preprocessTextForVoice, preprocessLaTeX } from '../../utils/helper';
import SubscribeButton from '../Purchases/subscribeButton';
import { useAuth } from '../../hooks/useAuth';
import AdditionalCreditsButton from '../Purchases/additionalCreditsButton';
import Markdown from './Markdown';
import PremiumPurchaseButton from '../Purchases/premiumPurchaseButton';
import safarivoice from '../../assets/images/Safarivoicefix.png';
import Lottie from 'lottie-react';
import thinkingAnimation from './thinkingAnimation.json';
import { useTheme } from '@mui/material/styles';
import VolumeMeter from './VolumeMeter';
import ScrollingTextField from './ScrollingTextField';
import { CreditsContext } from '../../context/CreditsContext';
import { motion } from 'framer-motion';
import { ContinuousColorLegend } from '@mui/x-charts';

interface VoiceChatProps {
  handleMessageSend: (
    userMessage: string,
    firstMessage: boolean,
    isVoiceMessage: boolean
  ) => Promise<any>;
  creatorProfileData: any;
  handleLikeDislikeMessage: (documentId: string, value: number) => void;
  messageDataItem?: MessageDataType;
  toggleVoiceChat: (newSetting: boolean) => void;
  useVoiceChat: boolean;
  setErrorCode: React.Dispatch<React.SetStateAction<number>>;
  setShowTimeoutModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCheckPermissionsModal: () => void;
  isWidget?: boolean;
  showTranscript?: boolean;
  ignoreCredits?: boolean;
  showAssignmentButton?: boolean;
  handleStartAssignment?: () => void;
  isAwaitingMessage?: boolean;
  setIsAwaitingMessage?: React.Dispatch<React.SetStateAction<boolean>>;
  shouldPlayMessage?: boolean;
  shouldStopAudio?: boolean;
  setShouldStopAudio?: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldPlayMessage?: React.Dispatch<React.SetStateAction<boolean>>;
  requiredPremiumKey?: string;
  premiumAccess?: boolean;
}

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

const VoiceChat: React.FC<VoiceChatProps> = (props) => {
  const {
    handleMessageSend,
    creatorProfileData,
    handleLikeDislikeMessage,
    messageDataItem,
    toggleVoiceChat,
    useVoiceChat,
    setErrorCode,
    setShowTimeoutModal,
    showCheckPermissionsModal,
    isWidget = false,
    ignoreCredits,
    showAssignmentButton,
    handleStartAssignment,
    showTranscript,
    isAwaitingMessage,
    setIsAwaitingMessage,
    shouldPlayMessage,
    setShouldPlayMessage,
    shouldStopAudio = false,
    setShouldStopAudio,
    requiredPremiumKey,
    premiumAccess,
  } = props;

  const documentId = messageDataItem?.documentId;
  const likeDislike = messageDataItem?.likeDislike;
  const message = messageDataItem?.editedMessage || messageDataItem?.message;
  const eventTracker = useAnalyticsEventTracker();

  const {
    transcript,
    resetTranscript,
    browserSupportsSpeechRecognition,
    interimTranscript,
    finalTranscript,
  } = useSpeechRecognition();

  const [disableMicrophone, setDisableMicrophone] = useState(false);
  const listeningStartTime = useRef(0);
  const avatarCanvas = useRef(null);
  const setAvatarCanvas = useCallback((val: any) => {
    resizeCanvases();
    avatarCanvas.current = val;
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const microphoneCanvas = useRef(null);
  const setMicrophoneCanvas = useCallback((val: any) => {
    resizeCanvases();
    microphoneCanvas.current = val;
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const micButton = useRef(null);
  const setMicButton = useCallback((val: any) => {
    micButton.current = val;
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const isListening = useRef(false);
  const isPlayingAudio = useRef(false);
  const setIsPlayingAudio = useCallback((val: boolean) => {
    requestAnimationFrame(speakingAnimation);
    isPlayingAudio.current = val;
    forceRenderIsPlayingAudio((prev) => !prev);
  }, []); //eslint-disable-line react-hooks/exhaustive-deps
  const [, forceRenderIsPlayingAudio] = useState(false);
  const awaitingResp = useRef(false);
  const awaitingRespStartTime = useRef(Date.now());
  const audioAnalyzer = useRef(null);
  const { volume } = useContext(VolumeContext);
  const volumeControl = useRef(null);
  const audioQueue = useRef(null);
  const emptyTranscriptCount = useRef(0);
  // const px_ratio = useRef(
  //   window.devicePixelRatio ||
  //     window.screen.availWidth / document.documentElement.clientWidth
  // );
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);
  const { credits } = useContext(CreditsContext);
  const { authUser, isAnonymousUser } = useAuth();
  const isSelfChat = authUser?.documentId === creatorProfileData?.documentId;
  const [isSafari, setIsSafari] = useState(false);
  const [openHelpPopup, setOpenHelpPopup] = useState(false);

  const parentRef = useRef(null);
  // const [leftValue, setLeftValue] = useState('0px');
  const hasMounted = useRef(false);
  const theme = useTheme();

  const [activeChunkIndex, setActiveChunkIndex] = useState<number>(-1);
  const [messageChunks, setMessageChunks] = useState<string[]>([]);

  const [animateUserTranscript, setAnimateUserTranscript] = useState(false);

  useEffect(() => {
    if (awaitingResp.current) {
      setAnimateUserTranscript(true);
    }
  }, [awaitingResp.current]);

  useEffect(() => {
    if (isListening.current) {
      setAnimateUserTranscript(false);
    }
  }, [isListening.current]);

  const firstMotionDivRef = useRef(null);
  const [firstDivHeight, setFirstDivHeight] = useState(0);

  useEffect(() => {
    if (firstMotionDivRef.current) {
      setFirstDivHeight(firstMotionDivRef.current.offsetHeight);
    }
  }, [transcript, animateUserTranscript]);

  const [animatePersonaTranscript, setAnimatePersonaTranscript] =
    useState(false);

  useEffect(() => {
    if (isPlayingAudio.current) {
      setAnimatePersonaTranscript(true);
    }
  }, [isPlayingAudio.current]);

  useEffect(() => {
    if (isListening.current) {
      setAnimatePersonaTranscript(false);
    }
  }, [isListening.current]);

  // useEffect(() => {
  //   if (firstMotionDivRef.current) {
  //     setFirstDivHeight(firstMotionDivRef.current.offsetHeight);
  //   }
  // }, [transcript, animate]);

  const isDarkMode = theme.palette.mode === 'dark';
  // const calculateLeft = () => {
  //   if (parentRef.current) {
  //     const parentWidth = parentRef.current.offsetWidth;
  //     setLeftValue(
  //       parentWidth > 1500 ? `${(parentWidth - 1500) / 2 + 24}px` : '0px'
  //     );
  //   }
  // };

  // useLayoutEffect(() => {
  //   calculateLeft(); // Initial calculation
  //   window.addEventListener('resize', calculateLeft); // Recalculate on resize
  //   return () => window.removeEventListener('resize', calculateLeft); // Cleanup listener
  // }, []);

  useEffect(() => {
    const detectSafariBrowser = () => {
      const userAgent = navigator.userAgent;
      const isSafariBrowser =
        /Safari/.test(userAgent) && !/Chrome|CriOS/.test(userAgent);
      const isNotMobile = !/Mobi/.test(userAgent);

      if (isSafariBrowser && isNotMobile) {
        setIsSafari(true);
      }
    };
    detectSafariBrowser();
    startListening();
  }, []);

  const handleOpenHelpPopup = () => {
    setOpenHelpPopup(true);
  };

  const handleCloseHelpPopup = () => {
    setOpenHelpPopup(false);
  };

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    const timeout = setTimeout(() => {
      if (isListening.current) {
        handleStopListening(false);
      }
    }, 3000);
    return () => clearTimeout(timeout);
  }, [interimTranscript]);

  const handleChunkChange = useCallback((index: number) => {
    setActiveChunkIndex(index);
  }, []);

  React.useEffect(() => {
    if (browserSupportsSpeechRecognition) {
      window.addEventListener('resize', resizeCanvases, false);
      resizeCanvases();

      return function cleanup() {
        window.removeEventListener('resize', resizeCanvases, false);
        awaitingResp.current = false;
        isListening.current = false;
        setIsPlayingAudio(false);
        setDisableMicrophone(false);
        stopAudio();
      };
    } else {
      // automatically switch to chat if browser not supported
      toggleVoiceChat(false);
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (messageDataItem === undefined) {
      // send initial message here
      handleStopListening(true);
    }
  }, [messageDataItem]); //eslint-disable-line react-hooks/exhaustive-deps

  const startListening = () => {
    if (!isListening.current) {
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
      listeningStartTime.current = Date.now();
      isListening.current = true;
      requestAnimationFrame(listeningAnimation);
    }
  };

  const handleStopListening = async (initialMessage: Boolean) => {
    SpeechRecognition.stopListening();
    isListening.current = false;
    if (transcript.length > 0 || initialMessage) {
      emptyTranscriptCount.current = 0;
      awaitingResp.current = true;
      awaitingRespStartTime.current = Date.now();
      setDisableMicrophone(true);
      resizeCanvases(); // force animations to update sizes
      // requestAnimationFrame(thinkingAnimation);
      try {
        let msg = await handleMessageSend(
          transcript || '',
          !!initialMessage,
          true
        );
        chunkAndGetTTS(msg);
      } catch (err) {
        console.log(err);
        awaitingResp.current = false;
        setDisableMicrophone(false);
      }
    } else {
      emptyTranscriptCount.current++;
      if (emptyTranscriptCount.current >= 3) {
        showCheckPermissionsModal();
      }
    }
  };

  const stopAudio = () => {
    audioQueue.current?.forceStop();
  };

  useEffect(() => {
    if (isAwaitingMessage) {
      awaitingResp.current = true;
      setIsAwaitingMessage(false);
    }
  }, [isAwaitingMessage]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldPlayMessage) {
      handlePlayMessage();
      setShouldPlayMessage(false);
    }
  }, [shouldPlayMessage]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldStopAudio) {
      setShouldStopAudio(false);
      stopAudio();
    }
  }, [shouldStopAudio]); //eslint-disable-line react-hooks/exhaustive-deps

  const handlePlayMessage = async () => {
    awaitingResp.current = true;
    awaitingRespStartTime.current = Date.now();
    setDisableMicrophone(true);
    resizeCanvases(); // force animations to update sizes
    // requestAnimationFrame(thinkingAnimation);

    chunkAndGetTTS(message);
  };

  function chunkAndGetTTS(message: string) {
    if (message) {
      try {
        let msg = preprocessTextForVoice(message);
        let sentences = msg.split('. ');
        setMessageChunks(sentences.map((s) => s.trim() + '.'));
        // rejoin chunks to avoid rate limits
        // keep first sentence alone so it plays as fast as possible
        // everything else should be less than 500 characters long
        let chunks = [];
        if (sentences.length) chunks.push(sentences[0]);
        if (sentences.length > 1) chunks.push(sentences[1]);
        for (let i = 2; i < sentences.length; i++) {
          let str = sentences[i];
          if (chunks[chunks.length - 1].length + str.length + 2 < 500) {
            chunks[chunks.length - 1] += '. ' + str;
          } else {
            chunks.push(str);
          }
        }

        // create queue which will start playing when first chunk is recieved
        audioQueue.current = new AudioChunkQueue(
          chunks,
          creatorProfileData.voiceId,
          awaitingResp,
          volumeControl,
          audioAnalyzer,
          setDisableMicrophone,
          setIsPlayingAudio,
          volume.current,
          setErrorCode,
          setShowTimeoutModal,
          startListening,
          handleChunkChange
        );
        eventTracker('voice-chat');
      } catch (e) {
        eventTracker('voice-chat failed');
        throw e;
      }
    } else {
      awaitingResp.current = false;
      setDisableMicrophone(false);
    }
  }

  function resizeCanvases() {
    // update zoom level:
    // function updateZoom() {
    //   if (window.screen.availWidth < 900) {
    //     // force mobile devices to look a certain way
    //     px_ratio.current = 1;
    //   } else {
    //     let newPx_ratio =
    //       window.devicePixelRatio ||
    //       window.screen.availWidth / document.documentElement.clientWidth;
    //     if (newPx_ratio !== px_ratio.current) {
    //       px_ratio.current = newPx_ratio;
    //     }
    //   }
    // }
    // updateZoom();

    const microphoneBox = document.getElementById('microphone-canvas-box');
    const avatarCanvasBox = document.getElementById('avatar-canvas-box');
    if (microphoneCanvas.current) {
      microphoneCanvas.current.width = microphoneBox.clientWidth;
      microphoneCanvas.current.height = microphoneBox.clientHeight;
      requestAnimationFrame(listeningAnimation);
    }
    if (avatarCanvas.current) {
      avatarCanvas.current.width = avatarCanvasBox.clientWidth;
      avatarCanvas.current.height = avatarCanvasBox.clientHeight;
      // requestAnimationFrame(thinkingAnimation);
    }
    setForceRefresh(!forceRefresh);
  }

  const animateConcentricCircles = (ctx, center, innerRadius, outerRadius) => {
    // solid color
    // ctx.fillStyle = theme.palette.background['paper-elevation-19'];

    // ctx.beginPath();
    // ctx.arc(...center, outerRadius + 5, 0, 2 * Math.PI);
    // ctx.fill();

    // gradient
    const [x, y] = center;

    const gradient = ctx.createRadialGradient(
      x,
      y,
      innerRadius,
      x,
      y,
      outerRadius
    );

    gradient.addColorStop(0, theme.palette.background['paper-elevation-25']); // Inner color
    gradient.addColorStop(1, theme.palette.background['paper-elevation-26']); // Outer color
    ctx.fillStyle = gradient;

    ctx.beginPath();
    ctx.arc(x, y, outerRadius + 5, 0, 2 * Math.PI);
    ctx.fill();

    // ctx.fillStyle = '#94D7FF'; // light blue
    // ctx.beginPath();
    // ctx.arc(...center, outerRadius, 0, 2 * Math.PI);
    // ctx.fill();

    // ctx.fillStyle = '#5EABDA'; // main blue
    // ctx.beginPath();
    // ctx.arc(
    //   ...center,
    //   innerRadius + ((outerRadius - innerRadius) * 2) / 3,
    //   0,
    //   2 * Math.PI
    // );
    // ctx.fill();

    // ctx.fillStyle = '#3486B8'; // dark blue
    // ctx.beginPath();
    // ctx.arc(
    //   ...center,
    //   innerRadius + ((outerRadius - innerRadius) * 1) / 3,
    //   0,
    //   2 * Math.PI
    // );
    // ctx.fill();
  };

  const listeningAnimation = () => {
    const canvas = microphoneCanvas.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // const grd = ctx.createLinearGradient(0, 0, 0, canvas.height);
      // grd.addColorStop(0, 'white');
      // grd.addColorStop(1, '#B8E5FA');
      // ctx.fillStyle = grd;
      // ctx.fillRect(0, 0, canvas.width, canvas.height);

      if (!isListening?.current) {
        // solid rectangle
        ctx.fillStyle = '#8ad5f8'; // light blue
        ctx.fillRect(0, 32 + 32, canvas.width, canvas.height - 32 - 32);
      } else {
        let maxAmp = 32 + 32;
        let startY = 32 + 32;
        let amp, freq, phase;

        ctx.fillStyle = '#3a9de0'; // dark blue
        amp =
          (maxAmp / 4) *
          Math.sin(
            (0.96 * (Date.now() - awaitingRespStartTime.current)) / 1000
          ); // 1s oscillations
        freq = ((2 * Math.PI) / canvas.width) * 2.78; // 2 waves per canvas width
        phase =
          2 *
          Math.PI *
          (((0.7 * (Date.now() - awaitingRespStartTime.current)) / 1000) % 1);
        fillWave(canvas, ctx, startY, amp, freq, phase);

        ctx.fillStyle = '#3EACEF'; // main blue
        amp =
          (maxAmp / 2.5) *
          Math.sin(
            (1.13 * (Date.now() - awaitingRespStartTime.current)) / 1000
          ); // 1s oscillations
        freq = ((2 * Math.PI) / canvas.width) * 3.14; // 2 waves per canvas width
        phase =
          2 *
          Math.PI *
          (((-0.4 * (Date.now() - awaitingRespStartTime.current)) / 1000) % 1);
        fillWave(canvas, ctx, startY, amp, freq, phase);

        ctx.fillStyle = '#8ad5f8'; // light blue
        amp =
          (maxAmp / 3) *
          Math.sin((3 * (Date.now() - awaitingRespStartTime.current)) / 1000); // 1s oscillations
        freq = ((2 * Math.PI) / canvas.width) * 2; // 2 waves per canvas width
        phase =
          2 *
          Math.PI *
          (((0.38 * (Date.now() - awaitingRespStartTime.current)) / 1000) % 1);
        fillWave(canvas, ctx, startY, amp, freq, phase);
        // keep drawing animation until a response is recieved
        requestAnimationFrame(listeningAnimation);
      }
    }
  };

  const fillWave = (canvas, ctx, startY, amplitude, freq, phase) => {
    ctx.beginPath();
    ctx.moveTo(0, startY);

    for (let x = 0; x < canvas.width; x++) {
      let y = amplitude * Math.sin(freq * x + phase);
      ctx.lineTo(x, y + startY);
    }

    ctx.lineTo(canvas.width, canvas.height);
    ctx.lineTo(0, canvas.height);
    ctx.lineTo(0, startY);
    ctx.fill();
  };

  // const thinkingAnimation = () => {
  //   const canvas = avatarCanvas.current;
  //   if (canvas) {
  //     const ctx = canvas.getContext('2d');
  //     ctx.clearRect(0, 0, canvas.width, canvas.height);

  //     if (awaitingResp.current) {
  //       // keep drawing animation until a response is recieved
  //       let curTime = Date.now();
  //       let freq = 0.75; // Hz
  //       let center = [canvas.width / 2, canvas.height / 2];
  //       let radius = canvas.width / 2 / 1.5 + 10;

  //       ctx.translate(...center);
  //       ctx.save();
  //       ctx.beginPath();
  //       ctx.arc(0, 0, radius, 0, Math.PI * 2, false);
  //       ctx.clip();
  //       const grd = ctx.createLinearGradient(0, 0, radius, 0);
  //       grd.addColorStop(0, '#3a9de0');
  //       grd.addColorStop(1, 'white');
  //       ctx.fillStyle = grd;
  //       ctx.rotate(2 * Math.PI * (((freq * curTime) / 1000) % 1));
  //       ctx.fillRect(0, 0, radius, radius);
  //       ctx.closePath();
  //       ctx.restore();
  //       ctx.resetTransform();

  //       // requestAnimationFrame(thinkingAnimation);
  //     }
  //   }
  // };

  const speakingAnimation = () => {
    const canvas = avatarCanvas.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (isPlayingAudio.current) {
        // draw speaking animation
        let curTime = Date.now();
        let center = [canvas.width / 2, canvas.height / 2];
        let innerRadius = canvas.width / 2 / 1.5 + 5;
        let freq = 0.5; // frequency of pulsation in Hz
        let maxChange = canvas.width / 2 - innerRadius;
        let outerRadius: any;

        if (audioAnalyzer.current) {
          const bufferLength = audioAnalyzer.current.frequencyBinCount;
          const dataArray = new Uint8Array(bufferLength);
          audioAnalyzer.current.getByteFrequencyData(dataArray);

          let sum = 0;
          dataArray.forEach((el) => (sum += el * el));

          const volume = Math.sqrt(sum / dataArray.length);

          // circle radius increases with volume
          outerRadius = innerRadius + (maxChange * volume) / 80;
        } else {
          // show sinusoidal animation if no analyzer
          outerRadius =
            innerRadius +
            (maxChange / 2) *
              (1 -
                Math.cos(
                  (2 *
                    Math.PI *
                    freq *
                    (curTime - listeningStartTime.current)) /
                    1000
                ));
        }
        animateConcentricCircles(ctx, center, innerRadius, outerRadius);

        requestAnimationFrame(speakingAnimation);
      }
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'stretch',
        height: '100%',
        width: '100%',
        marginTop: isWidget ? '20px' : '0',
        maxWidth: '1080px',
        margin: '0 auto',
        px: '16px',
      }}
    >
      {/* profile picture, transcripts */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // justifyContent: 'space-evenly',
          justifyContent: 'flex-start',
          flexGrow: 1,
          width: '100%',
          maxHeight: 'calc(100% - 168px)',
          overflowX: 'hidden',
        }}
      >
        {/* Box 1 */}
        <Box
          sx={{
            width: isWidget
              ? '30vh'
              : { xs: '18vh', sx: '20vh', md: '22vh', lg: '22vh', xl: '24vh' },
            height: isWidget
              ? '30vh'
              : { xs: '18vh', sx: '20vh', md: '22vh', lg: '22vh', xl: '24vh' },
            mt: isWidget
              ? '0vh'
              : { xs: '9vh', sx: '10vh', md: '11vh', lg: '11vh', xl: '12vh' },
            mb: isWidget
              ? '0vh'
              : {
                  xs: '4.5vh',
                  sx: '5.5vh',
                  md: '5.5vh',
                  lg: '5.5vh',
                  xl: '6vh',
                },
            position: 'relative',
          }}
        >
          <Box
            id='avatar-canvas-box'
            sx={{
              position: 'absolute',
              width: '150%',
              height: '150%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              overflow: 'hidden',
            }}
          >
            <canvas id='avatar-canvas' ref={setAvatarCanvas}></canvas>
          </Box>
          <Box
            sx={{
              position: 'absolute',
              width: '130%',
              height: '130%',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                filter: isDarkMode ? 'invert(1)' : 'invert(0)',
                width: '100%',
                height: '100%',
                position: 'absolute',
              }}
            >
              {awaitingResp.current && (
                <Lottie animationData={thinkingAnimation} loop autoPlay />
              )}
            </div>
          </Box>

          {/* Avatar */}
          <Avatar
            src={creatorProfileData?.profilePhotoURL}
            alt={creatorProfileData?.fullName}
            sx={{
              width: '100%',
              height: '100%',
              aspectRatio: 1,
              borderRadius: '40%',
              fontSize: '8.125rem',
              position: 'relative',
            }}
          />
        </Box>

        {/* for liking/unliking response */}
        {enableMonetization &&
        !isAnonymousUser &&
        !isSelfChat &&
        !isWidget &&
        ((!requiredPremiumKey && !ignoreCredits && credits === 0) ||
          (requiredPremiumKey && !premiumAccess && !ignoreCredits)) ? (
          // Box 2.1
          <Box
            sx={{
              width: '100%',
              margin: 'auto 0', // Centers vertically and ensures horizontal margin is 0
              display: 'flex',
              justifyContent: 'center', // Centers the button horizontally
            }}
          >
            {requiredPremiumKey && !premiumAccess ? (
              <PremiumPurchaseButton
                requiredPremiumKey={requiredPremiumKey}
                redirectURL={`/${creatorProfileData.userName}/chat`}
              />
            ) : authUser.isSubscribed ? (
              <AdditionalCreditsButton
                redirectURL={`/${creatorProfileData.userName}/chat`}
              />
            ) : (
              <SubscribeButton
                redirectURL={`/${creatorProfileData.userName}/chat`}
              />
            )}
          </Box>
        ) : (
          // original code

          // <Box
          //   sx={{
          //     flexGrow: 1,
          //     width: '100%',
          //     display: 'flex',
          //     flexDirection: 'column',
          //     justifyContent: 'flex-start',
          //     alignItems: 'center',
          //     mb: isWidget
          //     ? '0vh'
          //     : { xs: '3vh', sx: '4vh', md: '4vh', lg: '4vh', xl: '4vh' },
          //   }}
          // >
          //   <Typography
          //     variant='body1'
          //     sx={{
          //       color: 'text.primary',
          //       backgroundColor: 'action.selected',
          //       padding: '16px',
          //       borderRadius: '16px',
          //       textAlign: 'center',
          //       maxWidth: '100%',
          //       whiteSpace: 'pre-line', // Allows line breaks
          //       overflow: 'hidden',
          //       textOverflow: 'ellipsis',
          //       display: '-webkit-box',
          //       WebkitBoxOrient: 'vertical',
          //       WebkitLineClamp: 2,
          //       mb: '24px' // Ensures only the last 2 lines are visible
          //     }}
          //   >
          //     {transcript || '...'} {/* Shows '...' initially */}
          //   </Typography>
          //   {/* {isPlayingAudio.current && ( */}
          //   <ScrollingTextField
          //     chunks={messageChunks}
          //     activeChunkIndex={activeChunkIndex}
          //     placeholder='Message will appear here...'
          //   />
          //   {/* )} */}

          // <>
          //   <Box
          //     sx={{
          //       display: 'flex',
          //       alignItems: 'center',
          //       justifyContent: 'space-between',
          //       width: '100%',
          //       position: 'relative',
          //       visibility:
          //         messageDataItem && !awaitingResp.current
          //           ? 'visible'
          //           : 'hidden',
          //       mt: '24px'
          //     }}
          //   >
          //     <Box sx={{ display: 'flex', alignItems: 'center' }}>
          //       <IconButton
          //         id={`button-like-message-${documentId}`}
          //         sx={{
          //           padding: '0px',
          //           margin: '0px 6px 0px 0px',
          //           color:
          //             likeDislike === 1
          //               ? theme.palette.text.primary
          //               : theme.palette.text.secondary,
          //         }}
          //         onClick={() => handleLikeDislikeMessage(documentId, 1)}
          //       >
          //         <ThumbsUpIcon
          //           height='30px'
          //           width='30px'
          //           fill={
          //             likeDislike === 2 ? theme.palette.text.disabled : 'none'
          //           }
          //         />
          //       </IconButton>

          //       <IconButton
          //         id={`button-dislike-message-${documentId}`}
          //         sx={{
          //           padding: '0px',
          //           margin: '0px 0px 0px 7px',
          //           color:
          //             likeDislike === 2
          //               ? theme.palette.text.primary
          //               : theme.palette.text.secondary,
          //         }}
          //         onClick={() => handleLikeDislikeMessage(documentId, 2)}
          //       >
          //         <ThumbsDownIcon
          //           height='30px'
          //           width='30px'
          //           fill={
          //             likeDislike === 2 ? theme.palette.text.disabled : 'none'
          //           }
          //         />
          //       </IconButton>
          //     </Box>

          //     <Button
          //       id={`button-play-message-${documentId}`}
          //       variant='contained'
          //       color='secondary'
          //       size='large'
          //       sx={{
          //         position: 'absolute',
          //         left: '50%',
          //         transform: 'translateX(-50%)',
          //       }}
          //       onClick={handlePlayMessage}
          //       endIcon={
          //         <PlayArrowIcon
          //           sx={{ fill: (theme) => theme.palette.primary.main }}
          //         />
          //       }
          //     >
          //       Relay
          //     </Button>
          //   </Box>
          // </>
          // </Box>

          // animatied code v1

          // <Box
          //   sx={{
          //     flexGrow: 1,
          //     width: '100%',
          //     display: 'flex',
          //     flexDirection: 'column',
          //     justifyContent: 'flex-start',
          //     alignItems: 'center',
          //     position: 'relative',
          //   }}
          // >
          //   <motion.div
          //     initial={{
          //       position: 'absolute',
          //       bottom: 0,
          //       color: theme.palette.text.primary,
          //       backgroundColor: theme.palette.action.selected,
          //     }}
          //     animate={
          //       animate
          //         ? {
          //             position: 'absolute',
          //             bottom: 'unset',
          //             top: 0,
          //             color: theme.palette.primary.contrastText,
          //             backgroundColor: theme.palette.primary.main,
          //           }
          //         : {}
          //     }
          //     transition={{
          //       duration: animate ? 2.5 : 0,
          //       ease: 'easeOut',
          //     }}
          //     style={{
          //       padding: '16px',
          //       borderRadius: '16px',
          //       textAlign: 'center',
          //       maxWidth: '100%',
          //       whiteSpace: 'pre-line',
          //       overflow: 'hidden',
          //       textOverflow: 'ellipsis',
          //       display: '-webkit-box',
          //       WebkitBoxOrient: 'vertical',
          //       WebkitLineClamp: 2,
          //       marginBottom: '24px',
          //     }}
          //   >
          //     {transcript || '...'}

          //   </motion.div>

          //   <motion.div
          //     initial={{ opacity: 0 }}
          //     animate={isPlayingAudio.current ? { opacity: 1 } : {}}
          //     transition={{
          //       duration: 2.5,
          //       ease: 'easeOut',
          //       // delay: 2.5
          //     }}
          //     style={{
          //       width: '100%',
          //       display: 'flex',
          //       flexDirection: 'column',
          //       gap: '24px',
          //     }}
          //   >
          //     <ScrollingTextField
          //       chunks={messageChunks}
          //       activeChunkIndex={activeChunkIndex}
          //       placeholder='Message will appear here...'
          //     />

          // <Box
          //   sx={{
          //     display: 'flex',
          //     alignItems: 'center',
          //     justifyContent: 'space-between',
          //     width: '100%',
          //     position: 'relative',
          //     visibility:
          //       messageDataItem && !awaitingResp.current
          //         ? 'visible'
          //         : 'hidden',
          //   }}
          // >
          //   <Box sx={{ display: 'flex', alignItems: 'center' }}>
          //     <IconButton
          //       id={`button-like-message-${documentId}`}
          //       sx={{
          //         padding: '0px',
          //         margin: '0px 6px 0px 0px',
          //         color:
          //           likeDislike === 1
          //             ? theme.palette.text.primary
          //             : theme.palette.text.secondary,
          //       }}
          //       onClick={() => handleLikeDislikeMessage(documentId, 1)}
          //     >
          //       <ThumbsUpIcon
          //         height='30px'
          //         width='30px'
          //         fill={
          //           likeDislike === 2 ? theme.palette.text.disabled : 'none'
          //         }
          //       />
          //     </IconButton>

          //     <IconButton
          //       id={`button-dislike-message-${documentId}`}
          //       sx={{
          //         padding: '0px',
          //         margin: '0px 0px 0px 7px',
          //         color:
          //           likeDislike === 2
          //             ? theme.palette.text.primary
          //             : theme.palette.text.secondary,
          //       }}
          //       onClick={() => handleLikeDislikeMessage(documentId, 2)}
          //     >
          //       <ThumbsDownIcon
          //         height='30px'
          //         width='30px'
          //         fill={
          //           likeDislike === 2 ? theme.palette.text.disabled : 'none'
          //         }
          //       />
          //     </IconButton>
          //   </Box>

          //   <Button
          //     id={`button-play-message-${documentId}`}
          //     variant='contained'
          //     color='secondary'
          //     size='large'
          //     sx={{
          //       position: 'absolute',
          //       left: '50%',
          //       transform: 'translateX(-50%)',
          //     }}
          //     onClick={handlePlayMessage}
          //     endIcon={
          //       <PlayArrowIcon
          //         sx={{ fill: (theme) => theme.palette.primary.main }}
          //       />
          //     }
          //   >
          //     Relay
          //   </Button>
          // </Box>
          //   </motion.div>

          // </Box>

          // v2

          <Box
            sx={{
              flexGrow: 1,
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <motion.div
              ref={firstMotionDivRef}
              initial={{
                position: 'absolute',
                bottom: 0,
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.action.selected,
              }}
              animate={
                animateUserTranscript
                  ? {
                      position: 'absolute',
                      bottom: 'unset',
                      top: 0,
                      color: theme.palette.primary.contrastText,
                      backgroundColor: theme.palette.primary.main,
                    }
                  : {}
              }
              transition={{
                duration: animateUserTranscript ? 1.5 : 0,
                ease: 'easeOut',
              }}
              style={{
                padding: '16px',
                borderRadius: '16px',
                textAlign: 'center',
                maxWidth: '100%',
                whiteSpace: 'pre-line',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                marginBottom: '24px',
              }}
            >
              {transcript || '...'}
            </motion.div>

            {/* <motion.div
              initial={{ opacity: 0 }}
              animate={animatePersonaTranscript ? { opacity: 1 } : {}}
              transition={{
                duration: 2.5,
                ease: 'easeOut',
              }}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                marginTop: `${firstDivHeight + 24}px`,
              }}
            >
              <ScrollingTextField
                chunks={messageChunks}
                activeChunkIndex={activeChunkIndex}
                placeholder='Message will appear here...'
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  position: 'relative',
                  visibility:
                    messageDataItem && !awaitingResp.current
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    id={`button-like-message-${documentId}`}
                    sx={{
                      padding: '0px',
                      margin: '0px 6px 0px 0px',
                      color:
                        likeDislike === 1
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                    }}
                    onClick={() => handleLikeDislikeMessage(documentId, 1)}
                  >
                    <ThumbsUpIcon
                      height='30px'
                      width='30px'
                      fill={
                        likeDislike === 2 ? theme.palette.text.disabled : 'none'
                      }
                    />
                  </IconButton>

                  <IconButton
                    id={`button-dislike-message-${documentId}`}
                    sx={{
                      padding: '0px',
                      margin: '0px 0px 0px 7px',
                      color:
                        likeDislike === 2
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                    }}
                    onClick={() => handleLikeDislikeMessage(documentId, 2)}
                  >
                    <ThumbsDownIcon
                      height='30px'
                      width='30px'
                      fill={
                        likeDislike === 2 ? theme.palette.text.disabled : 'none'
                      }
                    />
                  </IconButton>
                </Box>

                <Button
                  id={`button-play-message-${documentId}`}
                  variant='contained'
                  color='secondary'
                  size='large'
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                  onClick={handlePlayMessage}
                  endIcon={
                    <PlayArrowIcon
                      sx={{ fill: (theme) => theme.palette.primary.main }}
                    />
                  }
                >
                  Relay
                </Button>
              </Box> */}

            <motion.div
              initial={{ opacity: 0, x: 0 }}
              animate={
                animatePersonaTranscript
                  ? {
                      opacity: 1,
                      x: 0,
                      transition: { duration: 0.1, ease: 'easeOut' },
                    }
                  : {
                      opacity: 0,
                      x: -150,
                      transition: { duration: 1.5, ease: 'easeInOut' },
                    }
              }
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
                marginTop: `${firstDivHeight + 24}px`,
              }}
            >
              <ScrollingTextField
                chunks={messageChunks}
                activeChunkIndex={activeChunkIndex}
                placeholder='Message will appear here...'
              />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  position: 'relative',
                  visibility:
                    messageDataItem && !awaitingResp.current
                      ? 'visible'
                      : 'hidden',
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    id={`button-like-message-${documentId}`}
                    sx={{
                      padding: '0px',
                      margin: '0px 6px 0px 0px',
                      color:
                        likeDislike === 1
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                    }}
                    onClick={() => handleLikeDislikeMessage(documentId, 1)}
                  >
                    <ThumbsUpIcon
                      height='30px'
                      width='30px'
                      fill={
                        likeDislike === 1 ? theme.palette.text.disabled : 'none'
                      }
                    />
                  </IconButton>

                  <IconButton
                    id={`button-dislike-message-${documentId}`}
                    sx={{
                      padding: '0px',
                      margin: '0px 0px 0px 7px',
                      color:
                        likeDislike === 2
                          ? theme.palette.text.primary
                          : theme.palette.text.secondary,
                    }}
                    onClick={() => handleLikeDislikeMessage(documentId, 2)}
                  >
                    <ThumbsDownIcon
                      height='30px'
                      width='30px'
                      fill={
                        likeDislike === 2 ? theme.palette.text.disabled : 'none'
                      }
                    />
                  </IconButton>
                </Box>

                <Button
                  id={`button-play-message-${documentId}`}
                  variant='contained'
                  color='secondary'
                  size='large'
                  sx={{
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                  onClick={handlePlayMessage}
                  endIcon={
                    <PlayArrowIcon
                      sx={{ fill: (theme) => theme.palette.primary.main }}
                    />
                  }
                >
                  Relay
                </Button>
              </Box>
            </motion.div>
          </Box>
        )}
      </Box>

      {/* mic, volume and chat-toggle */}
      <Box
        ref={parentRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          maxHeight: '128px',
        }}
      >
        {/* <Box
          id='microphone-canvas-box'
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            maxHeight: '128px',
            overflow: 'hidden',
          }}
        >
          <canvas
            id='microphone-canvas'
            ref={setMicrophoneCanvas}
            width='800'
          />
        </Box> */}

        {showAssignmentButton && (
          <Popover
            open={showAssignmentButton}
            anchorEl={micButton.current}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            slotProps={{
              paper: {
                sx: {
                  background: 'transparent',
                  boxShadow: 'none',
                },
              },
            }}
            sx={{
              zIndex: 1,
            }}
          >
            <Button
              onClick={handleStartAssignment}
              variant='contained'
              sx={{
                mt: 2,
                mb: 2,
                ml: 2,
                mr: 2,
                maxWidth: '200px',
                alignSelf: 'center',
                boxShadow: '0 0 0 12px #C2E8FF',
                '&:hover': {
                  boxShadow: '0 0 0 12px #C2E8FF !important',
                },
              }}
            >
              Start Assignment
            </Button>
          </Popover>
        )}

        {/* <Box
          sx={{
            position: 'absolute',
            bottom: '0',
            left: leftValue,
          }}
        > */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          <VoiceChatToggle
            toggleVoiceChat={toggleVoiceChat}
            useVoiceChat={useVoiceChat}
            audioQueue={audioQueue}
          />
        </Box>

        <VolumeMeter
          isListening={isListening.current}
          dotSize={4}
          padding={1}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '120px',
            height: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: '48px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <IconButton
            id={`button-microphone`}
            ref={setMicButton}
            sx={{
              // padding: '10px',
              width: '70px',
              height: '70px',
              borderRadius: '70px',
              // mt: '39px',
              // mb: '50px',
              border: `3px solid ${theme.palette.primary.main}`,
              color: (theme) => theme.palette.primary.main,
              backgroundColor: (theme) =>
                theme.palette.background['paper-elevation-2'],
              ':hover': {
                backgroundColor: (theme) =>
                  theme.palette.background['paper-elevation-2'],
                '& svg': {
                  // color: isListening.current ? 'red' : '#3486B8',
                  transition: 'all 0.25s',
                },
              },
              '&.Mui-disabled': {
                // backgroundColor: 'white',
                border: `3px solid ${theme.palette.action.disabled}`,
              },
            }}
            disabled={
              (enableMonetization &&
                !isAnonymousUser &&
                !isWidget &&
                !isSelfChat &&
                !ignoreCredits &&
                ((!requiredPremiumKey && credits === 0) ||
                  (requiredPremiumKey && !premiumAccess))) ||
              disableMicrophone ||
              showAssignmentButton
            }
            onPointerDown={() => {
              if (!isListening.current) {
                startListening();
              } else {
                handleStopListening(false);
              }
            }}
          >
            {isListening.current ? (
              <StopIcon
                sx={{
                  height: '45px',
                  width: '45px',
                  color: (theme) => theme.palette.primary.main,
                }}
              />
            ) : (
              <MicIcon
                sx={{
                  height: '45px',
                  width: '45px',
                  color:
                    (enableMonetization &&
                      !isAnonymousUser &&
                      !isSelfChat &&
                      !ignoreCredits &&
                      credits === 0) ||
                    disableMicrophone
                      ? (theme) => theme.palette.action.disabled
                      : (theme) => theme.palette.primary.main,
                }}
              />
            )}
          </IconButton>
        </Box>
        {
          <Box
            sx={{
              position: 'absolute',
              bottom: '12px',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '100%',
              maxWidth: '600px',
            }}
          >
            <Typography
              sx={{
                width: '100%',
                alignItems: 'center',
                position: 'relative',
                textAlign: 'center',
                // top: '-9.5em',
                color: (theme) => theme.palette.primary.main,
              }}
            >
              {(enableMonetization &&
                !isAnonymousUser &&
                !isSelfChat &&
                !ignoreCredits &&
                credits === 0) ||
              disableMicrophone ||
              showAssignmentButton
                ? ' '
                : isListening.current
                ? 'Listening...'
                : 'Click to start speaking...'}
            </Typography>
          </Box>
        }

        {isSafari && (
          <Box
            sx={{
              position: 'absolute',
              right: '20px',
              top: '10px',
              cursor: 'pointer',
            }}
            onClick={handleOpenHelpPopup}
          >
            <Typography variant='body2' color='primary'>
              Having Trouble with Voice?
            </Typography>
          </Box>
        )}

        <Dialog open={openHelpPopup} onClose={handleCloseHelpPopup}>
          <DialogContent>
            <Typography
              variant='h5'
              gutterBottom
              align='center'
              sx={{ fontWeight: 'bold' }}
            >
              Having Trouble With Voice?
            </Typography>
            <Typography
              align='center'
              sx={{ color: 'black', fontSize: '1.2rem' }}
            >
              You may need to adjust your safari settings!
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <img
                src={safarivoice}
                alt='Safari Voice Settings Help'
                style={{ maxWidth: '100%' }}
              />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: 'center',
              mt: -3,
              paddingBottom: '20px',
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
              <Button
                onClick={handleCloseHelpPopup}
                variant='outlined'
                sx={{
                  color: '#009CDB !important',
                  border: '1px solid #009CDB !important',
                  width: '50%',
                  maxHeight: '36px',
                }}
              >
                I'm not having any problems
              </Button>
              <Button
                onClick={() => window.open('/faq')}
                variant='contained'
                sx={{ width: '50%', maxHeight: '36px' }}
              >
                Go to FAQs
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default VoiceChat;
