import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './bannerItem.css';

interface BannerItemProps {
  item: {
    name: string;
    mainText: string;
    subText: string;
    link: string;
    backgroundImage: string;
    mainImage: string;
  }
}

const BannerItem: React.FC<BannerItemProps> = ({item}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.up("xs"));

  const mainTextVariant = () => {
    if (xl) return "h4";
    if (xs) return "h5";
    return "h5";
  }

  const subTextVariant = () => {
    if (xl) return "h6";
    if (xs) return "h7";
  }

  const textGap = () => {
    if (xl) return "20.37px";
    if (sm) return "15px";
    if (xs) return "8px";
  }

  return (
    <Box
      className='banner-item-background'
      sx={{
        width: '100%',
        height: {
          xl: '383px',
          sm: '280px',
          xs: '443px',
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        backgroundImage:`url(${item.backgroundImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: 'hidden',
      }}
    >
      <Box
        className='banner-item-text'
        display='flex'
        height='100%'
        flexDirection='column'
        sx={{
          width: {
            xl: '382px',
            md: '282px',
            sm: '234px',
            xs: '100%',
          },
          gap: textGap(),
          zIndex: 2,
          justifyContent: {
            sm: 'center',
            xs: 'flex-end',
          },
          alignItems: 'center',
          py: 2,
          px: {
            xs: 2,
            sm: 0,
          }
        }}
      >
        <Typography
          variant={mainTextVariant()}
          sx={{
            color: 'white',
            textAlign: 'center',
            textShadow: 'black 0px 0px 10px'
          }}
        >
          {item.mainText}
        </Typography>
        <Typography
          variant={subTextVariant()}
          sx={{
            color: 'white',
            textAlign: 'center',
            textShadow: 'black 0px 0px 10px'
          }}
        >
          {item.subText}
        </Typography>
        <Button
          variant='contained'
          size='large'
          color='secondary'
          fullWidth={false}
          disableElevation
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            navigate(item.link)
          }}
          sx={{
            width: 'fit-content'
          }}
          href={item.link || null}
        >
          Chat Now
        </Button>
      </Box>

      <Box
        className='banner-item-person'
        display='flex'
        justifyContent='center'
        alignItems='flex-start'
        sx={{
          height: {
            xl: '383px',
            sm: '280px',
            xs: '383px',
          },
          width: {
            xl: '506px',
            md: '374px',
            sm: '310px',
            xs: '100%',
          },
          zIndex: 1,
          position: {
            xs: 'absolute',
            sm: 'static',
          },
          opacity: 0,
          transition: 'opacity 0.25s ease-out',
        }}
      >
        <img
          src={item.mainImage}
          style={{
            height: sm ? '100%' : 'auto',
            width: sm ? '100%' : 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: sm ? 'contain' : 'cover',
            margin: 0,
            padding: 0,
          }}
          alt={`${item.name} Banner Main`}
          loading="lazy"
        />
      </Box>
    </Box>
  )
}

export default BannerItem;
