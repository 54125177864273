import { SVGProps, memo } from 'react';
const ExploreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "23"} height={props.height || "22"} viewBox="0 0 23 22" fill={props.fill || "none"} stroke={props.stroke || "none"} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1094 20.2C16.1904 20.2 20.3094 16.081 20.3094 11C20.3094 5.91898 16.1904 1.8 11.1094 1.8C6.02836 1.8 1.90937 5.91898 1.90937 11C1.90937 16.081 6.02836 20.2 11.1094 20.2ZM11.1094 22C17.1845 22 22.1094 17.0751 22.1094 11C22.1094 4.92487 17.1845 0 11.1094 0C5.03424 0 0.109375 4.92487 0.109375 11C0.109375 17.0751 5.03424 22 11.1094 22Z" fill={props.color || "white"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3645 7.74465L9.07455 8.96541L7.85379 14.2554L13.1437 13.0346L14.3645 7.74465ZM16.4062 6.90218C16.5724 6.18211 15.927 5.53676 15.207 5.70293L8.18261 7.32393C7.81001 7.40992 7.51906 7.70086 7.43308 8.07347L5.81207 15.0978C5.6459 15.8179 6.29125 16.4633 7.01132 16.2971L14.0357 14.6761C14.4083 14.5901 14.6992 14.2991 14.7852 13.9265L16.4062 6.90218Z" fill={props.color || "white"}/>
    <path d="M12.1094 11C12.1094 11.5523 11.6617 12 11.1094 12C10.5571 12 10.1094 11.5523 10.1094 11C10.1094 10.4477 10.5571 10 11.1094 10C11.6617 10 12.1094 10.4477 12.1094 11Z" fill={props.color || "white"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.1094 12C11.6617 12 12.1094 11.5523 12.1094 11C12.1094 10.4477 11.6617 10 11.1094 10C10.5571 10 10.1094 10.4477 10.1094 11C10.1094 11.5523 10.5571 12 11.1094 12Z" fill={props.color || "white"}/>
  </svg>


);

export default memo(ExploreIcon)
