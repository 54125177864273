import { auth } from '../../utils/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-toastify';
import { FirebaseError } from 'firebase/app';
import * as Yup from 'yup';
import { ACCEPTED_DOCUMENT_FORMATS, SUPPORTED_VOICE_SAMPLE_TYPES } from '../../config/const';

export type newFormPartsType = {
  validationSchema: any;
  components: FormComponentNames[];
  showSteps?: boolean;
}

// Function to handle sending of Verification email to the user's email
export const handleVerifyEmail = async () => {
  try {
    await sendEmailVerification(auth.currentUser);
    toast.success('Verification email has been sent');
  } catch (error) {
    const firebaseError = (error as FirebaseError)?.code;
    switch (firebaseError) {
      case 'auth/too-many-requests':
        toast.error('Too many requests sent. Please try again after a minute.');
        break;
      default:
        toast.error('Sorry there was an error. Please try again later.');
        console.error(error);
    }
  }
};

export const verifyFileSize = (fileSizeInKB, maxSizeInKB) => {
  if (fileSizeInKB === 0) {
    toast.error('Selected file is empty. Please select a non-empty file.');
    return false;
  }
  if (fileSizeInKB >= maxSizeInKB) {
    toast.error(
      'File too Big, please select a file less than ' +
        Math.floor(maxSizeInKB / 1000) +
        ' MB'
    );
    return false;
  }
  return true;
};

export type FormComponentNames =
  'documents' | 'googleDrive' | 'weblinks' | 'socialMedia' |
  'introMessage' | 'voiceSample' | 'saveButton' | 'twitter' |
  'youtube' | 'journaling' | 'basicTraining' | 'premiumTraining' | 'footer' |
  'suggestedQuestions';

export const addSaveButton = (newFormParts: newFormPartsType) => {
  newFormParts.components.push('saveButton')
}

export const addDocumentsFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create document form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    userDocument: Yup.array().of(
      Yup.mixed<File>()
        .test({
          message: 'File formats supported are txt, docx, pdf and pptx.',
          test: (file, context) => {
            if (!file) return true;

            const isValid = ACCEPTED_DOCUMENT_FORMATS.includes(file?.name.split('.').pop());
            if (!isValid) context?.createError();
            return isValid;
          }
        })
    ),
  };
  newFormParts.components.push('documents')
}

export const addGoogleDriveFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Google Drive form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    google_drive_links: Yup.array().of(
      Yup.object({
        url: Yup.string().matches(
          /^(https:\/\/)?(drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=|drive\/folders\/)[\w-]+(?:\?.*)?)$/,
          {
            message: 'Invalid Google Drive Link',
            excludeEmptyString: true
          }
        )
      })
    ),
  };
  newFormParts.components.push('googleDrive')
}

export const addWeblinksFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create weblinks form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    userWebPage: Yup.array().of(
      Yup.object().shape({
        web_link: Yup.string().matches(
          /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-z]{2,}([/?].*)?$/,
          {
            message: 'Please enter valid link to content',
            excludeEmptyString: true
          }
        ),
      })
    ).nullable(),
  };
  newFormParts.components.push('weblinks')
}

export const addSocialMediaFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Social Media form
  newFormParts.components.push('socialMedia')
}

export const addTwitterFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Twitter form
  newFormParts.components.push('twitter')
}

export const addYoutubeFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Youtube form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    youtube_links: Yup.array().of(
      Yup.object({
        url: Yup.string().matches(
          /^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtube\.com\/embed\/|youtu\.be\/)[\w-]{11}/,
          {
            message: 'Invalid Youtube Link. Must start with https://youtube.com/watch?v={video_id} or https://youtube.com/embed/{video_id}',
            excludeEmptyString: true
          }
        )
      }).nullable()
    ),
  };
  newFormParts.components.push('youtube')
}

export const addIntroMessageFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Introduction form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    introMessage: Yup.object({
      message: Yup.string()
      .required('Introduction Message is required')
      .min(100, 'Please provide at least 100 characters for your AI Persona Introduction')
      .test({
        message: 'Please provide at least 3 sentences for your AI Persona Introduction',
        test: (msg, context) => {
          const isValid = msg.match(/\(?[^\.\?\!]+[\.!\?]\)?/g).length >= 3;
          if (!isValid) context?.createError();
          return isValid;
        }
      }),
    })
  };
  newFormParts.components.push('introMessage')
}

export const addSuggestedQuestionsFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Suggested Questions form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    introMessage: Yup.object({
      chips: Yup.array().compact((el) => {
        if (typeof el === 'string') {
          return !Boolean(el.length)
        } else {
          return true;
        }
      }).min(3, 'At least three suggested questions are required'),
    }),
  };
  newFormParts.components.push('suggestedQuestions')
}

export const addIntroAndSuggestedQuestionsFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Suggested form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    introMessage: Yup.object({
      message: Yup.string()
        .required('Introduction Message is required')
        .min(100, 'Please provide at least 100 characters for your AI Persona Introduction')
        .test({
          message: 'Please provide at least 3 sentences for your AI Persona Introduction',
          test: (msg, context) => {
            const isValid = msg.match(/\(?[^\.\?\!]+[\.!\?]\)?/g)?.length >= 3;
            if (!isValid) context?.createError();
            return isValid;
          }
        }),
      chips: Yup.array().compact((el) => {
        if (typeof el === 'string') {
          return !Boolean(el.length)
        } else {
          return true;
        }
      }).min(3, 'At least three suggested questions are required'),
    }),
  };
  newFormParts.components.push('introMessage')
  newFormParts.components.push('suggestedQuestions')
}


export const addVoiceSampleFormData = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Voice Sample form
  newFormParts.validationSchema = {
    ...newFormParts.validationSchema,
    voiceSample: Yup.mixed<File | string>()
      .test({
        message: 'Please upload an audio file with a .mp3, .wav, or standard audio format',
        test: (file, context) => {
          if (!file) return true;

          let isValid = false;
          if (typeof file === 'string') {
            isValid = SUPPORTED_VOICE_SAMPLE_TYPES.includes(file.split('.').pop());
          } else if (typeof file === 'object') {
            isValid = SUPPORTED_VOICE_SAMPLE_TYPES.includes(file?.name?.split('.').pop());
          }
          if (!isValid) context?.createError();
          return isValid;
        }
      }).nullable(),
  };
  newFormParts.components.push('voiceSample')
}

export const addJournaling = (newFormParts: newFormPartsType) => {
  // Add everything needed to create Journaling form
  newFormParts.components.push('journaling')
}

export const addBasicTraining = (newFormParts: newFormPartsType) => {
  // Add everything needed to add basic training header
  newFormParts.components.push('basicTraining')
}

export const addPremiumTraining = (newFormParts: newFormPartsType) => {
  // Add everything needed to add premium training header
  newFormParts.components.push('premiumTraining')
}

export const addFooter = (newFormParts: newFormPartsType) => {
  // Add everything needed to add onboarding footer
  newFormParts.components.push('footer')
}
