import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Timestamp } from "firebase/firestore";
import { Box } from "@mui/material";
import TiersCard from "../Purchases/TiersCard";
import FormBuilder from "./formBuilder";

const CreatorOnBoarding: React.FC = () => {
  const { authUser } = useAuth();
  const [validSubscription, setValidSubscription] = useState<boolean>(null);

  useEffect(() => {
    if (authUser?.subscription) {
      if (authUser.subscription?.pricing_plan?.expiration_date > Timestamp.fromDate(new Date())){
        setValidSubscription(true);
      } else {
        setValidSubscription(false);
      }
    } else {
      setValidSubscription(false);
    }
  }, [authUser])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        sx={{
          maxWidth: {
            xl: '1200px',
          },
        }}
      >
        {
          validSubscription === null ?
          <></> : // loading wheel
          (
            validSubscription ?
            <FormBuilder />:
            <Box p={3}>
              <TiersCard />
            </Box>
          )
        }
      </Box>
    </Box>
  )
}

export default CreatorOnBoarding;


// import * as React from 'react';

// import CreatorOnBoardingContainer from './creatorOnBoardingContainer';

// export default function CreatorOnBoarding() {
//   return <CreatorOnBoardingContainer />;
// }
