import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { Suspense, useState, useRef, useEffect } from "react";
import { useProfileData } from "../../hooks/useProfileData";
import StripeCheckoutForm from '../StripeCheckoutForm';
import { getPrices } from "../../utils/api";
import VerifiedIcon from '@mui/icons-material/Verified';
import TrialButton from "./trialButton";
import { wrapPromise } from "../../utils/helper";
import { useTheme } from '@mui/material/styles';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

interface PremiumPurchaseButtonProps {
  requiredPremiumKey: string;
  redirectURL?: string;
  sampleData?: any;
}

const PremiumPurchaseButton: React.FC<PremiumPurchaseButtonProps> = (props) => {
  const { requiredPremiumKey, redirectURL = "", sampleData = null } = props;
  const { creatorProfileData } = useProfileData();

  // const dataPromise = useRef(null);
  const loadedData = useRef(sampleData);
  const isLoading = useRef<boolean>(false);
  const [ stripeOpen, setStripeOpen ] = useState<boolean>(false);
  const [ stripeItem, setStripeItem ] = useState<string>(null);
  const [ trialPeriod, setTrialPeriod ] = useState<number>(0);
  const [ dataPromise, setDataPromise ] = useState(null);
  const [ , setRefresh ] = useState<boolean>(false);
  const theme = useTheme();

  useEffect(() => {
    if (sampleData){
      loadedData.current = sampleData;
      setRefresh((r: boolean) => !r)
    } else {
      setDataPromise(wrapPromise(loadData()))
    }
  }, [sampleData]) //eslint-disable-line react-hooks/exhaustive-deps

  const handlePremiumPurchase = () => {
    // disable buttons if just showing test data
    if (!sampleData) {
      setStripeItem(requiredPremiumKey)
      setStripeOpen(true)
    }
  };

  const loadData = () => {
    if (!isLoading.current && !loadedData.current) {
      isLoading.current = true;
      if (requiredPremiumKey) {
        let promise = getPrices([requiredPremiumKey])
                      .then(res => res.json())
                      .then(data => {
                        if (data) {
                          loadedData.current = data[requiredPremiumKey];
                        }
                      })
                      .catch(err => {
                        console.log(err)
                        loadedData.current = null;
                      })
                      .finally(() => {
                        isLoading.current = false;
                      })

        return promise;
      } else {
        isLoading.current = false;
        return Promise.resolve();
      }
    } else {
      return Promise.resolve();
    }
  }

  const Loading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: (theme) => theme.palette.background["paper-elevation-10"],
      border: `1px solid ${theme.palette.background["paper-elevation-0"]}`,
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: '1rem',
            paddingBottom: '0.75rem',
            '& img': {
              marginRight: '0.5rem',
              height: '24px',
            }
          }}
        >
          <Typography
            component='h6'
            variant='h6'
            fontWeight={600}
            sx={{
              fontSize: '16px',
              color: (theme) => theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            {`Subscribe to ${creatorProfileData.fullName}`}
          </Typography>
        </Box>
        <Box
          sx={{
            pl: '1rem',
            pr: '1rem',
            display: 'flex',
          }}
        >
          <CircularProgress size={30} />
          <Typography
            component='h6'
            variant='h6'
            fontWeight={400}
            sx={{
              fontSize: '14px',
              color: (theme) => theme.palette.text.primary,
              marginBottom: '0.75rem',
              textAlign: 'center',
              ml: 1,
            }}
          >
            Loading...
          </Typography>
        </Box>
        <Button
          id={`button-premium-purchase`}
          variant="contained"
          sx={{
            height: '32px !important',
            padding: '6px 12px !important',
            borderRadius:'6px',
            width: '50%',
            fontSize: '14px',
            marginBottom: '1.5rem'
          }}
          fullWidth
          startIcon={<CircularProgress size={30}/>}
        >
          Loading...
        </Button>
      </Box>
    )
  }

  const RealButton = () => {
    if (!loadedData.current) {
      if (dataPromise) {
        dataPromise.read()
      }
    }

    let priceStr = ""
    if (loadedData.current) {
      let data = loadedData.current;
      if (data.currency === 'usd') priceStr += "$"
      priceStr += data.unit_amount/100
      if (data.type === 'recurring') priceStr += `/${data.recurring.interval}`
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: (theme) => theme.palette.background["paper-elevation-10"],
          border: `1px solid ${theme.palette.background["paper-elevation-0"]}`,
          borderRadius: '5px',
          width: '100%',
        }}
      >
        <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '1rem',
          paddingBottom: '0.75rem',
          '& img': {
            marginRight: '0.5rem',
            height: '24px',
          }
        }}>
          <Typography
            component='h6'
            variant='h6'
            fontWeight={600}
            sx={{
              fontSize: '16px',
              color: (theme) => theme.palette.text.primary,
              textAlign: 'center',
            }}
          >
            {loadedData?.current?.title || `Subscribe to ${creatorProfileData.fullName}`}
          </Typography>
          <VerifiedIcon sx={{ml: 0.5, fill: '#3EACEF'}}/>
        </Box>
        <Box
          sx={{
            pl: '1rem',
            pr: '1rem',
          }}
        >
          <Typography
            component='h6'
            variant='h6'
            fontWeight={400}
            sx={{
              fontSize: '14px',
              color: (theme) => theme.palette.text.primary,
              marginBottom: '0.75rem',
              textAlign: 'center',
            }}
          >
            {loadedData?.current?.description || `Unlock access to ${creatorProfileData.fullName}'s AI persona`}
          </Typography>
        </Box>
        <Box
          display='flex'
          flexDirection={{xs: 'column', sm: 'row'}}
        >
          <Button
            id={`button-premium-purchase`}
            variant="contained"
            sx={{
              height: '32px !important',
              padding: '6px 12px !important',
              borderRadius:'6px',
              fontSize: '14px',
              marginBottom: {xs: 2, sm: '1.5rem'},
              width: {xs: '100%', sm: 'auto'}
            }}
            onClick={handlePremiumPurchase}
          >
            {/* Subscribe */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 2,
                mr: 2
              }}
            >
              <Typography variant='h7' mr={8}>Subscribe</Typography>
              <Typography variant='h7'>{priceStr}</Typography>
            </Box>
          </Button>
          {
            loadedData?.current?.metadata?.trial_code &&
            <Box
              ml={{xs: 0, sm: 1}}
              width={{xs: '100%', sm: 'auto'}}
            >
              <TrialButton
                loadedData={loadedData}
                requiredPremiumKey={requiredPremiumKey}
                setTrialPeriod={setTrialPeriod}
                setStripeItem={setStripeItem}
                setStripeOpen={setStripeOpen}
              />
            </Box>
          }
        </Box>
        <StripeCheckoutForm item={stripeItem} isOpen={stripeOpen} setIsOpen={setStripeOpen} redirectURL={redirectURL || ""} isPremium={true} trialPeriod={trialPeriod}/>
      </Box>
    )
  }

  if (!enableMonetization) return <></>

  return (
    <Suspense fallback={<Loading />}>
      <RealButton />
    </Suspense>
  )
}

export default PremiumPurchaseButton
