// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
// import Avatar from '@mui/material/Avatar';
// import { debounce, IconButton } from '@mui/material/';
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';

// import { useAuth } from '../../hooks/useAuth';
// import AuthUserProfile from './authUserProfile';
// import FanCreatorItem from './fanCreatorItem';
// import CreateMimioContainer from './createMimioContainer';
// import { USER_TYPE } from '../../config/const';
// import styles from './fanCreatorList.module.css';
// import { getCreatorsList, getSortedCreatorList, getCorporateAccounts } from '../../utils/api';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import CircleIcon from '@mui/icons-material/Circle';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SortIcon from '@mui/icons-material/Sort';
// import { grey } from '@mui/material/colors';
// // import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
// import { SortByContext } from '../../context/SortByContext';
// import { useNavigate, useParams } from 'react-router-dom';
// import CourseBannerFull from '../../assets/images/banner images/banner.png';
// import GooglePlayButton from '../../assets/images/banner images/googleplaybutton.png';
// import BannerImg from '../../assets/images/banner images/bannerimg.png';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Slider from "react-slick";

// const PAGE_LIMIT = 14;

// //create mimio feature to show/hide create my mimio section
// const createMIMIOFeature =
//   process.env.REACT_APP_ENABLE_CREATE_MIMIO_FEATURE === "true";

// const enableCreatorSortBy =
//   process.env.REACT_APP_ENABLE_CREATOR_SORT_BY === "true";

// const SORT_BY_OPTIONS = {
//   'topFans': 'Most Fans',
//   'topMessages': 'Most Messages',
//   'aToz': 'Alphabetical (A-Z)',
//   'zToa': 'Alphabetical (Z-A)',
//   'oldestFirst': 'Oldest Accounts',
//   'trending': 'Trending',
// };

// export default function FanCreatorList() {
//   const { enterpriseName } = useParams();
//   const searchValue = React.useRef<string>('');
//   const [refresh, setRefresh] = React.useState<boolean>(false);
//   const searchTimer = React.useRef<any>(0);
//   const isLoading = React.useRef<boolean>(false);
//   const { authUser } = useAuth();
//   const [creatorsData, setCreatorsData] = React.useState([]);
//   const [showSortByMenu, setShowSortByMenu] = React.useState(false);
//   const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>(null);
//   const currentPage = React.useRef<number>(1);
//   const lastVisibleDoc = React.useRef<any>(null);
//   const fullyLoaded = React.useRef<boolean>(false);
//   const { sortBy } = React.useContext(SortByContext);
//   const isMobile = React.useRef<boolean>(false);
//   const bottom = React.useRef(null);
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     checkMobile();

//     window.addEventListener('resize', checkMobile, false);

//     const observer = new IntersectionObserver((entries) => {
//       if (entries[0].isIntersecting) {
//         if (searchValue.current?.length) {
//           creatorSearch();
//         } else {
//           handleFetchData();
//         }
//       }
//     });
//     observer.observe(bottom.current);

//     return () => {
//       window.removeEventListener('resize', checkMobile, false);
//       observer.disconnect();
//     };
//   }, [creatorsData]) //eslint-disable-line react-hooks/exhaustive-deps

//   React.useEffect(() => {
//     // refetch data any time sortBy is changed
//     if (authUser?.uid && !isLoading.current) {
//       currentPage.current = 1;
//       fullyLoaded.current = false;
//       handleFetchData();
//     }
//   }, [sortBy.current]) //eslint-disable-line react-hooks/exhaustive-deps

//   React.useEffect(() => {
//     // handle searchValue changes
//     if (searchValue.current?.length && !isLoading.current) {
//       currentPage.current = 1;
//       fullyLoaded.current = false;
//       lastVisibleDoc.current = null;
//       setCreatorsData([])
//       creatorSearch();
//     } else {
//       // default to sortBy list on empty searchValue
//       if (authUser?.uid && !isLoading.current) {
//         currentPage.current = 1;
//         fullyLoaded.current = false;
//         handleFetchData();
//       }
//     }
//   }, [searchValue.current]) //eslint-disable-line react-hooks/exhaustive-deps

//   let checkMobile = () => {
//     if (window.screen.availWidth < 900) {
//       isMobile.current = true;
//     } else {
//       isMobile.current = false;
//     }
//   }

//   let creatorSearch = async () => {
//     if (!fullyLoaded.current && !isLoading.current) {
//       isLoading.current = true;

//       getCreatorsList(authUser?.uid, searchValue.current || '', currentPage.current, PAGE_LIMIT)
//         .then(async (res) => {
//           if (res.ok) {
//             await res.json().then(data => {
//               if (data.data.length === 0) {
//                 fullyLoaded.current = true;
//               }

//               let redoSearch = false;

//               // make sure the search at least fills the page
//               setCreatorsData(prevData => {
//                 if (prevData.length + data.data.length < 6) {
//                   redoSearch = true;
//                 }
//                 return [...prevData, ...data.data]
//               })
//               currentPage.current++;
//               isLoading.current = false;

//               if (redoSearch && data.data.length) creatorSearch();
//             })
//           } else {
//             isLoading.current = false;
//             throw res.error
//           }
//         })
//     }
//   };

//   const handleSearch = (event: any) => {
//     const newValue = event?.target?.value?.toLowerCase();
//     if (searchTimer?.current) {
//       clearTimeout(searchTimer.current);
//       searchTimer.current = 0;
//     }

//     searchTimer.current = setTimeout(() => {
//       searchValue.current = newValue;
//       searchTimer.current = 0;
//       setRefresh(!refresh)
//     }, 250);
//   };

//   const handleFetchData = () => {
//     if (!fullyLoaded.current && !isLoading.current) {
//       isLoading.current = true;
//       setRefresh(curState => !curState);
//       if (currentPage.current === 1) {
//         setCreatorsData([]);
//       }

//       // Determine if we're on a corporate account page
//       const isCorporateAccountPage = !!enterpriseName;
//       const isStanford = window.location.hostname === 'stanford.soopra.ai';
//       const effectiveSortBy = isCorporateAccountPage ? "aToz" : sortBy.current;

//       const fetchData = isStanford
//       ? getSortedCreatorList(authUser.uid, currentPage.current, 10000, effectiveSortBy)
//       : isCorporateAccountPage
//         ? getCorporateAccounts(enterpriseName, currentPage.current, PAGE_LIMIT, effectiveSortBy)
//         : getSortedCreatorList(authUser.uid, currentPage.current, PAGE_LIMIT, effectiveSortBy);

//       fetchData.then(resp => {
//         if (resp.ok) {
//           return resp.json();
//         } else {
//           isLoading.current = false;
//           throw new Error('Failed to fetch data');
//         }
//       }).then(data => {
//         currentPage.current += 1;
//         if (!data.data.length || effectiveSortBy === 'trending') {
//           fullyLoaded.current = true;
//         }
//         isLoading.current = false;

//         setCreatorsData(prevData => [...prevData, ...data.data]);
//       }).catch(err => {
//         console.error('Error in creator fetch:', err);
//         isLoading.current = false;
//         fullyLoaded.current = true;
//         setRefresh(curState => !curState);
//       });
//     }
//   };

//   const CourseBanner = () => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     return (
//       <>
//         {!isMobile && (
//           <a
//             href="https://play.google.com/store/apps/details?id=ai.mimio.app&hl=en_ZA"
//             target="_blank"
//             rel="noopener noreferrer"
//             style={{ width: '100%', height: '100%', display: 'block' }}
//           >
//             <img
//               className="mobile-hidden"
//               src={CourseBannerFull}
//               loading="lazy"
//               style={{
//                 width: '79vw',
//                 height: '150px',
//                 objectFit: 'cover',
//                 margin: 0,
//                 padding: 0,
//               }}
//               alt="Course Banner"
//             />
//           </a>
//         )}
//         {isMobile && (
//           <a
//             href="https://play.google.com/store/apps/details?id=ai.mimio.app&hl=en_ZA"
//             target="_blank"
//             rel="noopener noreferrer"
//             style={{ width: '100%', height: '100%', display: 'block' }}
//           >
//             <Box
//               sx={{
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 textAlign: 'center',
//                 color: 'white',
//                 padding: '10px',
//                 backgroundColor: '#009CDB',
//               }}
//             >
//               <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
//                 AI-powered experts are now pocket sized
//               </Typography>
//               <Typography variant="body2" component="div" sx={{ marginBottom: '10px' }}>
//                 Download the Soopra mobile app on Android now!
//               </Typography>
//               <Box
//                 component="img"
//                 src={GooglePlayButton}
//                 alt="Google Play Button"
//                 sx={{
//                   width: '150px', // Adjust the size as needed
//                   height: 'auto',
//                   marginTop: '10px',
//                 }}
//               />
//             </Box>
//           </a>
//         )}
//       </>
//     );
//   };

//   const SecondBanner = () => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     return (
//       <Box
//         sx={{
//           width: '100%',
//           height: '150px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           boxSizing: 'border-box',
//           textAlign: 'center', // Center text on mobile
//           paddingLeft: isMobile ? 2 : 4,
//           paddingRight: isMobile ? 2 : 0,
//         }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center', // Center elements on mobile
//           }}
//         >
//           <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
//             Interactive Learning Courses Are Here
//           </Typography>
//           <Typography variant="subtitle1" sx={{ marginTop: '8px' }}>
//             AI powered courses designed by real-life experts.
//           </Typography>
//           <Button
//               variant="contained"
//               color="primary"
//               sx={{ marginTop: '12px', width: '300px' }}
//               onClick={() => navigate('/courses')}
//             >
//           View Courses
//         </Button>
//         </Box>

//         {!isMobile && (
//           <Box
//             sx={{
//               width: '55%',
//               display: 'flex',
//               justifyContent: 'flex-end',
//             }}
//           >
//             <img
//               src={BannerImg}
//               alt="Interactive Learning"
//               style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//             />
//           </Box>
//         )}
//       </Box>
//     );
//   };

//   const CombinedBanner = () => {
//     const settings = {
//       dots: false,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       autoplay: true,
//       autoplaySpeed: 3000,
//       arrows: false,
//     };

//     return (
//       <Box
//         sx={{
//           width: '100%',
//           height: '150px',
//           margin: '0 !important',
//           padding: '0 !important',
//           position: 'relative',
//           overflow: 'hidden', // Ensure no overflow on mobile
//         }}
//       >
//         <Slider {...settings}>
//           <div>
//             <CourseBanner />
//           </div>
//           <div>
//             <SecondBanner />
//           </div>
//         </Slider>
//       </Box>
//     );
//   };

//   return (
//     <Box display='flex' flex={1} >
//       <Box
//         width='100%'
//         display='flex'
//         flexDirection='column'
//         alignItems='center'
//         textAlign='center'
//       >
//         <AuthUserProfile authUserData={authUser} />
//         {!window.location.href.startsWith('https://stanford.soopra.ai') && <CombinedBanner />}
//         {createMIMIOFeature && authUser?.userType === USER_TYPE.FOLLOWER && (
//           <Box className={styles.createContainerMob}>
//             <CreateMimioContainer />
//           </Box>
//         )}

//         <Grid container>
//           { (window.location.href.startsWith('https://stanford.soopra.ai') || !window.location.href.startsWith('https://sambatv.soopra.ai')) && (
//             <Box
//               mt={1}
//               mb={1}
//               display='flex'
//               width='100%'
//               flexDirection='column'
//               textAlign='left'
//             >
//               <div style={{
//                 display: 'flex',
//                 justifyContent: enableCreatorSortBy ? 'space-between' : 'center',
//                 alignItems: 'center'
//               }}>
//                 <TextField
//                   type='text'
//                   margin='normal'
//                   fullWidth
//                   id='text-input-search'
//                   label='Search and chat with expert AI Personas'
//                   name='search'
//                   autoComplete='off'
//                   className='creator-search'
//                   onKeyDown={debounce(handleSearch, 500)}
//                   InputProps={{
//                     endAdornment: (
//                       <InputAdornment position='end'>
//                         <SearchIcon />
//                       </InputAdornment>
//                     ),
//                   }}
//                   sx={{
//                     width: '80%',
//                     flexGrow: isMobile.current ? 1 : 0,
//                     '&.creator-search input': {
//                       borderWidth: 0,
//                     },
//                     mt: 1,
//                     mb: 1,
//                     ml: isMobile.current ? 1 : 0,
//                     mr: isMobile.current ? 1 : 0,
//                   }}
//                 />
//                 {enableCreatorSortBy &&
//                   <>
//                     {isMobile.current ?
//                       <IconButton
//                         id='button-sort-menu'
//                         sx={{
//                           padding: '8px',
//                         }}
//                         onClick={(e) => {
//                           setShowSortByMenu(true)
//                           setMenuAnchor(e?.currentTarget)
//                         }}
//                       >
//                         <SortIcon
//                           height='20px'
//                           width='20px'
//                         />
//                       </IconButton> :
//                       <Box
//                         id='button-sort-menu'
//                         onClick={(e) => {
//                           setShowSortByMenu(true)
//                           setMenuAnchor(e?.currentTarget)
//                         }}
//                         sx={{
//                           display: isMobile.current ? 'none' : 'flex',
//                           justifyContent: 'flex-end',
//                           alignItems: 'center',
//                           padding: 1,
//                           ml: 1,
//                           '&:hover': {
//                             cursor: 'pointer',
//                             backgroundColor: `${grey[100]}`,
//                             borderRadius: '8px'
//                           }
//                         }}
//                       >
//                         <Typography
//                           ml={1}
//                           mr={1}
//                           fontSize='0.9rem'
//                           component='h5'
//                           variant='h6'
//                         >
//                           {SORT_BY_OPTIONS[sortBy.current]}
//                         </Typography>
//                         <ExpandMoreIcon
//                           height='20px'
//                           width='20px'
//                         />
//                       </Box>
//                     }
//                     <Menu
//                       id='menu-sort-by'
//                       open={Boolean(showSortByMenu)}
//                       onClose={() => { setShowSortByMenu(false) }}
//                       anchorEl={menuAnchor}
//                       anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
//                       transformOrigin={{ vertical: 0, horizontal: 'right', }}
//                       PaperProps={{
//                         style: {
//                           display: 'flex',
//                           justifyContent: 'flex-start',
//                           alignItems: 'center',
//                           borderRadius: '10px',
//                           overflow: 'hidden'
//                         },
//                       }}
//                     >
//                       {Object.entries(SORT_BY_OPTIONS).map(([value, label], index) => (
//                         <MenuItem
//                           id={`button-sortby-option-${value}`}
//                           key={index}
//                           onClick={() => {
//                             if (sortBy.current !== value) {
//                               sortBy.current = value; // triggers a useEffect
//                             }
//                             setShowSortByMenu(false);
//                           }}
//                           sx={{
//                             display: 'flex',
//                             justifyContent: 'flex-start',
//                             alignItems: 'center',
//                             textAlign: 'center',
//                             p: 1,
//                             pt: 0.2,
//                             pb: 0.2,
//                             borderRadius: '.7em',
//                             minHeight: 0,
//                           }}
//                         >
//                           <CircleIcon
//                             sx={{
//                               height: '10px',
//                               width: '10px',
//                               // color: 'mainBlue.main',
//                               visibility: sortBy.current === value ? 'visible' : 'hidden'
//                             }}
//                           />
//                           <Typography
//                             ml={1}
//                             mr={1}
//                             fontSize='0.8rem'
//                             component='h5'
//                             variant='h6'
//                             fontWeight={sortBy.current === value ? 'bold' : 'normal'}
//                           >
//                             {label}
//                           </Typography>
//                           <CircleIcon
//                             sx={{
//                               height: '10px',
//                               width: '10px',
//                               // color: 'mainBlue.main',
//                               visibility: 'hidden'
//                             }}
//                           />
//                         </MenuItem>
//                       ))}
//                     </Menu>
//                   </>
//                 }
//               </div>
//             </Box>
//           )}

//         {window.location.hostname === 'stanford.soopra.ai' ? (
//           creatorsData
//             ?.filter(
//               creatorItem =>
//                 creatorItem.userName === 'ronjon' ||
//                 creatorItem.userName === 'sortino-chat' ||
//                 creatorItem.userName === 'carlos_seligo'
//             )
//             .map((creatorItem, i, arr) => (
//               <FanCreatorItem
//                 creatorItem={creatorItem}
//                 key={creatorItem?.creatorId}
//               />
//             ))
//         ) : (
//           creatorsData?.length >= 1 ? (
//             <Box width='100%' sx={{ pb: 2 }}>
//               <Grid container spacing={2}>
//                 {creatorsData?.map((creatorItem, i, arr) =>
//                   <FanCreatorItem
//                     creatorItem={creatorItem}
//                     key={creatorItem?.creatorId}
//                   />
//                 )}
//               </Grid>
//               {(fullyLoaded.current || !isLoading.current) || window.location.hostname === 'sambatv.mimio.ai' ? <></> :
//                 <Box
//                   display='flex'
//                   width='100%'
//                   padding='1.5rem'
//                   alignItems='center'
//                   justifyContent='center'
//                 >
//                   <CircularProgress size={30} />
//                 </Box>
//               }
//             </Box>
//           ) : isLoading.current && creatorsData?.length === 0 ? (
//             <Box
//               display='flex'
//               width='100%'
//               padding='1.5rem'
//               alignItems='center'
//               justifyContent='center'
//             >
//               <CircularProgress size={30} />
//             </Box>
//           ) : (
//             <Box
//               mt={2}
//               rowGap={2}
//               sx={{
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 display: 'flex',
//                 width: '100%',
//                 justifyContent: 'center',
//               }}
//             >
//               <Avatar
//                 sx={{
//                   width: 80,
//                   height: 80,
//                 }}
//               />
//               <Typography
//                 component='h5'
//                 variant='body2'
//                 fontSize='1rem'
//                 fontWeight={500}
//               >
//                 No persona found
//               </Typography>
//             </Box>
//           )
//         )}
//         </Grid>
//         <div className="custom-bottom" ref={bottom}/>
//       </Box>
//     </Box>
//   );
// }

// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
// import Avatar from '@mui/material/Avatar';
// import { debounce, IconButton, Stack } from '@mui/material/';
// import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';

// import { useAuth } from '../../hooks/useAuth';
// import AuthUserProfile from './authUserProfile';
// import FanCreatorItem from './fanCreatorItem';
// import CreateMimioContainer from './createMimioContainer';
// import { USER_TYPE } from '../../config/const';
// import styles from './fanCreatorList.module.css';
// import {
//   getCreatorsList,
//   getSortedCreatorList,
//   getCorporateAccounts,
// } from '../../utils/api';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import CircleIcon from '@mui/icons-material/Circle';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import SortIcon from '@mui/icons-material/Sort';
// import { grey } from '@mui/material/colors';
// // import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
// import { SortByContext } from '../../context/SortByContext';
// import { useNavigate, useParams } from 'react-router-dom';
// import CourseBannerFull from '../../assets/images/banner images/banner.png';
// import GooglePlayButton from '../../assets/images/banner images/googleplaybutton.png';
// import BannerImg from '../../assets/images/banner images/bannerimg.png';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import Slider from 'react-slick';

// const PAGE_LIMIT = 14;

// //create mimio feature to show/hide create my mimio section
// const createMIMIOFeature =
//   process.env.REACT_APP_ENABLE_CREATE_MIMIO_FEATURE === 'true';

// const enableCreatorSortBy =
//   process.env.REACT_APP_ENABLE_CREATOR_SORT_BY === 'true';

// const SORT_BY_OPTIONS = {
//   topFans: 'Most Fans',
//   topMessages: 'Most Messages',
//   aToz: 'Alphabetical (A-Z)',
//   zToa: 'Alphabetical (Z-A)',
//   oldestFirst: 'Oldest Accounts',
//   trending: 'Trending',
// };

// export default function FanCreatorList() {
//   const { enterpriseName } = useParams();
//   const searchValue = React.useRef<string>('');
//   const [refresh, setRefresh] = React.useState<boolean>(false);
//   const searchTimer = React.useRef<any>(0);
//   const isLoading = React.useRef<boolean>(false);
//   const { authUser } = useAuth();
//   const [creatorsData, setCreatorsData] = React.useState([]);
//   const [showSortByMenu, setShowSortByMenu] = React.useState(false);
//   const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>(null);
//   const currentPage = React.useRef<number>(1);
//   const lastVisibleDoc = React.useRef<any>(null);
//   const fullyLoaded = React.useRef<boolean>(false);
//   const { sortBy } = React.useContext(SortByContext);
//   const isMobile = React.useRef<boolean>(false);
//   const bottom = React.useRef(null);
//   const navigate = useNavigate();

//   React.useEffect(() => {
//     checkMobile();

//     window.addEventListener('resize', checkMobile, false);

//     const observer = new IntersectionObserver((entries) => {
//       if (entries[0].isIntersecting) {
//         if (searchValue.current?.length) {
//           creatorSearch();
//         } else {
//           handleFetchData();
//         }
//       }
//     }, {
//       rootMargin: '100px', // Load more content before reaching the bottom
//     });
//     observer.observe(bottom.current);

//     return () => {
//       window.removeEventListener('resize', checkMobile, false);
//       observer.disconnect();
//     };
//   }, [creatorsData]); //eslint-disable-line react-hooks/exhaustive-deps

//   React.useEffect(() => {
//     // refetch data any time sortBy is changed
//     if (authUser?.uid && !isLoading.current) {
//       currentPage.current = 1;
//       fullyLoaded.current = false;
//       handleFetchData();
//     }
//   }, [sortBy.current]); //eslint-disable-line react-hooks/exhaustive-deps

//   React.useEffect(() => {
//     // handle searchValue changes
//     if (searchValue.current?.length && !isLoading.current) {
//       currentPage.current = 1;
//       fullyLoaded.current = false;
//       lastVisibleDoc.current = null;
//       setCreatorsData([]);
//       creatorSearch();
//     } else {
//       // default to sortBy list on empty searchValue
//       if (authUser?.uid && !isLoading.current) {
//         currentPage.current = 1;
//         fullyLoaded.current = false;
//         handleFetchData();
//       }
//     }
//   }, [searchValue.current]); //eslint-disable-line react-hooks/exhaustive-deps

//   let checkMobile = () => {
//     if (window.screen.availWidth < 900) {
//       isMobile.current = true;
//     } else {
//       isMobile.current = false;
//     }
//   };

//   let creatorSearch = async () => {
//     if (!fullyLoaded.current && !isLoading.current) {
//       isLoading.current = true;

//       getCreatorsList(
//         authUser?.uid,
//         searchValue.current || '',
//         currentPage.current,
//         PAGE_LIMIT
//       ).then(async (res) => {
//         if (res.ok) {
//           await res.json().then((data) => {
//             if (data.data.length === 0) {
//               fullyLoaded.current = true;
//             }

//             let redoSearch = false;

//             // make sure the search at least fills the page
//             setCreatorsData((prevData) => {
//               if (prevData.length + data.data.length < 6) {
//                 redoSearch = true;
//               }
//               return [...prevData, ...data.data];
//             });
//             currentPage.current++;
//             isLoading.current = false;

//             if (redoSearch && data.data.length) creatorSearch();
//           });
//         } else {
//           isLoading.current = false;
//           throw res.error;
//         }
//       });
//     }
//   };

//   const handleSearch = (event: any) => {
//     const newValue = event?.target?.value?.toLowerCase();
//     if (searchTimer?.current) {
//       clearTimeout(searchTimer.current);
//       searchTimer.current = 0;
//     }

//     searchTimer.current = setTimeout(() => {
//       searchValue.current = newValue;
//       searchTimer.current = 0;
//       setRefresh(!refresh);
//     }, 250);
//   };

//   const handleFetchData = () => {
//     if (!fullyLoaded.current && !isLoading.current) {
//       isLoading.current = true;
//       setRefresh((curState) => !curState);
//       if (currentPage.current === 1) {
//         setCreatorsData([]);
//       }

//       // Determine if we're on a corporate account page
//       const isCorporateAccountPage = !!enterpriseName;
//       const isStanford = window.location.hostname === 'stanford.soopra.ai';
//       const effectiveSortBy = isCorporateAccountPage ? 'aToz' : sortBy.current;

//       const fetchData = isStanford
//         ? getSortedCreatorList(
//             authUser.uid,
//             currentPage.current,
//             10000,
//             effectiveSortBy
//           )
//         : isCorporateAccountPage
//         ? getCorporateAccounts(
//             enterpriseName,
//             currentPage.current,
//             PAGE_LIMIT,
//             effectiveSortBy
//           )
//         : getSortedCreatorList(
//             authUser.uid,
//             currentPage.current,
//             PAGE_LIMIT,
//             effectiveSortBy
//           );

//       fetchData
//         .then((resp) => {
//           if (resp.ok) {
//             return resp.json();
//           } else {
//             isLoading.current = false;
//             throw new Error('Failed to fetch data');
//           }
//         })
//         .then((data) => {
//           currentPage.current += 1;
//           if (!data.data.length || effectiveSortBy === 'trending') {
//             fullyLoaded.current = true;
//           }
//           isLoading.current = false;

//           setCreatorsData((prevData) => [...prevData, ...data.data]);
//         })
//         .catch((err) => {
//           console.error('Error in creator fetch:', err);
//           isLoading.current = false;
//           fullyLoaded.current = true;
//           setRefresh((curState) => !curState);
//         });
//     }
//   };

//   const CourseBanner = () => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     return (
//       <>
//         {!isMobile && (
//           <a
//             href='https://play.google.com/store/apps/details?id=ai.mimio.app&hl=en_ZA'
//             target='_blank'
//             rel='noopener noreferrer'
//             style={{ width: '100%', height: '100%', display: 'block' }}
//           >
//             <img
//               className='mobile-hidden'
//               src={CourseBannerFull}
//               loading='lazy'
//               style={{
//                 width: '79vw',
//                 height: '150px',
//                 objectFit: 'cover',
//                 margin: 0,
//                 padding: 0,
//               }}
//               alt='Course Banner'
//             />
//           </a>
//         )}
//         {isMobile && (
//           <a
//             href='https://play.google.com/store/apps/details?id=ai.mimio.app&hl=en_ZA'
//             target='_blank'
//             rel='noopener noreferrer'
//             style={{ width: '100%', height: '100%', display: 'block' }}
//           >
//             <Box
//               sx={{
//                 position: 'absolute',
//                 top: 0,
//                 left: 0,
//                 width: '100%',
//                 height: '100%',
//                 display: 'flex',
//                 flexDirection: 'column',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 textAlign: 'center',
//                 color: 'white',
//                 padding: '10px',
//                 backgroundColor: '#009CDB',
//               }}
//             >
//               <Typography
//                 variant='h6'
//                 component='div'
//                 sx={{ fontWeight: 'bold' }}
//               >
//                 AI-powered experts are now pocket sized
//               </Typography>
//               <Typography
//                 variant='body2'
//                 component='div'
//                 sx={{ marginBottom: '10px' }}
//               >
//                 Download the Soopra mobile app on Android now!
//               </Typography>
//               <Box
//                 component='img'
//                 src={GooglePlayButton}
//                 alt='Google Play Button'
//                 sx={{
//                   width: '150px', // Adjust the size as needed
//                   height: 'auto',
//                   marginTop: '10px',
//                 }}
//               />
//             </Box>
//           </a>
//         )}
//       </>
//     );
//   };

//   const SecondBanner = () => {
//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     return (
//       <Box
//         sx={{
//           width: '100%',
//           height: '150px',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           boxSizing: 'border-box',
//           textAlign: 'center', // Center text on mobile
//           paddingLeft: isMobile ? 2 : 4,
//           paddingRight: isMobile ? 2 : 0,
//         }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center', // Center elements on mobile
//           }}
//         >
//           <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
//             Interactive Learning Courses Are Here
//           </Typography>
//           <Typography variant='subtitle1' sx={{ marginTop: '8px' }}>
//             AI powered courses designed by real-life experts.
//           </Typography>
//           <Button
//             variant='contained'
//             color='primary'
//             sx={{ marginTop: '12px', width: '300px' }}
//             onClick={() => navigate('/courses')}
//           >
//             View Courses
//           </Button>
//         </Box>

//         {!isMobile && (
//           <Box
//             sx={{
//               width: '55%',
//               display: 'flex',
//               justifyContent: 'flex-end',
//             }}
//           >
//             <img
//               src={BannerImg}
//               alt='Interactive Learning'
//               style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
//             />
//           </Box>
//         )}
//       </Box>
//     );
//   };

//   const CombinedBanner = () => {
//     const settings = {
//       dots: false,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1,
//       autoplay: true,
//       autoplaySpeed: 3000,
//       arrows: false,
//     };

//     return (
//       <Box
//         sx={{
//           width: '100%',
//           height: '150px',
//           margin: '0 !important',
//           padding: '0 !important',
//           position: 'relative',
//           overflow: 'hidden', // Ensure no overflow on mobile
//         }}
//       >
//         <Slider {...settings}>
//           <div>
//             <CourseBanner />
//           </div>
//           <div>
//             <SecondBanner />
//           </div>
//         </Slider>
//       </Box>
//     );
//   };

//   return (
//     <Stack maxWidth={'lg'} margin={'auto'}>
//       <Stack direction={'row'} justifyContent='space-between' mt={4}>
//         {
//           <Typography variant='h6' color='text.primary' align='left'>
//             Chat with Experts
//           </Typography>
//         }
//         {enableCreatorSortBy && (
//           <>
//             {isMobile.current ? (
//               <IconButton
//                 id='button-sort-menu'
//                 sx={{
//                   padding: '8px',
//                 }}
//                 onClick={(e) => {
//                   setShowSortByMenu(true);
//                   setMenuAnchor(e?.currentTarget);
//                 }}
//               >
//                 <SortIcon height='20px' width='20px' />
//               </IconButton>
//             ) : (
//               <Box
//                 id='button-sort-menu'
//                 onClick={(e) => {
//                   setShowSortByMenu(true);
//                   setMenuAnchor(e?.currentTarget);
//                 }}
//                 sx={{
//                   display: isMobile.current ? 'none' : 'flex',
//                   justifyContent: 'flex-end',
//                   alignItems: 'center',
//                   padding: 1,
//                   ml: 1,
//                   border: '1px solid black',
//                   '&:hover': {
//                     cursor: 'pointer',
//                     backgroundColor: `${grey[100]}`,
//                     // borderRadius: '8px',
//                   },
//                 }}
//               >
//                 <Typography
//                   ml={1}
//                   mr={1}
//                   fontSize='0.9rem'
//                   component='h5'
//                   variant='h6'
//                 >
//                   {SORT_BY_OPTIONS[sortBy.current]}
//                 </Typography>
//                 <ExpandMoreIcon height='20px' width='20px' />
//               </Box>
//             )}
//             <Menu
//               id='menu-sort-by'
//               open={Boolean(showSortByMenu)}
//               onClose={() => {
//                 setShowSortByMenu(false);
//               }}
//               anchorEl={menuAnchor}
//               anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//               transformOrigin={{ vertical: 0, horizontal: 'right' }}
//               PaperProps={{
//                 style: {
//                   display: 'flex',
//                   justifyContent: 'flex-start',
//                   alignItems: 'center',
//                   borderRadius: '10px',
//                   overflow: 'hidden',
//                 },
//               }}
//             >
//               {Object.entries(SORT_BY_OPTIONS).map(([value, label], index) => (
//                 <MenuItem
//                   id={`button-sortby-option-${value}`}
//                   key={index}
//                   onClick={() => {
//                     if (sortBy.current !== value) {
//                       sortBy.current = value; // triggers a useEffect
//                     }
//                     setShowSortByMenu(false);
//                   }}
//                   sx={{
//                     display: 'flex',
//                     justifyContent: 'flex-start',
//                     alignItems: 'center',
//                     textAlign: 'center',
//                     p: 1,
//                     pt: 0.2,
//                     pb: 0.2,
//                     borderRadius: '.7em',
//                     minHeight: 0,
//                   }}
//                 >
//                   <CircleIcon
//                     sx={{
//                       height: '10px',
//                       width: '10px',
//                       // color: 'mainBlue.main',
//                       visibility:
//                         sortBy.current === value ? 'visible' : 'hidden',
//                     }}
//                   />
//                   <Typography
//                     ml={1}
//                     mr={1}
//                     fontSize='0.8rem'
//                     component='h5'
//                     variant='h6'
//                     fontWeight={sortBy.current === value ? 'bold' : 'normal'}
//                   >
//                     {label}
//                   </Typography>
//                   <CircleIcon
//                     sx={{
//                       height: '10px',
//                       width: '10px',
//                       // color: 'mainBlue.main',
//                       visibility: 'hidden',
//                     }}
//                   />
//                 </MenuItem>
//               ))}
//             </Menu>
//           </>
//         )}
//       </Stack>
//       {window.location.hostname === 'stanford.soopra.ai' ? (
//             creatorsData
//               ?.filter(
//                 (creatorItem) =>
//                   creatorItem.userName === 'ronjon' ||
//                   creatorItem.userName === 'sortino-chat' ||
//                   creatorItem.userName === 'carlos_seligo'
//               )
//               .map((creatorItem, i, arr) => (
//                 <FanCreatorItem
//                   creatorItem={creatorItem}
//                   key={creatorItem?.creatorId}
//                 />
//               ))
//           ) : creatorsData?.length >= 1 ? (
//             <Box
//               width='100%'
//               sx={{
//                 pb: 2,
//                 backgroundColor: (theme) => theme.palette.primary.contrastText,
//               }}
//             >
//               <Grid
//                 container
//                 spacing={2}
//                 maxWidth='lg'
//                 sx={{ margin: '0 auto', p: 0.5 }}
//               >
//                 {creatorsData?.map((creatorItem, i, arr) => (
//                   <FanCreatorItem
//                     creatorItem={creatorItem}
//                     key={creatorItem?.creatorId}
//                   />
//                 ))}
//               </Grid>
//               {fullyLoaded.current ||
//               !isLoading.current ||
//               window.location.hostname === 'sambatv.mimio.ai' ? (
//                 <></>
//               ) : (
//                 <Box
//                   display='flex'
//                   width='100%'
//                   padding='1.5rem'
//                   alignItems='center'
//                   justifyContent='center'
//                 >
//                   <CircularProgress size={30} />
//                 </Box>
//               )}
//             </Box>
//           ) : isLoading.current && creatorsData?.length === 0 ? (
//             <Box
//               display='flex'
//               width='100%'
//               padding='1.5rem'
//               alignItems='center'
//               justifyContent='center'
//             >
//               <CircularProgress size={30} />
//             </Box>
//           ) : (
//             <Box
//               mt={2}
//               rowGap={2}
//               sx={{
//                 flexDirection: 'column',
//                 alignItems: 'center',
//                 display: 'flex',
//                 width: '100%',
//                 justifyContent: 'center',
//               }}
//             >
//               <Avatar
//                 sx={{
//                   width: 80,
//                   height: 80,
//                 }}
//               />
//               <Typography
//                 component='h5'
//                 variant='body2'
//                 fontSize='1rem'
//                 fontWeight={500}
//               >
//                 No persona found
//               </Typography>
//             </Box>
//           )}
//       <div className='custom-bottom' ref={bottom} />
//     </Stack>
//     // <Box display='flex' flex={1}>
//     //   <Box
//     //     width='100%'
//     //     display='flex'
//     //     flexDirection='column'
//     //     alignItems='center'
//     //     textAlign='center'
//     //   >
//     //     <AuthUserProfile authUserData={authUser} />
//     //     {/* {!window.location.href.startsWith('https://stanford.soopra.ai') && <CombinedBanner />}
//     //     {createMIMIOFeature && authUser?.userType === USER_TYPE.FOLLOWER && (
//     //       <Box className={styles.createContainerMob}>
//     //         <CreateMimioContainer />
//     //       </Box>
//     //     )} */}

//     //     <Grid container>
//     //       {(window.location.href.startsWith('https://stanford.soopra.ai') ||
//     //         !window.location.href.startsWith('https://sambatv.soopra.ai')) && (
//     //         <Box
//     //           mt={1}
//     //           mb={1}
//     //           display='flex'
//     //           width='100%'
//     //           flexDirection='column'
//     //           textAlign='left'
//     //           maxWidth='lg'
//     //           margin='auto'
//     //           bgcolor='red'
//     //         >
//     //           <div
//     //             style={{
//     //               display: 'flex',
//     //               justifyContent: enableCreatorSortBy
//     //                 ? 'space-between'
//     //                 : 'center',
//     //               alignItems: 'center',
//     //             }}
//     //           >
//     //             {/* <TextField
//     //               type='text'
//     //               margin='normal'
//     //               fullWidth
//     //               id='text-input-search'
//     //               label='Search and chat with expert AI Personas'
//     //               name='search'
//     //               autoComplete='off'
//     //               className='creator-search'
//     //               onKeyDown={debounce(handleSearch, 500)}
//     //               InputProps={{
//     //                 endAdornment: (
//     //                   <InputAdornment position='end'>
//     //                     <SearchIcon />
//     //                   </InputAdornment>
//     //                 ),
//     //               }}
//     //               sx={{
//     //                 width: '80%',
//     //                 flexGrow: isMobile.current ? 1 : 0,
//     //                 '&.creator-search input': {
//     //                   borderWidth: 0,
//     //                 },
//     //                 mt: 1,
//     //                 mb: 1,
//     //                 ml: isMobile.current ? 1 : 0,
//     //                 mr: isMobile.current ? 1 : 0,
//     //               }}
//     //             /> */}
//     //             {/* {
//     //               <Typography variant='h6' color='text.primary' align='left'>
//     //                 Chat with Experts
//     //               </Typography>
//     //             } */}
//     //             {enableCreatorSortBy && (
//     //               <>
//     //                 {isMobile.current ? (
//     //                   <IconButton
//     //                     id='button-sort-menu'
//     //                     sx={{
//     //                       padding: '8px',
//     //                     }}
//     //                     onClick={(e) => {
//     //                       setShowSortByMenu(true);
//     //                       setMenuAnchor(e?.currentTarget);
//     //                     }}
//     //                   >
//     //                     <SortIcon height='20px' width='20px' />
//     //                   </IconButton>
//     //                 ) : (
//     //                   <Box
//     //                     id='button-sort-menu'
//     //                     onClick={(e) => {
//     //                       setShowSortByMenu(true);
//     //                       setMenuAnchor(e?.currentTarget);
//     //                     }}
//     //                     sx={{
//     //                       display: isMobile.current ? 'none' : 'flex',
//     //                       justifyContent: 'flex-end',
//     //                       alignItems: 'center',
//     //                       padding: 1,
//     //                       ml: 1,
//     //                       '&:hover': {
//     //                         cursor: 'pointer',
//     //                         backgroundColor: `${grey[100]}`,
//     //                         borderRadius: '8px',
//     //                       },
//     //                     }}
//     //                   >
//     //                     <Typography
//     //                       ml={1}
//     //                       mr={1}
//     //                       fontSize='0.9rem'
//     //                       component='h5'
//     //                       variant='h6'
//     //                     >
//     //                       {SORT_BY_OPTIONS[sortBy.current]}
//     //                     </Typography>
//     //                     <ExpandMoreIcon height='20px' width='20px' />
//     //                   </Box>
//     //                 )}
//     //                 <Menu
//     //                   id='menu-sort-by'
//     //                   open={Boolean(showSortByMenu)}
//     //                   onClose={() => {
//     //                     setShowSortByMenu(false);
//     //                   }}
//     //                   anchorEl={menuAnchor}
//     //                   anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
//     //                   transformOrigin={{ vertical: 0, horizontal: 'right' }}
//     //                   PaperProps={{
//     //                     style: {
//     //                       display: 'flex',
//     //                       justifyContent: 'flex-start',
//     //                       alignItems: 'center',
//     //                       borderRadius: '10px',
//     //                       overflow: 'hidden',
//     //                     },
//     //                   }}
//     //                 >
//     //                   {Object.entries(SORT_BY_OPTIONS).map(
//     //                     ([value, label], index) => (
//     //                       <MenuItem
//     //                         id={`button-sortby-option-${value}`}
//     //                         key={index}
//     //                         onClick={() => {
//     //                           if (sortBy.current !== value) {
//     //                             sortBy.current = value; // triggers a useEffect
//     //                           }
//     //                           setShowSortByMenu(false);
//     //                         }}
//     //                         sx={{
//     //                           display: 'flex',
//     //                           justifyContent: 'flex-start',
//     //                           alignItems: 'center',
//     //                           textAlign: 'center',
//     //                           p: 1,
//     //                           pt: 0.2,
//     //                           pb: 0.2,
//     //                           borderRadius: '.7em',
//     //                           minHeight: 0,
//     //                         }}
//     //                       >
//     //                         <CircleIcon
//     //                           sx={{
//     //                             height: '10px',
//     //                             width: '10px',
//     //                             // color: 'mainBlue.main',
//     //                             visibility:
//     //                               sortBy.current === value
//     //                                 ? 'visible'
//     //                                 : 'hidden',
//     //                           }}
//     //                         />
//     //                         <Typography
//     //                           ml={1}
//     //                           mr={1}
//     //                           fontSize='0.8rem'
//     //                           component='h5'
//     //                           variant='h6'
//     //                           fontWeight={
//     //                             sortBy.current === value ? 'bold' : 'normal'
//     //                           }
//     //                         >
//     //                           {label}
//     //                         </Typography>
//     //                         <CircleIcon
//     //                           sx={{
//     //                             height: '10px',
//     //                             width: '10px',
//     //                             // color: 'mainBlue.main',
//     //                             visibility: 'hidden',
//     //                           }}
//     //                         />
//     //                       </MenuItem>
//     //                     )
//     //                   )}
//     //                 </Menu>
//     //               </>
//     //             )}
//     //           </div>
//     //         </Box>
//     //       )}

//     //       {window.location.hostname === 'stanford.soopra.ai' ? (
//     //         creatorsData
//     //           ?.filter(
//     //             (creatorItem) =>
//     //               creatorItem.userName === 'ronjon' ||
//     //               creatorItem.userName === 'sortino-chat' ||
//     //               creatorItem.userName === 'carlos_seligo'
//     //           )
//     //           .map((creatorItem, i, arr) => (
//     //             <FanCreatorItem
//     //               creatorItem={creatorItem}
//     //               key={creatorItem?.creatorId}
//     //             />
//     //           ))
//     //       ) : creatorsData?.length >= 1 ? (
//     //         <Box
//     //           width='100%'
//     //           sx={{
//     //             pb: 2,
//     //             backgroundColor: (theme) => theme.palette.primary.contrastText,
//     //           }}
//     //         >
//     //           <Grid
//     //             container
//     //             spacing={2}
//     //             maxWidth='lg'
//     //             sx={{ margin: '0 auto', p: 0.5 }}
//     //           >
//     //             {creatorsData?.map((creatorItem, i, arr) => (
//     //               <FanCreatorItem
//     //                 creatorItem={creatorItem}
//     //                 key={creatorItem?.creatorId}
//     //               />
//     //             ))}
//     //           </Grid>
//     //           {fullyLoaded.current ||
//     //           !isLoading.current ||
//     //           window.location.hostname === 'sambatv.mimio.ai' ? (
//     //             <></>
//     //           ) : (
//     //             <Box
//     //               display='flex'
//     //               width='100%'
//     //               padding='1.5rem'
//     //               alignItems='center'
//     //               justifyContent='center'
//     //             >
//     //               <CircularProgress size={30} />
//     //             </Box>
//     //           )}
//     //         </Box>
//     //       ) : isLoading.current && creatorsData?.length === 0 ? (
//     //         <Box
//     //           display='flex'
//     //           width='100%'
//     //           padding='1.5rem'
//     //           alignItems='center'
//     //           justifyContent='center'
//     //         >
//     //           <CircularProgress size={30} />
//     //         </Box>
//     //       ) : (
//     //         <Box
//     //           mt={2}
//     //           rowGap={2}
//     //           sx={{
//     //             flexDirection: 'column',
//     //             alignItems: 'center',
//     //             display: 'flex',
//     //             width: '100%',
//     //             justifyContent: 'center',
//     //           }}
//     //         >
//     //           <Avatar
//     //             sx={{
//     //               width: 80,
//     //               height: 80,
//     //             }}
//     //           />
//     //           <Typography
//     //             component='h5'
//     //             variant='body2'
//     //             fontSize='1rem'
//     //             fontWeight={500}
//     //           >
//     //             No persona found
//     //           </Typography>
//     //         </Box>
//     //       )}
//     //     </Grid>
//     //     <div className='custom-bottom' ref={bottom} />
//     //   </Box>
//     // </Box>
//   );
// }

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
// import TextField from '@mui/material/TextField';
// import InputAdornment from '@mui/material/InputAdornment';
// import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import { debounce, IconButton } from '@mui/material/';
// import Grid from '@mui/material/Grid';
import { Grid2 as Grid } from "@mui/material";
import Button from '@mui/material/Button';

import { useAuth } from '../../hooks/useAuth';
// import AuthUserProfile from './authUserProfile';
import FanCreatorItem from './fanCreatorItem';
// import CreateMimioContainer from './createMimioContainer';
// import { USER_TYPE } from '../../config/const';
// import styles from './fanCreatorList.module.css';
import {
  getCreatorsList,
  getSortedCreatorList,
  getCorporateAccounts,
} from '../../utils/api';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CircleIcon from '@mui/icons-material/Circle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
// import { grey } from '@mui/material/colors';
// import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import { SortByContext } from '../../context/SortByContext';
import { useNavigate, useParams } from 'react-router-dom';
import CourseBannerFull from '../../assets/images/banner images/banner.png';
import GooglePlayButton from '../../assets/images/banner images/googleplaybutton.png';
import BannerImg from '../../assets/images/banner images/bannerimg.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Slider from 'react-slick';

const PAGE_LIMIT = 14;

//create mimio feature to show/hide create my mimio section
// const createMIMIOFeature =
//   process.env.REACT_APP_ENABLE_CREATE_MIMIO_FEATURE === 'true';

const enableCreatorSortBy =
  process.env.REACT_APP_ENABLE_CREATOR_SORT_BY === 'true';

const SORT_BY_OPTIONS = {
  topFans: 'Most Fans',
  topMessages: 'Most Messages',
  aToz: 'Alphabetical (A-Z)',
  zToa: 'Alphabetical (Z-A)',
  oldestFirst: 'Oldest Accounts',
  trending: 'Trending',
};

export default function FanCreatorList() {
  const { enterpriseName } = useParams();
  const searchValue = React.useRef<string>('');
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const searchTimer = React.useRef<any>(0);
  const isLoading = React.useRef<boolean>(false);
  const { authUser } = useAuth();
  const [creatorsData, setCreatorsData] = React.useState([]);
  const [showSortByMenu, setShowSortByMenu] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>(null);
  const currentPage = React.useRef<number>(1);
  const lastVisibleDoc = React.useRef<any>(null);
  const fullyLoaded = React.useRef<boolean>(false);
  const { sortBy } = React.useContext(SortByContext);
  const isMobile = React.useRef<boolean>(false);
  const bottom = React.useRef(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    checkMobile();

    window.addEventListener('resize', checkMobile, false);

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (searchValue.current?.length) {
            creatorSearch();
          } else {
            handleFetchData();
          }
        }
      },
      {
        rootMargin: '100px', // Load more content before reaching the bottom
      }
    );
    observer.observe(bottom.current);

    return () => {
      window.removeEventListener('resize', checkMobile, false);
      observer.disconnect();
    };
  }, [creatorsData]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // refetch data any time sortBy is changed
    if (authUser?.uid && !isLoading.current) {
      currentPage.current = 1;
      fullyLoaded.current = false;
      handleFetchData();
    }
  }, [sortBy.current]); //eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    // handle searchValue changes
    if (searchValue.current?.length && !isLoading.current) {
      currentPage.current = 1;
      fullyLoaded.current = false;
      lastVisibleDoc.current = null;
      setCreatorsData([]);
      creatorSearch();
    } else {
      // default to sortBy list on empty searchValue
      if (authUser?.uid && !isLoading.current) {
        currentPage.current = 1;
        fullyLoaded.current = false;
        handleFetchData();
      }
    }
  }, [searchValue.current]); //eslint-disable-line react-hooks/exhaustive-deps

  let checkMobile = () => {
    if (window.screen.availWidth < 900) {
      isMobile.current = true;
    } else {
      isMobile.current = false;
    }
  };

  let creatorSearch = async () => {
    if (!fullyLoaded.current && !isLoading.current) {
      isLoading.current = true;

      getCreatorsList(
        authUser?.uid,
        searchValue.current || '',
        currentPage.current,
        PAGE_LIMIT
      ).then(async (res) => {
        if (res.ok) {
          await res.json().then((data) => {
            if (data.data.length === 0) {
              fullyLoaded.current = true;
            }

            let redoSearch = false;

            // make sure the search at least fills the page
            setCreatorsData((prevData) => {
              if (prevData.length + data.data.length < 6) {
                redoSearch = true;
              }
              return [...prevData, ...data.data];
            });
            currentPage.current++;
            isLoading.current = false;

            if (redoSearch && data.data.length) creatorSearch();
          });
        } else {
          isLoading.current = false;
          throw res.error;
        }
      });
    }
  };

  const handleSearch = (event: any) => {
    const newValue = event?.target?.value?.toLowerCase();
    if (searchTimer?.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = 0;
    }

    searchTimer.current = setTimeout(() => {
      searchValue.current = newValue;
      searchTimer.current = 0;
      setRefresh(!refresh);
    }, 250);
  };

  const handleFetchData = () => {
    if (!fullyLoaded.current && !isLoading.current) {
      isLoading.current = true;
      setRefresh((curState) => !curState);
      if (currentPage.current === 1) {
        setCreatorsData([]);
      }

      // Determine if we're on a corporate account page
      const isCorporateAccountPage = !!enterpriseName;
      const isStanford = window.location.hostname === 'stanford.soopra.ai';
      const effectiveSortBy = isCorporateAccountPage ? 'aToz' : sortBy.current;

      const fetchData = isStanford
        ? getSortedCreatorList(
            authUser?.uid,
            currentPage.current,
            10000,
            effectiveSortBy
          )
        : isCorporateAccountPage
        ? getCorporateAccounts(
            enterpriseName,
            currentPage.current,
            PAGE_LIMIT,
            effectiveSortBy
          )
        : getSortedCreatorList(
            authUser?.uid,
            currentPage.current,
            PAGE_LIMIT,
            effectiveSortBy
          );

      fetchData
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            isLoading.current = false;
            throw new Error('Failed to fetch data');
          }
        })
        .then((data) => {
          currentPage.current += 1;
          if (!data.data.length || effectiveSortBy === 'trending') {
            fullyLoaded.current = true;
          }
          isLoading.current = false;

          setCreatorsData((prevData) => [...prevData, ...data.data]);
        })
        .catch((err) => {
          console.error('Error in creator fetch:', err);
          isLoading.current = false;
          fullyLoaded.current = true;
          setRefresh((curState) => !curState);
        });
    }
  };

  const CourseBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <>
        {!isMobile && (
          <a
            href='https://play.google.com/store/apps/details?id=ai.mimio.app&hl=en_ZA'
            target='_blank'
            rel='noopener noreferrer'
            style={{ width: '100%', height: '100%', display: 'block' }}
          >
            <img
              className='mobile-hidden'
              src={CourseBannerFull}
              loading='lazy'
              style={{
                width: '79vw',
                height: '150px',
                objectFit: 'cover',
                margin: 0,
                padding: 0,
              }}
              alt='Course Banner'
            />
          </a>
        )}
        {isMobile && (
          <a
            href='https://play.google.com/store/apps/details?id=ai.mimio.app&hl=en_ZA'
            target='_blank'
            rel='noopener noreferrer'
            style={{ width: '100%', height: '100%', display: 'block' }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                color: 'white',
                padding: '10px',
                backgroundColor: '#009CDB',
              }}
            >
              <Typography
                variant='h6'
                component='div'
                sx={{ fontWeight: 'bold' }}
              >
                AI-powered experts are now pocket sized
              </Typography>
              <Typography
                variant='body2'
                component='div'
                sx={{ marginBottom: '10px' }}
              >
                Download the Soopra mobile app on Android now!
              </Typography>
              <Box
                component='img'
                src={GooglePlayButton}
                alt='Google Play Button'
                sx={{
                  width: '150px', // Adjust the size as needed
                  height: 'auto',
                  marginTop: '10px',
                }}
              />
            </Box>
          </a>
        )}
      </>
    );
  };

  const SecondBanner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Box
        sx={{
          width: '100%',
          height: '150px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxSizing: 'border-box',
          textAlign: 'center', // Center text on mobile
          paddingLeft: isMobile ? 2 : 4,
          paddingRight: isMobile ? 2 : 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center', // Center elements on mobile
          }}
        >
          <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
            Interactive Learning Courses Are Here
          </Typography>
          <Typography variant='subtitle1' sx={{ marginTop: '8px' }}>
            AI powered courses designed by real-life experts.
          </Typography>
          <Button
            variant='contained'
            color='primary'
            sx={{ marginTop: '12px', width: '300px' }}
            onClick={() => navigate('/courses')}
          >
            View Courses
          </Button>
        </Box>

        {!isMobile && (
          <Box
            sx={{
              width: '55%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <img
              src={BannerImg}
              alt='Interactive Learning'
              style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const CombinedBanner = () => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
    };

    return (
      <Box
        sx={{
          width: '100%',
          height: '150px',
          margin: '0 !important',
          padding: '0 !important',
          position: 'relative',
          overflow: 'hidden', // Ensure no overflow on mobile
        }}
      >
        <Slider {...settings}>
          <div>
            <CourseBanner />
          </div>
          <div>
            <SecondBanner />
          </div>
        </Slider>
      </Box>
    );
  };

  return (
    <Box
      width='100%'
      display='flex'
      flex={1}
      bgcolor={(theme) => theme.palette.background.default}
    >
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        alignItems='center'
        textAlign='center'
      >
        {/* <AuthUserProfile authUserData={authUser} /> */}
        {/* {!window.location.href.startsWith('https://stanford.soopra.ai') && <CombinedBanner />}
        {createMIMIOFeature && authUser?.userType === USER_TYPE.FOLLOWER && (
          <Box className={styles.createContainerMob}>
            <CreateMimioContainer />
          </Box>
        )} */}

        <Grid container  
        sx={{
            width: '100%',
            margin: 'auto',
            maxWidth: 'lg',
          }}>
          {(window.location.href.startsWith('https://stanford.soopra.ai') ||
            !window.location.href.startsWith('https://sambatv.soopra.ai')) && (
            <Box
              sx={{
                pt: { xs: 2, sm: 2.5, md: 4.5 },
                px: { xs: 2.5, md: 4 },
                margin: 'auto',
                maxWidth: 'lg',
              }}
              display='flex'
              width='100%'
              flexDirection='column'
              textAlign='left'
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: enableCreatorSortBy
                    ? 'space-between'
                    : 'center',
                  alignItems: 'center',
                }}
              >
                {/* <TextField
                  type='text'
                  margin='normal'
                  fullWidth
                  id='text-input-search'
                  label='Search and chat with expert AI Personas'
                  name='search'
                  autoComplete='off'
                  className='creator-search'
                  onKeyDown={debounce(handleSearch, 500)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: '80%',
                    flexGrow: isMobile.current ? 1 : 0,
                    '&.creator-search input': {
                      borderWidth: 0,
                    },
                    mt: 1,
                    mb: 1,
                    ml: isMobile.current ? 1 : 0,
                    mr: isMobile.current ? 1 : 0,
                  }}
                /> */}
                <Typography variant='h7' color='text.primary' align='left'>
                  Chat with Experts
                </Typography>
                {enableCreatorSortBy && (
                  <>
                    {isMobile.current ? (
                      <IconButton
                        id='button-sort-menu'
                        sx={{
                          padding: '8px',
                        }}
                        onClick={(e) => {
                          setShowSortByMenu(true);
                          setMenuAnchor(e?.currentTarget);
                        }}
                      >
                        <SortIcon height='20px' width='20px' />
                      </IconButton>
                    ) : (
                      <Box
                        id='button-sort-menu'
                        onClick={(e) => {
                          setShowSortByMenu(true);
                          setMenuAnchor(e?.currentTarget);
                        }}
                        sx={{
                          width: '200px',
                          display: isMobile.current ? 'none' : 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: 1,
                          ml: 1,
                          border: '1px solid',
                          borderColor: 'text.secondary',
                          '&:hover': {
                            cursor: 'pointer',
                            // backgroundColor: `${grey[100]}`,
                            // borderRadius: '8px',
                          },
                        }}
                      >
                        <Typography
                          ml={1}
                          mr={1}
                          fontSize='0.9rem'
                          component='h5'
                          variant='h6'
                          color='text.secondary'
                        >
                          {SORT_BY_OPTIONS[sortBy.current]}
                        </Typography>
                        <ExpandMoreIcon
                          height='20px'
                          width='20px'
                          color='action'
                        />
                      </Box>
                    )}
                    <Menu
                      id='menu-sort-by'
                      open={Boolean(showSortByMenu)}
                      onClose={() => {
                        setShowSortByMenu(false);
                      }}
                      anchorEl={menuAnchor}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      transformOrigin={{ vertical: 0, horizontal: 'right' }}
                      PaperProps={{
                        style: {
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          borderRadius: '10px',
                          overflow: 'hidden',
                        },
                      }}
                    >
                      {Object.entries(SORT_BY_OPTIONS).map(
                        ([value, label], index) => (
                          <MenuItem
                            id={`button-sortby-option-${value}`}
                            key={index}
                            onClick={() => {
                              if (sortBy.current !== value) {
                                sortBy.current = value; // triggers a useEffect
                              }
                              setShowSortByMenu(false);
                            }}
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              textAlign: 'center',
                              p: 1,
                              pt: 0.2,
                              pb: 0.2,
                              borderRadius: '.7em',
                              minHeight: 0,
                            }}
                          >
                            <CircleIcon
                              sx={{
                                height: '10px',
                                width: '10px',
                                // color: 'mainBlue.main',
                                visibility:
                                  sortBy.current === value
                                    ? 'visible'
                                    : 'hidden',
                              }}
                            />
                            <Typography
                              ml={1}
                              mr={1}
                              fontSize='0.8rem'
                              component='h5'
                              variant='h6'
                              fontWeight={
                                sortBy.current === value ? 'bold' : 'normal'
                              }
                            >
                              {label}
                            </Typography>
                            <CircleIcon
                              sx={{
                                height: '10px',
                                width: '10px',
                                // color: 'mainBlue.main',
                                visibility: 'hidden',
                              }}
                            />
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </>
                )}
              </div>
            </Box>
          )}

          {window.location.hostname === 'stanford.soopra.ai' ? (
            creatorsData
              ?.filter(
                (creatorItem) =>
                  creatorItem.userName === 'ronjon' ||
                  creatorItem.userName === 'sortino-chat' ||
                  creatorItem.userName === 'carlos_seligo'
              )
              .map((creatorItem, i, arr) => (
                <FanCreatorItem
                  creatorItem={creatorItem}
                  key={creatorItem?.creatorId}
                />
              ))
          ) : creatorsData?.length >= 1 ? (
            // removing code of temp commit
            <Box width='100%'>
              <Grid
                container
                sx={{
                  px: { xs: 1.25, md: 2.5 },
                  pt: { xs: 1, sm: 1.25, md: 1 },
                  pb: { xs: 1, sm: 1.25, md: 2 },
                  margin: 'auto',
                  maxWidth: 'lg',
                }}
              >
                {creatorsData?.map((creatorItem, i, arr) => (
                  <FanCreatorItem
                    creatorItem={creatorItem}
                    key={creatorItem?.creatorId}
                  />
                ))}
              </Grid>
              {fullyLoaded.current ||
              !isLoading.current ||
              window.location.hostname === 'sambatv.mimio.ai' ? (
                <></>
              ) : (
                <Box
                  display='flex'
                  width='100%'
                  padding='1.5rem'
                  alignItems='center'
                  justifyContent='center'
                >
                  <CircularProgress size={30} />
                </Box>
              )}
            </Box>
          ) : isLoading.current && creatorsData?.length === 0 ? (
            <Box
              display='flex'
              width='100%'
              padding='1.5rem'
              alignItems='center'
              justifyContent='center'
            >
              <CircularProgress size={30} />
            </Box>
          ) : (
            <Box
              mt={2}
              rowGap={2}
              sx={{
                flexDirection: 'column',
                alignItems: 'center',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Avatar
                sx={{
                  width: 80,
                  height: 80,
                }}
              />
              <Typography
                component='h5'
                variant='body2'
                fontSize='1rem'
                fontWeight={500}
              >
                No persona found
              </Typography>
            </Box>
          )}
        </Grid>
        <div className='custom-bottom' ref={bottom} />
      </Box>
    </Box>
  );
}
