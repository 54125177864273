
import { SVGProps, memo } from 'react';
const VoiceSampleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4586 7.81497V11.9178C15.4586 12.41 15.8576 12.809 16.3498 12.809C16.842 12.809 17.2409 12.41 17.2409 11.9178V7.81497C17.2409 7.32278 16.842 6.92379 16.3498 6.92379C15.8576 6.92379 15.4586 7.32278 15.4586 7.81497ZM0.9 7.81497V11.9178C0.9 12.41 1.29899 12.809 1.79117 12.809C2.28336 12.809 2.68235 12.41 2.68235 11.9178V7.81497C2.68235 7.32278 2.28336 6.92379 1.79117 6.92379C1.29899 6.92379 0.9 7.32278 0.9 7.81497ZM11.9646 5.13761V14.5949C11.9646 15.0871 12.3636 15.4861 12.8557 15.4861C13.3479 15.4861 13.7469 15.0871 13.7469 14.5949V5.13761C13.7469 4.64542 13.3479 4.24643 12.8557 4.24643C12.3636 4.24643 11.9646 4.64543 11.9646 5.13761ZM4.39403 5.13761V14.5949C4.39403 15.0871 4.79303 15.4861 5.28521 15.4861C5.77739 15.4861 6.17638 15.0871 6.17638 14.5949V5.13761C6.17638 4.64542 5.77739 4.24643 5.28521 4.24643C4.79302 4.24643 4.39403 4.64543 4.39403 5.13761ZM8.12297 1.84746V17.6701C8.12297 18.1933 8.54716 18.6175 9.07043 18.6175C9.59369 18.6175 10.0179 18.1933 10.0179 17.6701V1.84746C10.0179 1.32419 9.59369 0.9 9.07043 0.9C8.54716 0.9 8.12297 1.32419 8.12297 1.84746Z" fill={props?.color || "#424242"} stroke="black" strokeWidth="0.2"/>
</svg>

);

export default memo(VoiceSampleIcon)
