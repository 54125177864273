import { SVGProps, memo } from 'react';
const CallToActionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "23"} height={props.height || "22"} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Vector">
  <path d="M8.62488 0.494141L10.5277 6.82977L17.2498 8.62317L10.5277 10.4166L8.62488 16.7522L6.72208 10.4166L0 8.62317L6.72208 6.82977L8.62488 0.494141Z" fill="url(#paint0_linear_1034_30289)"/>
  <path d="M17.9688 12.0103L19.0788 15.7061L23 16.7522L19.0788 17.7984L17.9688 21.4941L16.8589 17.7984L12.9376 16.7522L16.8589 15.7061L17.9688 12.0103Z" fill="url(#paint1_linear_1034_30289)"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_1034_30289" x1="7.5" y1="5.99414" x2="23.1293" y2="23.3807" gradientUnits="userSpaceOnUse">
  <stop stopColor="#FF7ED2"/>
  <stop offset="1" stopColor="#009FFF"/>
  </linearGradient>
  <linearGradient id="paint1_linear_1034_30289" x1="7.5" y1="5.99414" x2="23.1293" y2="23.3807" gradientUnits="userSpaceOnUse">
  <stop stopColor="#FF7ED2"/>
  <stop offset="1" stopColor="#009FFF"/>
  </linearGradient>
  </defs>
  </svg>
)


export default memo(CallToActionIcon);
