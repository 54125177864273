import { Box, Button, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { LoginFormProps } from ".";
import SoopraLogo from "../../svg/SoopraLogo";
import CloseIcon from "../../svg/CloseIcon";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { FirebaseError } from "firebase/app";
import { toast } from 'react-toastify';
import { COMMON_FIREBASE_ERROR_CODE } from "../../config/errors";

const DEFAULT_ERROR_STATE = {email: null};
Object.freeze(DEFAULT_ERROR_STATE);

const ForgotPassword: React.FC<LoginFormProps> = ({setFormType, handleClose}) => {
  const [ email, setEmail ] = useState<string>("");
  const [ errors, setErrors ] = useState(DEFAULT_ERROR_STATE);
  const [ step, setStep ] = useState<number>(1);
  const theme = useTheme();
  const { handleSendResetPasswordEmail } = useAuth();

  const handleBackButton = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (step === 2) {
      setStep(1);
    } else {
      setFormType('signin');
    }
  }

  const handleSubmit = async () => {
    setErrors(DEFAULT_ERROR_STATE)
    if (email) {
      handleSendResetPasswordEmail(email)
        .then(() => {
          setStep(2);
        })
        .catch((error) => {
          const firebaseError = (error as FirebaseError)?.code;
          toast.error(
            firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
              ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
              : 'Invalid details'
          );
        });
    } else {
      let error = {email: 'Email is required'};
      setErrors(error)
    }
  }

  return (
    <Box
      p={4}
      display='flex'
      flexDirection='column'
      width='100%'
      maxWidth={'sm'}
      gap={4}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Box >
          <IconButton
            onClick={handleBackButton}
            color='primary'
            sx={{p: 0,}}
          >
            <KeyboardBackspaceIcon
              color='primary'
              sx={{ fontSize: 34}}
            />
          </IconButton>
        </Box>
        <Box
          flexGrow={1}
          display='flex'
          justifyContent='center'
        >
          <SoopraLogo color={theme.palette.primary.main} width='145px' height='34px'/>
        </Box>
        <Box
          sx={{
            mt: -1,
          }}
        >
          <IconButton
            onClick={handleClose}
            color='primary'
          >
            <CloseIcon
              height='24px'
              width='24px'
              stroke={theme.palette.text.primary}
              strokeWidth={2}
            />
          </IconButton>
        </Box>
      </Box>

      { step === 2 ?
        <>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            width='100%'
            gap={1}
          >
            <Typography variant="h5">
              Check Your Email
            </Typography>
            <Typography variant="body2" textAlign='center'>
              {`A password reset link has been sent to ${email}.`}
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            gap={2}
          >
            <Button
              variant="contained"
              size="large"
              sx={{
                boxShadow: (theme) => `${theme.shadows[2]} !important`,
              }}
              onClick={() => {
                setFormType('signin');
              }}
            >
              Return To Sign In
            </Button>

            <Button
              variant="contained"
              size="large"
              color="secondary"
              sx={{
                boxShadow: (theme) => `${theme.shadows[2]} !important`,
              }}
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </> :
        <>
          <Box
            display='flex'
            flexDirection='column'
            alignItems='center'
            width='100%'
            gap={1}
          >
            <Typography variant="h5">
              Forgot Password?
            </Typography>
            <Typography variant="body2">
              Enter your email to receive a password reset link.
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            gap={2}
          >
            <TextField
              type='text'
              fullWidth
              margin='none'
              label='Email'
              placeholder="Email address"
              value={email}
              error={!!errors.email}
              helperText={errors.email}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSubmit();
                }
              }}
              autoComplete='off'
            />

            <Button
              variant="contained"
              size="large"
              sx={{
                boxShadow: (theme) => `${theme.shadows[2]} !important`,
              }}
              disabled={!email}
              onClick={handleSubmit}
            >
              Send Password Reset Link
            </Button>
          </Box>
        </>
      }
    </Box>
  )
};

export default ForgotPassword;
