import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, ReactElement, useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { returnIsLocationChatScreen } from './utils/helper';
import { useAuth } from './hooks/useAuth';
import { auth } from './utils/firebase';

interface PrivateRouteProps {
  children: ReactElement<any, any>;
  authUserAccess?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children, authUserAccess }) => {
  const { token, authUser, authLoading } = useAuth();
  const location = useLocation();
  const { userName } = useParams();

  useEffect(() => {
    const hostname = window.location.hostname;
    const pathname = window.location.pathname;

    // List of paths to exclude from redirection
    const excludedPaths = [
      '/',
      '/sambatv',
      '/sambatv-mar',
      '/signin',
      '/signin-email',
      '/logout-user',
      '/signup',
      '/verify-email',
      '/create-profile',
      '/dashboard',
      '/sambatv/update',
      '/sambatv-mar/update',
      '/aipersona/samba.tv',
      '/widget',
      '/creators'
    ];

    const widgetPathPattern = /^\/[^/]+\/widget$/;

    if (
      !excludedPaths.includes(pathname) &&
      !widgetPathPattern.test(pathname)
    ) {
      if (hostname === 'sambatv.soopra.ai') {
        window.location.href = 'https://sambatv.soopra.ai/aipersona/samba.tv';
      } else if (hostname === 'sambatv.mimio.ai') {
        window.location.href = 'https://sambatv.mimio.ai/aipersona/samba.tv';
      } else if (hostname === 'stanford.soopra.ai') {
        window.location.href = 'https://stanford.soopra.ai/creators';
      }

    }
  }, []);

  if (authLoading) {
    return (
      <Box
        sx={{
          height: '100dvh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (!token || !authUser) {
    let redirectToPath = '/signin';
    if (location?.pathname !== undefined) {
      if (returnIsLocationChatScreen(location)) {
        redirectToPath = `/signin?redirectTo=${encodeURIComponent(
          location?.pathname
        )}`;
      } else if (location.pathname === '/logout-user') {
        redirectToPath = '/'; // send users back to discovery page
      }
    }
    return <Navigate to={redirectToPath} replace />;
  }

  if (
    location?.pathname !== '/create-profile' &&
    location?.pathname !== '/logout-user' &&
    authUser &&
    !authUser?.userName
  ) {
    return <Navigate to={'/create-profile'} replace state={location?.state}/>;
  }
  if (
    location?.pathname !== '/verify-email' &&
    location?.pathname !== '/logout-user' &&
    authUser?.documentId &&
    auth?.currentUser?.emailVerified !== undefined &&
    auth.currentUser.emailVerified === false
  ) {
    toast.error('Please verify your email to continue');
    return <Navigate to={'/verify-email'} replace state={location?.state}/>;
  }
  if (
    location?.pathname === '/journaling' &&
    authUser &&
    authUser?.normalizedUserName !== userName?.toLowerCase()
  ) {
    return <Navigate to={`/${authUser?.normalizedUserName}/journaling`} replace state={location?.state}/>;
  }
  if (
    authUserAccess &&
    authUser &&
    authUser?.normalizedUserName !== userName?.toLowerCase()
  ) {
    toast.error('You are not authorized to access this location');
    return <Navigate to={'/'} replace />;
  }
  return children;
};

export default PrivateRoute;
