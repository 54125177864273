import { Box, Button, Typography } from "@mui/material";
import CTAImage from "../../assets/images/banner images/call_to_action_image.jpg"
import CallToActionIcon from "../../svg/CallToActionIcon";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const CTAFeatures = [
  {
    icon: '⚡',
    title: 'Real-Time Training',
    description: 'Personas that evolve alongside you',
  },
  {
    icon: '🎓',
    title: 'Verified Personas',
    description: 'Build trust and authenticity',
  },
  {
    icon: '📓',
    title: 'Journaling',
    description: 'Capture insights that grow personas',
  },
  {
    icon: '🧠',
    title: 'Multi-Channel Training',
    description: 'Train AI from content like YouTube',
  },
  {
    icon: '💰',
    title: 'Dynamic Monetization Opportunities',
    description: 'Earn with premium courses, chats, and low commission rates',
  },
]

const CallToAction: React.FC = () => {
  const navigate = useNavigate();
  const { authUser, setLoginModalVisible } = useAuth();

  return (
    <Box
      sx={{
        width: '100%',
        px: {
          xs: 2,
          md: 0,
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          borderRadius: (theme) => theme.shape.borderRadius,
          overflow: 'hidden',
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
        }}
      >
        <Box
          sx={{
            minWidth: {
              xs: '100%',
              sm: '386px',
            },
            height: {
              xs: '307px',
              sm: 'auto',
            },
            backgroundImage:`url(${CTAImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            p: 4,
            backgroundImage: 'linear-gradient(0.3turn, #B180F033, #B180F033, #02B1FF33)',
            width: {
              xs: '100%',
              sm: 'auto',
            }
          }}
          display='flex'
          flexDirection='column'
          justifyContent='center'
          gap={3}

        >
          <Box
            display='flex'
            flexDirection='column'
          >
            <Typography
              variant="h5"
              pb={0.5}
            >
              Grow Your Influence, Monetize Your Expertise
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              Soopra enables creators to build AI personas for monetizing expertise, offering personalized interactions, real-time training, and multiple revenue streams, all while maintaining authenticity and engagement with their audience.
            </Typography>
          </Box>
          <Button // this button is custom
            size='medium'
            variant="contained"
            sx={{
              borderRadius: (theme) => `${theme.shape.borderRadiusRound*4}px !important`,
              boxShadow: (theme) => `${theme.shadows[2]} !important`,
              width: 'fit-content',
              backgroundColor: 'white !important',
              color: 'black !important',
            }}
            startIcon={<CallToActionIcon />}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              if (authUser) {
                navigate('/creator-onboarding')
              } else {
                setLoginModalVisible({
                  isOpen: true,
                  formType: 'signup',
                  currentStep: 1,
                })
              }
            }}
            href={authUser ? '/creator-onboarding' : null}
          >
            Create My AI Persona
          </Button>
          <Box
            display='flex'
            flexDirection='column'
            gap={2}
          >
            <Typography
              variant='h7'
            >
              New AI persona features:
            </Typography>
            <Box
              display='flex'
              flexDirection='column'
              gap={1}
            >
              {
                CTAFeatures.map((feat) => (
                  <Box
                    key={feat.title}
                    display='flex'
                    gap={0.5}
                    m={0}
                    p={0}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      {feat.icon}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: (theme) => theme.palette.text.secondary,
                      }}
                    >
                      <strong>{feat.title}</strong>
                      {" - "}
                      {feat.description}
                    </Typography>
                  </Box>
                ))
              }
            </Box>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

export default CallToAction;
