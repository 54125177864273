import { SVGProps, memo } from 'react';

const ProfileIcon2 = (props: SVGProps<SVGSVGElement>) => (

<svg width="9" height="14" viewBox="0 0 9 14" fill={props.fill || "none"} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M7.15165 1.34835C8.61612 2.81282 8.61612 5.18718 7.15165 6.65165C5.68718 8.11612 3.31282 8.11612 1.84835 6.65165C0.383883 5.18718 0.383883 2.81282 1.84835 1.34835C3.31282 -0.116116 5.68718 -0.116117 7.15165 1.34835ZM6.07933 2.42067C6.95157 3.29291 6.95157 4.70709 6.07933 5.57933C5.20709 6.45157 3.79291 6.45157 2.92067 5.57933C2.04843 4.70709 2.04843 3.29291 2.92067 2.42067C3.79291 1.54843 5.20709 1.54843 6.07933 2.42067Z" />
<path d="M1.51636 12.8268C1.45388 11.241 2.73966 9.95544 4.38824 9.95544C6.0323 9.95544 7.4163 11.2339 7.48584 12.8137V12.9947C7.48584 13.3963 7.8248 13.7342 8.24292 13.7495C8.66104 13.7647 9 13.4515 9 13.0498L9 12.8543C9 12.8451 8.99982 12.8359 8.99946 12.8268C8.9053 10.4372 6.81511 8.5 4.33089 8.5C1.85166 8.5 -0.0830712 10.4294 0.00274807 12.8124L0.00274806 12.9947C0.00274805 13.3963 0.341703 13.7342 0.759826 13.7495C1.17795 13.7647 1.5169 13.4515 1.5169 13.0498L1.5169 12.8543C1.5169 12.8451 1.51672 12.8359 1.51636 12.8268Z" />
</svg>

);

export default memo(ProfileIcon2);
