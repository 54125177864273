import { PRICES } from "../../config/const"
import { TiersCardItemDescriptions } from "./TiersCardItem"

export const FREE_FEATURES = {
  'Create AI Persona': [
    `With Soopra, you can create a personalized AI persona “Soopra”  that embodies your unique voice, expertise, and knowledge. This AI persona is an interactive agent to engage with your audience 24/7, allowing you to scale your interactions without being physically present. The creation process is simple yet powerful. You start by inputting information about yourself—your background, areas of expertise, preferred communication style, and the type of audience you typically engage with. Your AI then trains itself to mimic your communication patterns, responding to queries in a way that reflects your personality.`,
    `Once your AI persona is set up, it becomes your digital twin, capable of handling a variety of tasks. Whether you’re a professor looking to answer student questions or a consultant offering advice to clients, your AI persona can interact with users in real-time. The persona can handle both simple and complex queries, with the ability to dive deep into topics that match your areas of expertise. What sets Soopra apart is the customization and authenticity—your AI persona will sound like you, providing users with a personalized experience that feels genuine.`,
    {
      link: '/faq',
      description: 'How to Create your Soopra Persona'
    }
  ],
  'Share your Persona with Others to Text Chat': [
    `Soopra’s “Text Chat” feature allows your AI persona to engage in natural, conversational text-based interactions with your audience. Whether it’s answering FAQs, providing detailed explanations, or offering tailored advice, your AI persona will be able to handle it all in real-time. This feature is available 24/7, ensuring that your audience always has access to your expertise.`,
    `The text chat functionality is designed to simulate real conversations, making users feel like they are directly communicating with you. It supports rich text responses, including links, images, and multimedia, to enhance the interaction. For example, if someone asks a technical question, your persona can provide a detailed response along with relevant resources such as articles or tutorials. The AI continuously learns from these interactions, improving its ability to understand and respond to complex queries over time.`,
  ],
  'Journaling': [
    `Soopra's “Journaling” feature allows you to train your AI persona through regular conversations.`,
  ],
  'Monetize your AI Persona': [
    `Soopra operates on a % commission model, meaning that for every paid interaction or monetized service provided by your AI persona, Soopra takes a % fee per pricing tier. This includes revenue from subscriptions. This means that whenever a transaction occurs, such as a user subscribing to access your AI persona, Soopra retains % of that transaction as a commission for facilitating and hosting the service. This helps us maintain and improve the platform, ensuring that your AI persona remains accessible and efficient.`
  ],
  '50% Soopra Paywall Commission': [],
  '500 AI Persona Chat Responses': []
}

export const STANDARD_FEATURES = {
  'All Free Features': [],
  'View Chat History': [
    `The View Chat History feature gives you complete transparency into how your AI persona interacts with users. You can access a detailed log of all conversations that your AI persona has had, providing insights into the types of questions being asked, how the persona is responding, and user satisfaction.`,
    `This history is a valuable tool for fine-tuning your persona’s performance. By reviewing the chat logs, you can see where the AI might need additional training or adjustments to better handle specific topics. Additionally, the chat history can help you identify recurring themes or frequently asked questions, which could inform your content strategy or highlight areas where your audience needs more guidance. This feature ensures that you remain in control of your persona’s interactions and allows for continuous improvement.`,
    `This feature is available in the dashboard in the hamburger menu.`
  ],
  'Real-Time Training': [
    `Soopra’s Real-Time Training feature provides you with the ability to update and tweak your AI persona on the fly. Whether you want to adjust how your persona responds to specific queries, change its tone, or add new content for it to reference, you can do so in real-time without any downtime.`,
    `This feature is particularly useful for keeping your AI persona up to date with the latest information, trends, or changes in your field. For instance, if you’re a consultant and a new industry regulation is introduced, you can immediately update your persona with the latest knowledge, ensuring it provides accurate and relevant advice. Real-time training give you the flexibility to make ongoing adjustments as your persona grows and evolves.`,
    {
      link: '/faq',
      description: 'How does real-time training work?'
    }
  ],
  'Analytics': [
    `Soopra’s Analytics feature provides in-depth insights into how your AI persona is performing. You’ll receive data on metrics like the number of interactions, user satisfaction rates, common topics or questions, and engagement trends. These analytics help you understand how your persona is being used and where there are opportunities for improvement.`,
    `For instance, you’ll be able to see which topics generate the most interest from your audience, how quickly your persona responds, and whether users are satisfied with the answers they receive. You can also track the overall growth of your persona’s engagement over time, helping you gauge its impact on your audience. This data-driven approach allows you to make informed decisions about how to optimize your AI persona for better engagement and monetization.`
  ],
  '40% Soopra Paywall Commission': [],
  '1,500 AI Persona Chat Responses': []
}

export const PREMIUM_FEATURES = {
  'All Standard Features': [],
  'Verified': [],
  'Train your AI Persona with your YouTube videos': [],
  'Offer courses with your AI Persona': [
    `The Offer Courses feature allows you to expand your reach by offering structured educational content directly through your AI persona. Whether you’re a professor, coach, or expert in your field, you can create and deliver courses that users can access at any time.`,
    `These courses can be segmented into lessons, modules, or topics, with your persona acting as the guide throughout the learning experience. You can offer free content to engage users or charge a premium for more advanced material, creating a new revenue stream. The course content can be updated in real-time, and your AI persona can offer personalized recommendations based on user progress and interaction patterns.`,
    {
      link: '/faq',
      description: 'How to create courses'
    }
  ],
  '20% Soopra Paywall Commission': [],
  '5,000 AI Persona Chat Responses': []
}

export const ULTIMATE_FEATURES = {
  'All Premium Features': [],
  'Create Multiple Personas*': [
    `With the “Create Multiple Personas” feature, users can develop and maintain multiple AI personas, each tailored to a specific domain, audience, or purpose. This allows experts and users to address a wide range of queries or interactions more effectively.`,
    `*coming soon`
  ],
  'Give your Persona your voice': [],
  'Images in chat*': [
    `The “Images in Chat” feature enhances interactions by allowing AI personas to respond to user queries with visuals, making the conversation richer and more engaging. This functionality enables the AI persona to generate or retrieve relevant images to complement its textual responses, providing users with a more interactive and informative experience.`,
    `*coming soon`
  ],
  'Create your 3D Avatar*': [
    `The 3D Avatar feature transforms your AI persona into a visually engaging, three-dimensional representation of yourself, creating a more immersive and interactive experience for your audience. This technology enables your AI persona to appear as a lifelike, animated avatar, enhancing engagement through a realistic, visual presence that goes beyond text and voice interactions.`,
    `With this feature, your AI persona can mimic facial expressions, gestures, and even body language, making interactions more personal and human-like. It’s an ideal tool for educators, consultants, and influencers who want to deliver a more impactful digital presence. Imagine your AI persona presenting a lecture, guiding a virtual tour, or demonstrating a product—all with the visual engagement that only a 3D avatar can provide.`,
    `This 3D avatar can be customized to reflect your appearance and personal style. You can choose attributes like clothing, background, and even specific gestures that align with how you communicate in real life. The avatar can be used in various contexts, such as virtual classrooms, webinars, or interactive sessions, providing a consistent and compelling visual representation of your AI persona.`,
    `*coming soon`
  ],
  'API Access*': [
    `Soopra’s API Access feature will allow you to integrate your AI persona into third-party applications or websites. This means you can use your persona’s capabilities beyond Soopra’s platform, embedding it in your own site, app, or enterprise system.`,
    `The API will provide access to your persona’s core functionalities, including text and voice chat, course offerings, and even real-time analytics. This opens up endless possibilities for customization and interaction, allowing you to offer seamless AI-powered experiences across multiple platforms.`,
    `*coming soon`
  ],
  'Train your AI Persona with your X/Twitter account and posts': [],
  'Email blast your subscribers': [],
  '15% Soopra Paywall Commission': [],
  'Unlimited AI Persona Chat Responses': []
}

export const ENTERPRISE_FEATURES = {
  'All Ultimate Features': [],
  'Train multiple personas': [],
  'Create Employee Expertise': [],
  "Own and control your company's persona": [],
  'Customized Functionalities': [],
}

export const ACADEMIC_FEATURES = {
  'All Ultimate Features': [],
  'Train multiple personas for your staff': [],
  'Available to all students': [],
  'Office hours with your AI persona': [],
  'Integrate into current learning management systems': [],
  'And many more!': [],
}

export type UPGRADE_FEATURE = keyof typeof UPGRADE_TEXT;

export const UPGRADE_TEXT = {
  'dashboard': {
    title: "Upgrade to Standard to Access Dashboard",
    description: "Access analytics, chat history, and edit your AI Persona's responses.",
    bold: ['Analytics', 'View Chat History', 'Real Time Training'],
    tier: 'Standard',
  },
  'courses': {
    title: "Upgrade to Premium to Publish and Monetize Courses",
    description: "Publish and sell courses to your fans and knowledge seekers.",
    bold: 'Offer courses with your AI Persona',
    tier: 'Premium',
  },
  'personality': {
    title: "Upgrade to Premium to Add Your Personality",
    description: "Add your key personality traits to your AI Persona.",
    bold: 'Give your Persona Personality',
    tier: 'Premium',
  },
  'youtube': {
    title: "Upgrade to Premium to Train with Your YouTube Videos",
    description: "Use your YouTube videos to train your AI Persona's knowledge and personality.",
    bold: 'Train your AI Persona with your YouTube videos',
    tier: 'Premium',
  },
  'voice': {
    title: "Upgrade to Ultimate to Give Your AI Persona Your Voice",
    description: "Add a voice sample and your AI Persona will sound just like you.",
    bold: 'Give your Persona your voice',
    tier: 'Ultimate',
  },
  'twitter': {
    title: 'Upgrade to Ultimate to Connect X/Twitter',
    description: 'Train your AI Persona with your X/Twitter account and posts.',
    bold: 'Train your AI Persona with your X/Twitter account and posts',
    tier: 'Ultimate',
  },
  'email': {
    title: 'Upgrade to Ultimate to Email blast your subscribers',
    description: 'Update your community about anything you want, anytime you want.',
    bold: 'Email blast your subscribers',
    tier: 'Ultimate',
  },
  'chatLimitStandard': {
    title: 'Upgrade to Standard to Increase Chat Limit',
    description: 'Increase your chat limit to 1,500 responses per month.',
    bold: '1,500 AI Persona Chat Responses',
    tier: 'Standard',
  },
  'chatLimitPremium': {
    title: 'Upgrade to Premium to Increase Chat Limit',
    description: 'Increase your chat limit to 5,000 responses per month.',
    bold: '5,000 AI Persona Chat Responses',
    tier: 'Premium',
  },
  'chatLimitUltimate': {
    title: 'Upgrade to Ultimate to Increase Chat Limit',
    description: 'Unlock unlimited AI persona chat responses.',
    bold: 'Unlimited AI Persona Chat Responses',
    tier: 'Ultimate',
  },
  'justOpen': { // default to just open a plain tier card
    title: '',
    description: '',
    bold: '',
    tier: '',
  }
}

export const ALL_TIERS: TiersCardItemDescriptions[] = [
  {
    size: {xs: 12, md: 6, lg: 3},
    embeddedSize: {xs: 12, lg: 6, xl: 3},
    name: 'Beginner',
    description: 'The basics you need to get started and see the value of an AI persona.',
    tier: 'free',
    priceMain: 'Free',
    priceMinor: '',
    features: FREE_FEATURES,
  },
  {
    size: {xs: 12, md: 6, lg: 3},
    embeddedSize: {xs: 12, lg: 6, xl: 3},
    name: 'Standard',
    description: 'Train, analyze, and perfect your AI Persona and performance.',
    tier: 'standard',
    priceMain: `$${PRICES['standard']}`,
    priceMinor: 'USD/Month',
    features: STANDARD_FEATURES,
  },
  {
    size: {xs: 12, md: 6, lg: 3},
    embeddedSize: {xs: 12, lg: 6, xl: 3},
    name: 'Premium',
    description: 'Everything you need to scale and monetize your knowledge and influence.',
    tier: 'premium',
    priceMain: `$${PRICES['premium']}`,
    priceMinor: 'USD/Month',
    features: PREMIUM_FEATURES,
  },
  {
    size: {xs: 12, md: 6, lg: 3},
    embeddedSize: {xs: 12, lg: 6, xl: 3},
    name: 'Ultimate',
    description: 'Scale, monetize, and take your AI Persona with you. This is our best subscription available.',
    tier: 'ultimate',
    priceMain: `$${PRICES['ultimate']}`,
    priceMinor: 'USD/Month',
    features: ULTIMATE_FEATURES,
  },
]
