// import styles from './FAQ.module.css';
import { useState } from 'react';
import data from './data';
import Question from './Question';
import { Box, Container, Button, Grid } from '@mui/material';
import { Typography } from '@mui/material';

const FAQ = () => {
  const [category, setCategory] = useState('creators');
  const [questions, setQuestions] = useState(data.creators);

  const handleToggle = (type) => {
    setCategory(type);
    setQuestions(data[type]);
  };

  const buttons = ['creators', 'parents', 'students', 'subscriptions', 'product'];

  return (
    <Box
      width='100%'
      display='flex'
      justifyContent='center'
      p={3}
    >
      <Box
        display='flex'
        flexDirection='column'
        maxWidth='1200px'
        width='100%'
        gap={3}
      >
        <Typography
          variant='h6'
        >
          Frequently Asked Questions
        </Typography>
        <Box
          display='flex'
          gap={1}
          flexWrap='wrap'
        >
          {buttons.map((name) => {
            return (
              <Button
                size='large'
                variant={category === name ? 'contained' : 'outlined'}
                onClick={() => handleToggle(name)}
                key={name}
                sx={{
                  textTransform: 'capitalize !important',
                  px: (theme) => `${theme.spacing(3)} !important`,
                  py: (theme) => `${theme.spacing(1.25)} !important`,
                  boxShadow: (theme) => `${theme.shadows[0]} !important`,
                }}
              >
                {name}
              </Button>
            )
          })}
        </Box>
        <Box
          display='flex'
          flexDirection='column'
          gap={1.5}
        >
          {questions.map((question) => (
            <Question key={question.id} {...question} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FAQ;
