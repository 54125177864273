import { SVGProps, memo } from 'react';

const EnrollIcon = (props: SVGProps<SVGSVGElement>) => (

<svg width="24" height="24" viewBox="0 0 24 24" fill={props.fill || "none"}  xmlns="http://www.w3.org/2000/svg" >
<path fillRule="evenodd" clipRule="evenodd" d="M3.1001 4.8501C3.1001 3.33131 4.33131 2.1001 5.8501 2.1001H15.1501C16.6689 2.1001 17.9001 3.33131 17.9001 4.8501V5.0001C17.9001 5.49715 17.4972 5.9001 17.0001 5.9001C16.503 5.9001 16.1001 5.49715 16.1001 5.0001V4.8501C16.1001 4.32543 15.6748 3.9001 15.1501 3.9001H5.8501C5.32543 3.9001 4.9001 4.32543 4.9001 4.8501V19.1501C4.9001 19.6748 5.32543 20.1001 5.8501 20.1001H15.1501C15.6748 20.1001 16.1001 19.6748 16.1001 19.1501V17.5001C16.1001 17.003 16.503 16.6001 17.0001 16.6001C17.4972 16.6001 17.9001 17.003 17.9001 17.5001V19.1501C17.9001 20.6689 16.6689 21.9001 15.1501 21.9001H5.8501C4.33131 21.9001 3.1001 20.6689 3.1001 19.1501V4.8501Z" />
<path fillRule="evenodd" clipRule="evenodd" d="M18.8701 7.97955L20.096 7.24515C20.2135 7.17471 20.3439 7.1282 20.4795 7.10829C20.6151 7.08838 20.7533 7.09546 20.8862 7.12913C21.0191 7.16279 21.144 7.22238 21.2538 7.30446C21.3635 7.38654 21.456 7.48951 21.5259 7.60744L22.2863 8.8909C22.3959 9.07551 22.4278 9.29603 22.3751 9.50414C22.3224 9.71225 22.1893 9.89096 22.005 10.0011L20.575 10.8577L18.8701 7.97955ZM19.8278 11.3044L19.826 11.3014L18.1226 8.42615L10.8597 12.7781L12.5638 15.6551L19.8278 11.3044ZM13.1226 16.5881L20.3866 12.2373C20.6495 12.0798 20.871 11.9481 21.1338 11.7906L22.5631 10.9345C22.9944 10.6767 23.3059 10.2583 23.4293 9.77123C23.5527 9.28411 23.478 8.76794 23.2215 8.33582L22.4615 7.05314C22.3186 6.81197 22.1295 6.60139 21.905 6.43352C21.6805 6.26565 21.425 6.14379 21.1533 6.07494C20.8816 6.0061 20.5989 5.99162 20.3215 6.03234C20.0442 6.07305 19.7776 6.16817 19.5371 6.31221L18.3112 7.04666C18.0483 7.20418 17.8265 7.3358 17.5637 7.49331L10.3008 11.8453C9.91256 12.0779 9.32119 12.3375 9.11119 12.755L7.87457 15.2134L7.87327 15.216C7.76988 15.4207 7.72005 15.6482 7.72845 15.8774C7.73689 16.1076 7.80378 16.3318 7.92282 16.5289C8.04185 16.7261 8.20912 16.8897 8.40888 17.0043C8.60863 17.119 8.83428 17.1809 9.06457 17.1842L11.7766 17.209C12.2371 17.2132 12.7389 16.8179 13.1226 16.5881ZM11.7865 16.1215L9.08029 16.0968C9.0346 16.0962 8.98983 16.0839 8.9502 16.0612C8.91058 16.0384 8.87739 16.006 8.85377 15.9668C8.83016 15.9277 8.81689 15.8832 8.81521 15.8376C8.81354 15.7919 8.82351 15.7466 8.8442 15.7058L10.0827 13.2436L11.7865 16.1215Z" />
<path d="M9.81384 16.1982L9.81571 16.1945M18.8701 7.97955L20.575 10.8577L22.005 10.0011C22.1893 9.89096 22.3224 9.71225 22.3751 9.50414C22.4278 9.29603 22.3959 9.07551 22.2863 8.8909L21.5259 7.60744C21.456 7.48951 21.3635 7.38654 21.2538 7.30446C21.144 7.22238 21.0191 7.16279 20.8862 7.12913C20.7533 7.09546 20.6151 7.08838 20.4795 7.10829C20.3439 7.1282 20.2135 7.17471 20.096 7.24515L18.8701 7.97955ZM19.8278 11.3044L19.826 11.3014L18.1226 8.42615L10.8597 12.7781L12.5638 15.6551L19.8278 11.3044ZM13.1226 16.5881L20.3866 12.2373C20.6495 12.0798 20.871 11.9481 21.1338 11.7906L22.5631 10.9345C22.9944 10.6767 23.3059 10.2583 23.4293 9.77123C23.5527 9.28411 23.478 8.76794 23.2215 8.33582L22.4615 7.05314C22.3186 6.81197 22.1295 6.60139 21.905 6.43352C21.6805 6.26565 21.425 6.14379 21.1533 6.07494C20.8816 6.0061 20.5989 5.99162 20.3215 6.03234C20.0442 6.07305 19.7776 6.16817 19.5371 6.31221L18.3112 7.04666C18.0483 7.20418 17.8265 7.3358 17.5637 7.49331L10.3008 11.8453C9.91256 12.0779 9.32119 12.3375 9.11119 12.755L7.87457 15.2134L7.87327 15.216C7.76988 15.4207 7.72005 15.6482 7.72845 15.8774C7.73689 16.1076 7.80378 16.3318 7.92282 16.5289C8.04185 16.7261 8.20912 16.8897 8.40888 17.0043C8.60863 17.119 8.83428 17.1809 9.06457 17.1842L11.7766 17.209C12.2371 17.2132 12.7389 16.8179 13.1226 16.5881ZM11.7865 16.1215L10.0827 13.2436L8.8442 15.7058C8.82351 15.7466 8.81354 15.7919 8.81521 15.8376C8.81689 15.8832 8.83016 15.9277 8.85377 15.9668C8.87739 16.006 8.91058 16.0384 8.9502 16.0612C8.98983 16.0839 9.0346 16.0962 9.08029 16.0968L11.7865 16.1215Z"/>
</svg>

);

export default memo(EnrollIcon);
