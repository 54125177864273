import { SVGProps, memo } from "react";

interface ProfileIconProps extends SVGProps<SVGSVGElement> {
  darkMode?: boolean; // Prop to determine if dark mode is enabled
}

const ProfileIcon = ({ darkMode = false, ...props }: ProfileIconProps) => {
  const fillColor = props?.color || (darkMode ? "#FFFFFF" : "#424242"); // White for dark mode, dark gray for light mode

  return (
    <svg
      width="12"
      height="17"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.54371 7.52345C9.94667 6.17221 9.94667 3.98141 8.54371 2.63016C7.14075 1.27892 4.86611 1.27892 3.46315 2.63016C2.06019 3.98141 2.06019 6.17221 3.46315 7.52345C4.86611 8.87469 7.14075 8.87469 8.54371 7.52345ZM9.73067 8.66665C11.7892 6.68403 11.7892 3.46958 9.73067 1.48696C7.67217 -0.495654 4.33469 -0.495654 2.2762 1.48696C0.2177 3.46958 0.2177 6.68403 2.2762 8.66665C4.33469 10.6493 7.67217 10.6493 9.73067 8.66665Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83732 12.6514C3.72662 12.6514 2.02399 14.1541 1.70327 16.1477C1.62911 16.6087 1.26391 17 0.797011 17C0.354482 17 -0.0152288 16.6484 0.0262461 16.2078C0.302713 13.2709 2.73104 11.0043 5.77487 11.0043C8.79884 11.0043 11.3849 13.2414 11.908 16.1503C11.9907 16.6099 11.6118 17 11.145 17C10.7024 17 10.347 16.6468 10.2518 16.2146C9.80511 14.1878 7.97158 12.6514 5.83732 12.6514Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default memo(ProfileIcon);
