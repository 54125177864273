import { Box, Button, Grid2 as Grid, InputAdornment, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Suspense, useEffect, useRef, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import PremiumPurchaseButton from '../Purchases/premiumPurchaseButton';
import Lottie from 'lottie-react';
import LoadingFace from '../../assets/animations/LoadingFace.json'
import { getPrices, setCustomPrice } from '../../utils/api';
import { wrapPromise } from '../../utils/helper';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";

const EditCustomPriceCard: React.FC = () => {
    const { authUser } = useAuth();
    const [ requiredPremiumKey, setRequiredPremiumKey ] = useState<string>("");
    const [ description, setDescription ] = useState<string>(`Unlock access to ${authUser.fullName}'s AI persona`);
    const [ price, setPrice ] = useState<string>("10.00")
    const [ frequency, setFrequency ] = useState<string>("month")
    const [ sampleData, setSampleData ] = useState<any>({
      "currency": 'usd',
      "description": description,
      "recurring": {"interval": frequency},
      "title": `Subscribe to ${authUser.fullName}`,
      "type": 'recurring',
      "unit_amount": parseFloat(price)*100,
    });
    const [ existingData, setExistingData ] = useState({});
    const [ isSaving, setIsSaving ] = useState<boolean>(false);
    const [ hasSaved, setHasSaved ] = useState<boolean>(false);
    const loadedData = useRef(wrapPromise(Promise.resolve()));
    const debounceTime = 250;
    const eventTracker = useAnalyticsEventTracker();

    useEffect(() => {
      if (authUser.subscription?.custom_product_id) {
        let key = authUser.subscription?.custom_product_id;
        // await lookup of existing price
        loadedData.current = wrapPromise(
          getPrices([key])
          .then((res) => {
            if (res.ok) {
              return res.json()
            } else {
              throw res;
            }
          }).then((data) => {
            // set description, price, frequency and requiredPremiumKey
            setDescription(data[key].description)
            setPrice((data[key].unit_amount/100).toString())
            setFrequency(data[key].recurring['interval'])
            let formattedData = {
              "currency": 'usd',
              "description": data[key].description,
              "last_updated": "on " + (new Date(data[key].last_updated * 1000)).toLocaleString(),
              "recurring": {"interval": data[key].recurring['interval']},
              "title": `Subscribe to ${authUser.fullName}`,
              "type": 'recurring',
              "unit_amount": data[key].unit_amount,
            }
            setSampleData(formattedData)
            setExistingData({...formattedData})
            setRequiredPremiumKey(key)
          }).catch((error) => {
            // To-do: Error handling
            console.log(error)
          })
        )
      }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (shouldUpdateSampleData()) {
        let temp = setTimeout(() => {
          let newSampleData = {...sampleData};
          newSampleData.description = description;
          newSampleData.recurring = {...newSampleData.recurring};
          newSampleData.recurring['interval'] = frequency;
          newSampleData.unit_amount = parseFloat(price)*100;
          setSampleData(newSampleData)
        }, debounceTime);

        return () => clearTimeout(temp);
      }
    }, [description, price, frequency]) //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (hasSaved) {
        setTimeout(() => {
          setHasSaved(false)
          eventTracker('edit-price-card save-success')
        }, 2000)
      }
    }, [hasSaved]) //eslint-disable-line react-hooks/exhaustive-deps

    const handleSaveChanges = () => {

      if (!isSaving && !hasSaved) {
        setIsSaving(true)

        let mode: ('create' | 'update') = (!!requiredPremiumKey ? 'update' : 'create')

        setCustomPrice(
          authUser.documentId,
          mode,
          String(sampleData.title),
          description,
          parseFloat(price)*100,
          frequency,
          requiredPremiumKey
        ).then((res: any) => {
          if (res.ok) {
            return res.json()
          } else {
            throw res
          }
        }).then((data: any) => {
          if (data['data_modified']){
            setHasSaved(true);

            setExistingData({...sampleData, "last_updated": "just now"})
            if (!requiredPremiumKey) {
              setRequiredPremiumKey(data.product)
            }
          } else {
            throw new Error('Failed to save changes...')
          }
        }).catch((error) => {
          // To-do: Error handling
          console.log(error)
        }).finally(() => {
          setIsSaving(false);
        })
      }
    }

    const LoadedPurchaseButton = () => {
      loadedData.current.read();

      return (
        <PremiumPurchaseButton
          requiredPremiumKey={requiredPremiumKey}
          sampleData={sampleData}
        />
      )
    }

    const shouldUpdateSampleData: (() => boolean) = () => {
      if (description !== sampleData.description) return true;
      if (frequency !== sampleData.recurring['interval']) return true;
      if (Number(price) !== (Number(sampleData['unit_amount'])/100)) return true;
      return false;
    }

    const hasChangedData: (() => boolean) = () => {
      if (description !== existingData['description']) return true;
      if (Number(price) !== existingData['unit_amount']/100) return true;
      if (frequency !== existingData['recurring']['interval']) return true;
      return false;
    }

    return (
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='100%'
      >
        <Typography
          variant='h6'
          color='primary'
          pt={1}
          pb={3}
          width='100%'
        >
          Monetize Your AI Persona
        </Typography>

        <Box
          display='flex'
          flexDirection='column'
          justifyContent='flex-start'
          width='100%'
        >

          <Typography
            variant='h7'
            pb={1}
            sx={{
              color: (theme) => theme.palette.text.disabled
            }}
          >
            Price Card Title
          </Typography>

          <TextField
            disabled
            id='title'
            value={`Subscribe to ${authUser.fullName}`}
            variant='outlined'
          />

          <Typography
            variant='h7'
            pt={2}
            pb={1}
          >
            Description / Benefits
          </Typography>

          <TextField
            id='description'
            value={description}
            variant='outlined'
            size='small'
            multiline
            maxRows={4}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
          />

          <Grid
            container
            spacing={2}
            pt={2}
          >
            <Grid size={{xs: 12, sm: 6}}>
              <Typography
                variant='h7'
              >
                Subscription Price
              </Typography>

              <TextField
                id='price'
                value={price}
                variant='outlined'
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(e.target.value)}
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }
                }}
                sx={{
                  mt: 1,
                }}
              />
            </Grid>

            <Grid size={{xs: 12, sm: 6}}>
              <Typography
                variant='h7'
              >
                Subscription Frequency
              </Typography>

              <Select
                id='frequency'
                fullWidth
                value={frequency}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFrequency(e.target.value)}
                sx={{
                  mt: 1,
                }}
              >
                <MenuItem value={'day'}>Per Day</MenuItem>
                <MenuItem value={'month'}>Per Month</MenuItem>
                <MenuItem value={'year'}>Per Year</MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Box
            display='flex'
            alignItems='center'
            justifyContent='flex-end'
            pt={3}
          >
            {
              existingData["last_updated"] &&
              <Typography
                variant='body2'
                sx={{
                  fontStyle: 'italic',
                  color: (theme) => theme.palette.text.secondary
                }}
              >
                Price Card last updated {existingData["last_updated"]}
              </Typography>
            }
            <Button
              sx={{
                ml: 2,
                borderRadius: 20,
                pl: 4,
                pr: 4,
              }}
              variant='contained'
              color={hasSaved ? 'success' : 'primary'}
              disabled={isSaving || (!hasChangedData() && !hasSaved)}
              onClick={handleSaveChanges}
            >
              {
                hasSaved ? (
                  "Saved!"
                ) :
                isSaving ?
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  sx={{
                    "& .loading-animation-face path": {
                      fill: 'white'
                    },
                    "& .loading-animation-outline path": {
                      fill: 'white'
                    },
                  }}
                >
                  <Lottie
                    animationData={LoadingFace}
                    loop={true}
                    style={{
                      maxWidth: '30px',
                      maxHeight: '30px',
                      marginRight: '8px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  />
                  Saving...
                </Box>
                : requiredPremiumKey ? "Save" : "Set My Price Card"
              }
            </Button>
          </Box>

          {/* <Typography
            variant='h7'
            pb={1}
          >
            Price Card Preview
          </Typography>

          <Typography
            variant='body2'
            pb={1}
          >
            Users will see this on your Profile
          </Typography>

          <Suspense fallback={
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              sx={{
                backgroundColor: 'gray',
                border: '1px solid lightgray',
                borderRadius: '5px',
                "& .loading-animation-face path": {
                  fill: 'white'
                },
                "& .loading-animation-outline path": {
                  fill: 'white'
                },
              }}
              width='100%'
              height='145px'
            >
              <Lottie
                animationData={LoadingFace}
                loop={true}
                style={{
                  width: '100px',
                  height: '100px',
                }}
              />
            </Box>
          }>
            <LoadedPurchaseButton />
          </Suspense> */}
        </Box>
      </Box>
    )
}

export default EditCustomPriceCard
