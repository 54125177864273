import { SVGProps, memo } from 'react';
import { useTheme } from '@mui/material/styles';

const JournalIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  const strokeColor = theme.palette.mode === 'dark' ? '#ffffff' : '#424242'; // Change colors based on theme

  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.37939 15.8753C1.37939 15.3117 1.60328 14.7712 2.0018 14.3727C2.40033 13.9741 2.94084 13.7502 3.50444 13.7502H14.9797M1.37939 15.8753C1.37939 16.4389 1.60328 16.9794 2.0018 17.3779C2.40033 17.7764 2.94084 18.0003 3.50444 18.0003H14.9797V1H3.50444C2.94084 1 2.40033 1.22389 2.0018 1.62241C1.60328 2.02093 1.37939 2.56144 1.37939 3.12504V15.8753Z"
        stroke={strokeColor}
        strokeWidth="1.70003"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(JournalIcon);
