import { createTheme } from '@mui/material/styles';
import SoopraTheme from './config/theme.json';

declare module '@mui/material/styles/createPalette' {
  interface TypeAction {
    imagehover: string;
    imageHoverDisabledBackground: string;
    imageHoverDisabledText: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    twitterIcon: Palette['primary'];
    linkedinIcon: Palette['primary'];
    instagramIcon: Palette['primary'];
    youtubeIcon: Palette['primary'];
    tiktokIcon: Palette['primary'];
    mainBlue: Palette['primary'];
    lightBlue: Palette['primary'];
    darkBlue: Palette['primary'];
    themeBlue: Palette['primary'];
    themeGrey: Palette['primary'];
  }

  interface PaletteOptions {
    twitterIcon?: PaletteOptions['primary'];
    linkedinIcon?: PaletteOptions['primary'];
    instagramIcon?: PaletteOptions['primary'];
    youtubeIcon?: PaletteOptions['primary'];
    tiktokIcon?: PaletteOptions['primary'];
    mainBlue?: PaletteOptions['primary'];
    lightBlue?: PaletteOptions['primary'];
    darkBlue?: PaletteOptions['primary'];
    themeBlue?: PaletteOptions['primary'];
    themeGrey?: PaletteOptions['primary'];
  }

  interface PaletteColor {
    _states?: {
      hover?: string;
      selected?: string;
      focus?: string;
      focusVisible?: string;
      outlinedBorder?: string;
    };
  }
  interface TypeBackground {
    "paper-elevation-0"?: string;
    "paper-elevation-1"?: string;
    "paper-elevation-2"?: string;
    "paper-elevation-3"?: string;
    "paper-elevation-4"?: string;
    "paper-elevation-5"?: string;
    "paper-elevation-6"?: string;
    "paper-elevation-7"?: string;
    "paper-elevation-8"?: string;
    "paper-elevation-9"?: string;
    "paper-elevation-10"?: string;
    "paper-elevation-11"?: string;
    "paper-elevation-12"?: string;
    "paper-elevation-13"?: string;
    "paper-elevation-14"?: string;
    "paper-elevation-15"?: string;
    "paper-elevation-16"?: string;
    "paper-elevation-17"?: string;
    "paper-elevation-18"?: string;
    "paper-elevation-19"?: string;
  }

  interface BreakpointOverrides {
    xs: true;
    popup: true;
    sm: true;
    dsm: true;
    md: true;
    laptop: true;
    lg: true;
    xl: true;
    xxl: true;
  }

  interface Theme {
    shape: {
      borderRadius: number;
      borderRadiusMd: number;
      borderRadiusLg: number;
      borderRadiusRound: number;
    }
  }

  interface ThemeOptions {
    shape: {
      borderRadius?: number;
      borderRadiusMd?: number;
      borderRadiusLg?: number;
      borderRadiusRound?: number;
    }
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    twitterIcon: true;
    linkedinIcon: true;
    instagramIcon: true;
    youtubeIcon: true;
    tiktokIcon: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    h7: true;
    h8: true;
    body3: true;
    body4: true;
  }
}

// const basePalette = {
//   twitterIcon: {
//     main: '#1DA1F2',
//   },
//   linkedinIcon: {
//     main: '#0077B5',
//   },
//   instagramIcon: {
//     main: '#d62976',
//   },
//   youtubeIcon: {
//     main: '#FF0000',
//   },
//   tiktokIcon: {
//     main: '#ff0050',
//   },
// };

// const baseTheme: ThemeOptions = {
//   components: SoopraTheme.components,
//   shape: SoopraTheme.shape,
//   typography: SoopraTheme.typography,
// };

// try {
//   THEME_PALETTE = {
//     ...THEME_PALETTE,
//     ...SoopraTheme['colorSchemes'][colorScheme].palette
//   }
//   if (colorScheme === 'dark') {
//     THEME_PALETTE['colorSchemes'] = {dark: true}
//   }
// } catch {

// }

// // A custom theme for this app
// let theme = createTheme({
//   ...baseTheme,
//   colorSchemes: { light: true, dark: true },
//   cssVariables: true, // This is a top-level option
//   palette: {
//     ...THEME_PALETTE, // Pass palette-related configurations her
//   },
// });

SoopraTheme['cssVariables'] = {
  colorSchemeSelector: 'class'
};
SoopraTheme.colorSchemes.light.palette['mode'] = 'light';
SoopraTheme.colorSchemes.dark.palette['mode'] = 'dark';
// delete SoopraTheme.colorSchemes.light.palette.background;
SoopraTheme.components.MuiButton['defaultProps'] = {
  "disableRipple": true
};

let theme = createTheme({
  ...SoopraTheme,
})

export default theme;
