import * as React from 'react';
import { SVGProps, memo } from 'react';
import { useTheme } from '@mui/material/styles';

interface SendIconProps extends SVGProps<SVGSVGElement> {
  isTextEntered: boolean; // Prop to determine if text is entered
}

const SendChatIcon = ({ isTextEntered, ...props }: SendIconProps) => {
  const theme = useTheme();

  // Dynamically set colors based on text input and theme mode
  const background = isTextEntered
    ? theme.palette.primary.main
    : theme.palette.mode === 'dark'
    ? theme.palette.action.disabledBackground
    : theme.palette.action.disabledBackground;

  const arrowColor = isTextEntered
    ? theme.palette.primary.contrastText
    : theme.palette.mode === 'dark'
    ? theme.palette.action.disabled
    : theme.palette.action.disabled;

  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.0288086"
        width="32.9714"
        height="32"
        rx="16"
        fill={background} // Dynamic background color
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.87083 14.3137L15.8487 8.33579C16.2156 7.96895 16.8103 7.96895 17.1772 8.33579L23.1551 14.3137C23.5219 14.6805 23.5219 15.2753 23.1551 15.6421C22.7882 16.0089 22.1935 16.0089 21.8266 15.6421L17.4523 11.2678V24H15.5736V11.2678L11.1993 15.6421C10.8324 16.0089 10.2377 16.0089 9.87083 15.6421C9.50399 15.2753 9.50399 14.6805 9.87083 14.3137Z"
        fill={arrowColor} // Dynamic arrow color
      />
    </svg>
  );
};

export default memo(SendChatIcon);
