import { SVGProps, memo } from 'react';
const DarkModeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "24"} height={props.height || "24"} viewBox="0 0 24 24" fill={props.fill || "none"} stroke={props.stroke || "none"} xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="Subtract" d="M10.7843 4.42795L11.2794 4.84905L10.7843 4.42795ZM10.5543 4.00687L10.4061 3.37399L10.5543 4.00687ZM19.5567 16.135L19.8218 16.7285L19.5567 16.135ZM19.8965 16.4745L20.4617 16.7955L19.8965 16.4745ZM19.2915 15.5416C18.4397 15.9222 17.4955 16.1341 16.5 16.1341V17.4341C17.6818 17.4341 18.8065 17.1822 19.8218 16.7285L19.2915 15.5416ZM16.5 16.1341C12.7168 16.1341 9.65 13.0673 9.65 9.28413H8.35C8.35 13.7852 11.9989 17.4341 16.5 17.4341V16.1341ZM9.65 9.28413C9.65 7.59187 10.2628 6.04441 11.2794 4.84905L10.2891 4.00685C9.08011 5.42844 8.35 7.27185 8.35 9.28413H9.65ZM4.65 12.2832C4.65 8.56681 7.23311 5.45221 10.7025 4.63974L10.4061 3.37399C6.36205 4.32103 3.35 7.94955 3.35 12.2832H4.65ZM12.5 20.1332C8.16456 20.1332 4.65 16.6186 4.65 12.2832H3.35C3.35 17.3366 7.44659 21.4332 12.5 21.4332V20.1332ZM19.3312 16.1535C17.9809 18.5314 15.4271 20.1332 12.5 20.1332V21.4332C15.9136 21.4332 18.8897 19.5635 20.4617 16.7955L19.3312 16.1535ZM11.2794 4.84905C11.5482 4.533 11.5505 4.12129 11.3901 3.82104C11.2241 3.51023 10.8508 3.26985 10.4061 3.37399L10.7025 4.63974C10.6158 4.66006 10.5141 4.65108 10.4214 4.60476C10.3346 4.56135 10.277 4.49625 10.2435 4.43367C10.1783 4.31158 10.1775 4.13808 10.2891 4.00685L11.2794 4.84905ZM19.8218 16.7285C19.6644 16.7988 19.4982 16.7491 19.3995 16.6523C19.349 16.6027 19.3027 16.5291 19.2854 16.4335C19.2671 16.3313 19.2872 16.2311 19.3312 16.1535L20.4617 16.7955C20.6876 16.3976 20.5616 15.9713 20.31 15.7244C20.0669 15.4859 19.6707 15.3721 19.2915 15.5416L19.8218 16.7285Z" fill={props.color || "#424242"}/>
    </g>
  </svg>
);

export default memo(DarkModeIcon)
