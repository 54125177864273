import * as React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import { useAuth } from '../../hooks/useAuth';
import UserCourseData from './userCourseData';
import SocialMediaLinks from './socialMediaLinks';
import CallToAction from "../landingPage/callToAction";

export default function FanProfile() {
  const { authUser, authLoading } = useAuth();

  const {
    fullName = '',
    profilePhotoURL = '',
    userName = '',
    index = '',
    shortBio = '',
    subscription,
  } = authUser || {};

  return (
    <Box
      flex={1}
      display="flex"
      alignItems="center"
      flexDirection="column"
      mt={5}
    >
      {authLoading ? (
        <Box
          mt={2}
          sx={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={30} />
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" width="100%" >
          {/* Avatar + Name + Username */}
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={2}
          >
            {/* Avatar */}
            <Avatar
                src={profilePhotoURL}
                alt={fullName}
                sx={{
                  width: '204px',
                  height: '193.21px',
                  border: '1px solid #fff',
                  borderRadius: '86px',
                  objectFit: 'cover',
                }}
              >
                {fullName ? fullName[0] : ''}
              </Avatar>

            {/* Name & Username */}
            <Box
              display="flex"
              flexDirection="column"
              textAlign={{ xs: "center", sm: "left" }}
            >
              <Box
                  flexDirection="column"
                  textAlign={{ xs: "center", sm: "left" }}
                  width="100%"
                >
                <Typography component="h6" variant="h6" fontWeight={600}>
                  {fullName}
                </Typography>
              </Box>
              <Typography component="h6" variant="body1" color="text.secondary">
                @{userName}
              </Typography>
              <Link to={`/update-profile`} style={{ flex: 0 }}>
                <Button
                  id={`button-edit-info-${userName}`}
                  variant="contained"
                  size="large"
                  color="secondary"
                  startIcon={<EditOutlinedIcon />}
                  sx={{
                    width: '153px',
                    height: '42px',
                    borderRadius: 'borderRadiusRound',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    padding: '8px 22px',
                    mt: { xs: 2, sm: 12 },
                    backgroundColor: 'secondary.main',
                    color: 'rgba(255, 255, 255, 1)',
                    boxShadow: `
                      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                      0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                    `,
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                    },
                    whiteSpace: 'nowrap',
                  }}
                >
                  Edit Profile
                </Button>
              </Link>
            </Box>
          </Box>

          {/* Update Profile Button */}
          <Box width="100%" mt={2}>
            
          </Box>

          {/* User Course Data */}
          <UserCourseData authUser={authUser} profileData={authUser} />
        </Box>
      )}

      <CallToAction />
    </Box>
  );
}
