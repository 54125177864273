import { Box, Button, Checkbox, CircularProgress, FormControlLabel, IconButton,
  InputAdornment, TextField, Typography, useTheme } from "@mui/material";
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from "react";
import SoopraLogo from "../../svg/SoopraLogo";
import CloseIcon from '../../svg/CloseIcon';
import GoogleIcon from '../../svg/GoogleIcon';
import { USER_TYPE } from "../../config/const";
import { useAuth } from "../../hooks/useAuth";
import { COMMON_FIREBASE_ERROR_CODE } from "../../config/errors";
import { FirebaseError } from "firebase/app";
import { LoginFormProps } from ".";
import * as Yup from 'yup';
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Formik, Form } from 'formik';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { handleVerifyEmail } from "../creatorOnBoarding/helper";
import { getAuth } from 'firebase/auth';

interface SignUpFormType {
  email: string;
  password: string;
  confirmPassword?: string;
  acceptTermsAndConditions?: boolean;
  acceptEmails?: boolean;
  showPassword?: boolean;
  showConfirmPassword?: boolean;
}

const CreateAccount: React.FC<LoginFormProps> = ({setFormType, handleClose, currentStep, setCurrentStep}) => {
  const [ email, setEmail ] = useState<string>("");
  // const [ password, setPassword ] = useState<string>("");
  // const [ password2, setPassword2 ] = useState<string>("");
  const [ acceptTC, setAcceptTC ] = useState<boolean>(true);
  const [ acceptEmails, setAcceptEmails ] = useState<boolean>(true);
  // step 1: just email
  // step 2: password and password 2
  // step 3: show email verification
  // step 4: sign in -> should lead to setupaccount
  const [ isGoogleLoading, setIsGoogleLoading ] = useState<boolean>(false);
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [ refresh, setRefresh ] = useState<boolean>(false);
  const theme = useTheme();
  const { state } = useLocation();
  const {
    authUser,
    signInSignUpWithGoogle,
    checkUserExistWithEmail,
    signInWithEmailPassword,
    createUserAccountWithEmailPassword,
    logoutUser,
  } = useAuth();
  const auth = getAuth();

  useEffect(() => {
    if (authUser?.documentId) {
      if (authUser.email) setEmail(authUser.email)
      if (auth.currentUser.emailVerified === false){
        setCurrentStep(3); // show verify email step
      } else if (!authUser?.userName) { // logged in but no userName set up
        // show create profile step
        setFormType('setupaccount')
      } else {
        handleClose(null, null, true);
      }
    }
  }, [authUser, refresh]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleUserSignIn = (email: string, password: string) => {
    signInWithEmailPassword(email, password)
      .then(() => {
        toast.success('Signed in successfully');
        setRefresh(!refresh); // force useEffect to run after signin
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid login details'
        );
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const handleGoogleLogin = async () => {
    setIsGoogleLoading(true);
    signInSignUpWithGoogle({userType: USER_TYPE.FOLLOWER})
      .then(() => {
        toast.success('User signed in successfully');
      })
      .catch((error) => {
        const firebaseError = (error as FirebaseError)?.code;
        toast.error(
          firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
            ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
            : 'Invalid login details'
        );
      })
      .finally(() => {
        setIsGoogleLoading(false);
      })
  }

  const handleSwitchEmail = async () => {
    logoutUser()
      .then(() => {
        setCurrentStep(1)
      })
  }

  const handleSubmit = async (values: SignUpFormType) => {
    setFormSubmitting(true);
    if (currentStep === 1) {
      const isEmailExists = await checkUserExistWithEmail(values?.email);

      if (isEmailExists) {
        setFormSubmitting(false);
        toast.error('Account with this email already exists');
        return;
      }
      setFormSubmitting(false);
      setEmail(values.email)
      setAcceptTC(values.acceptTermsAndConditions)
      setAcceptEmails(values.acceptEmails)
      setCurrentStep(2);
    } else if (currentStep === 2) {
      createUserAccountWithEmailPassword(values)
        .then(() => {
          toast.success(
            'User registered successfully. Please check your email to verify your account and login again.'
          );
          setFormSubmitting(false);
          setCurrentStep(3);
        })
        .catch((error) => {
          console.log('sign up error', error);
          setFormSubmitting(false);
          const firebaseError = (error as FirebaseError)?.code;
          toast.error(
            firebaseError && COMMON_FIREBASE_ERROR_CODE[firebaseError]
              ? COMMON_FIREBASE_ERROR_CODE[firebaseError]
              : 'There is some error with the user registration. Please try again'
          );
        });
    } else if (currentStep === 3) {
      setFormSubmitting(false);
      setCurrentStep(4);
    } else if (currentStep === 4) {
      if (values.email && values.password) {
        const isEmailExists = await checkUserExistWithEmail(values.email);
        if (isEmailExists) {
          handleUserSignIn(values.email, values.password);
        } else {
          setFormSubmitting(false);
          toast.error('Invalid login details');
        }
      }
    }
  };

  const signinValidationSchemaStep1 = useMemo(() => Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .strict(true)
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Please enter valid email'
      ),
    acceptTermsAndConditions: Yup.boolean()
      .required("Required")
      .oneOf([true], "You must accept the Terms and Conditions"),
  }), []);

  const signinValidationSchemaStep2 = useMemo(() => Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
      .required('Password is required')
      .oneOf(
        [Yup.ref('password'), null],
        'Password and Re-enter password does not match'
      ),
  }), []);

  const signinValidationSchemaStep4 = useMemo(() => Yup.object().shape({
    password: Yup.string().required('Password is required'),
  }), []);

  const initialValues = useMemo(() =>
    ({
      email: email || (state?.email as string) || '',
      acceptTermsAndConditions: acceptTC,
      acceptEmails: acceptEmails,
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
    }), [currentStep]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderHeader = useCallback(() => {
    if (currentStep === 3) return (
      <Box
        display='flex'
        justifyContent='center'
        mb={-2.5}
      >
        <EmailOutlinedIcon sx={{fontSize: 60}}/>
      </Box>
    );

    return (
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
        width='100%'
      >
        <Box
          sx={[{
              mt: -1,
            },
            currentStep === 2 ? {
            } : {
              visibility: 'hidden'
            }
          ]}
        >
          <IconButton
            onClick={() => {setCurrentStep(currentStep-1)}}
            color='primary'
            sx={{p: 0,}}
          >
            <KeyboardBackspaceIcon
              color='primary'
              sx={{ fontSize: 40}}
            />
          </IconButton>
        </Box>
        <SoopraLogo color={theme.palette.primary.main} width='129px' height='34px'/>
        <Box
          sx={{
            mt: -1,
          }}
        >
          <IconButton
            onClick={handleClose}
            color='primary'
          >
            <CloseIcon
              height='24px'
              width='24px'
              stroke={theme.palette.text.primary}
              strokeWidth={2}
            />
          </IconButton>
        </Box>
      </Box>
    )
  }, [currentStep]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderTitle = useCallback((currentStep: number) => {
    switch (currentStep) {
      case 4:
        return (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            gap={0.75}
          >
            <Typography variant="h5" textAlign='center'>
              Sign In
            </Typography>
          </Box>
        )
      case 3:
        return (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            gap={0.75}
          >
            <Typography variant="h5" textAlign='center'>
              Check Your Email
            </Typography>
            <Typography
              variant="body2"
              textAlign='center'
              gap={0.5}
              sx={{
                color: (theme) => theme.palette.text.secondary
              }}
            >
              A verification email was sent to <b>{`${authUser?.email || auth?.currentUser?.email}.`}</b>
            </Typography>
          </Box>
        )
      case 2:
        return (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            gap={0.75}
          >
            <Typography variant="h5" textAlign='center'>
              Choose a Password
            </Typography>
          </Box>
        )
      default:
        return (
          <Box
            display='flex'
            flexDirection='column'
            width='100%'
            gap={0.75}
          >
            <Typography variant="h5" textAlign='center'>
              Create Your Free Account
            </Typography>
            <Typography
              variant="body2"
              display='flex'
              gap={0.5}
              justifyContent='center'
              alignItems='center'
            >
              {"Already have an account?"}
              <Typography variant="h8"
                onClick={() => {
                  setFormType('signin');
                }}
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                Sign in
              </Typography>
            </Typography>
          </Box>
        )
    }
  }, [authUser, auth]) //eslint-disable-line react-hooks/exhaustive-deps

  const renderForm = useCallback((currentStep: number) => {
    switch (currentStep) {
      case (4):
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={signinValidationSchemaStep4}
            onSubmit={handleSubmit}
          >
            {
              ({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                isValid,
              }) => (
                <Form
                  id={'form-signin'}
                  autoComplete='off'
                  style={{ width: '100%' }}
                  noValidate
                >

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    gap={2}
                  >
                    <TextField
                      type='email'
                      fullWidth
                      name='email'
                      margin='none'
                      label='Email address'
                      placeholder="Email address"
                      value={values.email}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched?.email && errors?.email}
                      disabled
                      autoComplete='off'
                    />
                    <TextField
                      type={values.showPassword ? 'text' : 'password'}
                      fullWidth
                      name='password'
                      margin='none'
                      label='Password'
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched?.password && errors?.password}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSubmit(values);
                        }
                      }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                // onClick={() => setFieldValue('showPassword', !values.showPassword)}
                                // onMouseDown={(event) => event.preventDefault()}
                                onMouseDown={() => setFieldValue('showPassword', true)}
                                onMouseUp={() => setFieldValue('showPassword', false)}
                                onMouseLeave={() => setFieldValue('showPassword', false)}
                                onTouchStart={() => setFieldValue('showPassword', true)}
                                onTouchEnd={() => setFieldValue('showPassword', false)}
                                onTouchCancel={() => setFieldValue('showPassword', false)}
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      }}
                      autoComplete='off'
                    />
                    <Typography
                      variant="body2"
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        setFormType('forgotpassword');
                      }}
                    >
                      Forgot Password?
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      type='submit'
                      sx={{
                        boxShadow: (theme) => `${theme.shadows[2]} !important`,
                      }}
                      disabled={!(isValid && !!values.password) || formSubmitting}
                      startIcon={
                        <Box display='flex' alignItems='center'>
                          {formSubmitting && (
                            <CircularProgress
                              size={20}
                              color='inherit'
                              sx={{
                                marginRight: 2,
                                marginLeft: -4.5,
                              }}
                            />
                          )}
                        </Box>
                      }
                    >
                      Sign In
                    </Button>
                  </Box>
                </Form>
              )
            }
          </Formik>
        )
      case (3):
        return (
          <>
            <Box
              display='flex'
              flexDirection='column'
              gap={3}
              mt={-1}
            >
              <Button
                variant='contained'
                size="large"
                sx={{
                  boxShadow: (theme) => `${theme.shadows[2]} !important`,
                }}
                onClick={() => handleSubmit(null)}
              >
                Continue
              </Button>
              <Button
                variant='contained'
                size="large"
                color='secondary'
                sx={{
                  boxShadow: (theme) => `${theme.shadows[2]} !important`,
                }}
                onClick={async () => {
                  setFormSubmitting(true)
                  handleVerifyEmail()
                    .then(() => {
                      setFormSubmitting(false)
                    })
                }}
                startIcon={
                  <Box display='flex' alignItems='center'>
                    {formSubmitting && (
                      <CircularProgress
                        size={20}
                        color='inherit'
                        sx={{
                          marginRight: 2,
                          marginLeft: -4.5,
                        }}
                      />
                    )}
                  </Box>
                }
              >
                Resend Verification Email
              </Button>
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              width='100%'
              gap={1}
            >
              <Typography
                variant='body2'
                textAlign='center'
                mt={-1}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleSwitchEmail}
              >
                Not your email address?
              </Typography>
              <Typography
                variant='body2'
                textAlign='center'
                mt={-1}
                sx={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                }}
                onClick={handleSwitchEmail}
              >
                Click here to enter new email address.
              </Typography>
            </Box>
          </>
        )

      case (2):
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={signinValidationSchemaStep2}
            onSubmit={handleSubmit}
          >
            {
              ({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                isValid,
              }) => (
                <Form
                  id={'form-signin-email'}
                  autoComplete='off'
                  style={{ width: '100%' }}
                  noValidate
                >

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    gap={2}
                  >
                    <TextField
                      type='email'
                      fullWidth
                      name='email'
                      margin='none'
                      label='Email address'
                      placeholder="Email address"
                      value={values.email}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched?.email && errors?.email}
                      disabled
                      autoComplete='off'
                    />
                    <TextField
                      type={values.showPassword ? 'text' : 'password'}
                      fullWidth
                      name='password'
                      margin='none'
                      label='Password'
                      placeholder="Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched?.password && errors?.password}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSubmit(values);
                        }
                      }}
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                // onClick={() => setFieldValue('showPassword', !values.showPassword)}
                                // onMouseDown={(event) => event.preventDefault()}
                                onMouseDown={() => setFieldValue('showPassword', true)}
                                onMouseUp={() => setFieldValue('showPassword', false)}
                                onMouseLeave={() => setFieldValue('showPassword', false)}
                                onTouchStart={() => setFieldValue('showPassword', true)}
                                onTouchEnd={() => setFieldValue('showPassword', false)}
                                onTouchCancel={() => setFieldValue('showPassword', false)}
                              >
                                {values.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      }}
                      autoComplete='off'
                    />
                    <TextField
                      type={values.showConfirmPassword ? 'text' : 'password'}
                      fullWidth
                      name='confirmPassword'
                      margin='none'
                      label='Re-enter Password'
                      placeholder="Re-enter Password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                      helperText={touched?.confirmPassword && errors?.confirmPassword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSubmit(values);
                        }
                      }}
                      autoComplete='off'
                      slotProps={{
                        input: {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle re-enter password visibility"
                                onMouseDown={() => setFieldValue('showConfirmPassword', true)}
                                onMouseUp={() => setFieldValue('showConfirmPassword', false)}
                                onMouseLeave={() => setFieldValue('showConfirmPassword', false)}
                                onTouchStart={() => setFieldValue('showConfirmPassword', true)}
                                onTouchEnd={() => setFieldValue('showConfirmPassword', false)}
                                onTouchCancel={() => setFieldValue('showConfirmPassword', false)}
                              >
                                {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      size="large"
                      type='submit'
                      sx={{
                        boxShadow: (theme) => `${theme.shadows[2]} !important`,
                      }}
                      disabled={!(isValid && !!values.password && !!values.confirmPassword) || formSubmitting}
                      startIcon={
                        <Box display='flex' alignItems='center'>
                          {formSubmitting && (
                            <CircularProgress
                              size={20}
                              color='inherit'
                              sx={{
                                marginRight: 2,
                                marginLeft: -4.5,
                              }}
                            />
                          )}
                        </Box>
                      }
                    >
                      Continue
                    </Button>
                  </Box>
                </Form>
              )
            }
          </Formik>
        )
      default:
        return (
          <Formik
            initialValues={initialValues}
            validationSchema={signinValidationSchemaStep1}
            onSubmit={handleSubmit}
          >
            {
              ({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isValid,
              }) => (
                <Form
                  id={'form-signin-email'}
                  autoComplete='off'
                  style={{ width: '100%' }}
                  noValidate
                >

                  <Box
                    display='flex'
                    flexDirection='column'
                    width='100%'
                    gap={2}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={
                        <Box display='flex' alignItems='center'>
                          {isGoogleLoading && (
                            <CircularProgress
                              size={20}
                              color='inherit'
                              sx={{ marginRight: 2 }}
                            />
                          )}
                          <GoogleIcon height={'30px'} width='30px' />
                        </Box>
                      }
                      sx={{
                        border: 'none !important',
                        // bgcolor: (theme) => `${theme.palette.grey[50]} !important`,
                        boxShadow: (theme) => `${theme.shadows[2]} !important`,
                      }}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();

                        handleGoogleLogin();
                      }}
                    >
                      Continue With Google
                    </Button>
                    <Typography
                      variant="body1"
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      or
                    </Typography>
                    <TextField
                      type='email'
                      fullWidth
                      name='email'
                      margin='none'
                      label='Email address'
                      placeholder="Email address"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched?.email && errors?.email}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSubmit(values);
                        }
                      }}
                      autoComplete='off'
                    />
                    <Button
                      variant="contained"
                      size="large"
                      type='submit'
                      sx={{
                        boxShadow: (theme) => `${theme.shadows[2]} !important`,
                      }}
                      disabled={!(isValid && !!values.email) || formSubmitting}
                      startIcon={
                        <Box display='flex' alignItems='center'>
                          {formSubmitting && (
                            <CircularProgress
                              size={20}
                              color='inherit'
                              sx={{
                                marginRight: 2,
                                marginLeft: -4.5,
                              }}
                            />
                          )}
                        </Box>
                      }
                    >
                      Sign Up
                    </Button>
                    <Box
                      display='flex'
                      flexDirection='column'
                      width='100%'
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='acceptTermsAndConditions'
                            checked={values.acceptTermsAndConditions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="I accept the Terms and Conditions"
                        required
                        slotProps={{typography: {
                          variant: 'body2'
                        }}}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            name='acceptEmails'
                            checked={values.acceptEmails}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="I want to receive email communications from Soopra.ai"
                        slotProps={{typography: {
                          variant: 'body2'
                        }}}
                      />
                    </Box>
                  </Box>
              </Form>
            )}
          </Formik>
      )
    }
  }, [initialValues, formSubmitting, isGoogleLoading]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      p={4}
      display='flex'
      flexDirection='column'
      width='100%'
      maxWidth={'sm'}
      gap={4}
    >
      {renderHeader()}
      {renderTitle(currentStep)}
      {renderForm(currentStep)}

    </Box>
  )
}

export default CreateAccount;
