import React, { createContext, useState, memo, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';

const useCredits = process.env.REACT_APP_ENABLE_CREDITS === 'true';

interface CreditsCtxType {
  credits: number,
  setCredits: React.Dispatch<React.SetStateAction<number>>,
  maxCredits: number,
  setMaxCredits: React.Dispatch<React.SetStateAction<number>>,
}

export const CreditsContext = createContext<CreditsCtxType>({
  credits: null,
  setCredits: null,
  maxCredits: null,
  setMaxCredits: null,
});

export const CreditsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [credits, setCredits] = useState<number>(useCredits ? 0 : 100);
  const [maxCredits, setMaxCredits] = useState<number>(useCredits ? 0 : 100);
  const { authUser } = useAuth();

  useEffect(() => {
    if (useCredits) {
      if (authUser && authUser.credits) {
        setCredits(authUser.credits.reduce((acc, curr) => acc + curr["remaining"], 0));
        setMaxCredits(authUser.credits.reduce((acc, curr) => acc + curr["maxAmount"], 0));
      } else {
        setCredits(0);
        setMaxCredits(0);
      }
    }
  }, [authUser])

  return (
    <CreditsContext.Provider
      value={{
        credits,
        setCredits,
        maxCredits,
        setMaxCredits
      }}
    >
      {children}
    </CreditsContext.Provider>
  );
};

export default memo(CreditsProvider);
