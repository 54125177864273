import { Grid2 as Grid } from "@mui/material";
import CourseItem, { CourseItemType } from "./courseItem";

const CourseGridItem: React.FC<CourseItemType> = (props: CourseItemType) => {

  return (
    <Grid
      size={{
        xs: 12,
        sm: 6,
        lg: 4,
        xl: 3,
        xxl: 3
      }}
      key={props.courseItem?.course_id}
    >
      <CourseItem {...props}/>
    </Grid>
  )
}

export default CourseGridItem
