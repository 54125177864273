import { Box, ClickAwayListener, debounce, InputAdornment, TextField, useTheme } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import SearchIcon from '@mui/icons-material/Search';
import { getCreatorsList } from "../../utils/api";
import SearchItem from "./SearchItem";
import Lottie from "lottie-react";
import LoadingFace from '../../assets/animations/LoadingFace.json'
import { drawerWidth, drawerWidthClosed, transitionClose, transitionOpen } from ".";
import { NavContext } from "../../context/NavContext";

const PAGE_LIMIT = 10;
const INITIAL_SEARCH_RESULTS = {
  results: [],
  currentPage: 0,
  isLoading: false,
  fullyLoaded: false,
};

const Search: React.FC = () => {
  const [ searchValue, setSearchValue ] = useState<string>('')
  const [ maxWidth, setMaxWidth ] = useState<string>('0px');
  const searchTimer = useRef<any>(0);
  const bottom = useRef(null);
  const [ listedUsers, setListedUsers ] = useState({recents: [], suggested: []});
  const [ searchResults, setSearchResults ] = useState(INITIAL_SEARCH_RESULTS);
  const { authUser } = useAuth();
  const theme = useTheme();
  const {
    mountedComponent, setMountedComponent,
    showProfileNav,
  } = useContext(NavContext);

  useEffect(() => { // this is for transition
      setMaxWidth(mountedComponent?.name === 'Search' ?
        (showProfileNav ?
          `calc(100% - ${drawerWidth + drawerWidthClosed}px)` :
          `calc(100% - ${drawerWidthClosed}px)`) : '0px')
  }, [mountedComponent, showProfileNav]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authUser) {
      // fetch recent and suggested creators
      // console.log('fetch recent and suggested creators')
    }
  }, [authUser])

  useEffect(() => {
    if (searchValue) {
      // search for creators
      if (!searchResults.isLoading && !searchResults.fullyLoaded) {
        creatorSearch();
      }
    } else {
      // reset
      resetSearch()
    }
  }, [searchValue]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        if (searchValue && !searchResults.isLoading && !searchResults.fullyLoaded) {
          creatorSearch();
        }
      }
    });

    observer.observe(bottom.current);

    return () => {
      observer.disconnect();
    }
  }, [searchResults]) //eslint-disable-line react-hooks/exhaustive-deps

  const resetSearch = () => {
    searchTimer.current = 0;
    setSearchValue('');
    setSearchResults({...INITIAL_SEARCH_RESULTS,
      results: [],
    });
  };

  const handleSearch = (event: any) => {
    const newValue = event?.target?.value?.toLowerCase();
    if (searchTimer?.current) {
      clearTimeout(searchTimer.current);
      searchTimer.current = 0;
    }

    searchTimer.current = setTimeout(() => {
      searchTimer.current = 0;
      setSearchValue(newValue);
    }, 250);
  };

  const creatorSearch = () => {
    getCreatorsList(authUser?.uid,
      searchValue,
      searchResults.currentPage+1,
      PAGE_LIMIT
    ).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw res
      }
    }).then(data => {
      if (data.data.length === 0) {
        setSearchResults(results => {
          let info = {...results,
            fullyLoaded: true,
            isLoading: false,
          };
          return info;
        })
      } else {
        setSearchResults(results => {
          let info = {...results,
            isLoading: false,
          };
          info.results.push(...data.data);
          info.currentPage++;
          return info;
        })
      }
    })
    .catch(err => {
      setSearchResults(results => {
        let info = {...results};
        info.isLoading = false;
        return info;
      })
      console.log(err)
    })

    setSearchResults(results => {
      let info = {...results};
      info.isLoading = true;
      return info;
    })
  }

  return (
    <Box
      sx={[{
        position: 'absolute',
        bgcolor: (theme) => theme.palette.divider,
        width: '100%',
        height: '100%',
        zIndex: 501,
        maxWidth: maxWidth,
        [theme.breakpoints.down('md')]: {
          maxWidth: (maxWidth === '0px') ? maxWidth : '100%',
        }
      },
    ]}
    >
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          if (mountedComponent?.name === 'Search'){
            setMountedComponent(null);
          }
        }}
      >
        <Box
          width={371}
          height='100%'
          sx={[
            {
              position: 'absolute',
              bgcolor: (theme) => theme.palette.background.default,
              borderRight: (theme) => (maxWidth === '0px') ? 'none' : `1px solid ${theme.palette.divider}`,
              zIndex: 2,
              overflowY: 'auto',
              maxWidth: maxWidth,
              [theme.breakpoints.down('md')]: {
                maxWidth: (maxWidth === '0px') ? maxWidth : '90%',
              }
            },
            (maxWidth === '0px') ? {...transitionClose(theme)} : {...transitionOpen(theme)}
          ]}
          display='flex'
          flexDirection='column'
        >
          <Box
            sx={{
              margin: 2,
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '90%',
              position: 'sticky',
              top: 0,
              zIndex: 3,
              bgcolor: (theme) => theme.palette.background.default,
            }}
          >
            <TextField
              type='text'
              fullWidth
              margin='normal'
              id='text-input-search'
              label='Search'
              placeholder="'Name, username, etc...'"
              onKeyDown={debounce(handleSearch, 500)}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }
              }}
              color='primary'
            />
          </Box>

          {
            !!searchResults.results.length ?
            searchResults.results.map((creator) =>
              <SearchItem
                item={creator}
                key={`${creator.creatorId}`}
                cleanUp={() => {
                  resetSearch()
                  if (mountedComponent?.name === 'Search'){
                    setMountedComponent(null);
                  }
                }}
              />
            ) :
            <>
              {
                !!listedUsers?.recents.length &&
                <Box>

                </Box>
              }
              {
                !!listedUsers?.suggested.length &&
                <Box>

                </Box>
              }
            </>
          }

          <Box ref={bottom} sx={{
            height: '5px',
            width: '100%',
            position: 'relative',
          }}/>
          {
            searchResults.isLoading &&
            <Box
              margin='auto'
              marginTop={1}
              sx={{
                '& path': {
                  fill: (theme) => theme.palette.primary.main
                }
              }}
            >
              <Lottie
                animationData={LoadingFace}
                loop={true}
                style={{
                  width: '100px',
                  height: '100px',
                }}
              />
            </Box>
          }
        </Box>
      </ClickAwayListener>
    </Box>
  )
}

export default Search;
