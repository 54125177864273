class AudioChunk {
  source: AudioBufferSourceNode;
  analyzer: AnalyserNode;
  gainNode: GainNode;
  chunkIndex: number;

  constructor(
    source: AudioBufferSourceNode,
    analyzer: AnalyserNode,
    gainNode: GainNode,
    chunkIndex: number
  ) {
    this.source = source;
    this.analyzer = analyzer;
    this.gainNode = gainNode;
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
    this.isValid = this.isValid.bind(this);
    this.chunkIndex = chunkIndex;

    if (!this.isValid()) {
      throw new Error('Invalid Audio Chunk');
    }
  }

  play() {
    this.source.start();
  }

  stop() {
    try {
      this.source.stop();
    } catch (err) {}
  }

  isValid() {
    if (this.source && this.analyzer && this.gainNode && this.play) {
      return true;
    } else {
      return false;
    }
  }
}

export default AudioChunk;
