import { Box, Button, Divider, IconButton, Link, TextField, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import { useCallback, useState } from "react";
import LanguageIcon from '@mui/icons-material/Language';
import DeleteIcon from '@mui/icons-material/Delete';

const WebLinksForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
    showSteps
  } = props;

  const [webLink, setWebLink] = useState<string>('');
  const [weblinkErrors, setWeblinkErrors] = useState<string>(null);

  const handleAddLink = () => {
    if (webLink) {
      // validate
      setWeblinkErrors(null);
      const validWebLink = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-z]{2,}([/?].*)?$/;
      if (!validWebLink.test(webLink)) {
        setWeblinkErrors('Please enter valid link to content')
      } else {
        // add to google drive folder list
        let copy = [...values.userWebPage];
        copy.push(
          {
            web_link: webLink,
            disabled: false
          }
        )
        setFieldValue('userWebPage', copy)
        setWebLink('')
      }
    }
  }

  const renderWebLinkListItem = useCallback(
    (document: any, index: number, onDeleteHandler: () => void) => {
      if (!document.web_link) {
        return (
          <Box
            key={`weblink-${index}`}
            display='none'
          ></Box>
        )
      }

      let modifiedWebLink = document.web_link;
      if (!modifiedWebLink.startsWith('http')) {
        modifiedWebLink = 'https://' + document.web_link;
      }
      return (<Box
        key={`weblink-${index}`}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        columnGap='12px'
        sx={{
          borderBottom: 1,
          borderColor: (theme) => theme.palette.divider,
          py: 1.5,
        }}
      >
        <Box display='flex'>
          <LanguageIcon sx={{ mr: 2, fontSize: '20px' }} />
          <Link
            underline="hover"
            href={`${modifiedWebLink}`}
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              align='left'
              variant='body2'
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {document.web_link}
            </Typography>
          </Link>
        </Box>
        <IconButton
          id={`button-delete-${document.name}-${index}`}
          color='error'
          size='small'
          sx={{ width: '30px', height: '30px' }}
          onClick={onDeleteHandler}
        >
          <DeleteIcon
            sx={{
              color: 'error.light',
              fontSize: '18px',
            }}
          />
        </IconButton>
      </Box>
    )},
    []
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
      >
        <Typography variant='h6' mb={-1.5}>
          {(showSteps ? '2. ': '') + 'Add Web Links'}
        </Typography>
        <Typography variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Connect your personal websites, portfolios, blogs, and any weblinks to your personal identity that will help train your AI Persona.
        </Typography>
        <TextField
          type='string'
          fullWidth
          name='google_drive_links'
          margin='none'
          label='Add Web Link'
          placeholder="www.website.com"
          value={webLink}
          onBlur={handleBlur}
          onChange={(e) => {
            setWebLink(e.target.value)
          }}
          error={Boolean(weblinkErrors)}
          helperText={weblinkErrors}
          autoComplete='off'
        />
        <Button
          variant="contained"
          size='large'
          fullWidth={false}
          sx={{
            width: 'fit-content',
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={handleAddLink}
          disabled={!webLink}
        >
          Add Web Link
        </Button>
      </Box>
      {
        !!values.userWebPage.some((val) => !!val.web_link) &&
        <Box
          display='flex'
          flexDirection='column'
        >
          <Typography variant='h7' mb={1.5}>
            My Web Links
          </Typography>
          <Divider />
          {
            (values?.userWebPage || []).map((document: any, index: number) =>
              renderWebLinkListItem(document, index, () => {
                let copy = [...(values.userWebPage || [])];
                copy?.splice(index, 1);
                setFieldValue('userWebPage', copy || null);
              })
            )
          }
        </Box>
      }
    </>
  )
}

export default WebLinksForm;
