import React, { FC, useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Textboxstar from '../../svg/textboxstar';
import SendChatIcon from '../../svg/SendChatIcon';

interface TextChatInputProps {
  sendDisabled: React.RefObject<boolean>;
  disabled: boolean;
  isTyping: boolean;
  handleMessageSend: (
    userMessage: string,
    firstMessage?: boolean,
    isVoiceMessage?: boolean
  ) => Promise<any>;
}

const TextChatInput: FC<TextChatInputProps> = (props) => {
  const { sendDisabled, disabled, isTyping, handleMessageSend } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [messageVal, setMessageVal] = useState<string>('');
  const theme = useTheme();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isTyping]); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '50px',
        backgroundColor: theme.palette.background.default,
        margin: '8px 16px',
        width: '100%',
      }}
    >
      {/* Left Icon - Textboxstar */}
      <Box sx={{ display: 'flex', alignItems: 'center', ml: 1.5, mr: 1 }}>
        <Textboxstar />
      </Box>

      {/* Input Field */}
      <TextField
        id="text-input-chat-message"
        autoComplete="off"
        inputRef={inputRef}
        value={messageVal}
        disabled={disabled}
        placeholder="Say something"
        variant="standard"
        fullWidth
        onChange={(e) => {
          sendDisabled.current = e.target.value.length === 0;
          setMessageVal(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !sendDisabled.current && !e.shiftKey) {
            e.preventDefault();
            if (!isTyping) {
              handleMessageSend(messageVal.trim());
              sendDisabled.current = true;
              setMessageVal('');
            }
          }
        }}
        onPaste={(e) => {
          e.preventDefault();
          if (e.clipboardData) {
            let content = e.clipboardData.getData('text/plain');
            sendDisabled.current = (messageVal + content).length === 0;
            setMessageVal((prev) => prev + content);
          }
        }}
        sx={{ flexGrow: 1 }}
        slotProps={{
          input: {
            disableUnderline: true, // Removes MUI's default underline
            style: { padding: '0px', height: '36px' }, // Fixed height
          }
        }}
      />

      {/* Right Icon - Send Button */}
      <IconButton
        color="primary"
        disabled={sendDisabled.current || isTyping}
        onClick={() => {
          if (!sendDisabled.current && !isTyping) {
            handleMessageSend(messageVal.trim());
            sendDisabled.current = true;
            setMessageVal('');
          }
        }}
      >
        <SendChatIcon isTextEntered={!!messageVal.trim()} />
      </IconButton>
    </Box>
  );
};

export default TextChatInput;
