// import { Link } from 'react-router-dom';
// import { Box, Typography, Button, Stack } from '@mui/material';
// import PersonIcon from '@mui/icons-material/Person';
// import ChatIcon from '@mui/icons-material/Chat';
// import { grey } from '@mui/material/colors';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import ChatButtonIcon from '../../svg/ChatButtonIcon';
// import Avatar from '@mui/material/Avatar';

// interface FanCreatorProfileItemType {
//   profileData: {
//     fullName: string;
//     profilePicUrl: string;
//     userName: string;
//     shortBio: string;
//     fanCount: number;
//     messageCount: number;
//     voiceId: string;
//   };
// }

// const formatCount = (num) => {
//   // Check if num is undefined or null
//   if (num == null) return '0'; // Returns '0' if num is undefined or null

//   if (num >= 1000) return (num / 1000).toFixed(1) + 'k';
//   return num.toString();
// };

// export default function FanCreatorProfileItem(
//   props: FanCreatorProfileItemType
// ) {
//   const {
//     fullName,
//     profilePicUrl,
//     userName,
//     shortBio,
//     fanCount,
//     messageCount,
//     voiceId,
//   } = props.profileData;

//   // Setup the chat URL
//   const chatUrl = `/${userName}/chat`;
//   const profileUrl = `/${userName}`;

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         width: '100%',
//         my: 2,
//         alignItems: 'flex-start',
//         flexDirection: 'column',
//         height: '150px',
//         textAlign: 'left',
//         position: 'relative', // Set relative positioning for the parent container
//         margin: '0px',
//       }}
//     >
//       {/* <Stack
//         sx={{
//           p: 0.5,
//         }}
//       >
//         <Avatar
//           src={profilePicUrl}
//           alt={fullName}
//           sx={{
//             width: '100%',
//             height: 'auto',
//             objectFit: 'cover',
//             aspectRatio: 1.78,
//             borderRadius: (theme) => theme.shape.borderRadius,
//           }}
//         />
//         <Stack
//           sx={{
//             px: 1,
//             pt: 1.5,
//           }}
//         >
//           <Typography variant='h7' color='text.primary' align='left'>
//             {fullName}
//           </Typography>
//           <Typography
//             component='p'
//             variant='body2'
//             color='text.secondary'
//             align='left'
//           >
//             {`@${userName}`}
//           </Typography>
//           <Typography
//             component='p'
//             variant='body2'
//             color='primary'
//             align='left'
//             sx={{
//               display: '-webkit-box',
//               overflow: 'hidden',
//               WebkitLineClamp: 2,
//               WebkitBoxOrient: 'vertical',
//               height: '2.8em',
//               wordBreak: 'break-all',
//             }}
//           >
//             {shortBio}
//           </Typography>
//           <Typography
//             component='p'
//             variant='body2'
//             color='text.secondary'
//             align='left'
//           >
//             Technology/Entrepreneurship
//           </Typography>
//         </Stack>
//       </Stack> */}

//       {/* Profile Section */}
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           alignItems: 'center',
//           mr: 2,
//           paddingTop: '10px',
//           width: '110px',
//           position: 'absolute',
//         }}
//       >
//         {/* Profile Picture */}
//         <Link id={`href-profile-${userName}`} to={profileUrl}>
//           <Avatar
//             src={profilePicUrl}
//             alt={fullName}
//             sx={{
//               mb: 1,
//               width: 80,
//               height: 80,
//               fontSize: '2.5rem',
//             }}
//           >
//             {fullName ? fullName[0] : ''}
//           </Avatar>
//           {voiceId ? (
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 position: 'absolute',
//                 width: 23,
//                 height: 23,
//                 borderRadius: '50%',
//                 top: 68,
//                 left: 72,
//                 backgroundColor: 'white',
//                 borderColor: grey[500] + '!important',
//                 border: 'solid 1px',
//               }}
//             >
//               <GraphicEqIcon
//                 sx={{
//                   width: 15,
//                   height: 15,
//                   color: `${grey[500]}`,
//                 }}
//               />
//             </Box>
//           ) : (
//             <></>
//           )}
//         </Link>
//         {/* Fans and Messages Count in the same line */}
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             color: 'grey',
//             marginTop: '10px',
//           }}
//         >
//           <PersonIcon sx={{ marginRight: '4px', fontSize: '15px' }} />
//           <Typography variant='body2'>{formatCount(fanCount)}</Typography>
//           <ChatIcon
//             sx={{ marginLeft: '12px', marginRight: '4px', fontSize: '15px' }}
//           />
//           <Typography variant='body2'>{formatCount(messageCount)}</Typography>
//         </Box>
//       </Box>

//       {/* Text Section */}
//       <Box
//         sx={{
//           flexGrow: 1,
//           display: 'flex',
//           flexDirection: 'column',
//           justifyContent: 'space-between', // Use space-between to keep the header at the top
//           marginTop: '6px',
//           paddingLeft: '120px',
//           paddingRight: '5px',
//         }}
//       >
//         {/* Text Content */}
//         <Box>
//           <Typography
//             component='h6'
//             variant='h6'
//             fontWeight={600}
//             sx={{
//               fontSize: '18px', // Change the font family
//               color: 'black',
//               display: '-webkit-box',
//               overflow: 'hidden',
//               WebkitLineClamp: 1,
//               WebkitBoxOrient: 'vertical',
//               height: '1.5em',
//               wordBreak: 'break-all',
//             }}
//           >
//             {fullName}
//           </Typography>
//           <Typography component='p' variant='body2' color='text.secondary'>
//             {`@${userName}`}
//           </Typography>
//           <Typography
//             component='p'
//             variant='body2'
//             sx={{
//               fontSize: '14px', // Change the font family
//               color: 'black',
//               display: '-webkit-box',
//               overflow: 'hidden',
//               WebkitLineClamp: 2,
//               WebkitBoxOrient: 'vertical',
//               height: '2.8em',
//               wordBreak: 'break-all',
//             }}
//           >
//             {shortBio}
//           </Typography>
//         </Box>
//       </Box>

//       {/* Buttons - Positioned absolutely within the parent container */}
//       <Box
//         sx={{
//           flexDirection: 'row',
//           position: 'absolute', // Position absolutely within the relative parent
//           bottom: 8, // Stick to the bottom
//           right: 0, // Align to the right
//           display: 'flex',
//           left: '110px',
//           paddingRight: '9px',
//           paddingBottom: '3px',
//         }}
//       >
//         <Link
//           id={`href-chat-${userName}`}
//           to={chatUrl}
//           style={{ textDecoration: 'none', flex: 1, marginLeft: '10px' }}
//         >
//           <Button
//             id={`button-chat-${userName}`}
//             variant='contained'
//             sx={{
//               height: '32px !important',
//               padding: '6px 12px !important',
//               borderRadius: '6px',
//               backgroundColor: window.location.href.startsWith(
//                 'https://stanford.soopra.ai'
//               )
//                 ? '#8C1515 !important'
//                 : 'primary.main',
//             }}
//             fullWidth
//           >
//             <ChatButtonIcon style={{ marginRight: 10 }} /> Chat
//           </Button>
//         </Link>
//         {voiceId ? (
//           <Link
//             id={`href-voice-${userName}`}
//             to={chatUrl}
//             state={{ useVoiceChat: true }}
//             style={{
//               textDecoration: 'none',
//               flex: 1,
//               marginLeft: '8px',
//               marginRight: '5px',
//             }}
//           >
//             <Button
//               id={`button-voice-${userName}`}
//               variant='contained'
//               sx={{
//                 height: '32px!important',
//                 padding: '6px 12px !important',
//                 borderRadius: '6px',
//                 backgroundColor: 'white !important',
//                 border: window.location.href.startsWith(
//                   'https://stanford.soopra.ai'
//                 )
//                   ? '1px solid #8C1515'
//                   : '1px solid #009CDB',
//                 color: window.location.href.startsWith(
//                   'https://stanford.soopra.ai'
//                 )
//                   ? '#8C1515 !important'
//                   : '#009CDB !important',
//               }}
//               fullWidth
//             >
//               <GraphicEqIcon sx={{ marginRight: 1 }} /> Talk
//             </Button>
//           </Link>
//         ) : (
//           <Button
//             id={`button-voice-disabled-${userName}`}
//             variant='contained'
//             disabled
//             sx={{
//               backgroundColor: 'white !important',
//               border: '1px solid #009CDB',
//               height: '32px!important',
//               padding: '6px 0px !important',
//               borderRadius: '6px',
//               flex: 1,
//               marginLeft: '8px',
//               marginRight: '5px',
//               color: grey[300] + '!important',
//               borderColor: grey[300] + '!important',
//             }}
//             fullWidth
//           >
//             <GraphicEqIcon sx={{ marginRight: 1 }} />
//             Talk
//           </Button>
//         )}
//       </Box>
//     </Box>
//   );
// }

// import { Link } from 'react-router-dom';
import { Box, Typography, Button, Stack } from '@mui/material';
// import PersonIcon from '@mui/icons-material/Person';
// import ChatIcon from '@mui/icons-material/Chat';
// import { grey } from '@mui/material/colors';
// import GraphicEqIcon from '@mui/icons-material/GraphicEq';
// import ChatButtonIcon from '../../svg/ChatButtonIcon';
import Avatar from '@mui/material/Avatar';
import SoopraIcon from '../../assets/images/soopra-icon.png';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../../component/useDeviceType';
import ChatIcon2 from "../../svg/ChatIcon2";
import ProfileIcon2 from "../../svg/ProfileIcon2";
import { useTheme } from '@mui/material/styles';

interface FanCreatorProfileItemType {
  profileData: {
    fullName: string;
    profilePicUrl: string;
    userName: string;
    shortBio: string;
    fanCount: number;
    messageCount: number;
    voiceId: string;
  };
}

// const formatCount = (num) => {
//   // Check if num is undefined or null
//   if (num == null) return '0'; // Returns '0' if num is undefined or null

//   if (num >= 1000) return (num / 1000).toFixed(1) + 'k';
//   return num.toString();
// };

export default function FanCreatorProfileItem(
  props: FanCreatorProfileItemType
) {
  const {
    fullName,
    profilePicUrl,
    userName,
    shortBio,
    // fanCount,
    // messageCount,
    // voiceId,
  } = props.profileData;

  // Setup the chat URL
  const chatUrl = `/${userName}/chat`;
  const profileUrl = `/${userName}`;
  const navigate = useNavigate();
  const { isTouchDevice, isMouseDevice } = useDeviceType();
  const theme = useTheme();

  return (
    <Stack
      onClick={!isMouseDevice ? (e) => navigate(profileUrl) : undefined}
      sx={{
        px: { xs: 1.25, md: 1.5 },
        py: { xs: 1, sm: 1.25, md: 2 },
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
          height: 'auto',
          ...(isMouseDevice && {
            '&:hover .overlay': {
              zIndex: 1,
              opacity: 1,
            },
          }),
        }}
      >
        <Avatar
          src={profilePicUrl || SoopraIcon}
          alt={fullName}
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            aspectRatio: '1.78',
            borderRadius: (theme) => theme.shape.borderRadius,
          }}
        />
        <Box
          className='overlay'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(2px)',
            borderRadius: (theme) => theme.shape.borderRadius,
            opacity: 0,
            zIndex: -1,
            transition: 'opacity 0.4s',
            backgroundColor: (theme) => theme.palette.action.imagehover,
            pointerEvents: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1.5,
          }}
        >
          <Button
            variant='contained'
            color='primary'
            size='medium'
            id={`button-voice-${userName}`}
            onClick={(e) => {
              e.stopPropagation();
              navigate(chatUrl, { state: { useVoiceChat: false } });
            }}
            sx={{ zIndex: 2 }}
            startIcon={<ChatIcon2 fill={theme.palette.secondary.main} />}
          >
            Chat
          </Button>
          <Button
            variant='contained'
            color='secondary'
            size='medium'
            id={`button-profile-${userName}`}
            onClick={(e) => {
              e.stopPropagation();
              navigate(profileUrl);
            }}
            sx={{ zIndex: 2 }}
            startIcon={<ProfileIcon2 fill={theme.palette.primary.main} />}
          >
            View Profile
          </Button>
        </Box>
      </Box>
      <Stack
        sx={{
          px: 1,
          pt: 1.5,
        }}
      >
        <Typography variant='h7' color='text.primary' align='left'>
          {fullName}
        </Typography>
        <Typography
          component='p'
          variant='body2'
          color='text.secondary'
          align='left'
        >
          {`@${userName}`}
        </Typography>
        <Typography
          component='p'
          variant='body2'
          color='primary'
          align='left'
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            // height: '2.8em',
            // wordBreak: 'break-all',
          }}
        >
          {shortBio}
        </Typography>
        {/* <Typography
            component='p'
            variant='body2'
            color='text.secondary'
            align='left'
          >
            Technology/Entrepreneurship
          </Typography> */}
      </Stack>
    </Stack>
  );
}
