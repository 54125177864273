import React, { createContext, useState, memo } from 'react';

interface NavCtxType {
  mountedComponent: any;
  setMountedComponent: React.Dispatch<any>;
  showProfileNav: boolean;
  setShowProfileNav: React.Dispatch<boolean>;
  handleContactModal: boolean;
  setHandleContactModal: React.Dispatch<boolean>;
}

export const NavContext = createContext<NavCtxType>({
  mountedComponent: null,
  setMountedComponent: null,
  showProfileNav: null,
  setShowProfileNav: null,
  handleContactModal: null,
  setHandleContactModal: null,
});

export const NavProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [mountedComponent, setMountedComponent] = useState(null);
  const [showProfileNav, setShowProfileNav] = useState<boolean>(false);
  const [handleContactModal, setHandleContactModal] = useState<boolean>(false);

  return (
    <NavContext.Provider
      value={{
        mountedComponent,
        setMountedComponent,
        showProfileNav,
        setShowProfileNav,
        handleContactModal,
        setHandleContactModal
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export default memo(NavProvider);
