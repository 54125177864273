import { SVGProps, memo } from 'react';
const MoreIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "18"} height={props.height || "15"} viewBox="0 0 18 15" fill={props.fill || "none"} stroke={props.stroke || "none"} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.0859375 1.58848C0.0859375 1.09142 0.488881 0.688477 0.985937 0.688477H16.9859C17.483 0.688477 17.8859 1.09142 17.8859 1.58848C17.8859 2.08553 17.483 2.48848 16.9859 2.48848H0.985937C0.488881 2.48848 0.0859375 2.08553 0.0859375 1.58848Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.0859375 7.58848C0.0859375 7.09142 0.488881 6.68848 0.985937 6.68848H16.9859C17.483 6.68848 17.8859 7.09142 17.8859 7.58848C17.8859 8.08553 17.483 8.48848 16.9859 8.48848H0.985937C0.488881 8.48848 0.0859375 8.08553 0.0859375 7.58848Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.0859375 13.5885C0.0859375 13.0914 0.488881 12.6885 0.985937 12.6885H16.9859C17.483 12.6885 17.8859 13.0914 17.8859 13.5885C17.8859 14.0855 17.483 14.4885 16.9859 14.4885H0.985937C0.488881 14.4885 0.0859375 14.0855 0.0859375 13.5885Z" fill={props.color || "#424242"}/>
  </svg>
);

export default memo(MoreIcon)
