import { Box, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";

const SocialMediaForm: React.FC<genericFormProps> = (props) => {

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      gap={2}
    >
      <Typography variant='h6' mb={-1.5}>
        Social Media
      </Typography>
      <Typography variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        Train your AI persona with your social Media Accounts
      </Typography>
    </Box>
  )
}

export default SocialMediaForm;
