import { Box, Button, Divider, IconButton, Link, TextField, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import { useCallback, useContext, useState } from "react";
import YouTubeIcon from '@mui/icons-material/YouTube';
import DeleteIcon from '@mui/icons-material/Delete';
import {TierContext} from "../../../context/TierContext";

const YoutubeLinksForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
    showSteps
  } = props;

  const [youtubeLink, setYoutubeLink] = useState<string>('');
  const [ytErrors, setYTErrors] = useState<string>(null);
  const { checkPermission } = useContext(TierContext);

  const handleAddLink = () => {
    if (youtubeLink) {
      // validate
      setYTErrors(null);
      const validYTLink = /^(https:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtube\.com\/embed\/)[\w-]{11}/;
      if (!validYTLink.test(youtubeLink)) {
        setYTErrors('Invalid YouTube link. Must start with https://youtube.com/watch?v={video_id} or https://youtube.com/embed/{video_id}')
      } else {
        // add to google drive folder list
        let copy = [...values.youtube_links];
        copy.push(
          {
            url: youtubeLink,
          }
        )
        setFieldValue('youtube_links', copy)
        setYoutubeLink('')
      }
    }
  }

  const renderYTListItem = useCallback(
    (document: any, index: number, onDeleteHandler: () => void) => (
      <Box
        key={`youtubelink-${index}`}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        columnGap='12px'
        sx={{
          borderBottom: 1,
          borderColor: (theme) => theme.palette.divider,
          py: 1.5,
        }}
      >
        <Box display='flex'>
          <YouTubeIcon sx={{ mr: 2, fontSize: '20px' }} />
          <Link
            underline="hover"
            href={`${document.url}`}
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              align='left'
              variant='body2'
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {document.url}
            </Typography>
          </Link>
        </Box>
        <IconButton
          id={`button-delete-${document.name}-${index}`}
          color='error'
          size='small'
          sx={{ width: '30px', height: '30px' }}
          onClick={onDeleteHandler}
        >
          <DeleteIcon
            sx={{
              color: 'error.light',
              fontSize: '18px',
            }}
          />
        </IconButton>
      </Box>
    ),
    []
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
      >
        <Typography variant='h6' mb={-1.5}>
          {(showSteps ? '1. ': '') + 'Youtube Links'}
        </Typography>
        <Typography variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Train your AI persona with your YouTube videos.
        </Typography>
        <TextField
          type='string'
          fullWidth
          name='youtube_links'
          margin='none'
          label='Add Youtube Link'
          placeholder="Add Youtube Link"
          value={youtubeLink}
          onBlur={handleBlur}
          onChange={(e) => {
            setYoutubeLink(e.target.value)
          }}
          error={Boolean(ytErrors)}
          helperText={ytErrors}
          autoComplete='off'
        />
        <Button
          variant="contained"
          size='large'
          fullWidth={false}
          sx={{
            width: 'fit-content',
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={() => {
            if (checkPermission("train:youtube")) {
              handleAddLink()
            }
          }}
          disabled={!youtubeLink}
        >
          Add Youtube Link
        </Button>
      </Box>
      {
        !!values.youtube_links.length &&
        <Box
          display='flex'
          flexDirection='column'
        >
          <Typography variant='h7' mb={1.5}>
            My Youtube Links
          </Typography>
          <Divider />
          {
            (values?.youtube_links || []).map((document: any, index: number) =>
              renderYTListItem(document, index, () => {
                let copy = [...(values.youtube_links || [])]
                copy.splice(index, 1);
                setFieldValue('youtube_links', copy || null);
              })
            )
          }
        </Box>
      }
    </>
  )
}

export default YoutubeLinksForm;
