import { Box, ClickAwayListener, Typography, useTheme } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { getNotifications } from "../../utils/api";
import Lottie from "lottie-react";
import LoadingFace from '../../assets/animations/LoadingFace.json';
import NotificationItem from "./NotificationItem";
import { drawerWidth, drawerWidthClosed, transitionClose, transitionOpen } from ".";
import { useNavigate } from "react-router-dom";
import { NavContext } from "../../context/NavContext";

const INITIAL_RESULTS = {
  results: [],
  currentPage: 0,
  isLoading: false,
  fullyLoaded: false,
};

const PAGE_LIMIT = 10;

const Notifications: React.FC = () => {
  const [ maxWidth, setMaxWidth ] = useState<string>('0px');
  const [ queryResults, setQueryResults ] = useState(INITIAL_RESULTS);
  const { authUser } = useAuth();
  const bottom = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
    const {
      mountedComponent, setMountedComponent,
      showProfileNav,
    } = useContext(NavContext);

  // load notifications on first mount
  useEffect(() => {
    if (authUser && !queryResults.results.length && !queryResults.isLoading) getNotificationsPage();
  }, [authUser]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { // this is for transition
    setMaxWidth(mountedComponent?.name === 'Notifications' ?
      (showProfileNav ?
        `calc(100% - ${drawerWidth + drawerWidthClosed}px)` :
        `calc(100% - ${drawerWidthClosed}px)`) : '0px')
  }, [mountedComponent, showProfileNav]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!queryResults.isLoading && !queryResults.fullyLoaded) {
            getNotificationsPage();
          }
        }
      });

      observer.observe(bottom.current);

      return () => {
        observer.disconnect();
      }
    }, [queryResults]) //eslint-disable-line react-hooks/exhaustive-deps

  const getNotificationsPage = () => {
    if (!authUser) return;
    getNotifications(authUser?.uid,
      queryResults.currentPage+1,
      PAGE_LIMIT
    ).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw res
      }
    }).then(data => {
      if (data.notifications?.length === 0) {
        setQueryResults(results => {
          let info = {...results,
            fullyLoaded: true,
            isLoading: false,
          };
          return info;
        })
      } else {
        setQueryResults(results => {
          let info = {...results,
            isLoading: false,
          };
          info.results.push(...data.notifications);
          info.currentPage++;
          return info;
        })
      }
    })
    .catch(err => {
      setQueryResults(results => {
        let info = {...results};
        info.isLoading = false;
        return info;
      })
      console.log(err)
    })

    setQueryResults(results => {
      let info = {...results};
      info.isLoading = true;
      return info;
    })
  }

  return (
    <Box
      sx={[{
        position: 'absolute',
        bgcolor: (theme) => theme.palette.divider,
        width: '100%',
        height: '100%',
        zIndex: 501,
        maxWidth: maxWidth,
        [theme.breakpoints.down('md')]: {
          maxWidth: (maxWidth === '0px') ? maxWidth : '100%',
        }
      },
    ]}
    >
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          if (mountedComponent?.name === 'Notifications'){
            setMountedComponent(null)
          }
        }}
      >
        <Box
          width={371}
          height='100%'
          sx={[
            {
              position: 'absolute',
              bgcolor: (theme) => theme.palette.background.default,
              borderRight: (theme) => (maxWidth === '0px') ? 'none' : `1px solid ${theme.palette.divider}`,
              zIndex: 2,
              overflowY: 'auto',
              maxWidth: maxWidth,
              [theme.breakpoints.down('md')]: {
                maxWidth: (maxWidth === '0px') ? maxWidth : '90%',
              }
            },
            (maxWidth === '0px') ? {...transitionClose(theme)} : {...transitionOpen(theme)}
          ]}
          display='flex'
          flexDirection='column'
        >
          <Typography variant="h7" px={2.5} py={3}>
            Notifications
          </Typography>

          {
            queryResults.results.map((item) =>
              <NotificationItem
                key={item.notification_id}
                item={item}
                onClickFunc={() => {
                  // Read status isn't being displayed anymore...
                  // if (!item.is_read) {
                  //   markNotificationRead(authUser?.uid, item.notification_id)
                  //     .then((res) => {
                  //       if (res.ok) {
                  //         // update results array
                  //       }
                  //     })
                  // }
                  if (mountedComponent?.name === 'Notifications'){
                    setMountedComponent(null)
                  };
                  if (item.link_to) {
                    navigate(item.link_to)
                  }
                }}
              />
            )
          }

          <Box ref={bottom} sx={{
            display: mountedComponent?.name === 'Notifications' ? 'block' : 'none',
            height: '5px',
            width: '100%',
            position: 'relative',
            minHeight: '5px'
          }}/>

          {
            queryResults.isLoading &&
            <Box
              margin='auto'
              marginTop={1}
              sx={{
                '& path': {
                  fill: (theme) => theme.palette.primary.main
                }
              }}
            >
              <Lottie
                animationData={LoadingFace}
                loop={true}
                style={{
                  width: '100px',
                  height: '100px',
                }}
              />
            </Box>
          }
        </Box>
      </ClickAwayListener>
    </Box>
  )
}

export default Notifications;
