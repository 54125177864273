import { Box, Button, Divider, Typography } from "@mui/material";
import { Suspense, useContext, useEffect, useRef, useState } from 'react';
import LoadingFace from '../../assets/animations/LoadingFace.json'
import { wrapPromise } from '../../utils/helper';
import Lottie from 'lottie-react';
import { useAuth } from "../../hooks/useAuth";
import { getRevenue } from "../../utils/api";
import { TierContext } from "../../context/TierContext";


interface SubscriptionAnalyticsProps {
  month: number;
  year: number;
}

function SubscriptionAnalytics(props: SubscriptionAnalyticsProps) {
  const { month, year } = props;
  const [ revenueData, setRevenueData ] = useState<{[key: string]: (string | number)}>({});
  const [ , setIsLoading ] = useState<boolean>(false);
  const loadedData = useRef(wrapPromise(Promise.resolve()));
  const { authUser } = useAuth();
  const { checkPermission } = useContext(TierContext)

  useEffect(() => {
    if (authUser.subscription?.custom_product_id) {
      let product_id = authUser.subscription?.custom_product_id;
      loadedData.current = wrapPromise(
        getRevenue(authUser.uid, product_id, month, year)
          .then(res => {
            if (res.ok) {
              return res.json()
            } else {
              throw res
            }
          }).then((data) => {
            setRevenueData(data)
          }).catch((error) => {
            // To-do: Error handling
            console.log(error)
          })
      )
      setIsLoading((cur) => !cur);
    }
  }, [authUser, month, year]) //eslint-disable-line react-hooks/exhaustive-deps

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  };

  const downloadCSV = () => {
    if (revenueData.subscribers) {
      const csvData = new Blob([convertToCSV(Object.values(revenueData.subscribers))], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      link.href = csvURL;
      link.download = `${authUser.userName}_subscribers_${month}_${year}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const LoadedRevenueDashboard = () => {
    loadedData.current.read();

    if (Object.keys(revenueData).length === 0) {
      return (
        <>
        </>
      )
    }

    return (
      <>
        <Typography
          variant='h7'
        >
          Subscription Analytics
        </Typography>
        <Divider sx={{mt: 1.5, mb: 2,}}/>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
          <Typography variant='body2' className='linechart-title'>
            Total Accounts Engaged
          </Typography>
          <Typography variant='body2' style={{ marginLeft: 'auto' }}>
            {revenueData.fan_count || 0}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
          <Typography variant='body2' className='linechart-title'>
            Total Subscriptions
          </Typography>
          <Typography variant='body2' style={{ marginLeft: 'auto' }}>
            {revenueData.subscribers ? Object.keys(revenueData.subscribers).length : 0}
          </Typography>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
          <Typography variant='body2' className='linechart-title' >
            Total Revenue
          </Typography>
          <Typography variant='body2' style={{ marginLeft: 'auto' }}>
            ${revenueData.income ? Math.round(Number(revenueData.income))/100 : 0}
          </Typography>
        </div>
        <Button variant='contained' fullWidth size='small'
          sx={{
            mt: 2
          }}
          onClick={() => {
            if (checkPermission("export:emails")) {
              downloadCSV()
            }
          }}>
          Download CSV - Account Emails
        </Button>
      </>
    )
  }

  return (
    <Box mt={2}>
      <Suspense fallback={
        <>
          <Typography
            variant='h7'
          >
            Subscription Analytics
          </Typography>
          <Divider sx={{mt: 1.5, mb: 2,}}/>
          <Box width='100%' display='flex' justifyContent='center' >
            <Lottie
              animationData={LoadingFace}
              loop={true}
              style={{
                width: '100px',
                height: '100px',
              }}
            />
          </Box>
        </>
      }>
        <LoadedRevenueDashboard />
      </Suspense>
    </Box>
  )
}

export default SubscriptionAnalytics;
