import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface VolumeMeterProps {
  isListening: boolean;
  dotSize?: number;
  padding?: number;
}

interface DotsCircleProps {
  level: number;
  radius: number;
  dotSize: number;
  padding: number;
}

const VolumeMeter: React.FC<VolumeMeterProps> = ({
  isListening,
  dotSize = 6,
  padding = 2,
}) => {
  const theme = useTheme();
  const [volumeLevels, setVolumeLevels] = useState([0, 0, 0, 0]);
  const audioContextRef = useRef<AudioContext | null>(null);
  const analyserRef = useRef<AnalyserNode | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    if (!isListening) {
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      setVolumeLevels([0, 0, 0, 0]);
      return;
    }

    const setupAudioAnalysis = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        mediaStreamRef.current = stream;

        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;

        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;

        const source = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);

        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const updateVolume = () => {
          analyser.getByteFrequencyData(dataArray);
          const sum = dataArray.reduce((acc, val) => acc + val * val, 0);
          const rmsVolume = Math.sqrt(sum / bufferLength);
          const normalizedVolume = Math.min(Math.max(rmsVolume / 128, 0), 1);
          const levels = [
            normalizedVolume > 0.25 ? 1 : 0,
            normalizedVolume > 0.5 ? 1 : 0,
            normalizedVolume > 0.75 ? 1 : 0,
            normalizedVolume > 0.9 ? 1 : 0,
          ];
          setVolumeLevels(levels);
          animationFrameRef.current = requestAnimationFrame(updateVolume);
        };

        updateVolume();
      } catch (error) {
        console.error('Error accessing microphone', error);
      }
    };

    setupAudioAnalysis();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [isListening]);

  const DotsCircle = ({ level, radius, dotSize, padding }: DotsCircleProps) => {
    const theme = useTheme();

    // Calculate number of dots that can fit in the circle
    const circumference = 2 * Math.PI * radius;
    const dotSpacing = dotSize + 2 * padding;
    const numberOfDots = Math.floor(circumference / dotSpacing);

    return (
      <Box
        sx={{
          position: 'absolute',
          width: `${radius * 2}px`,
          height: `${radius * 2}px`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {[...Array(numberOfDots)].map((_, index) => {
          const angle = (index / numberOfDots) * 2 * Math.PI;
          return (
            <Box
              key={index}
              sx={{
                position: 'absolute',
                width: `${dotSize}px`,
                height: `${dotSize}px`,
                borderRadius: '50%',
                backgroundColor:
                  index < numberOfDots * level
                    ? theme.palette.primary.main
                    :  theme.palette.background["paper-elevation-11"],
                transform: `rotate(${angle}rad) translate(${radius}px) rotate(-${angle}rad)`,
                transition: 'background-color 0.3s ease',
              }}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '48px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      {isListening && (
        <Box
          sx={{
            position: 'relative',
            width: '120px',
            height: '120px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DotsCircle
            level={volumeLevels[0]}
            radius={40}
            dotSize={dotSize}
            padding={padding}
          />
          <DotsCircle
            level={volumeLevels[1]}
            radius={46}
            dotSize={dotSize}
            padding={padding}
          />
          <DotsCircle
            level={volumeLevels[2]}
            radius={52}
            dotSize={dotSize}
            padding={padding}
          />
          <DotsCircle
            level={volumeLevels[3]}
            radius={58}
            dotSize={dotSize}
            padding={padding}
          />
        </Box>
      )}
    </Box>
  );
};

export default VolumeMeter;
