import { Message, TypingIndicator } from "@chatscope/chat-ui-kit-react";
import { Box } from "@mui/material";
import React from "react"

interface CustomTypingIndicatorProps {
  isTyping: boolean;
  avatarImage: string;
  fullName: string;
  isWidget?: boolean;
}

const CustomTypingIndicator: React.FC<CustomTypingIndicatorProps> = (props) => {
  const { isTyping, fullName, isWidget=false } = props;

  return (
    isTyping ?
      isWidget ?
        <Box sx={{
          background: 'transparent',
        }}>
          <Box
            display='flex'
            justifyContent='center'
            width='100%'
            paddingLeft='15px'
            sx={{
              background:'linear-gradient(147.36deg, #FFD703 -4.06%, #FF766B 30.19%, #FF2FB8 73.9%, #147BB5 111.7%)',
              WebkitBackgroundClip: 'text',
              backgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            <div style={{display:'flex', flexDirection: 'row', gap:'5px'}}>
              <div>
                {fullName}'s AI is thinking
              </div>
              <TypingIndicator style={{position: 'relative'}}/>
            </div>
          </Box>
        </Box>
      :
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          overflowY:'auto',
          paddingBottom: '10px'

        }}
      >
        { /* <Avatar
          src={avatarImage}
          alt={fullName}
          sx={{ m: 1, width: 32, height: 32, fontSize: '1rem'}}
        >
          {fullName ? fullName[0] : ''}
        </Avatar> */}

        <Message
          model={{
            message: 'typing...',
            direction: 'incoming',
            position: 'single',
          }}
        >
          <Message.CustomContent >
            <Box
              sx={{
                position: 'relative',
                height: '1.6rem',
                width: '3rem',
              }}
            >
              <TypingIndicator style={{backgroundColor: 'transparent'}}/>
            </Box>
          </Message.CustomContent>
        </Message>
      </Box> :
      <></>
  )
}

export default CustomTypingIndicator
