import { Box, Button, Grid2 as Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Lottie from "lottie-react";
import LoadingFace from '../../assets/animations/LoadingFace.json'
import { fetchCourseResultsByStudent, fetchFeaturedCourses } from "../../utils/api";
import { useAuth } from "../../hooks/useAuth";
import { setLoadingState } from "../userProfile/userCourseData";
import CourseItem from "../coursesList/courseItem";
import StripeCheckoutForm from "../StripeCheckoutForm";

const FeaturedCourses: React.FC = () => {
  const [ courses, setCourses ] = useState({data: [], isLoading: false});
  let [isStripeOpen, setIsStripeOpen] = useState(false);
  let [stripeItem, setStripeItem] = useState(null);
  let [stripeItemId, setStripeItemId] = useState(null);
  let [redirectURL, setRedirectURL] = useState(null);
  let [enrolledCourses, setEnrolledCourses] = useState([]);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { authUser, setLoginModalVisible } = useAuth();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));
  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    handleFetch();
  }, [])

  useEffect(() => {
    if (location?.state) {
      setStripeItem(location.state.item);
      setStripeItemId(location.state.item_id);
      setIsStripeOpen(location.state.isStripeOpen);
      setRedirectURL(location.state.redirectURL);
    }
  }, [location.state]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isStripeOpen) {
      setStripeItem(null);
      setStripeItemId(null);
      setRedirectURL(null);
    }
  }, [isStripeOpen])

  useEffect(() => {
      if (authUser) {
        fetchCourseResultsByStudent(authUser.uid)
          .then((results) => {
            setEnrolledCourses(
              results.map((result: any) => {
                return result.courseId;
              })
            );
          })
          .catch(err => {
            console.log(err)
          });
      }
    }, [authUser]);

  const handleFetch = () => {
    if (!courses.data.length) {
      setLoadingState(setCourses, true)
      fetchFeaturedCourses()
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
        })
        .then((data) => {
          setCourses((info) => {
            let copy = {...info};
            copy.data = data.data.map(entry => {
              entry.creator_name = entry.fullName;
              entry.creator_avatar = entry.profilePicUrl;
              return entry;
            });
            return copy;
          })
        })
        .catch((err) => {
          // to-do
        })
        .finally(() => {
          setLoadingState(setCourses, false)
        })
    }
  };

  let handleEnrollCourse = (event: any, item: any, item_id: any) => {
    event.preventDefault();
    if (authUser?.documentId) {
      setStripeItem(item);
      setStripeItemId(item_id);
      setRedirectURL(`/courses/${item_id}`);
      setIsStripeOpen(true);
    } else {
      // if not logged in, force login
      // pass in redirectURL and redirectState
      // use redirectURL to get back here
      // use redirectState to control the state of the popup modal

      setLoginModalVisible({
        isOpen: true,
        formType: 'signin',
        currentStep: 1,
        redirectOptions: {
          redirectURL: '/',
          redirectState: {
            item: item,
            item_id: item_id,
            isStripeOpen: true,
            redirectURL: `/courses/${item_id}`,
          },
        }
      })
    }
  };

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
    >
      <Box
        width='100%'
        display='flex'
        flexDirection='column'
        position='relative'
        sx={{
          px: {
            xs: 2,
            md: 0
          },
          pb: {xs: 2, sm: 1.75, md: 1},
        }}
      >
        <Button
          href="/courses"
          sx={{
            position:'absolute',
            right: 0,
            top: -8,
          }}
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            navigate('/courses')
          }}
        >
          <Typography variant="h7"
            sx={{
              paddingLeft: 1,
            }}
          >
            See All
          </Typography>
          < ChevronRightIcon />
        </Button>
        <Typography
          variant='h6'
          pb={0.5}
        >
          Featured Courses
        </Typography>
        <Typography
          variant='body2'
          color={theme.palette.text.secondary}
        >
          Guided courses designed by teachers with AI assistance to help learn through self-paced, personalized lessons. Learn from top-tier educators from institutions like Stanford, Harvard, and more!
        </Typography>
      </Box>
      {
        courses.isLoading ?
        <Box
          margin='auto'
          marginTop={1}
          sx={{
            '& path': {
              fill: (theme) => theme.palette.primary.main
            }
          }}
        >
          <Lottie
            animationData={LoadingFace}
            loop={true}
            style={{
              width: '100px',
              height: '100px',
            }}
          />
        </Box> :
        <Grid container rowSpacing={{xs: 1, sm: 0.5, md: -1}} columnSpacing={0}
          sx={{
            mx: {
              xs: 0.5,
              md: -1.5
            },
          }}
        >
          {
            courses.data.map((courseItem, index) => {
              let limit = 4;
              if (xl) limit = 4;
              else if (lg) limit = 3;
              else if (sm) limit = 2;
              else limit = 2;
              return (
                <Grid
                  size={{
                    xs: 12,
                    sm: 6,
                    lg: 4,
                    xl: 3
                  }}
                  sx={{
                    display: index+1 > limit ? 'none' : 'flex'
                  }}
                  key={index}
                >
                  <CourseItem
                    courseItem={courseItem}
                    handleEnrollCourse={handleEnrollCourse}
                    isEnrolled={
                      enrolledCourses.includes(courseItem?.course_id) ||
                      courseItem?.creator_id === authUser?.uid
                    }
                    disableButtons={false}
                  />
                </Grid>
              )
            })
          }
        </Grid>

      }
      <StripeCheckoutForm
        item={stripeItem}
        isOpen={isStripeOpen}
        setIsOpen={setIsStripeOpen}
        itemId={stripeItemId}
        redirectURL={redirectURL}
      />
    </Box>
  )
}

export default FeaturedCourses;
