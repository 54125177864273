import { Avatar, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface SearchItemProps {
  item: any;
  cleanUp?: () => void;
}

const SearchItem: React.FC<SearchItemProps> = ({item, cleanUp}) => {
  const navigate = useNavigate();

  return (
    <Box
      display='flex'
      width='90%'
      m={1}
      ml='auto'
      mr='auto'
      onClick={() => {
        if (cleanUp) cleanUp();
        navigate(`/${item.userName}/chat`)
      }}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Avatar
        src={item?.profilePicUrl}
        alt={item?.fullName}
        sx={{
          m: 1,
          width: '40px',
          height: '40px',
          fontSize: '1.25rem',
          aspectRatio: 1,
        }}
      >
        {item?.fullName ? item?.fullName[0] : ''}
      </Avatar>
      <Box flexGrow={1} display='flex' flexDirection='column'>
        <Typography variant="h7"
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          {item.fullName}
        </Typography>
        <Typography variant="body3"
          sx={{
            color: (theme) => theme.palette.text.primary
          }}
        >
          {`@${item.userName}`}
        </Typography>
        <Typography variant="body3"
          sx={{
            color: (theme) => theme.palette.text.primary,
            whiteSpace: "initial",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {item.shortBio || ''}
        </Typography>
      </Box>
    </Box>
  )
}

export default SearchItem;
