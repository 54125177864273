

import { SVGProps, memo } from 'react';
const WeblinksIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.88318 0C4.42969 0 0 4.55625 0 10.1656C0 15.7749 4.42969 20.3311 9.88318 20.3311C15.3367 20.3311 19.7664 15.7749 19.7664 10.1656C19.7664 4.55625 15.3367 0 9.88318 0ZM9.88318 18.5482C8.30187 17.1824 7.38958 15.5455 6.8422 14.0754H12.9546C12.0727 16.3379 10.6333 17.8601 9.88318 18.5482ZM6.38606 12.5115C6.11237 11.302 6.09209 10.3845 6.09209 10.1656C6.09209 9.33146 6.18332 8.54949 6.34551 7.81966H13.3904C13.6641 9.0291 13.6844 9.94661 13.6844 10.1656C13.6844 10.9997 13.5932 11.7816 13.431 12.5115H6.38606ZM1.53063 10.1656C1.53063 9.35231 1.65227 8.57035 1.855 7.81966H4.79461C4.6527 8.54949 4.57161 9.33146 4.57161 10.1656C4.57161 10.52 4.60202 11.3854 4.82502 12.5115H1.855C1.65227 11.7608 1.53063 10.9788 1.53063 10.1656ZM9.89332 1.78288C11.4746 3.14872 12.3869 4.78563 12.9343 6.25573H6.82193C7.70381 3.99324 9.14321 2.47101 9.89332 1.78288ZM14.9515 7.81966H17.9215C18.1242 8.57035 18.2459 9.35231 18.2459 10.1656C18.2459 10.9788 18.1242 11.7608 17.9215 12.5115H14.9819C15.1238 11.7816 15.2049 10.9997 15.2049 10.1656C15.2049 9.81107 15.1745 8.94569 14.9515 7.81966ZM17.3234 6.25573H14.5562C14.1203 4.87947 13.3803 3.33639 12.1538 1.89757C14.4041 2.55442 16.2794 4.14963 17.3234 6.25573ZM7.65313 1.88714C6.77125 2.91934 5.79813 4.37901 5.1798 6.25573H2.44292C3.49713 4.13921 5.38253 2.53357 7.65313 1.88714ZM2.44292 14.0754H5.21021C5.64608 15.4516 6.38606 16.9947 7.61258 18.4335C5.36226 17.7767 3.48699 16.1815 2.44292 14.0754ZM12.1132 18.444C12.9951 17.4118 13.9682 15.9521 14.5866 14.0754H17.3234C16.2692 16.1919 14.3838 17.7975 12.1132 18.444Z" fill={props?.color || "#424242"}/>
</svg>

);

export default memo(WeblinksIcon)
