import { Box, Switch, Typography, useColorScheme, useTheme } from "@mui/material";
import DarkModeIcon from "../../svg/DarkModeIcon";

interface ThemeModeToggleProps {
  isHamburgerMenu?: boolean;
}

const ThemeModeToggle: React.FC<ThemeModeToggleProps> = (props) => {
  const { isHamburgerMenu } = props;
  const { mode, setMode } = useColorScheme();
  const theme = useTheme();

  return (
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      mx={isHamburgerMenu ? 1 : 2.75}
      mt={1}
    >
      <Box display='flex' alignItems='center' gap={isHamburgerMenu ? 2 : 1} mr={1}>
        <DarkModeIcon color={theme.palette.primary.main}/>
        <Typography variant="body3"
          sx={[{
            textTransform: 'capitalize'
          },
          (isHamburgerMenu && {
            fontSize: "0.875rem",
            color: (theme) => theme.palette.text.secondary,
          }),
        ]}
        >
          Dark Mode
        </Typography>
      </Box>

      <Switch
        checked={mode === 'dark'}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setMode(event.target.checked ? 'dark' : 'light');
        }}
        color='primary'
      />
    </Box>
  )
}

export default ThemeModeToggle;
