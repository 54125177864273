import { Avatar, BottomNavigation, BottomNavigationAction, Box, ListItemIcon } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import ExploreIcon from "../../svg/ExploreIcon";
import ExpertsIcon from "../../svg/ExpertsIcon";
import CoursesIcon from "../../svg/CoursesIcon";
import ChatsIcon from "../../svg/ChatsIcon";
import AccountIcon from "../../svg/AccountIcon";
import NotificationsIcon from "../../svg/NotificationsIcon";
import { useContext, useMemo } from "react";
import SoopraIcon from '../../assets/images/soopra-icon.png';
import {NavContext} from "../../context/NavContext";

const BottomNavBarMobile: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    mountedComponent,
    setMountedComponent,

  } = useContext(NavContext)
  const { authUser, setLoginModalVisible } = useAuth();

  const NavItems = useMemo(() => [
    {
      'name': 'Discover',
      'icon': <ExploreIcon color='inherit' fill='inherit' />,
      'link': '/',
    },
    {
      'name': 'Experts',
      'icon': <ExpertsIcon width={24} height={22} color='inherit' fill='inherit' />,
      'link': '/creators'
    },
    {
      'name': 'Courses',
      'icon': <CoursesIcon width={24} height={22} color='inherit' fill='inherit' />,
      'link': '/courses'
    },
    {
      'name': 'Chats',
      'icon': <ChatsIcon className='chats' stroke='inherit' />,
      'link': null,
      'isHidden': true,
    },
    {
      'name': 'Account',
      'icon': authUser?.profilePicUrl ?
        <Avatar
          src={authUser.profilePicUrl || SoopraIcon}
          alt={authUser.fullName}
          sx={[{
            width: { xs: '26px', md: '40px' },
            height: { xs: '26px', md: '40px' },
            objectFit: 'cover',
            aspectRatio: '1.78',
            borderRadius: 0,
          },
          ]}
        /> : <AccountIcon color='inherit' fill='inherit' />,
      'link': authUser ? `/${authUser?.userName}` : null,
      'onMount': () => {
        if (!authUser) {
          setLoginModalVisible({
            isOpen: true,
            formType: 'signin',
            currentStep: 1,
          })
        }
      },
      'onDismount': () => {
      },
    },
    {
      'name': 'Notifications',
      'icon': <NotificationsIcon color='inherit' fill='inherit' />,
      'link': null,
      'isHidden': (process.env.REACT_APP_ENABLE_NOTIFICATIONS !== 'true' || !authUser),
      'onDismount': () => {
        setMountedComponent(null)
      },
      'onMount': () => {
        setMountedComponent({ name: 'Notifications' })
      },
    },
  ], [authUser]); //eslint-disable-line react-hooks/exhaustive-deps

  const isSelected = (navItem) => {
    if (!mountedComponent) {
      if (navItem?.name === 'Account') {
        return location.pathname.includes(`/${authUser?.userName}`) &&
          !location.pathname.includes('/chat')
      }
      return location.pathname === navItem.link
    } else {
      if (mountedComponent.name === 'more-menu') {
        return location.pathname === navItem.link;
      }
      return mountedComponent.name === navItem.name
    }
  };

  const handleNavClick = (navItem) => {
    if (navItem.link) {
      navigate(navItem.link)
    } else if (navItem.onMount && navItem.onDismount) {
      if (!mountedComponent) {
        navItem.onMount();
      } else {
        navItem.onDismount();
      }
    }
  }

  return (
    <Box
      sx={[{
          display: { md: 'none' },
          boxShadow: 'none',
          borderTop: 1,
          borderColor: theme => theme.palette.divider,
          zIndex: 500,
        },
        (
          location.pathname.includes('/chat') && {
            display: 'none',
          }
        )
      ]}
      // elevation={3}
    >
      <BottomNavigation
        showLabels={false}
        value={location.pathname}
      >
        {
          NavItems.map((item) => {
            return (
              (
                item.isHidden
              ) ?
                <BottomNavigationAction
                  key={item.name}
                  value={item.link}
                  icon={item.icon}
                  sx={{
                    display: 'none',
                  }}
                /> :
                <BottomNavigationAction
                  key={item.name}
                  value={item.link}
                  icon={
                    <ListItemIcon
                      sx={[
                        {
                          minWidth: 0,
                          justifyContent: 'center',
                          p: 1,
                          borderRadius: 4.5,
                          overflow: 'hidden',
                          stroke: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.main,
                          fill: (theme) => theme.palette.primary.main,
                          '& .MuiAvatar-root': {
                            borderRadius: 2.5,
                          },
                        },
                        isSelected(item) && {
                          backgroundColor: (theme) => theme.palette.primary.main,
                          color: (theme) => theme.palette.primary.contrastText,
                          fill: (theme) => theme.palette.primary.contrastText,
                          stroke: (theme) => theme.palette.primary.contrastText,
                          border: (theme) => `1.8px solid ${theme.palette.primary.main}`
                        },
                        isSelected(item) && item.name === 'Account' && {
                          '& .MuiAvatar-root': {
                            outline: (theme) => `2px solid ${theme.palette.primary.contrastText}`
                          }
                        }
                      ]}
                    >
                      {item.icon}
                    </ListItemIcon>
                  }
                  sx={{
                    minWidth: '0px',
                  }}
                  onClick={() => {
                    handleNavClick(item)
                  }}
                />
            )
          })
        }
      </BottomNavigation>
    </Box>
  )
}

export default BottomNavBarMobile;
