import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styles from './train.module.css';

// import CelebrationIcon from '../../svg/CelebrationIcon';
import LinearProgressWithLabel from '../../component/LinearProgressWithLabel';

interface TrainLevelItemsType {
  levelItem: Record<string, any>;
  handleLevelClick: (levelId: string) => void;
}

function TrainLevelItems(props: TrainLevelItemsType) {
  const { handleLevelClick, levelItem } = props;
  const {
    levelId,
    title,
    subTitle,
    cardContent,
    progress,
    isCurrent,
    isCompleted,
    isGenerating,
  } = levelItem || {};

  return (
    (<Box
      key={levelId}
      display='flex'
      className={`${styles.level} ${
        isCurrent
          ? styles.currentLevel
          : isCompleted
          ? styles.completedLevel
          : ''
      }`}
      sx={{ border: 'none', width: 1, p: 0 }}
    >
      <Box flexDirection='column' display='flex' rowGap={0.5} sx={{ width: 1 }}>
        <Typography
          variant={'h6'}
          fontWeight={600}
          color={isCurrent || isCompleted ? 'black' : 'gray'}
          title={title}
        >
          {title}
        </Typography>

        <Typography
          variant={'subtitle2'}
          fontWeight={500}
          color={isCurrent || isCompleted ? 'black' : 'gray'}
          title={subTitle}
        >
          {subTitle}
        </Typography>
        <Typography
          variant={'subtitle2'}
          fontWeight={500}
          color={isCurrent || isCompleted ? 'black' : 'gray'}
          title={cardContent}
        >
          {cardContent}
        </Typography>
        <LinearProgressWithLabel
          value={progress ? parseInt(progress) : 0}
          postFixLabel='Complete'
        />
        <Grid container justifyContent='center'>
          {isCompleted ? (
            (<Button
            id={`button-completed-level-${levelId}`}
              variant='contained'
              onClick={() => handleLevelClick(levelId)}
              disabled={true}
              size='large'
              sx={{
                px: 4,
                borderRadius: 3,
                fontWeight: 600,
                boxShadow: 'none',
                width: '100%',
              }}
            >Completed
                          </Button>) // change to 'Start Again' eventually
          ) : (
            <Button
              id={`button-start-level-${levelId}`}
              variant='contained'
              onClick={() => handleLevelClick(levelId)}
              disabled={!isCurrent}
              // color='mainBlue'
              size='large'
              sx={{
                px: 4,
                borderRadius: 3,
                border: 'none',
                '&:hover': {
                  boxShadow: 'none',
                },
                fontWeight: 600,
                boxShadow: 'none',
                width: '100%',
              }}
            >
              {isGenerating
                ? 'Level Generating...'
                : isCurrent
                ? 'Start'
                : 'Locked'}
            </Button>
          )}
        </Grid>
      </Box>
    </Box>)
  );
}

export default React.memo(TrainLevelItems);
