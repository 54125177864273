import { SVGProps, memo } from 'react';
const CoursesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "29"} height={props.height || "21"} viewBox="0 0 29 21" fill={props.fill || "none"} stroke={props.stroke || "none"} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28.0746 6.82303C28.0725 7.19202 27.8574 7.52371 27.5289 7.66467L14.4806 13.2645C14.2612 13.3587 14.0144 13.3571 13.7961 13.2602L0.621827 7.41309C0.295005 7.26804 0.0838191 6.9337 0.0859535 6.56471C0.088088 6.19572 0.303126 5.86404 0.631602 5.72307L13.68 0.123244C13.8994 0.0290753 14.1461 0.0306131 14.3644 0.12751L27.5387 5.97465C27.8655 6.1197 28.0767 6.45405 28.0746 6.82303ZM14.0116 1.96478L3.24716 6.58441L14.149 11.423L24.9134 6.80333L14.0116 1.96478Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.96243 17.8175L6.5832 18.6337C6.26559 18.4862 6.06243 18.1677 6.06243 17.8175V8.81753H7.86243V17.23C7.91409 17.252 7.96913 17.2752 8.02733 17.2995C8.46387 17.4817 9.07554 17.7247 9.7747 17.9673C11.2012 18.4622 12.8869 18.9175 14.1869 18.9175C15.487 18.9175 17.1726 18.4622 18.5991 17.9673C19.2983 17.7247 19.91 17.4817 20.3465 17.2995C20.4047 17.2752 20.4598 17.252 20.5114 17.23V8.81753H22.3114V17.8175C22.3114 18.1677 22.1083 18.4862 21.7906 18.6337L21.4114 17.8175C21.7906 18.6337 21.7906 18.6337 21.7906 18.6337L21.7858 18.636L21.7748 18.641L21.7346 18.6594C21.7 18.6751 21.6498 18.6978 21.5854 18.7263C21.4567 18.7833 21.2711 18.8641 21.0399 18.9606C20.5781 19.1533 19.9311 19.4104 19.1891 19.6678C17.7334 20.1729 15.8068 20.7175 14.1869 20.7175C12.567 20.7175 10.6405 20.1729 9.1847 19.6678C8.44272 19.4104 7.79576 19.1533 7.33394 18.9606C7.10274 18.8641 6.91718 18.7833 6.78846 18.7263C6.72407 18.6978 6.67385 18.6751 6.63919 18.6594L6.59902 18.641L6.588 18.636L6.5832 18.6337C6.5832 18.6337 6.5832 18.6337 6.96243 17.8175Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M25.2645 15.8175C24.7674 15.8175 24.3645 15.4146 24.3645 14.9175V7.81753H26.1645V14.9175C26.1645 15.4146 25.7615 15.8175 25.2645 15.8175Z" fill={props.color || "#424242"}/>
  </svg>
);

export default memo(CoursesIcon)
