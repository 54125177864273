import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { useState } from "react";
import { getIntroMessage } from "../../../utils/api";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';


const IntroductionForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
  } = props;

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const eventTracker = useAnalyticsEventTracker();

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      gap={2}
    >
      <Typography variant='h6' mb={-1.5}>
      AI Persona Introduction
      </Typography>
      <Typography variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
        }}
      >
      This introduction is generated from the information you provide (links, websites, documents, etc.) It will display when a user starts a chat or talk with your AI Persona.
      </Typography>
      <TextField
        multiline
        minRows={8}
        disabled={formSubmitting}
        name='introMessage.message'
        id='text-input-introMessage'
        label='Add an introduction for your AI Persona'
        placeholder='Add an introduction for your AI Persona'
        value={(values.introMessage as {message: string, chips: string[]})?.message || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        error={
          !isGenerating && touched.introMessage &&
          Boolean((errors.introMessage as unknown as {message: string, chips: string[]})?.message)
        }
        helperText={
          !isGenerating && touched.introMessage &&
          (errors.introMessage as unknown as {message: string, chips: string[]})?.message
        }
        sx={{
          width: '100%',
        }}
        slotProps={{
          inputLabel: {
            shrink: !!(values?.introMessage as {message: string, chips: string[]})?.message
          }
        }}
      />
      <Button
        variant="outlined"
        onClick={async () => {
          let oldValue = (values.introMessage as {message: string, chips: string[]}).message;
          setFieldValue('introMessage.message', 'Generating new introduction...')
          if (!isGenerating){
            setIsGenerating(true)
            getIntroMessage(values.documentId, true)
              .then((resp) => {
                  if (resp.status === 200){
                    eventTracker('generate-intro success')
                    return resp.json();
                  } else {
                    eventTracker('generate-intro failed')
                    setIsGenerating(false)
                  }
                }
              ).then((message) => {
                setFieldValue('introMessage.message', message?.response || "")
                setIsGenerating(false)
              }).catch((err) => {
                setFieldValue('introMessage.message', oldValue || "")
                eventTracker('generate-intro failed')
                setIsGenerating(false)
              })
          }
        }}
        startIcon={isGenerating ? <CircularProgress size={20}/> : <AutoAwesomeIcon />}
        sx={{
          width: 'fit-content',
          boxShadow: (theme) => `${theme.shadows[2]} !important`,
        }}
        disabled={isGenerating || formSubmitting}
      >
        Generate Introduction
      </Button>
    </Box>
  )
}

export default IntroductionForm;
