import { Box, Button, Typography } from "@mui/material"
import { useAuth } from "../../hooks/useAuth"
import { genericFormProps } from "./signup.types";

const SaveButton: React.FC<genericFormProps> = (props) => {
  const {
    values,
    formSubmitting,
    isValid,
    dirty,
    validationSchema
  } = props;

  const { authUser, authLoading } = useAuth();
  return (
    <Box
      display='flex'
      justifyContent='flex-end'
      alignItems='center'
      gap={2.5}
    >
      {
        !!authUser.lastIndexedOn &&
        <Typography variant="body3"
          sx={{
            fontStyle: 'italic',
            textAlign: 'right',
          }}
        >
          AI Persona last updated on {authUser.lastIndexedOn}
        </Typography>
      }
      <Button
        variant="contained"
        size='large'
        type='submit'
        disabled={!isValid || !dirty || formSubmitting || !authUser || authLoading}
        sx={{
          boxShadow: (theme) => `${theme.shadows[2]} !important`,
        }}
        // onMouseOver={() => {
        //   validationSchema.validate(values, {abortEarly: false})
        //     .then((responseData) => {
        //         console.log("no validation errors");
        //         console.log(responseData);
        //     })
        //     .catch((err) => {
        //       console.log(err);
        //       console.log(err.name); // ValidationError
        //       console.log(err.errors);
        //   });
        // }}
      >
        Save
      </Button>
    </Box>
  )
}

export default SaveButton;
