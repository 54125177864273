// import { Grid, Typography, Box, Button, Avatar } from "@mui/material";
// import { grey } from '@mui/material/colors';
// import SoopraIcon from '../../assets/images/soopra-icon.png';
// import DefaultUserImage from '../../assets/images/default-avatar.png';
// import { Link, useNavigate } from "react-router-dom";
// import { COURSE_PRICES, STRIPE_PRODUCT_LOOKUP_KEYS } from "../../config/const";
// import { useAuth } from '../../hooks/useAuth';

// interface CourseItemType {
//   courseItem: any;
//   handleEnrollCourse: (event: any, item: any, item_id: any) => void;
//   isEnrolled: boolean;
//   disableButtons?: boolean;
// }

// const allowTakeCourse = process.env.REACT_APP_ALLOW_TAKE_COURSE === "true";

// const CourseItem = (props: CourseItemType) => {
//   const { courseItem, handleEnrollCourse, isEnrolled, disableButtons } = props || {};
//   const navigate = useNavigate();
//   const { authUser } = useAuth();

//   const handleTakeCourse = (event: any) => {
//     event.preventDefault();
//     navigate(`/learn/${courseItem.course_id}`)
//   }

//   const EnrollOrTakeCourse = () => {
//     return (
//       !isEnrolled ?
//         <Button
//           id={`button-enroll-${courseItem.course_id}`}
//           variant="contained"
//           sx={{
//             height: '32px !important',
//             padding: '6px 12px !important',
//             borderRadius:'6px',
//             flex: 1,
//             mr: 1,
//           }}
//           fullWidth
//           onClick={(event) => {
//             if (!disableButtons) {
//               handleEnrollCourse(event, STRIPE_PRODUCT_LOOKUP_KEYS[courseItem.is_free_course ? "free_course" : courseItem.module_count], courseItem.course_id)
//             }}
//           }
//         >
//           Enroll
//         </Button> :
//       (allowTakeCourse || authUser?.beta_tester) ?
//         <Button
//           id={`button-take-course-${courseItem.course_id}`}
//           variant="contained"
//           sx={{
//             height: '32px !important',
//             padding: '6px 12px !important',
//             borderRadius:'6px',
//             flex: 1,
//             mr: 1,
//           }}
//           fullWidth
//           onClick={(event: any) => {
//             if (!disableButtons) {
//               handleTakeCourse(event)
//             }
//           }}
//         >
//           Take Course
//         </Button>
//          :
//         <Button
//           id={`button-enrolled-${courseItem.course_id}`}
//           color="primary"
//           variant="contained"
//           sx={{
//             height: '32px !important',
//             padding: '6px 12px !important',
//             borderRadius:'6px',
//             flex: 1,
//             mr: 1,
//             '&.Mui-disabled': {
//               color: '#353535 !important',
//             },
//           }}
//           fullWidth
//           disabled={true}
//         >
//           Enrolled
//         </Button>
//     )
//   }

//   return (
//     <Grid item
//       xs={12}
//       sm={6}
//       lg={4}
//       xl={4}
//       xxl={3}
//       key={courseItem?.course_id}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           borderRadius: '10px',
//           boxShadow: '0px 0px 4px 0px #bbb',
//           p: 2,
//         }}
//       >
//         <Box>
//           <img
//             src={courseItem?.banner_image || courseItem?.creator_avatar || SoopraIcon}
//             alt='course-banner'
//             width='100%'
//             style={{
//               objectFit: 'cover',
//               aspectRatio: 1.78,
//             }}
//             loading="lazy"
//           />
//         </Box>
//         <Box
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             mb: 1,
//             mt: 2
//           }}
//         >
//           <Avatar
//             alt={courseItem?.creator_name}
//             src={courseItem?.creator_avatar || DefaultUserImage}
//           />
//           <Typography
//             fontSize={'1rem'}
//             fontWeight={400}
//             ml={1.5}
//             align='left'
//             color={'black'}
//             noWrap
//           >
//             {courseItem?.creator_name}
//           </Typography>
//         </Box>
//         <Box>
//           <Typography
//             fontSize={'1.1rem'}
//             fontWeight={700}
//             mb={1}
//             align='left'
//             color={'black'}
//             noWrap
//           >
//           {courseItem?.name || "Course Title"}
//           </Typography>
//           <Typography
//             fontSize={'0.95rem'}
//             fontWeight={400}
//             align='left'
//             color={'black'}
//             noWrap
//           >
//             {[courseItem?.subject, courseItem?.grade, courseItem?.module_count + " Module Course"].join(", ")}
//           </Typography>
//           <Typography
//             fontSize={'0.95rem'}
//             fontWeight={400}
//             mb={2}
//             align='left'
//             color={'black'}
//           >
//             {COURSE_PRICES[courseItem.is_free_course ? "free_course" : courseItem.module_count]}
//           </Typography>
//         </Box>
//         <Box sx={{
//           display: 'flex',
//           flexDirection: 'row',
//         }}>
//           <EnrollOrTakeCourse />
//           <Link
//             id={`href-course-details-${courseItem.course_id}`}
//             to={!disableButtons ? `/courses/${courseItem.course_id}` : "#"}
//             state={{courseItem: courseItem.modules ? courseItem : null}}
//             style={{
//               flex: 1,
//               marginRight: 1
//             }}
//           >
//             <Button
//               id={`button-enroll-${courseItem.course_id}`}
//               variant="contained"
//               sx={{
//                 height: '32px !important',
//                 padding: '6px 12px !important',
//                 borderRadius:'6px',
//                 backgroundColor: 'white !important',
//                 border: '1px solid #009CDB',
//                 color: grey[900] + '!important',
//                 borderColor: grey[900] + '!important',
//               }}
//               fullWidth
//             >
//               Details
//             </Button>
//           </Link>
//         </Box>
//       </Box>
//     </Grid>
//   )
// }

// export default CourseItem;

import { Typography, Box, Button, Avatar, Stack } from '@mui/material';
import SoopraIcon from '../../assets/images/soopra-icon.png';
import DefaultUserImage from '../../assets/images/default-avatar.png';
import { useNavigate } from 'react-router-dom';
import { STRIPE_PRODUCT_LOOKUP_KEYS } from '../../config/const';
import { useAuth } from '../../hooks/useAuth';
import useDeviceType from '../../component/useDeviceType';
import EnrollIcon from "../../svg/EnrollIcon";
import EnrolledIcon from "../../svg/EnrolledIcon";
import InfoIcon from "../../svg/InfoIcon";
import { useTheme } from '@mui/material/styles';

export interface CourseItemType {
  courseItem: any;
  handleEnrollCourse: (event: any, item: any, item_id: any) => void;
  isEnrolled: boolean;
  disableButtons?: boolean;
}

const allowTakeCourse = process.env.REACT_APP_ALLOW_TAKE_COURSE === 'true';

const CourseItem = (props: CourseItemType) => {
  const { courseItem, handleEnrollCourse, isEnrolled, disableButtons } =
    props || {};
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const { isTouchDevice, isMouseDevice } = useDeviceType();
  const theme = useTheme();

  const handleTakeCourse = (event: any) => {
    event.preventDefault();
    navigate(`/learn/${courseItem.course_id}`);
  };

  const handleDetailsButtonNavigation = () => {
    if (!disableButtons) {
      navigate(`/courses/${courseItem.course_id}`, {
        state: { courseItem: courseItem.modules ? courseItem : null },
      });
    }
  };

  const EnrollOrTakeCourse = () => {
    return !isEnrolled ? (
      <Button
        id={`button-enroll-${courseItem.course_id}`}
        variant='contained'
        color='primary'
        size='medium'
        onClick={(event) => {
          event.stopPropagation();
          if (!disableButtons) {
            handleEnrollCourse(
              event,
              STRIPE_PRODUCT_LOOKUP_KEYS[
                courseItem.is_free_course
                  ? 'free_course'
                  : courseItem.module_count
              ],
              courseItem.course_id
            );
          }
        }}
        sx={{ zIndex: 2 }}
        startIcon={<EnrollIcon fill={theme.palette.secondary.main} />}
      >
        Enroll
      </Button>
    ) : allowTakeCourse || authUser?.beta_tester ? (
      <Button
        id={`button-take-course-${courseItem.course_id}`}
        variant='contained'
        color='primary'
        size='medium'
        onClick={(event: any) => {
          event.stopPropagation();
          if (!disableButtons) {
            handleTakeCourse(event);
          }
        }}
        sx={{ zIndex: 2 }}
        startIcon={<EnrollIcon fill={theme.palette.secondary.main} />}
      >
        Take Course
      </Button>
    ) : (
      <Button
        id={`button-enrolled-${courseItem.course_id}`}
        variant='contained'
        color='primary'
        size='medium'
        sx={{
          zIndex: 2,
          backgroundColor: (theme) =>
            `${theme.palette.action.imageHoverDisabledBackground} !important`,
          color: (theme) =>
            `${theme.palette.action.imageHoverDisabledText} !important`,
        }}
        startIcon={<EnrolledIcon fill={theme.palette.secondary.main} />}
        disabled
      >
        Enrolled
      </Button>
    );
  };

  return (
    <Stack
      onClick={
        !isMouseDevice ? (e) => handleDetailsButtonNavigation() : undefined
      }
      sx={{
        width: '100%',
        px: { xs: 1.25, md: 1.5 },
        py: { xs: 1, sm: 1.25, md: 2 },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
          height: 'auto',
          ...(isMouseDevice && {
            '&:hover .overlay': {
              zIndex: 1,
              opacity: 1,
            },
          }),
        }}
      >
        <Avatar
          src={
            courseItem?.banner_image || courseItem?.creator_avatar || SoopraIcon
          }
          alt={courseItem?.name || 'course-banner'}
          sx={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
            aspectRatio: '1.78',
            borderRadius: (theme) => theme.shape.borderRadius,
          }}
        />
        <Box
          className='overlay'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(2px)',
            borderRadius: (theme) => theme.shape.borderRadius,
            opacity: 0,
            zIndex: -1,
            transition: 'opacity 0.4s',
            backgroundColor: (theme) => theme.palette.action.imagehover,
            pointerEvents: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1.5,
          }}
        >
          <EnrollOrTakeCourse />
          <Button
            id={`button-course-details-${courseItem.course_id}`}
            variant='contained'
            color='secondary'
            size='medium'
            onClick={(e) => {
              e.stopPropagation();
              handleDetailsButtonNavigation();
            }}
            sx={{ zIndex: 2 }}
            startIcon={<InfoIcon fill={theme.palette.primary.main} />}
          >
            View Details
          </Button>
        </Box>
      </Box>
      <Stack
        sx={{
          px: 1,
          pt: 1.5,
        }}
      >
        <Typography
          component='p'
          variant='body2'
          color='text.secondary'
          align='left'
        >
          {courseItem?.subject}
        </Typography>
        <Typography variant='h7' color='text.primary' align='left' pt={0.5}>
          {courseItem?.name}
        </Typography>
        <Stack direction={'row'} alignItems='center' pt={0.5}>
          <Avatar
            sx={{ width: 24, height: 24 }}
            alt={courseItem?.creator_name}
            src={courseItem?.creator_avatar || DefaultUserImage}
          />
          <Typography
            component='p'
            variant='body3'
            color='text.secondary'
            align='left'
            pl={1}
          >
            {courseItem?.creator_name}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CourseItem;
