import { Avatar, Badge, Box, ListItem, Typography, useTheme } from "@mui/material";
// import SoopraIcon from '../../assets/images/soopra-icon.png';
import SoopraSmile from "../../svg/SoopraSmile";

interface NotificationItemProps {
  item: any;
  onClickFunc?: () => void;
}

const getTimestampAgo = (timestamp) => {
  const timeAgo = new Date().getTime() - new Date(timestamp * 1000).getTime();

  const minutes = Math.round(timeAgo / (1000 * 60));
  const hours = Math.round(minutes / 60);
  const days = Math.round(hours / 24);
  const weeks = Math.round(days / 7);
  const months = Math.round(weeks / 4);

  if (minutes < 1) {
    return 'Just now';
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks < 4) {
    return `${weeks} weeks ago`;
  } else {
    return `${months} months ago`;
  }
};

const NotificationItem: React.FC<NotificationItemProps> = ({item, onClickFunc}) => {
  const theme = useTheme();

  return (
    <Box
      width='100%'
      px={1}
      sx={{
        '&:first-of-type': {
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        },
        '&:last-of-type': {
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <ListItem
        key={item.notification_id}
        onClick={onClickFunc}
        divider
        sx={{
          borderColor: 'dashed',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          px: 1.5,
        }}
      >
        <Badge
          overlap='circular'
          badgeContent={''}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          // color={'mainBlue'}
          sx={{
            mr: 2,
            mt: 1.5,
            color: 'blue',
            '& .MuiBadge-badge': {
              width: '12px',
              height: '12px',
              borderRadius: '50%',
            },
          }}
          variant='dot'
          invisible={item.is_read}
        >
          {
            item.icon ?
            <Avatar
              src={item.icon}
              sx={{ ml: 0.5 }}
            /> :
            <Box
              sx={{
                borderRadius: theme.shape.borderRadiusLg,
                backgroundColor: theme.palette.text.primary, // TO-DO: check manual override here?
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 40,
                height: 40,
                p: 0.25,
              }}
            >
              <Box pl={0.25}
                display='flex'
                alignItems='center'
              >
                <SoopraSmile
                  color={theme.palette.primary.contrastText}
                  width={40}
                  height={40}
                />
              </Box>
            </Box>
          }
        </Badge>
        <Box sx={{ cursor: 'pointer' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 'auto',
              mt: 1,
            }}
          >
            <Typography variant='h7' >
              {item.title}
            </Typography>

            <Typography // Relative Date
              variant='body3'
              color='text.secondary'
            >
              {getTimestampAgo(item.timestamp)}
            </Typography>
          </Box>

          <Typography // Body
            variant='body2'
            color={item.is_read ? 'text.secondary' : 'text.primary'}
            sx={{ fontWeight: 400, mb: 1 }}
          >
            {item.text}
          </Typography>
        </Box>

      </ListItem>
    </Box>
  )
}

export default NotificationItem;
