import { Box, Button, Link, Typography } from '@mui/material';
import { useState } from 'react';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';

interface QuestionProps {
  id: number;
  title: string;
  info: string | Array<string | { text: string; link: string }>;
  image?: string;
}

const Question = ({ id, title, info, image }: QuestionProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
      border={1}
      borderRadius={2}
      px={2.5}
      py={1.25}
      gap={0.5}
      borderColor={(theme) => theme.palette.divider}
      onClick={() => setExpanded(!expanded)}
      sx={{
        cursor: 'pointer',
      }}
    >
      <Box
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        gap={1.25}
      >
        <Typography
          variant={expanded ? 'h7' : 'body1'}
          lineHeight={1.5}
        >
          {title}
        </Typography>
        <Button
          variant={expanded ? 'contained' : 'outlined'}
          size='large'
          sx={{
              minWidth: 0,
              width: 'fit-content',
              p: (theme) => `${theme.spacing(0)} !important`,
              boxShadow: (theme) => `${theme.shadows[0]} !important`,
            }}
        >
          {expanded ?
            <KeyboardArrowUpOutlinedIcon
              sx={{
                color: theme => theme.palette.primary.contrastText
              }}
            /> :
            <KeyboardArrowDownOutlinedIcon />
          }
        </Button>
      </Box>
      {
        expanded &&
          <>
            {
              Array.isArray(info) ?
              <Typography variant='body1'>
                {info.map((part, idx) => {
                  if (typeof part === 'string') {
                    return <>{part}</>
                  }
                  else {
                    return (
                      <Link
                        id={`href-question-${id}`}
                        key={idx}
                        href={part.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {part.text}
                      </Link>
                    )
                  }
                })}
              </Typography> :
              <Typography variant='body1'>
                {info}
              </Typography>
            }
            {image && <img src={image} alt="" />}
          </>
      }
    </Box>
  );
};

export default Question;
