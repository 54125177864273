import { Box, Button, Divider, IconButton, Link, TextField, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import { useCallback, useState } from "react";
import GoogleIcon from '@mui/icons-material/Google';
import DeleteIcon from '@mui/icons-material/Delete';


const GoogleDriveForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
    showSteps
  } = props;

  const [googleDriveLink, setGoogleDriveLink] = useState<string>('');
  const [gdriveErrors, setGdriveErrors] = useState<string>(null);

  const handleAddLink = () => {
    if (googleDriveLink) {
      // validate
      setGdriveErrors(null);
      const validGoogleDriveUrl = /^(https:\/\/)?(drive\.google\.com\/(?:file\/d\/|open\?id=|uc\?id=|drive\/folders\/)[\w-]+(?:\?.*)?)$/;
      if (!validGoogleDriveUrl.test(googleDriveLink)) {
        setGdriveErrors('Invalid Google Drive link. Must start with https://drive.google.com/file/d/{file_id}, https://drive.google.com/open?id={file_id}, https://drive.google.com/uc?id={file_id}, or https://drive.google.com/drive/folders/{folder_id}')
      } else {
        // add to google drive folder list
        let copy = [...values.google_drive_links];
        copy.push({url: googleDriveLink})
        setFieldValue('google_drive_links', copy)
        setGoogleDriveLink('')
      }
    }
  }

  const renderDriveListItem = useCallback(
    (document: any, index: number, onDeleteHandler: () => void) => (
      <Box
        key={`drivelink-${index}`}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        columnGap='12px'
        sx={{
          borderBottom: 1,
          borderColor: (theme) => theme.palette.divider,
          py: 1.5,
        }}
      >
        <Box display='flex'>
          <GoogleIcon sx={{ mr: 2, fontSize: '20px' }} />
          <Link
            underline="hover"
            href={`${document.url}`}
            rel="noreferrer"
            target="_blank"
          >
            <Typography
              align='left'
              variant='body2'
              sx={{
                wordBreak: 'break-word',
              }}
            >
              {document.url}
            </Typography>
          </Link>
        </Box>
        <IconButton
          id={`button-delete-${document.name}-${index}`}
          color='error'
          size='small'
          sx={{ width: '30px', height: '30px' }}
          onClick={onDeleteHandler}
        >
          <DeleteIcon
            sx={{
              color: 'error.light',
              fontSize: '18px',
            }}
          />
        </IconButton>
      </Box>
    ),
    []
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
      >
        <Typography variant='h6' mb={-1.5}>
          {(showSteps ? '2. ': '') + 'Google Drive Links'}
        </Typography>
        <Typography variant="body2" mb={-1.5}
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Add a link to a Google Drive folder. Google Drive Folders must be public to be synced and used for training.
        </Typography>
        <Typography variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          <strong>NOTE:</strong> After saving, you must reload this page to see the updated sync progress of your Google Drive Links.
        </Typography>
        <TextField
          type='string'
          fullWidth
          name='google_drive_links'
          margin='none'
          label='Add Google Drive Link'
          placeholder="Add Google Drive Link"
          value={googleDriveLink}
          onBlur={handleBlur}
          onChange={(e) => {
            setGoogleDriveLink(e.target.value)
          }}
          error={Boolean(gdriveErrors)}
          helperText={gdriveErrors}
          autoComplete='off'
        />
        <Button
          variant="contained"
          size='large'
          fullWidth={false}
          sx={{
            width: 'fit-content',
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
          onClick={handleAddLink}
          disabled={!googleDriveLink}
        >
          Add Google Drive Link
        </Button>
      </Box>
      {
        !!values.google_drive_links.length &&
        <Box
          display='flex'
          flexDirection='column'
        >
          <Typography variant='h7' mb={1.5}>
            My Google Drive Links
          </Typography>
          <Divider />
          {
            (values?.google_drive_links || []).map((document: any, index: number) =>
              renderDriveListItem(document, index, () => {
                let links = [...(values.google_drive_links || [])];
                links?.splice(index, 1);
                setFieldValue('google_drive_links', links || null);
              })
            )
          }
        </Box>
      }
    </>
  )
}

export default GoogleDriveForm;
