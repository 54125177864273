import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import Credit from '../../assets/images/credit.png';
import StripeCheckoutForm from '../StripeCheckoutForm';
// import theme from "../../theme";
import { useTheme } from '@mui/material/styles';

const enableMonetization = process.env.REACT_APP_ENABLE_MONETIZATION === 'true';

interface additionalCreditsButtonProps {
  redirectURL?: string,
}

const AdditionalCreditsButton: React.FC<additionalCreditsButtonProps> = (props) => {
  const [ stripeOpen, setStripeOpen ] = useState(false);
  const [ stripeItem, setStripeItem ] = useState(null);
  const { redirectURL } = props;
  const theme = useTheme();

  let handlePurchaseCredits = (event: any) => {
    event.preventDefault()
    setStripeItem("basic_10_pack")
    setStripeOpen(true)
  }

  if (!enableMonetization) return <></>

  return (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: (theme) => theme.palette.background["paper-elevation-10"],
      border: `1px solid ${theme.palette.background["paper-elevation-0"]}`,
      borderRadius: '5px',
      width: '100%',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '1rem',
        paddingBottom: '0.75rem',
        '& img': {
          marginRight: '0.5rem',
          height: '24px',
        }
      }}
    >
      <img src={Credit} alt='soopra-credit' />
      <Typography
        component='h6'
        variant='h6'
        fontWeight={600}
        sx={{
          fontSize: '16px', // Change the font family
          color: (theme) => theme.palette.text.primary,
          textAlign: 'center',
        }}
      >Buy Additional Credits to Continue</Typography>
    </Box>
    <Box
      sx={{
        pl: '1rem',
        pr: '1rem',
      }}
    >
      <Typography
        component='h6'
        variant='h6'
        fontWeight={400}
        sx={{
          fontSize: '14px', // Change the font family
          color: (theme) => theme.palette.text.primary,
          marginBottom: '0.75rem',
          textAlign: 'center',
        }}
      >
        10 additional credits which expire in a month for $0.99.
      </Typography>
    </Box>
    <Button
      id={`button-purchase-credits`}
      variant="contained"
      sx={{
        height: '32px !important',
        padding: '6px 12px !important',
        borderRadius:'6px',
        width: '50%',
        fontSize: '14px',
        marginBottom: '1.5rem'
      }}
      fullWidth
      onClick={handlePurchaseCredits}
    >
      Buy Credits
    </Button>
    <StripeCheckoutForm item={stripeItem} isOpen={stripeOpen} setIsOpen={setStripeOpen} redirectURL={redirectURL || ""}/>
  </Box>
  )
}

export default AdditionalCreditsButton;
