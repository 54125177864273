import { SVGProps, memo } from 'react';
import { useTheme } from '@mui/material/styles';

const TextboxStar = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  // Set color dynamically based on theme mode
  const fillColor =
    theme.palette.mode === 'dark'
      ? theme.palette.common.white // White in dark mode
      : theme.palette.primary.main; // Primary main in light mode

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.37488 0L11.4431 7.24072L18.7498 9.29032L11.4431 11.3399L9.37488 18.5806L7.30662 11.3399L0 9.29032L7.30662 7.24072L9.37488 0Z"
        fill={fillColor} // Dynamic fill color
      />
      <path
        d="M19.5313 13.1613L20.7378 17.385L25 18.5806L20.7378 19.7762L19.5313 24L18.3248 19.7762L14.0626 18.5806L18.3248 17.385L19.5313 13.1613Z"
        fill={fillColor} // Dynamic fill color
      />
    </svg>
  );
};

export default memo(TextboxStar);
