import * as React from 'react';
import { Suspense, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import CopyToClipboardButton from '../../component/CopyClipBoardButton';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import SocialMediaLinks from './socialMediaLinks';
import ChatButtonIcon from '../../svg/ChatButtonIcon';
import Textboxstar from '../../svg/textboxstar';
import JournalIcon from '../../svg/JournalIcon';
import { useAuth } from '../../hooks/useAuth';
import UserCourseData from './userCourseData';
import { Timestamp } from 'firebase/firestore';
import UserStreaksData from './userStreaksData';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SendChatIcon from '../../svg/SendChatIcon';
import { useNavigate } from 'react-router-dom';
import { drawerWidth, drawerWidthClosed } from '../leftNav';
import { useState } from 'react';
import { getPrices, getSubscriptions } from '../../utils/api';
import AttachMoneyIcon from "../../svg/Profilenavicons/PricecardIcon";


interface UserProfileDataType {
  authUser: UserFormType;
  profileData: UserFormType;
}
export default function UserProfileData(props: UserProfileDataType) {
  const {
    documentId = '',
    fullName = '',
    profilePhotoURL = '',
    userName = '',
    occupation = '',
    shareLink = '',
    index = '',
    shortBio = '',
    bannerPhotoUrl = '',
    total_fans = 0,
    total_messages = 0,
    voiceId,
    subscription,
    isMeethSubscribed=false,
    introMessage,
  } = props?.profileData || {};

  const { profileData } = props;


  const { documentId: authDocumentId = '' } = props?.authUser || {};
  const { authUser } = useAuth();
  const isProfileAuthUser = authUser?.documentId === documentId;

  const [premiumAccess, setPremiumAccess] = React.useState<boolean>(false);
  const [meethFullScreen, setMeethFullScreen] = React.useState<boolean>(false);

  const message = React.useRef(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const [soopraSub, setSoopraSub] = useState<any>(null);

  useEffect(() => {
    async function fetchSubscriptions() {
      try {
        getPrices([profileData?.subscription?.custom_product_id])
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
          })
          .then((data) => {
            let priceInfo = (data || {})[profileData?.subscription?.custom_product_id];
            if (priceInfo){
              setSoopraSub(priceInfo)
            }
          })
      } catch (error) {
        console.error('Error fetching subscription:', error);
      }
    }
    fetchSubscriptions();
  }, [profileData]);

  useEffect(() => {
    if (subscription?.custom_product_id) {
      let access = false;
      let premiumKey = subscription?.custom_product_id;
      if (documentId === authDocumentId) {
        access = true;
      } else if (authUser?.premiumPurchases) {
        let currentTimeStamp = Timestamp.fromDate(new Date());
        if (authUser.premiumPurchases[premiumKey]){
          // check if not expired
          if (authUser.premiumPurchases[premiumKey] > currentTimeStamp){
            access = true;
          }
        }
      }
      if (premiumAccess !== access) setPremiumAccess(access);
    }
  }, [authUser, subscription]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleSendMessage = (inputStr?: string) => {
    if (subscription?.custom_product_id && (authUser && !premiumAccess)) {
      if (inputStr || message.current?.value?.trim()) {
        // just navigate the user if they dont have premium access, dont send the message
        navigate(`/${userName}/chat`)
      }
    } else if (inputStr) {
      navigate(`/${userName}/chat`, { state: { message: inputStr } });
    } else if (message.current?.value?.trim()) {
      navigate(`/${userName}/chat`, { state: { message: message.current?.value } });
    }
  };

  const FrankSortinoFeatureButtons = React.lazy(() =>
    import('../customUIElements/FrankSortino').then((module) => ({
      default: module.FrankSortinoFeatureButtons,
    }))
  );

  const PremiumPurchaseButton = React.lazy(() =>
    import('../Purchases/premiumPurchaseButton')
  )

  const FrankSortinoYoutubeVideo = React.lazy(async () => {
    const module = await import('../customUIElements/FrankSortino');
    return { default: module.FrankSortinoYoutubeVideo };
  });

  const isStanfordUrl = window.location.href.startsWith('https://stanford.soopra.ai');

  return (
    <Box display="flex" flexDirection="row" width="100%">

      <Box sx={{ paddingTop: { xs: '16px', sm: '32px' }, paddingBottom: '32px', width: '100%', height: "100%",}}>
      {/* Outer container for Avatar and Name/Socials */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }} // Column layout for mobile, row for larger screens
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            gap={{ xs: 2, sm: 4 }} // Add spacing between avatar and text
          >
            {/* Avatar */}
            <Box position="relative"  mb={{ xs: 2 }}>
              <Avatar
                src={profilePhotoURL}
                alt={fullName}
                sx={{
                  width: '211px',
                  height: '203px',
                  border: '2px solid #fff',
                  borderRadius: '35%',
                  objectFit: 'cover',
                }}
              >
                {fullName ? fullName[0] : ''}
              </Avatar>
            </Box>

            {/* Name/Socials and Buttons */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{
              width: '100%',
              textAlign: { xs: 'center', sm: 'left' }, // Center text on mobile, left-align on larger screens
              height: '100%',
            }}
          >
            {/* TOP SECTION (Name, Socials) */}
            <Box mb={4}>
              {/* Full Name + Verified */}
              <Box display="flex" alignItems="center" justifyContent={{ xs: 'center', sm: 'flex-start' }} mb={0}>
                <Typography variant="h6" fontWeight={600} sx={{ m: 0 }}>
                  {fullName}
                </Typography>
              </Box>

              {/* Username */}
              <Typography variant="body2" fontWeight={400} color="text.secondary" sx={{ mb: 0 }}>
                @{userName}
              </Typography>

              <Typography variant="body2" fontWeight={400} color="text.secondary" sx={{ mb: soopraSub ? 0 : 1 }}>
                {occupation}
              </Typography>

              {/* Subscription Details */}
              {soopraSub && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb={1}
                    sx={{
                      borderRadius: '4px',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: 'fontFamily',
                        fontWeight: 'fontWeightRegular',
                        fontSize: '0.875rem',
                        lineHeight: '20.02px',
                        letterSpacing: '0.17px',
                        color: 'text.secondary',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                      }}
                    >
                      <AttachMoneyIcon color={theme.palette.primary.main}/><Box component="span" sx={{ pl: 1, }}> {/* Padding added here */}
                      {`Subscription - $${Math.round(soopraSub.unit_amount || 0) / 100}/${soopraSub.recurring?.interval || 'month'}`}
                    </Box>
                    </Typography>
                  </Box>
                )}
              {/* Social Media Links  */}
              <SocialMediaLinks profileData={profileData} />
            </Box>

            {/* BOTTOM SECTION (Buttons) */}
          <Box
            sx={{
              display: { xs: 'flex', sm: 'none' }, // Show this layout only on mobile
              flexDirection: 'column',
              gap: 1,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            {/* Chat Button */}
            <Link to="chat" style={{ flex: 0 }}>
              <Button
                id="button-chat"
                variant="contained"
                size="large"
                color="primary"
                disabled={!index}
                startIcon={<ChatButtonIcon />}
                sx={{
                  width: '100%', // Full width in mobile view
                  height: '42px',
                  borderRadius: 'borderRadiusRound',
                  padding: '8px 22px',
                  backgroundColor: 'primary.main',
                  color: 'rgba(66, 66, 66, 1)',
                  boxShadow: `
                    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                  `,
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Chat
              </Button>
            </Link>

            {/* Journal Button */}
            {authDocumentId === documentId && (
              <Link to="update/journaling" style={{ flex: 0 }}>
                <Button
                  id="button-journaling"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!index}
                  startIcon={<JournalIcon />}
                  sx={{
                    width: '100%', // Full width in mobile view
                    height: '42px',
                    borderRadius: 'borderRadiusRound',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    padding: '8px 22px',
                    backgroundColor: 'secondary.main',
                    color: 'rgba(255, 255, 255, 1)',
                    boxShadow: `
                      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                      0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                    `,
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                    },
                  }}
                >
                  Journal
                </Button>
              </Link>
            )}

            {/* Edit Profile Button */}
            {authDocumentId === documentId && (
              <Link to={`/${userName}/update`} style={{ flex: 0 }}>
                <Button
                  id={`button-edit-info-${userName}`}
                  variant="contained"
                  size="large"
                  color="secondary"
                  startIcon={<EditOutlinedIcon />}
                  sx={{
                    width: '100%', // Full width in mobile view
                    height: '42px',
                    borderRadius: 'borderRadiusRound',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    padding: '8px 22px',
                    backgroundColor: 'secondary.main',
                    color: 'rgba(255, 255, 255, 1)',
                    boxShadow: `
                      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                      0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                    `,
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                    },
                  }}
                >
                  Edit Profile
                </Button>
              </Link>
            )}

            {/* Share Button - Hidden in Mobile View */}
            {authDocumentId === documentId && (
              <Box
                sx={{
                  display: { xs: 'none', sm: 'block' }, // Hide in mobile view
                }}
              >
                <CopyToClipboardButton
                  copyLink={`${shareLink}`}
                  buttonProps={{
                    disabled: !index,
                  }}
                />
              </Box>
            )}
          </Box>

          {/* Desktop and larger screens layout */}
          <Box
            sx={{
              display: { xs: 'none', sm: 'flex' }, // Show this layout only on larger screens
              flexDirection: 'row',
              gap: 1,
            }}
          >
            {/* Chat Button */}
            <Link to={`/${userName}/chat`} style={{ flex: 0 }}>
              <Button
                id="button-chat"
                variant="contained"
                size="large"
                color="primary"
                disabled={!index}
                startIcon={<ChatButtonIcon />}
                sx={{
                  width: '106px',
                  height: '42px',
                  borderRadius: 'borderRadiusRound',
                  padding: '8px 22px',
                  marginTop: 'auto',
                  backgroundColor: 'primary.main',
                  color: 'rgba(66, 66, 66, 1)',
                  boxShadow: `
                    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                  `,
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Chat
              </Button>
            </Link>

            {/* Journal Button */}
            {authDocumentId === documentId && (
              <Link to="update/journaling" style={{ flex: 0 }}>
                <Button
                  id="button-journaling"
                  variant="contained"
                  size="large"
                  color="secondary"
                  disabled={!index}
                  startIcon={<JournalIcon />}
                  sx={{
                    width: '126px',
                    height: '42px',
                    borderRadius: 'borderRadiusRound',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    padding: '8px 22px',
                    backgroundColor: 'secondary.main',
                    color: 'rgba(255, 255, 255, 1)',
                    boxShadow: `
                      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                      0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                    `,
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                    },
                  }}
                >
                  Journal
                </Button>
              </Link>
            )}

            {/* Edit Profile Button */}
            {authDocumentId === documentId && (
              <Link to={`/${userName}/update`} style={{ flex: 0 }}>
                <Button
                  id={`button-edit-info-${userName}`}
                  variant="contained"
                  size="large"
                  color="secondary"
                  startIcon={<EditOutlinedIcon />}
                  sx={{
                    width: '153px',
                    height: '42px',
                    borderRadius: 'borderRadiusRound',
                    border: '1px solid',
                    borderColor: 'secondary.contrastText',
                    padding: '8px 22px',
                    backgroundColor: 'secondary.main',
                    color: 'rgba(255, 255, 255, 1)',
                    boxShadow: `
                      0px 3px 1px -2px rgba(0, 0, 0, 0.2),
                      0px 2px 2px 0px rgba(0, 0, 0, 0.14),
                      0px 1px 5px 0px rgba(0, 0, 0, 0.12)
                    `,
                    '&:hover': {
                      backgroundColor: 'secondary.dark',
                    },
                    whiteSpace: 'nowrap',
                  }}
                >
                  Edit Profile
                </Button>
              </Link>
            )}

            {/* Share Button */}
            {authDocumentId === documentId && (
              <Box>
                <CopyToClipboardButton
                  copyLink={`${shareLink}`}
                  buttonProps={{
                    disabled: !index,
                  }}
                />
              </Box>
            )}
          </Box>

          </Box>
        </Box>
          {shortBio && (
            <>
              <Typography component="p" variant="body2" color={'text.secondary'} sx={{ paddingTop: '24px' }}>
                Biography
              </Typography>
              <Typography component="p" variant="body2" color={'text.primary'} sx={{ paddingTop: '16px' }}>
                {shortBio}
              </Typography>
            </>
          )}
          <Box width="100%" paddingTop="32px">
          <UserStreaksData
            mode="profile"
            profileData={profileData}
          />
        </Box>

        {
          (typeof introMessage !== 'string' && introMessage?.chips) ?
            <Box
              width="100%"
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100dvw',
              }}
              gap={1.5}
            >
              <Typography
                component="p"
                variant="body2"
                color={'text.secondary'}
                sx={{
                  pb: 0.5,
                  overflow: 'hidden',
                }}
                textOverflow='ellipsis'
                whiteSpace='nowrap'
              >
                Suggested Questions
              </Typography>
              {
                introMessage.chips.map((val, ind) => {
                  return (
                    <Box
                      component="a"
                      key={`suggested-question-${ind}`}
                      sx={{
                        width: '100%',
                        px: 3,
                        py: 1.5,
                        border: 1,
                        borderRadius: (theme) => theme.shape.borderRadiusLg,
                        borderColor: (theme) => theme.palette.divider,
                        cursor: 'pointer',
                        textDecoration: 'none',
                      }}
                      href={`/${userName}/chat`}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleSendMessage(val)
                      }}
                    >
                      <Typography variant='body2' color='primary'>
                        {val}
                      </Typography>
                    </Box>
                  )
                })
              }
            </Box> :
            (!!isProfileAuthUser &&
              <Box
                width="100%"
                sx={{
                  mt: 3,
                  maxWidth: '100dvw',
                }}
              >
                {/* Title outside the dashed border */}
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight={500}
                  sx={{ mb: 1 }}
                >
                  Suggested Questions
                </Typography>

                {/* Box with dashed border */}
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 3,
                    backgroundColor: 'background.default',
                    borderRadius: 2,
                    border: '1px dashed rgba(0, 0, 0, 0.2)', // Dashed border
                  }}
                >
                  <Typography
                    variant="h7"
                    fontWeight={600}
                    sx={{ mb: 1 }}
                  >
                    Create a suggested question for people who visit your profile.
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3 }}
                  >
                    Visitors can click the suggested question to immediately start interacting with your AI persona.
                  </Typography>

                  <Button
                    variant="contained"
                    size="large"
                    sx={{
                      backgroundColor: 'grey.800',
                      color: 'white',
                      borderRadius: '8px',
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: 'grey.900',
                      },
                    }}
                    href={`/${authUser?.userName}/update/introduction`}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(`/${authUser?.userName}/update/introduction`)
                    }}
                  >
                    Create Suggested Question
                  </Button>
                </Box>
              </Box>)
        }
          {/* {
            (userName === 'srinivasjaini' || userName === 'sortino-chat') && !isStanfordUrl ? (
              <Suspense>
                <Box
                  mt={2}
                  px={2.5}
                  sx={{
                    width: {
                      xs: '60%',
                      sm: 258,
                    },
                    height: {
                      xs: 'calc(60vw * 9/16)',
                      sm: 157,
                    },
                  }}
                >
                  <FrankSortinoYoutubeVideo
                    videoId='Br2ezEP71XE'
                    title='Awesome Video'
                  />
                </Box>
                <Typography
                  component='p'
                  variant='body1'
                  color={'#000'}
                  px={2.5}
                  pt={1}
                  sx={{
                    width: {
                      xs: '60%',
                      sm: 256,
                    },
                  }}
                >
                  <strong>Frank Sortino</strong> - Chatbots Democratizing Investments - An Introductory Course
                </Typography>
              </Suspense>
            ) : (
              <></>
            )
          }  */}

        <Box width="100%" paddingTop="24px">
          <UserCourseData authUser={authUser} profileData={props?.profileData} />
        </Box>

        <Box width="100%" position="relative">
          {/* Floating Textbox */}
          <Box
              sx={{
                position: 'fixed',
                bottom: {
                  xs: '60px',
                  md: '16px',
                },
                left: {
                  xs: '50%',
                  md: `calc((${drawerWidthClosed + drawerWidth}px)/2 + 50%)`,
                },
                transform: 'translateX(-50%)',
                width: {
                  xs: '90%',
                  md: `calc(100% - ${drawerWidthClosed + drawerWidth}px)`,
                },
                maxWidth: '800px',
                height: '44px',
                backgroundColor: theme.palette.background.paper,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '100px',
                padding: {
                  xs: '8px 0px 8px 8px',
                  md: '8px 0px 8px 8px',
                },
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                alignItems: 'center',
                zIndex: 10,
              }}
            >
            <Box
              sx={{
                marginLeft: '8px', // Space from the left edge
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Textboxstar />
            </Box>
            <TextField
              variant="outlined"
              placeholder="Ask anything..."
              fullWidth
              autoComplete="off"
              inputRef={message}
              defaultValue=""
              slotProps={{
                input: {
                  sx: {
                    borderRadius: '100px',
                    paddingRight: '12px',
                    '& fieldset': { border: 'none' },
                  },
                }
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSendMessage();
                }
              }}
            />
            <IconButton
              color="primary"
              onClick={() => handleSendMessage()}
              sx={{
                ml: '12px', // Adjust spacing between the input and the button
              }}
            >
              <SendChatIcon isTextEntered={true} />
            </IconButton>
          </Box>
        </Box>
        <Box width="100%" mt={3}>
            {!index && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                flexDirection="column"
                gap={2}
                my={3}
              >
                <Typography
                  variant="body1"
                  fontWeight={500}
                  sx={{
                    justifyContent: "center",
                    padding: 0.8,
                    borderRadius: "none",
                    width: "100%",
                    maxWidth: "280px",
                  }}
                >
                  Your personality is being created! It may take a few minutes.
                </Typography>
                <CircularProgress
                  size={30}
                  sx={{
                    color: "text.secondary",
                  }}
                />
              </Box>
            )}

            {/* Premium subscription button */}
            {/* {
              (subscription?.custom_product_id && !premiumAccess) ?
              <Suspense >
                <Box p={2.5}>
                  <PremiumPurchaseButton requiredPremiumKey={subscription.custom_product_id} redirectURL={`/${userName}/chat`}/>
                </Box>
              </Suspense> : <></>
            } */}
            <Box>
            </Box>
          </Box>
        </Box>



    </Box>
  );
}
