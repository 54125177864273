import React, { useEffect, useMemo, useState } from "react";

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from "../../svg/Profilenavicons/ProfileIcon";
import CreditCardIcon from "../../svg/Profilenavicons/PurchasesIcon";
import AttachMoneyIcon from "../../svg/Profilenavicons/PricecardIcon";
import DashboardIcon from "../../svg/Profilenavicons/DashboardIcon";
import BookIcon from "../../svg/Profilenavicons/MycoursesIcon";
import DescriptionIcon from "../../svg/Profilenavicons/DocumentIcon";
import LanguageIcon from "../../svg/Profilenavicons/WeblinksIcon";
import FavoriteIcon from "../../svg/Profilenavicons/SocialmediaIcon";
import MicIcon from "../../svg/Profilenavicons/VoiceSampleIcon";
import EmojiPeopleIcon from "../../svg/Profilenavicons/IntroductionIcon";
import FaceIcon from "../../svg/Profilenavicons/PersonalityIcon";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import { useAuth } from "../../hooks/useAuth";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, Switch, Typography, useTheme } from "@mui/material";
import { USER_TYPE } from "../../config/const";
import { ScrollableBox, SectionTitle, StyledListItemText } from "./ProfileNav";
import { CreatePersonaButton } from "./createPersonaButton";
import ThemeModeToggle from "./themeModeToggle";
import { LoginModalVisibleType } from "../loginModal";
import SoopraLogo from "../../svg/SoopraLogo";
import ChatUsage from "../HamburgerMenu/ChatUsage";

interface HamburgerMenuProps {
  onContactClick: () => void;
  setLoginModalVisible: (value: React.SetStateAction<LoginModalVisibleType>) => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = (props) => {
  const {onContactClick, setLoginModalVisible} = props;
  const [open, setOpen] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const location = useLocation();
  const { authUser, updateVerifiedStatus } = useAuth();
  const theme = useTheme();

  const menuSections = useMemo(() => {
    if (authUser?.userType === USER_TYPE.CREATOR) {
      return [
        {
          title: "Account",
          items: [
            { label: "Profile", icon: <PersonIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}` },
            { label: "Purchases", icon: <CreditCardIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/purchases` },
            // { label: "My Courses", icon: <BookIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/courses`  },
          ],
        },
        {
          title: "AI Persona",
          items: [
            { label: "Price Card", icon: <AttachMoneyIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/revenue`},
            { label: "Dashboard", icon: <DashboardIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/dashboard` },
          ],
        },
        {
          title: "Training Material",
          items: [
            { label: "Documents", icon: <DescriptionIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/documents`  },
            { label: "Journal", icon: <BookIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/journaling`},
            { label: "Weblinks", icon: <LanguageIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/weblinks`},
            { label: "Social Media", icon: <FavoriteIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/social`},
            { label: "Voice Sample", icon: <MicIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/voice`},
            { label: "Introduction", icon: <EmojiPeopleIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/introduction`},
            // { label: "Personality", icon: <FaceIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/update/personality`},
          ],
        },
        {
          title: "More",
          items: [
            { label: "FAQ", icon: <HelpOutlineOutlinedIcon />, path: `/faq`  },
            { label: "Terms & Conditions", icon: <ArticleOutlinedIcon />, path: `/terms`},
            { label: "Contact Us", icon: <EmailOutlinedIcon />, path: null, onMount: onContactClick},
            { label: "Sign Out", icon: <LogoutIcon />, path: `/logout-user`},
          ],
        },
      ]
    }
    if (!!authUser) {
      return [
        {
          title: "Account",
          items: [
            { label: "Profile", icon: <PersonIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}` },
            { label: "Purchases", icon: <CreditCardIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/purchases` },
            // { label: "My Courses", icon: <BookIcon color={theme.palette.primary.main}/>, path: `/${authUser?.userName}/courses`  },
          ],
        },
        {
          title: "More",
          items: [
            { label: "FAQ", icon: <HelpOutlineOutlinedIcon />, path: `/faq`  },
            { label: "Terms & Conditions", icon: <ArticleOutlinedIcon />, path: `/terms`},
            { label: "Contact Us", icon: <EmailOutlinedIcon />, path: null, onMount: onContactClick},
            { label: "Sign Out", icon: <LogoutIcon />, path: `/logout-user`},
          ],
        },
        {
          title: "AI Persona",
          items: [],
        },
      ]
    }
    return [
      {
        title: "More",
        items: [
          { label: "FAQ", icon: <HelpOutlineOutlinedIcon />, path: `/faq`  },
          { label: "Terms & Conditions", icon: <ArticleOutlinedIcon />, path: `/terms`},
          { label: "Contact Us", icon: <EmailOutlinedIcon />, path: null, onMount: onContactClick},
        ],
      },
    ]
  }, [authUser, onContactClick, theme])

  useEffect(() => {
    if (authUser?.documentId) {
      // Fetch verified status on mount
      setIsPublished(authUser?.verified ?? false);
    }
  }, [authUser]);

  useEffect(() => {
    setOpen(false);
  }, [location.pathname])

  const handleToggleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newStatus = event.target.checked;
    setIsPublished(newStatus);

    // Update backend or show a toast message on failure
    try {
      if (authUser?.documentId) {
        await updateVerifiedStatus(authUser.documentId, newStatus);
        toast.success(`Persona ${newStatus ? "published" : "unpublished"} successfully`);
      }
    } catch (error) {
      toast.error("Failed to update persona status. Please try again.");
      setIsPublished(!newStatus); // Revert on failure
    }
  };

  const getSelectedPath = () => {
    const allPaths = menuSections.flatMap((section) =>
      section.items.map((item) => item.path)
    );
    return allPaths.includes(location.pathname) ? location.pathname : "/profile";
  };

  const selectedPath = getSelectedPath();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <div style={{ marginLeft: '16px' }}>
        <IconButton
          id='button-toggle-hamburger-menu'
          // ref={buttonRef}
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <Drawer
        variant='temporary'
        anchor='right'
        open={open}
        onClose={toggleDrawer}
        sx={[{
          width: '328px',
          },
          !open && {
            display: 'none',
          }
        ]}
        PaperProps={{
          style: {
            width: '350px',
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 3,
            gap: 2,
          }}
        >
          <Box
            display='flex'
            flexDirection='column'
          >
            <Box
              display='flex'
              height='40px'
              justifyContent='space-between'
              alignItems='center'
            >
              {
                (authUser?.userName) ?
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    @{authUser?.userName}
                  </Typography> :
                  <SoopraLogo
                    color={theme.palette.primary.main}
                    width='101px'
                    height='26px'
                  />
              }

              <IconButton
                onClick={toggleDrawer}
                color='primary'
                sx={{
                  minWidth: '36px',
                  minHeight: '36px',
                  height: '36px',
                  width: '36px',
                }}
              >
                <CloseIcon height='30px' width='30px' />
              </IconButton>
            </Box>

            {
              (authUser?.userType === USER_TYPE.CREATOR) &&
              <Box
                display='flex'
                justifyContent='space-between'
                alignItems='center'
                height='40px'
                sx={{
                  backgroundColor: (theme) => theme.palette.background["paper-elevation-7"],
                  mx: -2,
                  px: 2,
                }}
              >
                <Typography variant="body3">
                  Publish my AI Persona
                </Typography>
                <Switch checked={isPublished} onChange={handleToggleChange} />
              </Box>
            }

            {
              (!authUser) &&
              <Box
                display='flex'
                flexDirection='column'
                width='100%'
                py={5}
              >
                <Button color='primary' variant='contained' size='large'
                  sx={{
                    mb: 1,
                    boxShadow: (theme) => `${theme.shadows[2]} !important`,
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setLoginModalVisible({
                      isOpen: true,
                      formType: 'signup',
                      currentStep: 1,
                    })
                  }}
                >
                  Create Account
                </Button>
                <Button color='primary' variant='outlined' size='large'
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setLoginModalVisible({
                      isOpen: true,
                      formType: 'signin',
                      currentStep: 1,
                    })
                  }}
                >
                  Sign In
                </Button>
              </Box>
            }
          </Box>

          <ScrollableBox>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                mx: 2,
              }}
            >
              {menuSections.map((section, sectionIndex) => (
                <React.Fragment key={sectionIndex}>
                  <SectionTitle>{section.title}</SectionTitle>
                  <List>
                    {section.items.map((item, index) => (
                      <React.Fragment key={sectionIndex}>
                        <ListItemButton
                          component={Link}
                          to={item.path}
                          selected={selectedPath === item.path}
                          sx={{
                            px: 0,
                            borderRadius: 1,
                            backgroundColor: selectedPath === item.path ? "rgba(0, 0, 0, 0.1)" : "transparent",
                            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.05)" },
                          }}
                          onClick={(e) => {
                            if (item?.onMount) {
                              e.preventDefault();
                              e.stopPropagation();
                              item.onMount();
                            }
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 32,
                              ml: 0,
                              mr: 2,
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <StyledListItemText isselected={(selectedPath === item.path).toString()}>
                            {item.label}
                          </StyledListItemText>
                        </ListItemButton>
                        {
                          (section.title === 'More' && index === section.items.length -1) &&
                          <>
                            <Divider variant="fullWidth" sx={{mt: 1}}/>
                            <ThemeModeToggle isHamburgerMenu={true}/>
                          </>
                        }
                      </React.Fragment>
                    ))}
                  </List>
                </React.Fragment>
              ))}

              {
                (authUser && authUser.userType === USER_TYPE.FOLLOWER) &&
                <Box
                  flexGrow={1}
                  display='flex'
                  flexDirection='column'
                  alignItems='center'
                >
                  <CreatePersonaButton/>
                </Box>
              }
            </Box>
          </ScrollableBox>

          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-end'
            mx={-3}
            mb={-3}
          >
            <ChatUsage />
          </Box>
        </Box>
      </Drawer>
    </>
  )
}

export default HamburgerMenu;
