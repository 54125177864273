import { Box, Button, Card, CardContent, Grid2 as Grid, Typography } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import CheckIcon from '@mui/icons-material/Check';
import InfoIcon from '@mui/icons-material/Info';
import { TIER_LEVEL_CONVERSION } from "../../config/const";

export interface TiersCardItemDescriptions {
  size?: {xs?: number, sm?: number, md?: number, lg?: number};
  embeddedSize?: {xs?: number, md?: number, lg?: number, xl?: number};
  name: string;
  description: string;
  tier: string;
  priceMain: string;
  priceMinor?: string;
  features: {[x: string]: any[]};
}

export interface TiersCardItemProps extends TiersCardItemDescriptions {
  currentTier: string;
  recommendedTier: React.RefObject<string>;
  handlePurchaseButton: (tier: string) => void;
  openFeatModal: (title: string, description: string | any[]) => void;
  isEmbedded?: boolean;
}

const TiersCardItem: React.FC<TiersCardItemProps> = (props) => {
  const { size, name, description, priceMain, priceMinor, tier,
    currentTier, handlePurchaseButton, features, openFeatModal, recommendedTier,
    embeddedSize, isEmbedded,
   } = props;

  return (
    <Grid size={(isEmbedded ? embeddedSize : size) || {xs: 12, md: 6}} >
      <Card
        variant="outlined"
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: 2,
          boxShadow: (theme) => (tier === recommendedTier.current) ?
                                  `${theme.shadows[2]} !important` :
                                  `${theme.shadows[0]}`,
          backgroundColor: (theme) => (
            currentTier ?
              ((TIER_LEVEL_CONVERSION[tier] === TIER_LEVEL_CONVERSION[currentTier]) ?
                `${theme.palette.background["paper-elevation-6"]} !important` :
                `${theme.palette.background["paper-elevation-1"]} !important`)
            : `${theme.palette.background["paper-elevation-1"]} !important`
          )
        }}
      >
        <CardContent
          sx={{
            p: 3,
            bgcolor: 'transparent',
          }}
        >
          <Typography variant="h7">
            {name}
          </Typography>
          <Typography variant="body2"
            height={{xs: 53, lg: 64,}}
          >
            {description}
          </Typography>

          <Box
            display='flex'
            alignItems={'flex-end'}
            pt={{xs: 0.5, md: 1, lg: 3}}
            mb={1.25}
            gap={0.5}
          >
            <Typography variant="h4">
              {priceMain}
            </Typography>
            <Typography variant="caption" pb={0.25}>
              {priceMinor}
            </Typography>
          </Box>

          <Button
            variant={(
              recommendedTier.current === tier ||
              currentTier === tier) ? 'contained' : 'outlined'}
            size='large'
            fullWidth
            disabled={currentTier === tier}
            onClick={() => handlePurchaseButton(tier)}
            sx={{
              boxShadow: (theme) => `${theme.shadows[2]} !important`,
              textTransform: 'capitalize !important',
              mb: 3,
            }}
          >
            {currentTier ?
              ((TIER_LEVEL_CONVERSION[tier] < TIER_LEVEL_CONVERSION[currentTier]) ?
                `Downgrade to ${tier}` :
                ((TIER_LEVEL_CONVERSION[tier] === TIER_LEVEL_CONVERSION[currentTier]) ?
                  'My Current Plan' :
                  `Upgrade to ${tier}`))
              : `Choose ${tier}`}
          </Button>

          {Object.entries(features).map(([key,value], ind) => {
            return (
              <Box
                key={`${tier}-feat-${ind}`}
                display='flex'
                alignItems='center'
                sx={{
                  cursor: !!value?.length ? 'pointer' : 'auto',
                  mt: 0.5,
                }}
                onClick={() => {
                  if (value.length) {
                    openFeatModal(key, value)
                  }
                }}
              >
                <CheckIcon fontSize="small"/>
                <Typography variant='body2' sx={{ ml: 1 }}>
                  {`${key}`}
                </Typography>
                {
                  !!value.length &&
                    <InfoIcon
                      style={{
                        fill: 'gray',
                        marginLeft: 'auto',
                      }}
                    />
                }
              </Box>
            )
          })}
        </CardContent>
      </Card>
    </Grid>
  )
}

export default TiersCardItem;
