import { SVGProps, memo } from 'react';
const SoopraSmile = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width ||"56"} height={props.height || "41"} viewBox="0 0 56 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M53.7478 34.7531C44.7154 38.685 37.6152 40.1805 27.019 40.1805V33.9618C36.9001 33.9618 43.2698 32.3653 51.2329 28.8875C52.355 28.3974 53.6557 28.8994 54.18 30.0494L54.8842 31.5944C55.4327 32.7976 54.9223 34.2419 53.7478 34.7531Z" fill={props.color || "#424242"}/>
    <path d="M1.35695 34.7531C10.3894 38.685 17.4895 40.1805 28.0857 40.1805V33.9618C18.2046 33.9618 11.8349 32.3653 3.8718 28.8875C2.74968 28.3974 1.44903 28.8994 0.924755 30.0494L0.220474 31.5944C-0.328003 32.7976 0.182452 34.2419 1.35695 34.7531Z" fill={props.color || "#424242"}/>
    <path d="M13.987 27.1535C5.76868 27.1535 1.14826 21.7228 1.14826 13.5767C1.14826 4.90271 6.29889 0 13.987 0C22.2052 0 26.8257 5.43069 26.8257 13.5767C26.8257 22.2508 21.675 27.1535 13.987 27.1535ZM13.987 21.1194C18.0014 21.1194 20.2738 18.404 20.2738 13.5767C20.2738 8.74945 18.0014 6.03411 13.987 6.03411C9.9725 6.03411 7.70017 8.74945 7.70017 13.5767C7.70017 18.404 9.9725 21.1194 13.987 21.1194Z" fill={props.color || "#424242"}/>
    <path d="M41.3171 27.1535C33.0988 27.1535 28.4784 21.7228 28.4784 13.5767C28.4784 4.90271 33.629 0 41.3171 0C49.5354 0 54.1558 5.43069 54.1558 13.5767C54.1558 22.2508 49.0052 27.1535 41.3171 27.1535ZM41.3171 21.1194C45.3316 21.1194 47.6039 18.404 47.6039 13.5767C47.6039 8.74945 45.3316 6.03411 41.3171 6.03411C37.3027 6.03411 35.0303 8.74945 35.0303 13.5767C35.0303 18.404 37.3027 21.1194 41.3171 21.1194Z" fill={props.color || "#424242"}/>
  </svg>
)


export default memo(SoopraSmile);
