import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

interface LeftNavItemProps {
  val: {
    name: string;
    icon: any;
    link?: string;
    onDismount?: () => void;
    onMount?: () => void;
    isHidden?: boolean;
  };
  selected: boolean;
  onClickFunc: () => void;
  isOpen: boolean;
  isSmallItem?: boolean;
  setAnchorEl?: (val: any) => void;
}


const LeftNavItem: React.FC<LeftNavItemProps> = ({
  val,
  selected,
  onClickFunc,
  isOpen,
  isSmallItem,
  setAnchorEl
}) => {

  return (
    val.isHidden ?
      <ListItem
        key={val.name}
        sx={{
          display: 'none'
        }}
      /> :
      <ListItem
        key={val.name}
        disablePadding
        sx={[{
          display: 'block',
          backgroundColor: (theme) => {
            return selected ?
              "rgba(66, 66, 66, 0.08)" : // TO-DO: fix
              theme.palette.background.default
          },
          marginLeft: isSmallItem ? '0px' : '22px',
          marginRight: isSmallItem ? '0px' : '22px',
          borderRadius: (theme) => theme.shape.borderRadius,
          width: 'auto',
          overflow: 'hidden',
          mb: isSmallItem ? 0 : 2,
        },
        isSmallItem ? {
          mx: 1,
        } : {}
        ]}
        ref={val.name === "More" ? setAnchorEl : null}
      >
        <ListItemButton
          sx={[
            {
              minHeight: 48,
              px: isSmallItem ? 1 : 2.5,
              py: isSmallItem ? 0.25 : 0.625,
            },
            isOpen ? {justifyContent: 'initial'} : {justifyContent: 'center'},

          ]}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onClickFunc()
          }}
          href={val.link ? val.link : null}
        >
          <ListItemIcon
            sx={[
              {
                minWidth: 0,
                justifyContent: 'center',
                p: 1,
                borderRadius: 4.5,
                overflow: 'hidden',
              },
              isOpen ? {mr: isSmallItem ? 1 : 2} : {mr: 'auto'},
              selected ?
              {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.primary.contrastText,
                fill: (theme) => theme.palette.primary.contrastText,
                stroke: (theme) => theme.palette.primary.contrastText,
                border: (theme) => `1.8px solid ${theme.palette.primary.main}`
              } :
              {
                backgroundColor: (theme) => theme.palette.background.default,
                color: (theme) => theme.palette.primary.main,
                fill: (theme) => theme.palette.primary.main,
                stroke: (theme) => theme.palette.primary.main,
                border: (theme) => `1.8px solid ${theme.palette.divider}`
              },
              isSmallItem ? {
                border: 'none',
                '& > *': {
                  fontSize: 18,
                },
                stroke: 'none'
              } : {},
            ]}
          >
            {val.icon ? (
              isSmallItem ? val.icon :
                <Box
                  width={24}
                  height={22}
                  minWidth={24}
                  minHeight={22}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  {val.icon}
                </Box>
              ) : <></>}
          </ListItemIcon>
          <ListItemText
            primary={val.name}
            sx={[
              {
                transition: (theme) => `all ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`,
              },
              (isOpen || isSmallItem) ? {opacity: 1} : {opacity: 0, maxWidth: 0},
            ]}
            slotProps={{
              primary: {
                variant: isSmallItem ? 'body3' : (selected ? 'h8' : 'body2'),
                sx: [
                  (isSmallItem && selected) ? {
                    fontWeight: (theme) => theme.typography.fontWeightBold
                  } : {}]
              }
            }}
          />
        </ListItemButton>
      </ListItem>
  )
}

export default LeftNavItem;
