import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import { genericFormProps } from "../signup.types";
import { useState } from "react";
import useAnalyticsEventTracker from "../../../hooks/useAnalyticsEventTracker";
import { getIntroMessage } from "../../../utils/api";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const SuggestedQuestionsForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
  } = props;

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const eventTracker = useAnalyticsEventTracker();

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      gap={2}
    >
      <Typography variant='h6' mb={-1.5}>
        Suggested Questions
      </Typography>
      <Typography variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        These questions will be shown on your profile to help users get started interacting with your AI persona.
      </Typography>

      {
        (values.introMessage as {message: string, chips: string[]})?.chips.map((val, ind) => {
          return (
            <Box
              key={`suggested-question-${ind}`}
              display='flex'
              alignItems='center'
              gap={2}
            >
              <TextField
                multiline
                disabled={formSubmitting}
                name={`introMessage.chips.${ind}`}
                id={`text-input-suggested-question-${ind}`}
                label='Add a suggested question for your AI Persona'
                placeholder='Add a suggested question for your AI Persona'
                value={val || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!isGenerating && touched.introMessage && Boolean(errors.introMessage) && !val}
                helperText={
                  !isGenerating && touched.introMessage && !val &&
                  (errors.introMessage as unknown as {message: string, chips: string[]})?.chips
                }
                sx={{
                  flexGrow: 1,
                }}
                slotProps={{
                  inputLabel: {
                    shrink: !!val
                  }
                }}
              />
              <Button
                id={`button-delete-suggested-question-${ind}`}
                color='error'
                variant='contained'
                // sx={{ width: '30px', height: '30px' }}
                onClick={() => {
                  let questions = [...((values.introMessage as {message: string, chips: string[]}).chips || [])];
                  questions?.splice(ind, 1);
                  while (questions.length < 3) {
                    questions.push('')
                  }
                  setFieldValue('introMessage.chips', questions || null);
                }}
                sx={{
                  padding: '6px 10px !important',
                  minWidth: '40px !important',
                  boxShadow: (theme) => `${theme.shadows[2]} !important`,
                  '& span.MuiButton-startIcon': {
                    m: 0,
                  },
                }}
                startIcon={ <DeleteOutlinedIcon sx={{ fontSize: '21px' }} /> }
              />
            </Box>
          )
        })
      }
      <Button
        variant="outlined"
        onClick={async () => {
          let oldChips = (values.introMessage as {message: string, chips: string[]}).chips;
          setFieldValue('introMessage.chips', ['Generating new suggested questions...'])
          if (!isGenerating){
            setIsGenerating(true)
            getIntroMessage(values.documentId, true)
              .then((resp) => {
                  if (resp.status === 200){
                    eventTracker('generate-suggested-questions success')
                    return resp.json();
                  } else {
                    eventTracker('generate-suggested-questions failed')
                    setIsGenerating(false)
                  }
                }
              ).then((message: {chips: string[]}) => {
                let newChips = [...oldChips];
                newChips = newChips.filter((val) => !!val.length);
                for (let chip of message.chips) {
                  if (newChips.length < 5) {
                    newChips.push(chip)
                  }
                }
                setFieldValue('introMessage.chips', newChips || "")
                setIsGenerating(false)
              }).catch((err) => {
                setFieldValue('introMessage.chips', oldChips|| "")
                eventTracker('generate-intro failed')
                setIsGenerating(false)
              })
          }
        }}
        startIcon={isGenerating ? <CircularProgress size={20}/> : <AutoAwesomeIcon />}
        sx={{
          width: 'fit-content',
          boxShadow: (theme) => `${theme.shadows[2]} !important`,
        }}
        disabled={isGenerating || formSubmitting}
      >
        Generate Questions
      </Button>
    </Box>
  )
}

export default SuggestedQuestionsForm;
