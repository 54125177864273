

import { SVGProps, memo } from 'react';
const DocumentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447716 0 1 0H8.53953L13.8 6.74726V17.8C13.8 18.3523 13.3523 18.8 12.8 18.8H1C0.447715 18.8 0 18.3523 0 17.8V1ZM2.8 1.8C2.24772 1.8 1.8 2.24771 1.8 2.8V16C1.8 16.5523 2.24771 17 2.8 17H11C11.5523 17 12 16.5523 12 16V7.95664H7.7C7.42386 7.95664 7.2 7.73278 7.2 7.45664V1.8H2.8ZM9 3.51812L11.0571 6.15664H9.5C9.22386 6.15664 9 5.93278 9 5.65664V3.51812Z" fill={props?.color || "#424242"}/>
</svg>

);

export default memo(DocumentIcon)
