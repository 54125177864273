import { SVGProps, memo } from 'react';
const ExpertsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "25"} height={props.height || "19"} viewBox="0 0 25 19" fill={props.fill || "none"} stroke={props.stroke || "none"} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.37269 8.58713C10.9054 7.11149 10.9054 4.71902 9.37269 3.24338C7.84003 1.76774 5.35509 1.76774 3.82242 3.24338C2.28976 4.71902 2.28976 7.11149 3.82242 8.58713C5.35509 10.0628 7.84003 10.0628 9.37269 8.58713ZM10.6694 9.83557C12.9182 7.67044 12.9182 4.16007 10.6694 1.99494C8.42058 -0.170189 4.77454 -0.170189 2.52574 1.99494C0.276929 4.16007 0.276929 7.67044 2.52574 9.83557C4.77454 12.0007 8.42058 12.0007 10.6694 9.83557Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.41605 14.187C3.79142 14.187 1.74438 16.3132 1.84386 18.9359H0.0438569C-0.0932973 15.3198 2.72909 12.3883 6.34783 12.3883C9.96657 12.3883 13.0113 15.3198 13.1485 18.9359H11.3485C11.249 16.3132 9.04068 14.187 6.41605 14.187Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M21.0578 8.58713C22.5904 7.11149 22.5904 4.71902 21.0578 3.24338C19.5251 1.76774 17.0401 1.76774 15.5075 3.24338C13.9748 4.71902 13.9748 7.11149 15.5075 8.58713C17.0401 10.0628 19.5251 10.0628 21.0578 8.58713ZM22.3544 9.83557C24.6032 7.67044 24.6032 4.16007 22.3544 1.99494C20.1056 -0.170189 16.4596 -0.170189 14.2108 1.99494C11.962 4.16007 11.962 7.67044 14.2108 9.83557C16.4596 12.0007 20.1056 12.0007 22.3544 9.83557Z" fill={props.color || "#424242"}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.1011 14.187C15.4765 14.187 13.4294 16.3132 13.5289 18.9359H11.7289C11.5918 15.3198 14.4141 12.3883 18.0329 12.3883C21.6516 12.3883 24.6964 15.3198 24.8335 18.9359H23.0335C22.9341 16.3132 20.7257 14.187 18.1011 14.187Z" fill={props.color || "#424242"}/>
  </svg>
);

export default memo(ExpertsIcon)
