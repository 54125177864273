import { Dialog, Slide } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SignIn from "./signIn";
import ForgotPassword from "./forgotPassword";
import { useNavigate } from "react-router-dom";
import SetupAccount from "./setupAccount";
import AccountCreated from "./accountCreated";
import CreateAccount from "./createAccount";
import { useAuth } from "../../hooks/useAuth";

export type LoginFormType = 'signin' | 'signup' | 'forgotpassword' | 'setupaccount' | 'accountcreated';

interface TransitionProps {
  children: React.ReactElement<any, any>;
}
const Transition = React.forwardRef(function Transition(props: TransitionProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface LoginModalVisibleType {
  isOpen: boolean;
  formType: LoginFormType;
  currentStep?: number;
  redirectOptions?: {
    redirectURL?: string;
    redirectState?: {[key: string]: any};
  },
}

export interface LoginFormProps {
  setFormType: React.Dispatch<React.SetStateAction<LoginFormType>>;
  handleClose: (event?: any, reason?: any, shouldRedirect?: boolean) => void;
  setCurrentStep?: React.Dispatch<React.SetStateAction<number>>;
  currentStep?: number;
}

const LoginModal: React.FC = () => {
  const { loginModalVisible, setLoginModalVisible } = useAuth();
  const [ formType, setFormType ] = useState<LoginFormType>(loginModalVisible.formType);
  const [ redirectURL, setRedirectURL ] = useState<string>(null);
  const [ redirectState, setRedirectState ] = useState<Record<string, any>>(null);
  const [ currentStep, setCurrentStep ] = useState<number>(loginModalVisible.currentStep || 1);
  const navigate = useNavigate();

  useEffect(() => {
    if (formType !== loginModalVisible.formType) {
      setFormType(loginModalVisible.formType)
    }

    if (loginModalVisible.isOpen) {
      // store redirect information
      if (loginModalVisible.redirectOptions) {
        if (loginModalVisible.redirectOptions.redirectURL) {
          setRedirectURL(loginModalVisible.redirectOptions.redirectURL)
        }
        if (loginModalVisible.redirectOptions.redirectState) {
          setRedirectState(loginModalVisible.redirectOptions.redirectState)
        }
      }
    } else {
      // reset if closed
      setRedirectURL(null)
      setRedirectState(null)
    }
  }, [loginModalVisible]) //eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = useCallback((event: any, reason: any, shouldRedirect?: boolean) => {
    if (formType !== 'setupaccount' &&
      !(formType === 'signup' && currentStep === 3))
    {
      // don't let users exit without setting up userName
      setLoginModalVisible(info => {
        let copy = {...info};
        copy.isOpen = false;
        return copy;
      })

      if (shouldRedirect) {
        // redirect user as required
        if (redirectURL) {
          navigate(redirectURL, {state: redirectState});
        }
      }
    }
  }, [formType, currentStep, redirectURL, redirectState]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderSwitch = () => {
    switch (formType) {
      case 'forgotpassword':
        return <ForgotPassword setFormType={setFormType} handleClose={handleClose} />
      case 'signup':
        return <CreateAccount
          setFormType={setFormType}
          handleClose={handleClose}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      case 'setupaccount':
        return <SetupAccount setFormType={setFormType} handleClose={handleClose} />
      case 'accountcreated':
        return <AccountCreated setFormType={setFormType} handleClose={handleClose} />
      default :
        return <SignIn
          setFormType={setFormType}
          handleClose={handleClose}
          setCurrentStep={setCurrentStep}
        />
    }
  }

  return (
    <Dialog
      open={loginModalVisible.isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      // fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          borderRadius: {
            xs: 0,
            sm: 4,
          },
          maxWidth: {
            xs: '100%',
            sm: '425px',
          },
          m: 0,
          width: '100%',
          height: {
            xs: '100%',
            sm: 'fit-content',
          },
          minHeight: {
            xs: '100%',
            sm: 'auto',
          }
        },
      }}
    >
      { renderSwitch() }
    </Dialog>
  )
}

export default LoginModal;
