import { Box, Button, Modal, Backdrop, Typography, CircularProgress, TextField } from '@mui/material'
import { useContext, useState } from 'react'
import {JournalSaveContext} from '../../context/JournalSaveContext';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { handleSaveJournalChat } from '../../utils/api';
import { toast } from 'react-toastify';

const Journaling = () => {
  const [journalText, setJournalText] = useState('');
  const [savedChanges, setSavedChanges] = useState('');
  const [isSavingEntry, setSavingEntry] = useState(false);
  const {setSavedStatus, showSaveDialog, setShowSaveDialog} = useContext(JournalSaveContext)
  const placeholderText = 'Write or paste your journal entry here. This information will be used to train your AI Persona’s knowledge and personality.'
  const { authUser } = useAuth();
  const navigate = useNavigate();

  const handleJournalEntrySave = async (e) => {
    e.preventDefault();
    setSavingEntry(true);

    // Save function
    try {
      await postJournalEntry(journalText);
    }
    catch(error) {
      console.log(error)
    }
  }

  const postJournalEntry = async (message) => {
    try {
      let resp = await handleSaveJournalChat(authUser?.uid, message);
      if (resp) {
        setSavedChanges(journalText);
        setSavedStatus(true)
        toast.success('Saved journal entry successfully');
        setSavingEntry(false)
        navigate(-1)
      }
      else {
        toast.error('Unable to save journal entry');
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const handleJournalEntry = (e) => {
    setSavedStatus(false);
    setJournalText(e.target.value)
  }

  // Use for adding alertbox when route changes on Browser. (when back button next to url box of browser is clicked)

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (savedChanges !== journalText) {
  //       event.preventDefault();
  //       event.returnValue="";
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [savedChanges, journalText]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '330px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 4,
    maxHeight: '90vh',
  };

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={2}
      width={'100%'}
      height={'100%'}
    >
      <Typography variant='h6' mb={-1.5}>
        Journal
      </Typography>
      <Typography variant="body2"
        sx={{
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        Train your AI Persona's knowledge and personality with regular journal entries.
      </Typography>
      <TextField
        multiline
        minRows={8}
        placeholder={placeholderText}
        value={journalText}
        onChange={handleJournalEntry}
        sx={{
          width: '100%',
        }}
      />
      <Box
        className="journal-entry-save-btn"
        width={'100%'}
        display={'flex'}
        justifyContent={'right'}
      >
        <Button
          variant='contained'
          size='large'
          disabled = {savedChanges === journalText || isSavingEntry}
          onClick={handleJournalEntrySave}
        >
          {'Save Journal Entry'}
          {isSavingEntry && (
            <CircularProgress
              size={18}
              sx={{
                position: 'absolute',
              }}
            />)}
        </Button>
      </Box>
      <Modal
        open={showSaveDialog}
        slots={{ backdrop: Backdrop }}
        slotProps={{
            backdrop: {
            timeout: 500,
            },
        }}
      >
        <Box sx={style} display={'flex'} flexDirection={'column'} justifyContent={'center'} gap={'20px'}>
          <Box>

            <strong>Discard Journal Entry?</strong>
            <Typography variant='body2'>This entry will be lost and your AI Persona will not be updated.</Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            gap={1.25}
          >
            <Button
              variant='contained'
              sx={{
                borderRadius: '30px',
                height: '40px !important'
              }}
              onClick={() => setShowSaveDialog(false)}
            >
              Continue Editing
            </Button>
            <Button
              variant='outlined'
              onClick={() => {
                setShowSaveDialog(false)
                setSavedStatus(true)
                navigate(-1)
              }}
            >
              Discard
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default Journaling;
