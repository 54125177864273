import { Box, Typography } from "@mui/material";
import Slider from "react-slick";
import RonjonBackground from "../../assets/images/banner images/ronjon_background.png"
import RonjonSolo from "../../assets/images/banner images/ronjon_solo.png"
import PraveenaBackground from "../../assets/images/banner images/praveena_background.png"
import PraveenaSolo from "../../assets/images/banner images/praveena_solo.png"
import FrankBackground from "../../assets/images/banner images/frank_background.png"
import FrankSolo from "../../assets/images/banner images/frank_solo.png"
import MichaelBackground from "../../assets/images/banner images/michael_background.png"
import MichaelSolo from "../../assets/images/banner images/michael_solo.png"
import BannerItem from "./bannerItem";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const PrevArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <ArrowBackIosIcon
      className={className}
      onClick={onClick}
      style={{
        color: 'white',
        zIndex: 15,
        position: 'absolute',
        left: 16,
      }}
    />
  );
};

const NextArrow = (props: any) => {
  const { className, onClick } = props;
  return (
    <ArrowForwardIosIcon
      className={className}
      onClick={onClick}
      style={{
        color: 'white',
        zIndex: 15,
        position: 'absolute',
        right: 16,
      }}
    />
  );
};

const bannerSettings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        centerPadding: "0px",
        arrows: true,
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
      };

const isStaging = () => {
  return window.location.hostname.includes('staging') ||
    window.location.hostname.includes('localhost')
}

const BannerItems = [
  {
    name: 'ronjon',
    mainText: 'Everything You Want to Know About AI',
    subText: 'Dr. Ronjon Nag',
    link: isStaging() ? '/ronjon/chat' : '/ronjon/chat',
    backgroundImage: RonjonBackground,
    mainImage: RonjonSolo,
  },
  {
    name: 'praveena',
    mainText: 'AI Development: Leveraging Technology to Enhance Learning',
    subText: 'Praveena Dhanalakota',
    link: isStaging() ? '/Praveenad/chat' : '/praveena.dhanalakota/chat',
    backgroundImage: PraveenaBackground,
    mainImage: PraveenaSolo,
  },
  {
    name: 'frank',
    mainText: 'Investment Questions Answered!',
    subText: 'Frank Sortino',
    link: isStaging() ? '/srinivasjaini/chat' : '/sortino-chat/chat',
    backgroundImage: FrankBackground,
    mainImage: FrankSolo,
  },
  {
    name: 'snyder',
    mainText: 'The Big Data Approach',
    subText: 'Dr. Michael Snyder',
    link: isStaging() ? '/Graydon/chat' : '/mpsnyder@stanford.edu/chat',
    backgroundImage: MichaelBackground,
    mainImage: MichaelSolo,
  },
];

const Banner: React.FC = () => {

  return (
    <Box
      display='flex'
      flexDirection='column'
      width='100%'
    >
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        sx={{
          padding: {
            md: 0,
            xs: 2,
          },
          py: {
            md: 3,
          }
        }}
        gap={0.5}
      >
        <Typography variant="h6">
          Chat with Expert AI Personas
        </Typography>
        <Typography variant="body2">
        Their knowledge is at your fingertips! Chat with AI Personas of top-tier experts about their expertise.
        </Typography>
      </Box>

      <Slider {...bannerSettings}>
        {
          BannerItems.map((item) =>
            <BannerItem
              key={item.name}
              item={item}
            />
          )
        }
      </Slider>

    </Box>
  )
}

export default Banner;
