import { Grid2 as Grid } from "@mui/material"
import SubscriptionAnalytics from "./SubscriptionAnalytics"
import EditCustomPriceCard from "./editCustomPriceCard";

interface RevenueProps {

}

const Revenue: React.FC<RevenueProps> = () => {
  const now = new Date();

  return (
    <Grid container spacing={3} p={{xs: 2, md: 3}}>
      <Grid size={{xs: 12, md: 9}} >
        <EditCustomPriceCard />
      </Grid>

      <Grid size={{xs: 12, md: 3}} >
        <SubscriptionAnalytics month={now.getMonth()+1} year={now.getFullYear()}/>
      </Grid>
    </Grid>
  )
}

export default Revenue
