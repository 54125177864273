import { Box, Button, Divider, IconButton, Typography } from "@mui/material"
import { genericFormProps } from "../signup.types";
import { verifyFileSize } from "../helper";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import GoogleIcon from '@mui/icons-material/Google';
import InsertDriveFile from '@mui/icons-material/InsertDriveFile';
import { useCallback } from "react";
import { useAuth } from "../../../hooks/useAuth";


const DocumentsForm: React.FC<genericFormProps> = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    formSubmitting,
    isValid,
    dirty,
    showSteps
  } = props;

  const { authUser } = useAuth();

  const renderFileListItem = useCallback(
    (document: any, index: number, onDeleteHandler: () => void) => (
      <Box
        key={`${document.name}-${index}`}
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        columnGap='12px'
        sx={{
          borderBottom: 1,
          borderColor: (theme) => theme.palette.divider,
          py: 1.5,
        }}
      >
        <Box display='flex'>
          {
            document?.source === 'google_drive' ?
              <GoogleIcon sx={{ mr: 2, fontSize: '20px' }} /> :
              <InsertDriveFile sx={{ mr: 2, fontSize: '20px' }} />
          }
          <Typography
            align='left'
            variant='body2'
            sx={{
              wordBreak: 'break-word',
              ...(document &&
                typeof document === 'object' &&
                document.hasOwnProperty('is_indexed')
                ? !document.is_indexed
                  ? {
                    '&:hover::before': {
                      content: '"This document is not indexed."',
                      display: 'block',
                      position: 'absolute',
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '4px',
                      borderRadius: '8px',
                      zIndex: 1,
                    },
                  }
                  : {
                    // No hover effect and error message when is_indexed property exists and is true
                  }
                : // If its a new file, it dosent have is_indexed attribute so the hover msg says : Ready for upload
                document.hasOwnProperty('is_indexed')
                  ? {}
                  : {
                    '&:hover::before': {
                      //content:
                      //  '"This document is ready for upload. Click Save button below"',
                      display: 'block',
                      position: 'absolute',
                      backgroundColor: 'purple',
                      color: 'white',
                      padding: '4px',
                      borderRadius: '4px',
                      zIndex: 1,
                    },
                  }),
              color:
                document &&
                  typeof document === 'object' &&
                  document.hasOwnProperty('is_indexed') &&
                  !document.is_indexed
                  ? 'red' // Set the text color to red if document.is_indexed is False
                  : 'inherit', // Use the default text color otherwise
            }}
          >
            {document && typeof document === 'object' && document?.name
              ? document.name
              : document || ''}
          </Typography>
        </Box>
        <IconButton
          id={`button-delete-${document.name}-${index}`}
          color='error'
          size='small'
          sx={{ width: '30px', height: '30px' }}
          onClick={onDeleteHandler}
        >
          <DeleteIcon
            sx={{
              color: 'error.light',
              fontSize: '18px',
            }}
          />
        </IconButton>
      </Box>
    ),
    []
  );

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        width='100%'
        gap={2}
      >
        <Typography variant='h6' mb={-1.5}>
          {(showSteps ? '1. ': '') + 'Upload a Document'}
        </Typography>
        <Typography variant="body2"
          sx={{
            color: (theme) => theme.palette.text.secondary,
          }}
        >
          Upload articles, blogs, scripts, or any other content you've created to give a wider lens into your creativity.
        </Typography>
        <Button
          variant="contained"
          size='large'
          component='label'
          fullWidth={false}
          sx={{
            width: 'fit-content',
            boxShadow: (theme) => `${theme.shadows[2]} !important`,
          }}
        >
          Upload Document
          <input
            type='file'
            hidden
            multiple
            id='file-input-userDocument'
            name='userDocument'
            onChange={(event) => {
              const files = event?.target?.files || [];
              let newDocuments = [...values.userDocument];
              for (let i = 0; i < files?.length; i++) {
                const fileSize = Math.round(files[i]?.size / 1024);
                //Making sure that the document is not empty
                if (!verifyFileSize(fileSize, 1024000)) {
                  return;
                }
                //Making sure file extension is one among 'txt', 'doc', 'docx', 'pdf', and 'pptx'
                const allowedTypes = [
                  'txt',
                  'docx',
                  'pdf',
                  'pptx',
                ];
                if (!allowedTypes.includes(files[i]?.name.split('.').pop())) {
                  toast.error(
                    'The file type selected is not supported. The only supported file types are: txt, docx, pdf, and pptx'
                  );
                  return;
                }
                // check the file name exists in already uploaded files
                const allFiles = values?.userDocument?.concat(
                  values?.existingUserDocument || []
                );
                const fileExistsIndex = allFiles?.findIndex(
                  (fileItem: any) =>
                    fileItem?.name.trim() === files[i]?.name?.trim()
                );
                // if exists show the file name error
                if (fileExistsIndex !== -1) {
                  toast.error('The file name already exists');
                  return;
                }
                newDocuments.push(files[i]);
              }
              if (newDocuments.length !== values.userDocument.length) {
                setFieldValue('userDocument', newDocuments);
              }
            }}
          />
        </Button>
      </Box>

      {
        (!!(values?.existingUserDocument || []).length ||
        !!(values?.userDocument || []).length) &&
        <Box
          display='flex'
          flexDirection='column'
          width='100%'
        >
          <Typography variant='h7' mb={1.5}>
            My Documents
          </Typography>
          <Divider />
          {
            (values?.existingUserDocument || []).map((document: any, index: number) =>
              renderFileListItem(document, index, () => {
                let existing = [...values.existingUserDocument];
                existing?.splice(index, 1);
                setFieldValue(
                  'existingUserDocument',
                  existing
                );
                let deleted = [...values.deletedUserDocument]
                deleted?.push(document);
                setFieldValue(
                  'deletedUserDocument',
                  deleted
                );
              })
            )
          }
          {
            (values?.userDocument || []).map((document: any, index: number) =>
              renderFileListItem(document, index, () => {
                let docs = [...values.userDocument];
                docs?.splice(index, 1);
                setFieldValue('userDocument', docs || null);
              })
            )
          }
        </Box>
      }
    </>
  )
}

export default DocumentsForm;
