import { SVGProps, memo } from 'react';
const SoopraLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={props.width || "154"} height={props.height || "41"} viewBox="0 0 154 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M82.1977 26.7765C80.9858 26.7765 80.3041 26.0976 80.3041 24.8908V2.26291C80.3041 1.05609 80.9858 0.377253 82.1977 0.377253H91.022C97.9526 0.377253 101.853 3.6583 101.853 9.73012C101.853 15.4625 97.9526 19.083 91.022 19.083H88.636C87.4241 19.083 86.7424 19.7618 86.7424 20.9686V24.8908C86.7424 26.0976 86.0607 26.7765 84.8488 26.7765H82.1977ZM86.7424 11.1632C86.7424 12.37 87.4241 13.0489 88.636 13.0489H91.2113C93.9003 13.0489 95.4152 11.7289 95.4152 9.73012C95.4152 7.58047 93.9381 6.41136 91.2871 6.41136H88.636C87.4241 6.41136 86.7424 7.0902 86.7424 8.29702V11.1632Z" fill={props.color || "#424242"}/>
    <path d="M105.971 26.7765C104.759 26.7765 104.077 26.0976 104.077 24.8908V2.26291C104.077 1.05609 104.759 0.377253 105.971 0.377253H115.174C121.991 0.377253 125.816 3.62059 125.816 9.35298C125.816 12.1438 124.642 14.6328 122.824 15.9905C121.953 16.6693 121.915 17.3105 122.37 17.9139C123.089 18.8567 123.771 19.9127 124.225 20.8932C124.642 21.7606 125.134 23.1183 125.589 24.6645C126.005 26.0222 125.437 26.7765 123.96 26.7765H120.893C119.908 26.7765 119.188 26.3239 118.923 25.5319C118.166 23.3069 117.863 22.4772 117.825 22.3263C117.181 19.7995 115.704 18.5173 114.303 18.5173H112.409C111.197 18.5173 110.516 19.1961 110.516 20.4029V24.8908C110.516 26.0976 109.834 26.7765 108.622 26.7765H105.971ZM110.516 10.7861C110.516 11.9929 111.197 12.6717 112.409 12.6717H115.325C117.863 12.6717 119.264 11.5026 119.264 9.46612C119.264 7.50504 117.863 6.41136 115.401 6.41136H112.409C111.197 6.41136 110.516 7.0902 110.516 8.29702V10.7861Z" fill={props.color || "#424242"}/>
    <path d="M128.988 26.7765C127.398 26.7765 126.905 26.0976 127.473 24.6268L135.919 1.88578C136.26 0.867524 136.979 0.377253 138.077 0.377253H143.304C144.402 0.377253 145.122 0.867524 145.463 1.88578L153.795 24.6268C154.325 26.0976 153.833 26.7765 152.242 26.7765H149.364C148.228 26.7765 147.546 26.2862 147.205 25.2302L146.712 23.8348C146.372 22.7789 145.69 22.2886 144.554 22.2886H136.676C135.578 22.2886 134.858 22.7789 134.518 23.7971L133.987 25.2679C133.646 26.2862 132.927 26.7765 131.829 26.7765H128.988ZM137.737 14.6705C137.282 15.9151 137.926 16.8202 139.289 16.8202H142.054C143.417 16.8202 144.023 15.9151 143.569 14.6705L141.903 9.99411C141.297 8.33473 140.047 8.33473 139.441 9.95639L137.737 14.6705Z" fill={props.color || "#424242"}/>
    <path d="M5.73405 19.2714C6.7566 19.2714 7.1732 19.724 7.51405 19.988C8.61234 20.8177 10.203 21.3079 11.9451 21.3079C14.7476 21.3079 16.3383 20.516 16.3383 19.1206C16.3383 17.9137 15.1264 17.1972 12.5132 16.7824L9.67276 16.2921C3.68894 15.2738 1.03788 12.4831 1.03788 8.25918C1.03788 3.31876 5.31745 0 11.7557 0C16.8306 0 20.9966 2.48907 22.1327 6.18496C22.4357 7.35406 21.7919 8.07061 20.7693 8.07061H17.6259C16.6034 8.07061 16.1868 7.58034 15.8081 7.27864C14.7477 6.37352 13.2328 5.84554 11.5664 5.84554C9.02893 5.84554 7.58979 6.63751 7.58979 8.0329C7.58979 9.23972 8.72596 9.99399 11.1119 10.3334L14.1417 10.8991C20.0876 11.9551 22.8902 14.4819 22.8902 18.7812C22.8902 23.797 18.4591 27.1535 11.8315 27.1535C6.52936 27.1535 2.17405 24.7775 1.00001 21.1948C0.659159 20.0257 1.34087 19.2714 2.32554 19.2714H5.73405Z" fill={props.color || "#424242"}/>
    <path d="M77.2234 35.1253C68.191 39.0572 61.0909 40.5527 50.4946 40.5527V34.3339C60.3758 34.3339 66.7455 32.7375 74.7085 29.2597C75.8307 28.7696 77.1313 29.2715 77.6556 30.4216L78.3599 31.9666C78.9083 33.1697 78.3979 34.614 77.2234 35.1253Z" fill={props.color || "#424242"}/>
    <path d="M24.8326 35.1253C33.865 39.0572 40.9651 40.5527 51.5614 40.5527V34.3339C41.6802 34.3339 35.3105 32.7375 27.3474 29.2597C26.2253 28.7696 24.9247 29.2715 24.4004 30.4216L23.6961 31.9666C23.1476 33.1697 23.6581 34.614 24.8326 35.1253Z" fill={props.color || "#424242"}/>
    <path d="M37.4626 27.5256C29.2443 27.5256 24.6239 22.0949 24.6239 13.9489C24.6239 5.27486 29.7745 0.372149 37.4626 0.372149C45.6809 0.372149 50.3013 5.80284 50.3013 13.9489C50.3013 22.6229 45.1507 27.5256 37.4626 27.5256ZM37.4626 21.4915C41.4771 21.4915 43.7494 18.7762 43.7494 13.9489C43.7494 9.1216 41.4771 6.40625 37.4626 6.40625C33.4481 6.40625 31.1758 9.1216 31.1758 13.9489C31.1758 18.7762 33.4481 21.4915 37.4626 21.4915Z" fill={props.color || "#424242"}/>
    <path d="M64.7928 27.5256C56.5745 27.5256 51.9541 22.0949 51.9541 13.9489C51.9541 5.27486 57.1047 0.372149 64.7928 0.372149C73.011 0.372149 77.6315 5.80284 77.6315 13.9489C77.6315 22.6229 72.4808 27.5256 64.7928 27.5256ZM64.7928 21.4915C68.8072 21.4915 71.0796 18.7762 71.0796 13.9489C71.0796 9.1216 68.8072 6.40625 64.7928 6.40625C60.7783 6.40625 58.506 9.1216 58.506 13.9489C58.506 18.7762 60.7783 21.4915 64.7928 21.4915Z" fill={props.color || "#424242"}/>
  </svg>

);

export default memo(SoopraLogo)
