import { FieldValue } from 'firebase/firestore';
import { UserStreaksRequest } from '../userStreaks/userStreaks.types';
import { FormikProps } from 'formik';
import * as Yup from 'yup';

export interface UserSignUpFormType {
  email: string;
  password: string;
}

export interface UserCreateProfileFormType {
  documentId: string;
  fullName: string;
  userName?: string;
  profilePhoto?: any;
  profilePhotoPath?: string;
  profilePicUrl?: string;
  createdAt?: any;
  updatedAt?: any;
}

interface CommonUserType {
  id?: string;
  documentId?: string;
  email: string;
  password: string;
  userType: string;
  createdAt?: FieldValue | string;
  updatedAt?: FieldValue | string;
  userName: string;
  normalizedUserName?: string;
  topStreaksData?: UserStreaksRequest[];
  allStreaksData?: UserStreaksRequest[];
}

export const extendedKeys: Array<keyof UserFormType> = [
  'userDocument',
  'twitterURL',
  'linkedInURL',
  'instagramURL',
  'youtubeURL',
  'tiktokURL',
  'userWebPage',
  'youtube_links',
  'profilePhotoURL',
  'index',
  'age',
  'occupation',
  'gender',
  'birthPlace',
  'residencePlace',
  'voiceSample',
  'introMessage',
]

export interface UserFormType extends CommonUserType {
  // Organized by form:
  // Profile:
  profilePhoto: any;
  profilePhotoPath?: string;
  profilePicUrl?: string;
  profilePhotoURL?: string;
  existingProfilePhoto?: string;
  existingProfilePicUrl?: string;
  fullName: string;
  normalizedFullName: string;
  occupation?: string;
  expertise?: string;
  shortBio: string;
  // suggested questions
  twitterURL: string;
  linkedInURL: string;
  instagramURL: string;
  youtubeURL: string;
  tiktokURL: string;

  // Documents:
  userDocument: any[];
  existingUserDocument?: any[];
  deletedUserDocument?: any[];
  google_drive_links: {url: string}[];

  // Web Links:
  userWebPage: { web_link: string; disabled: boolean; is_indexed?: boolean }[];

  // Social Media Training:
  // x training
  youtube_links?: { url: string }[];

  // Voice Sample
  voiceSample?: any;
  voiceSamplePath?: string;
  existingVoiceSample?: string;
  voiceId?: string;

  // Introduction
  introMessage?: string | {message: string, chips: string[]};

  // Generic
  age: string;
  gender?: string;
  birthPlace?: string;
  residencePlace?: string;

  // Generated data
  documentPath?: any[];
  shareLink?: string;
  index?: string;
  total_fans?: number;
  total_messages?: number;

  // Immutable keys
  credits?: any[];
  customerId?: string;
  isSubscribed?: boolean;
  subscription?: any;
  isMeethSubscribed?:boolean;

  // // Outdated - phased out
  anythingElse: string;
  facebookAuthorised?: boolean;
  bannerPhoto?: any;
  bannerPhotoPath?: string;
  bannerImagePath?: string;
  bannerPhotoUrl?: string;
  existingBannerPhoto?: string;
  existingBannerUrl?: string;
}

export type genericFormProps = FormikProps<UserFormType> &
                              {
                                formSubmitting?: boolean;
                                validationSchema?: Yup.ObjectSchema<{[x: string]: any;}, Yup.AnyObject, {[x:string]: any;}, "">;
                                showSteps?: boolean;
                              };

export const IMMUTABLE_USER_KEYS = [
  'subscription',
  'chat_usage',
  'isSubscribed',
  'anonymous_chat_limit',
  'allow_widget',
  'whitelist_websites',
]

export interface FollowerUserType extends CommonUserType {
  creators?: string[];
  fullName?: string;
  existingProfilePhoto?: string;
  existingProfilePicUrl?: string;
  profilePhoto?: any;
}

export type FormMode = 'create' | 'update' | 'profileComplete';
