



import { SVGProps, memo } from 'react';
const PricecardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.42105 1.47273V16.5273H13.5789V1.47273H1.42105ZM0.789474 0C0.353459 0 0 0.366313 0 0.818182V17.1818C0 17.6337 0.353459 18 0.789473 18H14.2105C14.6465 18 15 17.6337 15 17.1818V0.818182C15 0.366313 14.6465 0 14.2105 0H0.789474Z" fill={props?.color || "#424242"}/>
<path d="M7.29927 14.1322V3.71901H7.94296V14.1322H7.29927ZM9.00662 7.15619C8.97522 6.82806 8.84046 6.57315 8.60235 6.39146C8.36423 6.20977 8.04108 6.11893 7.63289 6.11893C7.35552 6.11893 7.12134 6.15961 6.93032 6.24096C6.73931 6.3196 6.59278 6.42943 6.49073 6.57044C6.3913 6.71145 6.34158 6.87145 6.34158 7.05043C6.33635 7.19957 6.36644 7.32974 6.43186 7.44092C6.49989 7.5521 6.59278 7.64837 6.71053 7.72973C6.82827 7.80837 6.96434 7.87752 7.11872 7.93718C7.2731 7.99412 7.43795 8.04294 7.61326 8.08361L8.33545 8.26259C8.68608 8.34394 9.00793 8.45241 9.30099 8.588C9.59405 8.72359 9.84786 8.89037 10.0624 9.08833C10.277 9.28629 10.4431 9.5195 10.5609 9.78796C10.6813 10.0564 10.7427 10.3642 10.7454 10.7113C10.7427 11.2211 10.6172 11.6632 10.3686 12.0374C10.1226 12.4089 9.76675 12.6977 9.30099 12.9038C8.83784 13.1072 8.27919 13.2089 7.62504 13.2089C6.97611 13.2089 6.41092 13.1058 5.92946 12.8997C5.45062 12.6936 5.07644 12.3886 4.80693 11.9845C4.54003 11.5777 4.40004 11.0747 4.38696 10.4754H6.03151C6.04983 10.7547 6.12702 10.9879 6.26308 11.175C6.40176 11.3594 6.58624 11.4991 6.8165 11.594C7.04938 11.6862 7.31235 11.7323 7.60541 11.7323C7.89324 11.7323 8.14313 11.6889 8.35508 11.6021C8.56964 11.5154 8.7358 11.3947 8.85354 11.2401C8.97129 11.0856 9.03017 10.9079 9.03017 10.7073C9.03017 10.5201 8.97653 10.3629 8.86924 10.2354C8.76458 10.108 8.6102 9.99948 8.4061 9.91C8.20462 9.82051 7.95735 9.73915 7.66429 9.66594L6.78903 9.43815C6.11132 9.2673 5.57622 9.00019 5.18372 8.63682C4.79123 8.27344 4.59629 7.78396 4.59891 7.16839C4.59629 6.664 4.72581 6.22333 4.98748 5.8464C5.25176 5.46946 5.61416 5.17523 6.07469 4.96371C6.53521 4.75219 7.05854 4.64644 7.64466 4.64644C8.24125 4.64644 8.76196 4.75219 9.20679 4.96371C9.65423 5.17523 10.0022 5.46946 10.2508 5.8464C10.4994 6.22333 10.6276 6.65993 10.6355 7.15619H9.00662Z" fill={props?.color || "#424242"}/>
</svg>

);

export default memo(PricecardIcon)
