import { Avatar, Box, IconButton, Link, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from "../../svg/SearchIcon";
import SoopraLogo from "../../svg/SoopraLogo";
import { useProfileData } from "../../hooks/useProfileData";
import HamburgerMenu from "./hamburgerMenu";
import { useContext } from "react";
import { NavContext } from "../../context/NavContext";

const routesWithoutBackButton = [
  '/',
  '/creators',
  '/courses',
];

const titles = {
  'courses': 'Courses',
  'update': 'Edit Profile',
  'update/revenue': 'Price Card',
  'dashboard': 'Dashboard',
  'update/documents': 'My Documents',
  'update/journaling': 'Journal',
  'update/weblinks': 'Weblinks',
  'update/social': 'Social Media',
  'update/voice': 'Voice Sample',
  'update/introduction': 'Introduction',
  'update/personality': 'Personality',
}

const TopNavBarMobile: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { authUser, setLoginModalVisible } = useAuth();
  const theme = useTheme();
  const {
    setMountedComponent,
    setHandleContactModal
  } = useContext(NavContext);

  const { creatorProfileData } = useProfileData();

  const getTitle = () => {
    if (authUser?.userName) {
      let title = titles[location.pathname.split(`/${authUser.userName}/`).join('')];
      return title;
    }
    return "";
  }

  return (
    <Box
      sx={{
        display: { xs: 'flex', md: 'none' },
        boxShadow: 'none',
        borderBottom: 1,
        borderColor: theme => theme.palette.divider,
        zIndex: 500,
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '50px',
        minHeight: '50px',
        px: 2.5,
      }}
    >
      {
        (routesWithoutBackButton.includes(location.pathname)) ?
        <IconButton
          id='button-search'
          onClick={() => {
            setMountedComponent({ name: 'Search' })
          }}
          sx={[
            (location.pathname.includes('/chat') && {
              visibility: 'hidden',
            })
          ]}
        >
          <SearchIcon
            color={theme.palette.primary.main}
            fill={theme.palette.primary.main}
          />
        </IconButton> :
        <Box>
          <IconButton
            id='button-back'
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIcon
              sx={{
                height: '24px',
                width: '24px',
              }}
            />
          </IconButton>
        </Box>
      }

      {
        (!!getTitle()) ?
          <Typography variant="h7">
            {getTitle()}
          </Typography> :
          location.pathname.includes('/chat') ?
            <Link
              href={`/${creatorProfileData?.userName || ''}`}
              display='flex'
              alignItems='center'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate(`/${creatorProfileData?.userName || ''}`)
              }}
              gap={1.5}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Avatar
                src={creatorProfileData?.profilePhotoURL}
                alt={creatorProfileData?.fullName}
                sx={{ width: 40, height: 40, fontSize: '0.75rem' }}
              >
                {creatorProfileData?.fullName ? creatorProfileData?.fullName[0] : ''}
              </Avatar>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                mt={0.5}
              >
                <Typography variant="h7" sx={{verticalAlign: 'middle'}}>
                  {creatorProfileData?.fullName || 'hi'}
                </Typography>
                <Typography
                  variant="body3"
                  sx={{
                    color: theme.palette.text.secondary,
                    verticalAlign: 'middle'
                  }}
                >
                  {`@${creatorProfileData?.userName}`}
                </Typography>
              </Box>
            </Link> :
            <Link
              href="/"
              display='flex'
              alignItems='center'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate('/')
              }}
            >
              <SoopraLogo
                color={theme.palette.primary.main}
                width='101px'
                height='26px'
              />
            </Link>
      }

      <Box>
        <HamburgerMenu
          onContactClick={() => {
            setHandleContactModal(true);
          }}
          setLoginModalVisible={setLoginModalVisible}
        />
      </Box>
    </Box>
  )
}

export default TopNavBarMobile;
