import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Box, Button, Card, CardActions, CardContent, Dialog, DialogContent, Grid2 as Grid, IconButton, Link, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import StripeCheckoutForm from '../StripeCheckoutForm';
import { useEffect, useRef, useState } from "react";
import { CONTACT_US_MAIL, PRICES, TIER_LEVEL_CONVERSION } from "../../config/const";
import { ACADEMIC_FEATURES, ALL_TIERS, ENTERPRISE_FEATURES, FREE_FEATURES, PREMIUM_FEATURES, STANDARD_FEATURES, ULTIMATE_FEATURES, UPGRADE_FEATURE, UPGRADE_TEXT } from "./priceDescriptions";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Timestamp } from "firebase/firestore";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import useAnalyticsEventTracker from "../../hooks/useAnalyticsEventTracker";
import TiersCardItem from "./TiersCardItem";

interface TiersCardProps {
  upgradeFeature?: UPGRADE_FEATURE;
  setUpgradeFeature?: React.Dispatch<React.SetStateAction<string>>;
  isEmbedded?: boolean;
}

const TiersCard: React.FC<TiersCardProps> = ({upgradeFeature, setUpgradeFeature, isEmbedded}) => {
  // const [ variant, ] = useLocalStorage('variant', (Math.random() < 0.5 ? 'a' : 'b'))
  // const [ variantPrice, setVariantPrice ] = useState(PRICES)
  const [ stripeItem, setStripeItem ] = useState(null);
  const [ stripeOpen, setStripeOpen ] = useState(false);
  const [ trialPeriod, setTrialPeriod ] = useState(0);
  const [ featModalData, setFeatModalData ] = useState(null);
  const [ featModalOpen, setFeatModalOpen ] = useState<boolean>(false);
  const [ currentTier, setCurrentTier ] = useState<string>(null);

  const recommendedTier = useRef<string>('premium')

  const navigate = useNavigate();
  const { authUser } = useAuth();
  const eventTracker = useAnalyticsEventTracker();
  const location = useLocation();

  const variantPrice = PRICES;
  const variant = 'c';

  useEffect(() => {
    // reset to start
    recommendedTier.current = 'premium';
    setCurrentTier(null)

    if (authUser?.subscription?.pricing_plan) {
      let currentTimeStamp = Timestamp.fromDate(new Date());
      let current_plan = authUser.subscription.pricing_plan;
      if (current_plan.expiration_date > currentTimeStamp) {
        if (current_plan.tier === 100 ) {
          recommendedTier.current = 'premium';
          setCurrentTier('free')
        } else if (current_plan.tier === 1000 ) {
          recommendedTier.current = 'premium';
          setCurrentTier('standard')
        } else if (current_plan.tier === 2000) {
          recommendedTier.current = 'ultimate';
          setCurrentTier('premium')
        } else if (current_plan.tier === 3000) {
          recommendedTier.current = null;
          setCurrentTier('ultimate')
        } else if (current_plan.tier === 3100) {
          recommendedTier.current = null;
          setCurrentTier('enterprise')
        } else if (current_plan.tier === 3200) {
          recommendedTier.current = null;
          setCurrentTier('academic')
        }
      }
    }
  }, [authUser])

  const handlePurchaseButton = (tier: string) => {
    if (tier !== currentTier) {
      setStripeItem(`tier_${tier}_${variant}`)
      // if (!currentTier) {
      //   if (tier === 'free') {
      //     setTrialPeriod(0)
      //   } else if (tier === 'standard') {
      //     setTrialPeriod(7)
      //   } else {
      //     setTrialPeriod(15)
      //   }
      // } else {
      //   setTrialPeriod(0)
      // }
      eventTracker('tier-card start-purchase', {
        variant: variant,
        tier: tier,
        currentTier: currentTier,
        // trialPeriod: !currentTier
      })
      setStripeOpen(true)
    }
  }

  const openFeatModal = (title: string, description: string | any[]) => {
    setFeatModalData({
      'title': title,
      'description': description,
    })
    eventTracker('tier-card open-feat-description', {
      variant: variant,
      title: title,
    })
    setFeatModalOpen(true)
  }

  const FeatureDialog = () => {
    return (
      <Dialog
        open={featModalOpen}
        onClose={() => setFeatModalOpen(false)}
        fullWidth
        maxWidth='md'
      >
        <DialogContent
          sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <IconButton
            id='button-close-tiers-feat-info'
            onClick={() => setFeatModalOpen(false)}
            // color='primary'
            sx={{
              position: 'absolute',
              alignSelf: 'flex-end',
              minWidth: '36px',
              minHeight: '36px',
              height: '36px',
              width: '36px',
              top: '16px',
              right: '16px',
            }}
          >
            <CloseIcon
              height='30px'
              width='30px'
            />
          </IconButton>
          <Typography variant='h6'>
            {featModalData?.title}
          </Typography>
          {
            (featModalData?.description || []).map((entry, ind) => {
              if (typeof entry === 'object') {
                return (
                  <Link
                    href={entry.link}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(entry.link);
                    }}
                  >
                    <Typography
                      key={`${featModalData?.title}-${ind}`}
                      variant='body2'
                      sx={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {entry.description}
                    </Typography>
                  </Link>
                )
              } else {
                return (
                  <Typography
                    key={`${featModalData?.title}-${ind}`}
                    variant='body2'
                  >
                    {entry}
                  </Typography>
                )
              }
            })
          }
        </DialogContent>
      </Dialog>
    )
  }

  const UpgradeTierCard = () => {
    if (!UPGRADE_TEXT[upgradeFeature]) {
      return <></>
    }
    let { title, description, bold, tier } = UPGRADE_TEXT[upgradeFeature]
    let upgradeFeatures: {[key: string]: any[]} = {};

    let boldArr = [];
    if (typeof bold === 'object') boldArr = bold;
    else boldArr = [bold];

    switch (tier){
      case ('Standard'):
        upgradeFeatures = STANDARD_FEATURES;
        break;
      case ('Premium'):
        upgradeFeatures = PREMIUM_FEATURES;
        break;
      case ('Ultimate'):
        upgradeFeatures = ULTIMATE_FEATURES;
        break;
    }

    return (
      <Grid container
        spacing={{xs: 3 }}
        display='flex'
        flexDirection={{xs: 'column-reverse', md: 'row'}}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid size={{xs: 12, md: 6}} >
          <Card
            sx={{
              height: {xs: 'auto', md: 'calc(100% - 40px)'},
              boxShadow: 'none',
              backgroundColor: (theme) => theme.palette.background["paper-elevation-10"],
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <CardContent
              sx={{
                m: {xs: 1, md: 2},
              }}
            >
              <Box display='flex' alignItems={'flex-end'} >
                <Typography component='h6' fontWeight={600} fontSize={18}>
                  {tier}
                </Typography>
              </Box>

              <Box display='flex' alignItems={'flex-end'} mb={1}>
                <Typography component='h6' fontWeight={600} fontSize={30}>
                  ${`${variantPrice[tier.toLowerCase()]}`}
                </Typography>
                <Typography component='h6' fontWeight={600} fontSize={14} pl={0.5} pb={0.75}>
                  USD/Month
                </Typography>
              </Box>

              {Object.entries(upgradeFeatures).map(([key, value], ind) => {
                return (
                  <Box
                    key={`upgrade-feat-${ind}`}
                    display='flex'
                    alignItems='center'
                    sx={{
                      cursor: !!value.length ? 'pointer' : 'auto',
                      mt: 0.5,
                    }}
                    onClick={() => {
                      if (value.length) {
                        openFeatModal(key, value)
                      }
                    }}
                  >
                    <CheckIcon fontSize="small"/>
                    <Typography
                      component='h6'
                      fontSize={14}
                      sx={{
                        ml: 1,
                      }}
                      fontWeight={boldArr.includes(key) ? 600 : 400}
                    >
                      {`${key}`}
                    </Typography>
                    {/* {
                      !!value.length &&
                        <InfoIcon
                          style={{
                            fill: 'gray',
                            marginLeft: 'auto',
                          }}
                        />
                    } */}
                  </Box>
                )
              })}
            </CardContent>

            {/* <CardActions>
              <Typography component='body' textAlign='center' fontSize={14} fontStyle='italic'>
                *coming soon
              </Typography>
            </CardActions> */}
          </Card>
        </Grid>

        <Grid size={{xs: 12, md: 6}} >
          <Box display='flex' alignItems={'flex-end'} mb={1} mt={{xs: 2, md: 0}}>
            <Typography variant='h5'>
              {title}
            </Typography>
          </Box>

          <Box display='flex' alignItems={'flex-end'} mb={2}>
            <Typography variant='body2' >
              {description}
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='column'
            gap={1.5}
          >
            <Button
              variant='contained'
              size='large'
              onClick={() => handlePurchaseButton(tier.toLowerCase())}
              sx={{
                width: 'fit-content',
                boxShadow: (theme) => `${theme.shadows[2]} !important`,
              }}
            >
              {`Upgrade to ${tier}`}
            </Button>

            <Button
              variant='outlined'
              size='large'
              onClick={() => setUpgradeFeature('justOpen')}
              sx={{
                width: 'fit-content',
                boxShadow: (theme) => `${theme.shadows[2]} !important`,
              }}
            >
              See All Plans
            </Button>
          </Box>
        </Grid>

        <StripeCheckoutForm
          item={stripeItem}
          isOpen={stripeOpen}
          setIsOpen={(input:boolean) => {
            if (!input) {
              setStripeItem(null)
              setTrialPeriod(null)
            }
            setStripeOpen(input)
          }}
          trialPeriod={trialPeriod}
          redirectURL={location.pathname}
        />
        <FeatureDialog />
      </Grid>
    )
  }

  return (
    (upgradeFeature && upgradeFeature !== 'justOpen') ? <UpgradeTierCard /> :
    <Box
      display='flex'
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      width={'100%'}
      height={'100%'}
      gap={3}
    >
      <Typography variant='h5'>
        {
          !currentTier ?
          'Choose a plan to scale your knowledge' :
          'Manage My Plan'
        }
      </Typography>

      <Grid container rowSpacing={2} columnSpacing={3} alignItems='stretch'>
        {
          ALL_TIERS.map((value) => {
            return (
              <TiersCardItem
                {...value}
                currentTier={currentTier}
                recommendedTier={recommendedTier}
                handlePurchaseButton={handlePurchaseButton}
                openFeatModal={openFeatModal}
                isEmbedded={isEmbedded}
              />
            )
          })
        }
      </Grid>

      <StripeCheckoutForm
        item={stripeItem}
        isOpen={stripeOpen}
        setIsOpen={(input:boolean) => {
          if (!input) {
            setStripeItem(null)
            setTrialPeriod(null)
          }
          setStripeOpen(input)
        }}
        trialPeriod={trialPeriod}
        redirectURL={location.pathname}
      />

      <FeatureDialog />
    </Box>
  )
}

export default TiersCard;
