


import { SVGProps, memo } from 'react';
const PurchasesIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.791992 7.91675H18.2087M2.37533 3.16675H16.6253C17.4998 3.16675 18.2087 3.87563 18.2087 4.75008V14.2501C18.2087 15.1245 17.4998 15.8334 16.6253 15.8334H2.37533C1.50087 15.8334 0.791992 15.1245 0.791992 14.2501V4.75008C0.791992 3.87563 1.50087 3.16675 2.37533 3.16675Z" stroke={props?.color || "#1E1E1E"} strokeWidth="1.58333" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default memo(PurchasesIcon)
