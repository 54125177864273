import { useState, useEffect } from 'react';

function useDeviceType() {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [isMouseDevice, setIsMouseDevice] = useState(false);
  
    useEffect(() => {
      const checkTouchSupport = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
      };
  
      const checkMouseSupport = () => {
        return (
          window.matchMedia('(pointer: fine)').matches ||
          window.matchMedia('(any-pointer: fine)').matches ||
          'onmousemove' in window
        );
      };
  
      setIsTouchDevice(checkTouchSupport());
      setIsMouseDevice(checkMouseSupport());
  
      const handleMouseMove = () => setIsMouseDevice(true);
      window.addEventListener('mousemove', handleMouseMove, { once: true });
  
      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);
  
    return { isTouchDevice, isMouseDevice };
  }

  export default useDeviceType;
  