import { SVGProps, memo } from 'react';
const ChatIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width="36" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 0C3.58172 0 0 3.58172 0 8V20C0 24.4183 3.58172 28 8 28H23.381L31.8568 35.1197C32.5074 35.6662 33.5 35.2037 33.5 34.354V27.9846C37.6854 27.7265 41 24.2504 41 20V8C41 3.58172 37.4183 0 33 0H8Z" fill="url(#paint0_linear_10009_134128)"/>
    <path d="M22.7557 9.1268C22.8126 8.86411 23.1874 8.86411 23.2443 9.12681L24.3914 14.4172C24.4122 14.513 24.487 14.5878 24.5828 14.6086L29.8732 15.7557C30.1359 15.8126 30.1359 16.1874 29.8732 16.2443L24.5828 17.3914C24.487 17.4122 24.4122 17.487 24.3914 17.5828L23.2443 22.8732C23.1874 23.1359 22.8126 23.1359 22.7557 22.8732L21.6086 17.5828C21.5878 17.487 21.513 17.4122 21.4172 17.3914L16.1268 16.2443C15.8641 16.1874 15.8641 15.8126 16.1268 15.7557L21.4172 14.6086C21.513 14.5878 21.5878 14.513 21.6086 14.4172L22.7557 9.1268Z" fill="white"/>
    <path d="M14.2557 5.12681C14.3126 4.86411 14.6874 4.86411 14.7443 5.12681L15.446 8.3627C15.4667 8.45847 15.5415 8.53328 15.6373 8.55405L18.8732 9.25568C19.1359 9.31264 19.1359 9.68736 18.8732 9.74432L15.6373 10.446C15.5415 10.4667 15.4667 10.5415 15.446 10.6373L14.7443 13.8732C14.6874 14.1359 14.3126 14.1359 14.2557 13.8732L13.554 10.6373C13.5333 10.5415 13.4585 10.4667 13.3627 10.446L10.1268 9.74432C9.86411 9.68736 9.86411 9.31264 10.1268 9.25568L13.3627 8.55405C13.4585 8.53328 13.5333 8.45847 13.554 8.3627L14.2557 5.12681Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_10009_134128" x1="7.5" y1="-6.5" x2="36.5" y2="46" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FFD703"/>
    <stop offset="0.29" stopColor="#FF766B"/>
    <stop offset="0.66" stopColor="#FF2FB8"/>
    <stop offset="0.98" stopColor="#147BB5"/>
    </linearGradient>
    </defs>
    </svg>
);

export default memo(ChatIcon);
