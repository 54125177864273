import React from 'react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { Message } from '@chatscope/chat-ui-kit-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { MessageDataType } from './messages.types';
import { UserFormType } from '../creatorOnBoarding/signup.types';
import './chat-custom.css';
import { USER_TYPE } from '../../config/const';
import { preprocessLaTeX, removeHTML } from '../../utils/helper';
import Tooltip from '@mui/material/Tooltip';
import CustomImageList from './imageList';
import './messageItem.css';
import Markdown from './Markdown';
import { useTheme } from '@mui/material';
import LikeIcon2 from '../../svg/LikeIcon2';
import DisLikeIcon2 from '../../svg/DislikeIcon2';
import ShareIcon2 from '../../svg/ShareIcon2';
import ChatsIcon from '../../svg/ChatsIcon';

export interface MessageItemProps {
  messageDataItem: MessageDataType;
  authUserData: UserFormType | null;
  creatorUserData: UserFormType | null;
  indexKey: string;
  handleLikeDislikeMessage: (documentId: string, value: number) => void;
  showActionMenu: HTMLElement;
  setShowActionMenu: (value: Record<any, any>) => void;
  handleModal: 'edit' | 'feedback' | null;
  setHandleModal: (value: 'edit' | 'feedback' | null) => void;
  formData: Record<any, any> | null;
  setFormData: (value: Record<any, any> | null) => void;
  userName: string;
  handleShareButtonClick: (selectMessage: any) => void;
  showSelectMessages: boolean;
  setShowSelectMessages: (value: boolean) => void;
  resetMessageSelection: () => void;
  isAiTyping?: boolean;
  citations?: { source: string; source_id: string }[];
  isSelfChat: boolean;
  isCourseChat?: boolean;
  scrollToBottom?: () => void;
  isWidget?: boolean;
}

const MessageItem: React.FC<MessageItemProps> = (props) => {
  const {
    authUserData,
    messageDataItem,
    handleLikeDislikeMessage,
    showActionMenu,
    setHandleModal,
    formData,
    setFormData,
    userName,
    handleShareButtonClick,
    showSelectMessages,
    setShowSelectMessages,
    resetMessageSelection,
    isSelfChat,
    isCourseChat,
    scrollToBottom,
    isWidget = false,
  } = props;
  const {
    direction,
    documentId,
    likeDislike,
    message,
    sentTime,
    senderId,
    editedMessage,
    feedback,
    images,
    image_url,
    image_urls,
  } = messageDataItem;
  const {
    userType: authUserType,
    normalizedUserName: normalizedAuthUserName,
  } = authUserData || {};

  const [openTooltip, setOpenTooltip] = React.useState({
    index: null,
    open: false,
  });

  const handleCitationClick = (index) => {
    setOpenTooltip({ index, open: !openTooltip.open });
  };

  const uniqueCitations = React.useMemo(() => {
    if (!messageDataItem.citations) return [];
    const seen = new Set();
    return messageDataItem.citations.filter((citation) => {
      const duplicate = seen.has(citation.source_id);
      seen.add(citation.source_id);
      return !duplicate;
    });
  }, [messageDataItem.citations]);

  const theme = useTheme();

  return (
    <Box
      sx={[
        {
          width: '100%',
          display: 'flex',
          justifyContent: direction === 'incoming' ? 'flex-start' : 'flex-end',
          overflowY: 'auto',
          marginTop: '8px',
          marginBottom: '8px',
        },
        isWidget && {
          padding: '0px 10px 10px 10px',
        }
      ]
    }
    >
      {/* {!isWidget && direction === 'incoming' ? (
        <Avatar
          src={creatorProfilePhoto}
          alt={creatorUserData.fullName}
          sx={{ m: 1, width: 32, height: 32, fontSize: '1rem' }}
          slotProps={{
            img: {
              loading: 'lazy',
            },
          }}
        >
          {creatorUserData.fullName ? creatorUserData.fullName[0] : ''}
        </Avatar>
      ) : (
        <></>
      )} */}
      <Message
        model={{
          message: message,
          sentTime: sentTime.toString(),
          sender: senderId,
          direction: direction,
          position: 'single',
        }}
      >
        <Message.CustomContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <Markdown className={`markdown ${direction}`}>
              {preprocessLaTeX(editedMessage) || preprocessLaTeX(message)}
            </Markdown>

            <CustomImageList
              image_url={image_url}
              documentId={documentId}
              images={images}
              image_urls={image_urls}
              scrollToBottom={scrollToBottom}
            />

            <Box>
              {isSelfChat && uniqueCitations.length > 0 && (
                <div>
                  {uniqueCitations.map((citation, index) => (
                    <Tooltip
                      key={index}
                      open={openTooltip.index === index && openTooltip.open}
                      onClose={() => {
                        setTimeout(
                          () => setOpenTooltip({ index: null, open: false }),
                          200
                        );
                      }}
                      title={
                        <span style={{ fontSize: '0.8rem', padding: '4px' }}>
                          {isURL(citation.source_id) ? (
                            <a
                              href={citation.source_id}
                              target='_blank'
                              rel='noopener noreferrer'
                              style={{
                                color: 'white',
                                textDecoration: 'underline',
                              }}
                            >
                              {citation.source_id}
                            </a>
                          ) : (
                            citation.source_id
                          )}
                        </span>
                      }
                      placement='top'
                      arrow
                    >
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCitationClick(index);
                        }}
                        style={{
                          backgroundColor:
                            openTooltip.index === index && openTooltip.open
                              ? '#666'
                              : '#f0f0f0',
                          color:
                            openTooltip.index === index && openTooltip.open
                              ? '#fff'
                              : 'black',
                          fontSize: '12px',
                          minWidth: '30px',
                          width: '30px',
                          margin: '0 5px',
                          height: '20px',
                          transition: 'all 0.3s',
                        }}
                      >
                        {index + 1}
                      </Button>
                    </Tooltip>
                  ))}
                </div>
              )}
            </Box>
            {normalizedAuthUserName === userName &&
              authUserType === USER_TYPE.CREATOR &&
              direction === 'incoming' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {editedMessage ? (
                    <Typography
                      sx={{
                        display: 'flex',
                        // alignSelf: 'flex-end',
                        fontSize: '0.8rem',
                        color: '#A8A8A8',
                        mr: 1,
                      }}
                    >
                      Edited
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {!messageDataItem.isAiTyping && !isCourseChat && (
                    <Button
                      id={`button-edit-message-${documentId}`}
                      sx={{
                        display: 'flex',
                        alignSelf: 'flex-end',
                        height: '30px',
                        minWidth: '56px',
                        width: '56px',
                        fontSize: '0.8rem',
                        background: 'transparent',
                        border: '1px solid',
                        borderColor: (theme) => theme.palette.text.primary,
                        borderRadius: '15px',
                        color: (theme) => theme.palette.text.primary,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      size='small'
                      onClick={() => {
                        setFormData({
                          documentId,
                          message: removeHTML(editedMessage || message),
                        });
                        setHandleModal('edit');
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              )}
          </Box>
        </Message.CustomContent>
        {!messageDataItem.isAiTyping && (
          <Message.Footer
            sentTime='just now'
            style={{
              paddingLeft: isWidget ? '0.6em' : '0px',
              columnGap: '6px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              // marginTop: '3px',
            }}
          >
            {direction === 'incoming' && (
              <Box
                gap={isWidget ? 0.3 : 0.6}
                sx={{
                  display: 'flex',
                }}
              >
                <IconButton
                  id={`button-like-message-${documentId}`}
                  sx={{
                    padding: '4px',
                  }}
                  onClick={() => handleLikeDislikeMessage(documentId, 1)}
                >
                  <LikeIcon2
                    height='20px'
                    width='20px'
                    stroke={theme.palette.action.active}
                    fill={
                      likeDislike === 1 ? theme.palette.action.focus : 'none'
                    }
                  />
                </IconButton>
                <IconButton
                  id={`button-dislike-message-${documentId}`}
                  sx={{
                    padding: '4px',
                  }}
                  onClick={() => handleLikeDislikeMessage(documentId, 2)}
                >
                  <DisLikeIcon2
                    height='20px'
                    width='20px'
                    stroke={theme.palette.action.active}
                    fill={
                      likeDislike === 2 ? theme.palette.action.focus : 'none'
                    }
                  />
                </IconButton>
                <IconButton
                  id={`button-toggle-menu-message-${documentId}`}
                  sx={{
                    padding: '4px',
                  }}
                  aria-controls={
                    Boolean(
                      showActionMenu && formData?.documentId === documentId
                    )
                      ? 'long-menu'
                      : undefined
                  }
                  aria-expanded={
                    Boolean(
                      showActionMenu && formData?.documentId === documentId
                    )
                      ? 'true'
                      : undefined
                  }
                  onClick={() => {
                    setFormData({ documentId, feedback });
                    setHandleModal('feedback');
                  }}
                  aria-haspopup='true'
                >
                  <ChatsIcon
                    stroke={
                      Boolean(
                        showActionMenu && formData?.documentId === documentId
                      )
                        ? theme.palette.action.active
                        : theme.palette.action.disabled
                    }
                    height='20px'
                    width='20px'
                  />
                </IconButton>
                {!isWidget && (
                  <IconButton
                    id={`button-share-message-${documentId}`}
                    sx={{
                      padding: '4px',
                    }}
                    onClick={() => {
                      if (showSelectMessages) {
                        resetMessageSelection();
                        return;
                      }
                      setShowSelectMessages(!showSelectMessages);
                      handleShareButtonClick(messageDataItem);
                    }}
                  >
                    <ShareIcon2
                      height='20px'
                      width='20px'
                      stroke={theme.palette.action.active}
                    />
                  </IconButton>
                )}
              </Box>
            )}
          </Message.Footer>
        )}
      </Message>
    </Box>
  );
};

export default React.memo(MessageItem);

const isURL = (str: string) => {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
};
