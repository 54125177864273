

import { SVGProps, memo } from 'react';
const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M15.5455 1.51398H3.45455C2.40418 1.51398 1.55269 2.34424 1.55269 3.36842V12.6316C1.55269 13.6558 2.40418 14.486 3.45455 14.486H15.5455C16.5958 14.486 17.4473 13.6558 17.4473 12.6316V3.36842C17.4473 2.34424 16.5958 1.51398 15.5455 1.51398ZM3.45455 0C1.54665 0 0 1.50809 0 3.36842V12.6316C0 14.4919 1.54665 16 3.45455 16H15.5455C17.4533 16 19 14.4919 19 12.6316V3.36842C19 1.50809 17.4533 0 15.5455 0H3.45455Z" fill={props?.color || "#424242"}/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.6389 4.49395C15.9553 4.77679 15.9767 5.25621 15.6866 5.56476L10.9366 10.6174C10.6791 10.8913 10.2558 10.9428 9.93719 10.7389L6.61327 8.61199L4.0365 11.4498C3.75193 11.7632 3.2607 11.7923 2.93929 11.5148C2.61789 11.2373 2.58803 10.7584 2.87259 10.445L5.89532 7.11605C6.15134 6.8341 6.58083 6.77821 6.90372 6.98482L10.2374 9.11796L14.5407 4.5405C14.8307 4.23195 15.3224 4.21111 15.6389 4.49395Z" fill={props?.color || "#424242"}/>
</svg>

);

export default memo(DashboardIcon)
